import { v4 as uuidv4 } from "uuid";
import {
  Control,
  DropdownOption,
  GradingForm,
  Section,
  SubSection,
} from "../types/gradingForm";
import {
  ColumnMapControls,
  ColumnMapSection,
  ColumnMapSubsections,
  getGradingReportColumns,
} from "./GradingReportTemplate";

export type GradingOption = DropdownOption & {
  style?: { color?: string; background?: string };
};

const green = "#99cb01";
const orange = "#ff9a00";
const red = "#cc3333";
const white = "#ffffff";

export const notApplicable: GradingOption = { caption: "N" };
export const unableToInspect: GradingOption = { caption: "U" };
export const missing: GradingOption = {
  caption: "M",
  style: { background: red, color: white },
};
export const wrong: GradingOption = {
  caption: "W",
  style: { background: red, color: white },
};

export const num1to4: GradingOption[] = [
  { caption: "1", style: { background: green, color: white } },
  { caption: "2", style: { background: green, color: white } },
  { caption: "3", style: { background: orange, color: white } },
  { caption: "4", style: { background: red, color: white } },
];

export const num1to5: GradingOption[] = [
  { caption: "1", style: { background: green, color: white } },
  { caption: "2", style: { background: green, color: white } },
  { caption: "3", style: { background: orange, color: white } },
  { caption: "4", style: { background: red, color: white } },
  { caption: "5", style: { background: red, color: white } },
];

export const numSidesWithSigns: GradingOption[] = [
  { caption: "0", style: { background: red, color: white } },
  { caption: "1", style: { background: red, color: white } },
  { caption: "2", style: { background: orange, color: white } },
  { caption: "3", style: { background: orange, color: white } },
  { caption: "4", style: { background: green, color: white } },
];

export const spacingType: GradingOption[] = [
  { caption: "D" },
  { caption: "DW" },
  { caption: "B" },
  { caption: "ME" },
  { caption: "A" },
  { caption: "P" },
  { caption: "MO" },
];

export const conductorOptions: GradingOption[] = [
  { caption: "ND", style: { background: green, color: white } },
  { caption: "4", style: { background: red, color: white } },
];

export const poleCondition: GradingOption[] = [
  { caption: "ND", style: { background: green, color: white } },
  { caption: "5", style: { background: red, color: white } },
];

export const poleVerticality: GradingOption[] = [
  { caption: "1", style: { background: green, color: white } },
  { caption: "5", style: { background: red, color: white } },
];

export const checkBoxOptions: GradingOption[] = [
  { caption: "" },
  { caption: "X", style: { background: red, color: white } },
];

export const yesNoInvertedOptions: GradingOption[] = [
  { caption: "Y", style: { background: red, color: white } },
  { caption: "N", style: { background: green, color: white } },
];

export const yesNoOptions: GradingOption[] = [
  { caption: "Y", style: { background: green, color: white } },
  { caption: "N", style: { background: red, color: white } },
];

export const yesNoUnstyled: GradingOption[] = [
  { caption: "Y" },
  { caption: "N" },
];

export const towerTypeOptions: DropdownOption[] = [
  { caption: "T" },
  { caption: "S" },
];

const createDropdown = (
  caption: string,
  options: DropdownOption[]
): Control => {
  return {
    caption,
    type: "dropdown",
    options: JSON.parse(JSON.stringify(options)),
  };
};

const createControlsSection = (
  caption: string,
  controls: Control[]
): Section => {
  return {
    id: uuidv4(),
    caption,
    sectionJson: { controls },
  };
};

const createSubSection = (caption: string, controls: Control[]): SubSection => {
  return { caption, controls, id: uuidv4() };
};
const createSubSections = (
  caption: string,
  subsections: SubSection[]
): Section => {
  return {
    id: uuidv4(),
    caption,
    sectionJson: { subsections },
  };
};

export const createGradingForm = (
  packageId: string,
  assetContainerId: string
): GradingForm => {
  const reportColumns = getGradingReportColumns([], []);

  const mapControls = (map: ColumnMapControls): Control[] =>
    Object.entries(map.controls).map(([name, control]) =>
      createDropdown(name, control.options || [])
    );

  const mapSubSections = (map: ColumnMapSubsections): SubSection[] =>
    Object.entries(map.subsections)
      // Skip the Circuit Identifier section layer for the grading form.
      .flatMap(([name, subsection]): [string, ColumnMapSection][] => {
        if (subsection.type === "controls") return [[name, subsection]];
        return Object.entries(subsection.subsections);
      })
      // Ignore sub-sub-sections.
      .filter(([name, subsection]) => subsection.type === "controls")
      .map(([name, subsection]) => {
        if (subsection.type !== "controls") throw new Error("unreachable");
        return createSubSection(name, mapControls(subsection));
      });

  const sections = Object.entries(reportColumns).map(([name, map]) => {
    switch (map.type) {
      case "controls":
        return createControlsSection(name, mapControls(map));
      case "subsections":
        return createSubSections(name, mapSubSections(map));
      default:
        throw new Error("unknown section type");
    }
  });

  return {
    id: uuidv4(),
    packageId,
    assetContainerId,
    sections: sections,
  };
};
