import { makeStyles } from "@material-ui/core";

export const useDefectsToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: "10px",
    borderBottom: `1px solid ${theme.border.primary}`,
  },
  header: {
    color: theme.palette.primary.main,
    fontFamily: theme.typography.fontFamily,
    textTransform: "uppercase",
    fontWeight: 700,
  },
  list: {
    display: "flex",
  },
  listItem: {
    padding: "8px 4px !important",
    "&:first-of-type": {
      paddingLeft: "0 !important",
    },
    "&:last-of-type": {
      paddingRight: "0 !important",
      marginLeft: "auto",
    },
  },
  button: {
    border: `1px solid ${theme.border.primary}`,
    borderRadius: 0,
    "&:hover, &.active": {
      border: `1px solid ${theme.palette.primary.main}`,

      "& svg": {
        color: theme.palette.primary.main,
      },
    },
  },
  icon: {
    color: theme.palette.text.primary,
  },
  warning: {
    display: "flex",
    flexDirection: "row",
    minHeight: "100px",
    marginTop: "10px",
  },
  warningText: {
    marginTop: "3px",
    marginLeft: "3px",
  },
}));
