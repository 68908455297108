import { IJobRendererApi } from "@pulsion/forms-designer-2";
import {
  getControlAttachment, getJobData, getJobFormData,
  getTemplateOnlySections, saveRow, updateAttachmentMetadata, updateSectionData, uploadAttachment
} from "./formDesigner/JobFormService";
  
  const notImplemented = async (data: any) => {
    return null;
  };

  export const jobRendererService: IJobRendererApi = {
    getFormData: getJobFormData, //Gets a list of properties taken from the section data, including the JSON data
    addRepeatableSection: notImplemented, //Used in Velappity with repeatable, template and grid sections
    deleteCopyOfRepeatableSection: notImplemented,
    setAllowChanges: async (data: any) => {
      return true;
    },
    getFormListData: async () => { //Get job types
        return true;
    },
    getTemplateOnlySections,
    updateSectionData,  //Update a section in the database after it is changed
    createRow: notImplemented,
    deactivateRow: notImplemented,
    setMustBeComplete: async (data: any) => {
      return false;
    },
    getJobData,
    getControlAttachment,
    updateAttachmentMetadata,
    uploadAttachment,
    saveRow, //Saves JSON data corresponding to a new section (used in Velappity with grid sections, which are initially generated but not saved by the addRepeatableSection endpoint)
  };

