import { CancelToken } from "axios";
import axios from "../libs/axios/axios-instance";
import { DefectTypeData } from "../types/documents";

const baseUrl = process.env.REACT_APP_DEFECT_TYPES_ENDPOINT;
const axiosInstance = axios(baseUrl ?? "");

export const getDefectType = async (
  defectTypeId: string,
  cancelToken: CancelToken
) => {
  try {
    const config = { cancelToken };
    const urlPath = `/defect-types/${defectTypeId}/full`;
    const { data } = await axiosInstance.get<DefectTypeData[]>(urlPath, config);
    return data;
  } catch (e: any) {
    console.log(e);
    throw e.message ? e.message : e;
  }
};

export const getDefectTypes = async (
  cancelToken: CancelToken,
  flightId?: string
) => {
  try {
    const config = { cancelToken };

    let urlPath = `/defect-types/search`;
    if (flightId) urlPath += `/${flightId}`;

    const { data } = await axiosInstance.get<DefectTypeData[]>(urlPath, config);

    return data;
  } catch (e: any) {
    console.log(e);
    throw e.message ? e.message : e;
  }
};
