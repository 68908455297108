import { Geometry } from "geojson";
import { Mode } from "../../../components/general/types/Modify";
import { MAP_FEATURES_ENDPOINT } from "../../../libs/config";
import { RouteProps } from "../../../types";
import { PointOfInterestData } from "../../../types/documents";
import { ModifyContainer } from "../../general";
import {
  configInputs,
  configSubmit,
  constraints,
  initialData,
} from "./resources";

const putEndpoint = `${MAP_FEATURES_ENDPOINT}/point-of-interest/submit`;
const queryEndpoint = `${MAP_FEATURES_ENDPOINT}/point-of-interest`;

export interface Props extends RouteProps {
  mode: Mode;
  geometry: Geometry;
  id: string;
  onSuccess: (id: string, data: PointOfInterestData) => void;
}
type Component = (props: Props) => JSX.Element;

export const PointOfInterestModify: Component = ({
  mode,
  geometry,
  id,
  onSuccess,
  ...routeProps
}) => {
  return (
    <ModifyContainer<PointOfInterestData>
      {...routeProps}
      putEndpoint={putEndpoint}
      queryEndpoint={queryEndpoint}
      mode={mode}
      initialData={initialData(geometry)}
      manualQueryId={id}
      componentConfiguration={[configInputs(mode), configSubmit]}
      constraints={constraints}
      redirectPath={""}
      onSuccess={onSuccess}
      isDialog
    />
  );
};
