import {useEffect} from "react";
import {HooksLogger} from "../hooks/hooks-logger";

const logger = new HooksLogger("InterceptFetch");

const InterceptFetch = () => {

  useEffect(() => {
    if (!window.chrome?.webview) return ;
    const awaitController: () => Promise<ServiceWorker> = () => {
      if (navigator.serviceWorker.controller !== null) {
        return Promise.resolve(navigator.serviceWorker.controller);
      }
      return new Promise((resolve, reject) => {
        const handler = () => {
          if (navigator.serviceWorker.controller === null) return;
          navigator.serviceWorker.removeEventListener('controllerchange', handler);
          resolve(navigator.serviceWorker.controller);
        };
        navigator.serviceWorker.addEventListener('controllerchange', handler);
      });
    };

    const init = async () => {
      try {
        const existingRegistrations = await navigator.serviceWorker.getRegistrations();
        for (const registration of existingRegistrations) {
          await registration.unregister();
        }
        const registration = await navigator.serviceWorker.register('/serviceWorker.js');
        logger.info('Service worker registration succeeded:', registration);

        const controller = await awaitController();

        const messageChannel = new MessageChannel();
        controller.postMessage({type: 'PORT_INITIALIZATION'}, [messageChannel.port2]);
        messageChannel.port1.onmessage = (event) => {
          logger.request('request', {event});
          window.chrome.webview.postMessage(event.data);
        };

        window.chrome.webview.addEventListener('message', event => {
          logger.success('response', {event});
          messageChannel.port1.postMessage(event.data);
        });
      } catch (error) {
        logger.error('Service worker registration failed:', error);
      }
    };
    init();
  }, []);

  return <></>;
}

declare global {
  interface WebView2 {
    addEventListener(event: string, handler: (message: MessageEvent) => void): void;

    postMessage(data: any): void;
  }

  interface Chrome {
    webview: WebView2
  }

  interface Window {
    chrome: Chrome
  }
}

export default InterceptFetch;
