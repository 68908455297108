import {
  Box,
  Button,
  ButtonProps,
  Paper,
  Table as TableComponent,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import React, {
  Dispatch,
  MouseEventHandler,
  SetStateAction,
  useEffect,
  useMemo,
} from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Filters,
  Row,
  SortingRule,
  TableOptions,
  useFilters,
  useFlexLayout,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { Loader } from "..";
import { ExportData } from "../../../containers/gradingReport/GradingReportList";
import { Permissions, UserAccess } from "../../../types";
import { ShowIfAuthorised } from "../../authentication";
import BreadcrumbHeader from "../BreadcrumbHeader";
import { useStyles } from "./style";
import { TablePaginationActions } from "./TablePaginationActions";

/*
  The react table docs are useless when it comes to typescript

  Some useful resources:
    - Type declarations: 
        https://github.com/DefinitelyTyped/DefinitelyTyped/tree/master/types/react-table
    - For future projects, pay particular attention to the configuration 
      section of the readme in the above repo
    - React table example written in TypeScript from one of the React Table maintainers
        https://github.com/ggascoigne/react-table-example
*/

type TableEvent = React.MouseEvent<HTMLButtonElement, MouseEvent> | null;

type CreateButtonConfig = {
  label: React.ReactNode;
  userAccess: UserAccess;
  disabled?: boolean;
} & ({ path: string } | { handler: MouseEventHandler });

type TFinalColumnButtonConfig<T extends object> = {
  label: string;
  handler: (event: React.MouseEvent<HTMLButtonElement>, row: Row<T>) => void;
} & ButtonProps;

interface Props<D extends Record<string, unknown>> extends TableOptions<D> {
  header?: string | null;
  altHeaderStyle?: boolean;
  totalCount?: number;
  size?: number;
  rowsPerPageOptions?: number[];
  createButtonConfig?: CreateButtonConfig | CreateButtonConfig[];
  finalColumnButtonConfig?: (
    defaultBtn: TFinalColumnButtonConfig<D>
  ) => TFinalColumnButtonConfig<D>[];
  permissions: Permissions | null;
  loading?: boolean;
  loadProgress?: number;
  clickHandler?: (data: D) => void;
  clickHandlerTitle?: string;
  onSearchParameterChange?: (
    page: number,
    pageSize: number,
    sort: SortingRule<D>[]
  ) => void;
  children?: JSX.Element | Array<JSX.Element>;
  viewButtonText?: string;
  extraFinalColumnComponent?: (row: Row<D>, className: string) => JSX.Element;
  hidePagination?: boolean;
  localFilters?: Filters<D>;
  hiddenColumns?: string[];
  sortBy?: SortingRule<D>[];
  hideSortIndicator?: boolean;
  setExportData?: Dispatch<SetStateAction<ExportData | undefined>>;
}

interface TButtonProps extends ButtonProps {
  to?: string;
  component?: any;
}

export const Table = <T extends Record<string, unknown>>({
  header,
  altHeaderStyle,
  data: tableData,
  columns: tableColumns,
  totalCount = tableData.length,
  size = 25,
  rowsPerPageOptions = [],
  createButtonConfig,
  finalColumnButtonConfig,
  permissions,
  loading,
  loadProgress,
  clickHandler,
  manualPagination,
  onSearchParameterChange = () => {},
  rowHighlightStyling = true,
  children,
  viewButtonText = "View",
  extraFinalColumnComponent,
  hidePagination,
  hideSortIndicator,
  theme,
  getCellStyle = () => {},
  getHeaderCellStyle = () => {},
  useFlexTable = true,
  localFilters,
  hiddenColumns = [],
  sortBy: _sortBy = [],
  setExportData,
}: React.PropsWithChildren<Props<T>>): JSX.Element => {
  const data = useMemo(() => tableData, [tableData]);
  const columns = useMemo(() => tableColumns, [tableColumns]);
  const pageCount = useMemo(
    () => Math.ceil(totalCount / size),
    [totalCount, size]
  );

  const hooks = [
    ...(useFlexTable ? [useFlexLayout] : []),
    ...(localFilters ? [useFilters] : []),
    useSortBy,
    usePagination,
  ];
  const defaultTheme = useStyles();
  const classes = theme ?? defaultTheme;

  const instance = useTable<T>(
    {
      columns,
      data,
      initialState: {
        pageSize: size,
        pageIndex: 0,
        hiddenColumns,
        sortBy: _sortBy,
      },
      manualPagination,
      manualSortBy: manualPagination,
      pageCount,
      // this flag prevents weird behaviour when changing page
      // like fetching the next page then re-fetching the previous page immediately
      autoResetPage: false,
    },
    ...hooks
  );

  // NOTE: if a new hook plugin is used, it may be necessary to update
  // `types/react-table-config.d.ts` to get the correct types
  // See `TableOptions` interface
  const {
    // basic table props
    getTableProps,
    getTableBodyProps,
    headerGroups = [],
    prepareRow,
    state: { pageIndex, pageSize: tablePageSize, sortBy },
    // pagination props
    page: pageRows = [],
    gotoPage,
    setPageSize,
    setAllFilters,
    filteredRows,
  } = instance;

  // Return sorted and filtered table data in an exportable format
  useEffect(() => {
    setExportData?.({
      headers: headerGroups.map((column) => column.headers),
      rows: filteredRows
        .map((row) => {
          prepareRow(row);
          return row.cells.map((cell) => cell.value);
        })
        .sort((a, b) => a[0].localeCompare(b[0])),
    });
  }, [headerGroups, filteredRows, prepareRow, setExportData]);

  // If manual pagination is set, we want to forward any changes in table state
  // to trigger a new data fetch
  useEffect(() => {
    if (manualPagination) {
      onSearchParameterChange(pageIndex, tablePageSize, sortBy);
    }
  }, [
    manualPagination,
    pageIndex,
    tablePageSize,
    sortBy,
    onSearchParameterChange,
  ]);

  useEffect(() => {
    if (localFilters) {
      setAllFilters(localFilters);
    }
  }, [localFilters, setAllFilters]);

  const handleChangePage = (_event: TableEvent, newPage: number) => {
    gotoPage(newPage);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    gotoPage(0);
  };

  const renderBreadcrumbHeader = () => {
    const crumbs = [
      {
        name: header ?? "",
      },
    ];
    const renderCreateButton = () => {
      if (!createButtonConfig) return <></>;
      const buttonConfigs = Array.isArray(createButtonConfig)
        ? createButtonConfig
        : [createButtonConfig];

      return (
        <>
          {buttonConfigs.map((createButtonConfig) => {
            const { userAccess, label } = createButtonConfig;

            let buttonProps: TButtonProps = {
              color: "primary",
              variant: "contained",
              className: clsx(classes.button),
            };

            if ("handler" in createButtonConfig) {
              buttonProps.onClick = createButtonConfig.handler;
            } else if ("path" in createButtonConfig) {
              buttonProps.component = RouterLink;
              buttonProps.to = createButtonConfig.path;
            }

            return (
              <ShowIfAuthorised
                key={label?.toString()}
                userPermissions={permissions}
                {...userAccess}
              >
                <Button {...buttonProps}>{label}</Button>
              </ShowIfAuthorised>
            );
          })}
        </>
      );
    };

    if (header)
      return (
        <BreadcrumbHeader
          crumbs={crumbs}
          className={clsx(
            altHeaderStyle && classes.breadcrumb,
            !header && classes.justButtonHeader
          )}
          crumbProps={{
            ...(altHeaderStyle && { className: classes.headerCrumb }),
          }}
        >
          {renderCreateButton()}
        </BreadcrumbHeader>
      );
  };

  const renderSearchBar = (): JSX.Element | JSX.Element[] =>
    children ? (
      <TableRow>
        <TableCell
          className={clsx(classes.searchBar)}
          colSpan={headerGroups[0].headers.length}
        >
          {children}
        </TableCell>
      </TableRow>
    ) : (
      <></>
    );

  const renderFinalColumnButtons = (row: Row<T>) => {
    // const defaultButton = (
    //   <Button
    //     variant="outlined"
    //     onClick={clickHandler && (() => clickHandler(row.original))}
    //     size="small"
    //     className={clsx(classes.boldButton)}
    //     aria-label="view"
    //   >
    //     {viewButtonText}
    //   </Button>
    // );

    const commonProps: ButtonProps = {
      size: "small",
      variant: "outlined",
    };

    const defaultButtonConfig: TFinalColumnButtonConfig<T> = {
      handler: clickHandler
        ? (_event, { original }) => clickHandler(original)
        : (_event, _row) => {},
      "aria-label": "view",
      label: viewButtonText,
    };

    const finalButtonConfig = finalColumnButtonConfig
      ? finalColumnButtonConfig(defaultButtonConfig)
      : [defaultButtonConfig];

    let buttonElements: JSX.Element[] = [];

    for (let btn of finalButtonConfig) {
      const { label, handler, ...props } = btn;
      const onClick = (e: React.MouseEvent<HTMLButtonElement>) =>
        handler(e, row);
      const buttonElement = (
        <Button
          {...props}
          {...commonProps}
          className={clsx(btn.className, classes.boldButton)}
          onClick={onClick}
          key={label}
        >
          {label}
        </Button>
      );
      buttonElements.push(buttonElement);
    }

    return <>{buttonElements}</>;
  };

  const renderTable = () => {
    return (
      <>
        <TableContainer component={Paper} className={clsx(classes.table)}>
          <TableComponent {...getTableProps()}>
            <TableHead className={classes.tableHead}>
              {children && renderSearchBar()}
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <TableCell
                      className={clsx(
                        classes.titleRow,
                        classes.tableCell,
                        column.centered && classes.centered
                      )}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      style={getHeaderCellStyle(column)}
                    >
                      <TableSortLabel
                        hideSortIcon={!column.canSort || hideSortIndicator}
                        active={column.isSorted && !hideSortIndicator}
                        direction={column.isSortedDesc ? "desc" : "asc"}
                      >
                        <div>{column.render("Header")}</div>
                      </TableSortLabel>
                    </TableCell>
                  ))}
                  {clickHandler && (
                    <TableCell
                      className={clsx(
                        classes.tableCell,
                        classes.titleRow,
                        classes.buttonColumn
                      )}
                    ></TableCell>
                  )}
                </TableRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {loading ? (
                <TableRow>
                  <TableCell>
                    <Loader
                      active={loading}
                      value={loadProgress}
                      inline={true}
                    />
                  </TableCell>
                </TableRow>
              ) : (
                pageRows.map((row) => {
                  prepareRow(row);
                  return (
                    <TableRow
                      {...row.getRowProps()}
                      className={classes.tableRow}
                    >
                      {row.cells.map((cell) => (
                        <TableCell
                          className={clsx(
                            classes.tableCell,
                            cell.column.centered && classes.centered
                          )}
                          style={getCellStyle(cell)}
                          {...cell.getCellProps()}
                        >
                          <Typography noWrap variant="body2">
                            {typeof cell.value === "number" || cell.value
                              ? cell.render("Cell")
                              : "-"}
                          </Typography>
                        </TableCell>
                      ))}
                      {clickHandler && (
                        <TableCell
                          className={clsx(
                            classes.tableCell,
                            classes.centered,
                            classes.buttonColumn
                          )}
                        >
                          {renderFinalColumnButtons(row)}
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </TableComponent>
        </TableContainer>
        {!hidePagination && (
          <TablePagination
            role="navigation"
            component="div"
            page={pageIndex}
            count={totalCount}
            rowsPerPageOptions={rowsPerPageOptions}
            rowsPerPage={tablePageSize}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleRowsPerPageChange}
            SelectProps={{ title: "page options" }}
            backIconButtonProps={{
              classes: {
                root: classes.paginationIcons,
              },
              size: "small",
            }}
            nextIconButtonProps={{
              classes: {
                root: classes.paginationIcons,
              },
              size: "small",
            }}
            labelDisplayedRows={({ from, to, count }) =>
              `${from} to ${to} of ${count}`
            }
            ActionsComponent={TablePaginationActions}
          />
        )}
      </>
    );
  };

  return (
    <Box role="grid" display="flex" flexDirection="column">
      {renderBreadcrumbHeader()}
      {renderTable()}
    </Box>
  );
};
