import { IconButton } from "@material-ui/core";
import { FlightTakeoff } from "@material-ui/icons";
import { useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  createdDateTableCellRenderer,
  updatedDateTableCellRenderer,
} from "../../../components/general/table/DateTableCell";
import { isOfflineApp } from "../../../helpers";
import { useIsAuthorised } from "../../../hooks/authentication";
import { PACKAGES_ENDPOINT } from "../../../libs/config";
import { paths } from "../../../navigation";
import { packageStrings as strings } from "../../../resources/strings";
import { RouteProps } from "../../../types";
import type { PackageData } from "../../../types/documents";
import { TableContainer } from "../../general";

interface Props extends RouteProps {
  projectId: string;
}
type Component = (props: Props) => JSX.Element;

const endpoint = `${PACKAGES_ENDPOINT}/list-packages`;

export const PackageList: Component = ({ jwt, projectId, ...routeProps }) => {
  const history = useHistory();

  const filters = useMemo(() => ({ projectId }), [projectId]);

  const showGradingsButton = useIsAuthorised(
    routeProps.permissions,
    strings.global.entities.gradings,
    strings.global.permissions.create
  );

  const viewPackage = ({ id }: PackageData) => {
    history.push(paths.packages.view(id));
  };
  const viewGradings = ({ id }: PackageData) => {
    history.push(paths.packages.gradingForm(id));
  };

  return (
    <div>
      <TableContainer<PackageData>
        {...routeProps}
        endpoint={endpoint}
        jwt={jwt}
        header={strings.header.packages}
        altHeaderStyle
        tableColumns={[
          {
            id: "name",
            Header: strings.labels.packageName,
            accessor: "name",
          },
          {
            id: "assetsPatrolled",
            Header: strings.labels.assetsPatrolled,
            accessor: "assetsPatrolled",
          },
          {
            id: "assetsFootPatrolled",
            Header: strings.labels.assetsForFootPatrol,
            accessor: "assetsFootPatrolled",
          },
          {
            id: "assetsToPatrol",
            Header: strings.labels.assetsToPatrol,
            accessor: "assetsToPatrol",
          },
          {
            id: "assetsDefective",
            Header: strings.labels.assetsDefective,
            accessor: "assetsDefective",
          },
          {
            id: "createdDate",
            Header: strings.labels.dateCreated,
            accessor: "createdDate",
            Cell: createdDateTableCellRenderer,
          },
          {
            id: "updatedDate",
            Header: strings.labels.lastUpdated,
            accessor: "updatedDate",
            Cell: updatedDateTableCellRenderer,
          },
          {
            id: "flights",
            Header: strings.labels.flights,
            disableSortBy: true,
            accessor: ({ id }) => (
              <IconButton>
                <Link to={paths.packages.viewFlights(id)}>
                  <FlightTakeoff />
                </Link>
              </IconButton>
            ),
          },
        ]}
        createButtonConfig={{
          label: strings.labels.createPackage,
          userAccess: {
            entity: strings.global.entities.packages,
            permission: strings.global.permissions.create,
          },
          path: paths.packages.create(projectId),
        }}
        clickHandler={viewPackage}
        finalColumnButtonConfig={(defaultBtn) => {
          const buttons = [defaultBtn];
          if (!isOfflineApp() && showGradingsButton) {
            buttons.push({
              label: "Gradings",
              handler: (_event, row) => viewGradings(row.original),
            });
          }

          return buttons;
        }}
        filters={filters}
        viewButtonText="View"
        manualPagination={!isOfflineApp()}
      />
    </div>
  );
};
