import { CancelToken } from "axios";
import axios from "../libs/axios/axios-instance";
import { MAP_FEATURES_ENDPOINT } from "../libs/config";
import { MapFeatureGeoJSON } from "../types/documents";

const baseUrl = MAP_FEATURES_ENDPOINT + "/";
const axiosInstance = axios(baseUrl ?? "");

export const getMapFeatureGeoJSON = async (
  cancelToken: CancelToken,
  offsetId: string,
  type: "avoidance-zone" | "point-of-interest"
) => {
  try {
    let body = { offsetId };
    const config = { cancelToken };

    const urlPath = `${type}/get-all`;
    const { data } = await axiosInstance.post<MapFeatureGeoJSON>(
      urlPath,
      body,
      config
    );

    return data;
  } catch (e: any) {
    console.log(e);
    throw e.message ? e.message : e;
  }
};
