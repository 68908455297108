import { ImageList, ImageListItem, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useMemo, useState } from "react";
import { useImageUrls } from "../../../../hooks/images/useImageUrls";
import { ImageMetadata } from "../../../../types/documents/Images";
import { Pagination } from "./Pagination/Pagination";

interface Props {
  onImageClick: (index: number) => void;
  imageMetadata: ImageMetadata[];
  pageSize: number;
}

export const ImageGrid = ({
  onImageClick,
  imageMetadata,
  pageSize = 12,
}: Props) => {
  const classes = useStyles();

  const [page, setSelectedPage] = useState(0);

  const thumbnailMetadata = useMemo(
    () => imageMetadata.slice(page * pageSize, (page + 1) * pageSize),
    [imageMetadata, pageSize, page]
  );

  const urls = useImageUrls({
    type: "thumb",
    imageMetadata: thumbnailMetadata,
  });

  const renderImage = ({ taken, thumbName }: ImageMetadata, index: number) => {
    const date = new Date(taken);

    return (
      <ImageListItem key={thumbName} className={classes.imageListItem}>
        <img
          className={classes.img}
          src={urls[thumbName]}
          alt={thumbName}
          onClick={() => onImageClick(pageSize * page + index)}
        />

        <Typography className={classes.timestamp}>
          {date.toLocaleTimeString()}
          <br />
          {date.toLocaleDateString()}
        </Typography>
      </ImageListItem>
    );
  };

  return (
    <>
      <ImageList rowHeight={140} gap={20} className={classes.container}>
        {thumbnailMetadata.map(renderImage)}
      </ImageList>

      <Pagination
        pageIndex={page}
        count={imageMetadata.length}
        handleChangePage={(_event, newPage) => setSelectedPage(newPage)}
        pageSize={pageSize}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    margin: `${theme.spacing(1)}px !important`,
  },
  imageListItem: {
    width: `170px !important`,
  },
  timestamp: {
    color: "#444444",
    fontSize: "1rem",
    fontWeight: 600,
    lineHeight: 1.25,
  },
  img: {
    objectFit: "cover",
    width: "100%",
    height: "70%",

    "&:hover": {
      cursor: "pointer",
    },
  },
}));
