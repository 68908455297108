import { FeatureCollection } from "geojson";
import { CsvFile, ShapeFile } from "../FileUpload";

export type AssetsFileType = "csv" | "shp";
export type AssetsFile = CsvFile | ShapeFile;

export enum AssetLoadStatus {
  SplittingInput = "splittingInput",
  AwaitingColumnSelection = "awaitingColumnSelection",
  AwaitingTypeMapSelection = "awaitingTypeMapSelection",
  Processing = "processing",
  Succeeded = "succeeded",
  Failed = "failed",
}

export type AssetLoad = {
  id: string;
  clientName: string;
  clientId: string;
  error: string | null;
  fileName: string;
  status: AssetLoadStatus;
  availableColumns: string[] | undefined;
  typeMap: Record<string, string> | undefined;
};

export type CoordinateSystem = "EPSG:4326" | "EPSG:27700" | "EPSG:29903";

export type ColumnSelections = {
  id: string;
  name: string;
  displayName: string;
  assetType: string;
  circuitId: string;
  towerSide: string;
  x: string;
  y: string;
  coordinateSystem: CoordinateSystem;
  transmissionVoltage: string;

  containerName: string;
  containerKeys: string[];
};

export type AssetTypeMap = Record<string, string> & { id: string };

export type AssetGeoJson = {
  data: FeatureCollection;
  offsetId: string;
};

export type AssetContainer = {
  id: string;
  name: string;
  clientId: string;
};

export type AssetType = {
  id: string;
  name: string;
};

export type AssetDetails = {
  name: string;
  assetContainer: AssetContainer;
  assetType: AssetType;
  towerSide: string;
  circuitId: string;
  transmissionVoltage: number;
  properties: object;
};

export type AssetFilter = {
  id?: string;
  name: string;
  clientId: string;
  groupedBy: string;
  selectedGroups: string[];
  createdById?: string;
  updatedById?: string;
  createdDate?: string;
  updatedDate?: string;
};
