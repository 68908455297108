import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  searchButtonPlacement: {
    position: "absolute",
    top: "90px",
    left: "20px",
    zIndex: 9,
  },
  searchPanelPlacement: {
    position: "absolute",
    top: "64px",
    left: "0px",
    zIndex: 9,
    bottom: 0,
    overflow: "hidden auto",
  },

  panel: {
    backgroundColor: theme.backgroundShades.white,
    padding: "0px",
    height: "max-content",
    minHeight: "400px",
    maxHeight: "100%",
  },
  minPanel: {
    maxWidth: "256px",
  },
  maxPanel: {
    maxWidth: "500px",
  },
  minPanelIcon: {
    transform: "rotateY(180deg)",
  },
  header: {
    backgroundColor: theme.backgroundShades.grey,
    borderBottom: `1px solid ${theme.border.primary}`,
    padding: "10px",
  },
  form: {
    padding: "10px",
  },
  dropdown: {
    width: "100%",
    marginTop: theme.spacing(4, "!important"),

    "& .MuiSelect-root": {
      fontSize: "1.1rem",
      fontWeight: 500,
      textAlign: "left",
      paddingLeft: theme.spacing(2),
      backgroundColor: theme.backgroundShades.grey,
      border: theme.spacing(0.125, "solid", `${theme.border.primary}`),
    },
  },
  dropdownInline: {
    width: "100%",
    marginTop: 0,

    "& .MuiSelect-root": {
      fontSize: "1.1rem",
      fontWeight: 500,
      textAlign: "left",
      paddingLeft: theme.spacing(2),
      backgroundColor: theme.backgroundShades.grey,
      border: theme.spacing(0.125, "solid", `${theme.border.primary}`),
    },
  },
  filterLabel: {
    fontWeight: 600,
    textAlign: "left",
    padding: theme.spacing(0.5),
    marginTop: theme.spacing(2),
  },
  inlineLabel: {
    fontSize: theme.spacing(2 / 0.75),
    fontWeight: "bolder",
    position: "relative",
    display: "inline-flex",
    transform: "translate(0,6px) scale(0.75)",
    marginRight: "2px",
  },
  label: {
    fontSize: theme.spacing(2 / 0.75),
    fontWeight: "bolder",
    paddingLeft: theme.spacing(3.3),
    //strange way to do it but not sure why the label for this and input are sitting differently
    transform: "translate(0,14px) scale(0.75)",
  },
}));
