import { useEffect, useState } from "react";
import { API } from "../../API";
import { useCancelToken } from "../general";

type AssetGroupsProps = {
  clientId?: string;
  jwt: string;
};
type AssetGroupsResult = [boolean, any[]];

export const useAssetGroups = ({
  jwt,
  clientId,
}: AssetGroupsProps): AssetGroupsResult => {
  const [loading, setLoading] = useState(false);
  const [assetGroups, setAssetGroups] = useState<any[]>([]);

  const cancelToken = useCancelToken();
  useEffect(() => {
    const getAssetGroups = async () => {
      if (!clientId) return;
      try {
        const APIFunctions = new API(cancelToken, jwt);
        const groups = await APIFunctions.getAssetGroups(clientId as string);

        setAssetGroups(() => (groups ? [...groups] : []));
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    setLoading(true);
    getAssetGroups();
  }, [clientId, cancelToken, jwt]);

  return [loading, assetGroups];
};
