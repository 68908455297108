import { ApiContextProvider, JobSectionContainer } from "@pulsion/forms-designer-2";
import { jobRendererService } from "../../services/jobRendererService";
import { rendererTheme as theme } from "./rendererTheme";

interface Props {
    jobId: string;
}

const JobRenderer = ({jobId}: Props) => {

    return (
        <ApiContextProvider api={jobRendererService}>
            <JobSectionContainer inputTheme={theme} jobId={jobId} rootOrganisation=""/>
        </ApiContextProvider>
    );
}

export { JobRenderer };

