import { useContext, useEffect } from "react";

import MapContext from "../MapContext";
import TileLayer from "ol/layer/Tile";
import { OSM } from "ol/source";
import { mapStrings as strings } from "../../../resources/strings";

interface Props {
  name: string;
  defaultVisible?: boolean;
}

type Component = (props: Props) => JSX.Element;

export const OSMLayer: Component = ({ name, defaultVisible }) => {
  const map = useContext(MapContext);

  useEffect(() => {
    if (!map) return;

    const osmLayer = new TileLayer({
      properties: {
        title: name,
        layerType: strings.layerTypes.osm,
        type: "base",
      },
      visible: !!defaultVisible,
      source: new OSM(),
      zIndex: -2,
    });

    map.addLayer(osmLayer);

    return () => {
      map.removeLayer(osmLayer);
    };
  }, [map, defaultVisible, name]);

  return <></>;
};
