import { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { API } from "../../API";
import BreadcrumbHeader from "../../components/general/BreadcrumbHeader";
import DeleteEditClearSaveButtons from "../../components/general/DeleteEditClearSaveButtons";
import { Props } from "../../components/general/ModifyComponent";
import { useCancelToken } from "../../hooks/general";
import { PROJECTS_ENDPOINT } from "../../libs/config";
import { notifications } from "../../libs/notifications";
import { paths } from "../../navigation";
import { projectStrings as strings } from "../../resources/strings";
import { ProjectData } from "../../types/documents";

export default function ProjectsBreadcrumbHeader({
  mode,
  permissions,
  data,
  jwt,
  handleModeSwitch,
}: Props<ProjectData>) {
  const cancelToken = useCancelToken();
  const history = useHistory();
  const [deleteLoading, setDeleteLoading] = useState(false);

  const APIFunctions = useMemo(
    () => new API<ProjectData>(cancelToken, jwt),
    [cancelToken, jwt]
  );

  const { id } = data;

  const getHeaderFromMode = () => {
    switch (mode) {
      case "view":
        return strings.header.viewProject;
      case "create":
        return strings.header.createProject;
      case "update":
        return strings.header.editProject;
    }
  };

  const crumbs = [
    { name: strings.header.projects, link: paths.projects.list },
    {
      name: getHeaderFromMode(),
    },
  ];

  const deleteProject = async () => {
    setDeleteLoading(true);
    try {
      await APIFunctions.delete(PROJECTS_ENDPOINT, id);
      notifications.success(strings.notifications.deleted);
      history.push(paths.projects.list);
    } catch (error) {
      notifications.error(strings.notifications.failedDelete);
    }
    setDeleteLoading(false);
  };

  return (
    <BreadcrumbHeader crumbs={crumbs}>
      <DeleteEditClearSaveButtons
        entity={strings.global.entities.projects}
        mode={mode}
        permissions={permissions}
        deleteFunction={deleteProject}
        deleteLoading={deleteLoading}
        handleModeSwitch={handleModeSwitch}
      />
    </BreadcrumbHeader>
  );
}
