import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@material-ui/core";
import { useState } from "react";

interface Props {
  gridRef?: boolean;
  open: boolean;
  onClose: () => void;
  onSubmit: (gridRef: string) => void;
}

type Component = (props: Props) => JSX.Element;

export const GridRefInputDialog: Component = ({ open, onClose, onSubmit }) => {
  const [gridRef, setGridRef] = useState("");

  const isValid = () => {
    // Irish is single capital letter (excluding "I") followed by two groups of numbers the same length
    const irish =
      /^[A-HJ-Z]\s?(\d{5}\s?\d{5}|\d{4}\s?\d{4}|\d{3}\s?\d{3}|\d{2}\s?\d{2}|\d{1}\s?\d{1})$/;

    // British is the same format as Irish, but with one of S,T,N,O,H,J at the front
    const british =
      /^[STNOHJ][A-HJ-Z]\s?(\d{5}\s?\d{5}|\d{4}\s?\d{4}|\d{3}\s?\d{3}|\d{2}\s?\d{2}|\d{1}\s?\d{1})$/;

    return irish.test(gridRef) || british.test(gridRef);
  };

  const submitDialog = () => {
    if (isValid()) {
      onSubmit(gridRef);
      closeDialog();
    }
  };

  const closeDialog = () => {
    setGridRef("");
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      fullWidth={true}
      BackdropProps={{ style: { backgroundColor: "transparent" } }}
    >
      <DialogTitle>Enter Grid Reference</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item>
            <TextField
              label="Tile"
              autoFocus
              type={"text"}
              value={gridRef}
              error={!isValid()}
              onChange={(event) => setGridRef(event.target.value.toUpperCase())}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button onClick={submitDialog} color="primary" variant="contained">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
