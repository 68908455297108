import { useEffect, useState } from "react";
import { GroupedAssetGrouping } from "../../API";
import { DropdownOptions } from "../../types";

export const useAssetGroupValueOptions = (
  assetGroupValues: GroupedAssetGrouping[]
) => {
  const [assetGroupValueOptions, setAssetGroupValueOptions] =
    useState<DropdownOptions>([]);

  useEffect(() => {
    const groupOptions: DropdownOptions = assetGroupValues.map((group) => ({
      label: group.value,
      value: group.value,
    }));
    setAssetGroupValueOptions(() => [...groupOptions]);
  }, [assetGroupValues]);

  return assetGroupValueOptions;
};
