import { makeStyles, TextField } from "@material-ui/core";
import clsx from "clsx";
import type { ChangeHandler, FormInput } from "../types/Modify";

interface Props<D extends object> {
  config: FormInput<D>;
  handleChange: ChangeHandler;
  errorMessage?: string;
}

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: theme.spacing(2 / 0.75),
    fontWeight: "bolder",
  },
  inlineLabel: {
    transform: "translate(0, 7px) scale(1)",
    position: "relative",
    marginRight: "5px",
    fontWeight: "bolder",
  },
  input: {
    marginTop: theme.spacing(4, "!important"),
  },
  inlineInput: {
    marginTop: "0 !important",
    marginRight: "5px",
    fontSize: "1.1rem",
    fontWeight: 500,
    textAlign: "left",
    paddingLeft: theme.spacing(2),
    backgroundColor: theme.backgroundShades.grey,
    border: theme.spacing(0.125, "solid", `${theme.border.primary}`),
  },
  textFieldInline: {
    flexDirection: "row",
  },
}));

export const Input = <D extends object>({
  config,
  handleChange,
}: Props<D>): JSX.Element => {
  const classes = useStyles();
  const { errorMessage, value, name, inline = false, ...inputConfig } = config;
  return (
    <TextField
      className={clsx(inline ? classes.textFieldInline : "")}
      // style={{ flexDirection: "row" }}
      {...inputConfig}
      value={value}
      helperText={errorMessage}
      error={Boolean(errorMessage)}
      variant={"standard"}
      onChange={handleChange}
      InputProps={{
        title: name,
        disableUnderline: true,
        className: clsx(inline ? classes.inlineInput : classes.input),
        id: name,
      }}
      inputProps={{
        "data-testid": `input-${name}`,
        name,
      }}
      InputLabelProps={{
        shrink: !inline,
        className: inline ? classes.inlineLabel : classes.label,
        htmlFor: name,
      }}
    />
  );
};
