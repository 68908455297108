import { InputColumn } from "@pulsion/forms-designer-2/build/Components/Common/Table/CustomColumns";
import { ButtonConfigData } from "./types";

export const tableColumns: InputColumn<ButtonConfigData>[] = [
  {
    id: "name",
    Header: "Name",
    accessor: "name",
    isSortable: false,
  },
  {
    id: "clientName",
    Header: "Client",
    accessor: "clientName",
    isSortable: false,
  },
  {
    id: "assessmentTypeName",
    Header: "Assessment Type",
    accessor: "assessmentTypeName",
    isSortable: false,
  },
  {
    id: "createdBy",
    Header: "Created By",
    accessor: "createdBy",
    isSortable: false,
  },
  {
    id: "createdDate",
    Header: "Created Date",
    accessor: "createdDate",
    isSortable: false,
  },
  {
    id: "activeName",
    Header: "Active",
    accessor: "activeName",
    isSortable: false,
  },
];
