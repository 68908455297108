import { CellObject, Range, utils, writeFile } from "xlsx-js-style";
import { ExportData } from "./GradingReportList";

const defaultStyle = {
  font: {
    name: "Arial",
    sz: 8,
  },
  alignment: {
    vertical: "center",
    horizontal: "center",
  },
};

export const exportTable = (
  { headers, rows }: ExportData,
  filename: string
) => {
  const [merges, formattedHeaders] = formatHeaders(headers);
  const lastHeaderRow = formattedHeaders[formattedHeaders.length - 1];
  const formattedData = formatData(rows, lastHeaderRow);

  const worksheet = utils.json_to_sheet(
    [...formattedHeaders, ...formattedData],
    { skipHeader: true }
  );

  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, worksheet);

  worksheet["!merges"] = merges;

  // Set height of last header row
  const height = lastHeaderRow.reduce(
    (prev, curr) => Math.max(prev, (curr.v?.toString().length ?? 0) * 4),
    20
  );
  worksheet["!rows"] = Array(headers.length);
  worksheet["!rows"][headers.length - 1] = { hpx: height };

  writeFile(workbook, filename, { compression: true });
};

const formatHeaders = (headers: any[][]): [Range[], CellObject[][]] => {
  const merges: Range[] = [];

  // Length of each row is equal to the length of the last header row
  const rowLength = headers[headers.length - 1].length;

  const formattedHeaders = headers.map((header, rowIndex) => {
    const row = Array<CellObject>(rowLength).fill({
      v: "",
      t: "s",
      s: defaultStyle,
    });

    let colIndex = 0;
    header.forEach((col) => {
      const headerWidth = col.totalVisibleHeaderCount;

      if (col.placeholderOf) {
        colIndex += headerWidth;
      } else {
        row[colIndex] = {
          v: col.originalId ?? col.Header,
          t: "s",
          s: getHeaderCellStyle(col, rowIndex === headers.length - 1),
        };

        // Add borders to other cells in merged range
        for (let i = 1; i < headerWidth; i++) {
          row[colIndex + i] = getMergedCellPadding(i === headerWidth - 1);
        }

        merges.push({
          s: { r: rowIndex, c: colIndex },
          e: { r: rowIndex, c: colIndex + headerWidth - 1 },
        });

        colIndex += headerWidth;
      }
    });

    return row;
  });

  return [merges, formattedHeaders];
};

const formatData = (
  data: string[][],
  lastHeader: CellObject[]
): CellObject[][] => {
  return data.map((row, rowIndex) =>
    row.map((value, colIndex) => {
      // Copy left border from last header row if present
      const leftBorder = lastHeader[colIndex].s?.border?.left?.style ?? "thin";

      const isFirstRow = rowIndex === 0;
      const isLastRow = rowIndex === data.length - 1;
      const isLastColumn = colIndex === row.length - 1;

      return {
        v: value ?? "",
        t: "s",
        s: {
          ...defaultStyle,
          border: {
            top: { style: isFirstRow ? "medium" : "thin" },
            bottom: { style: isLastRow ? "medium" : "thin" },
            left: { style: leftBorder },
            right: { style: isLastColumn ? "medium" : "thin" },
          },
        },
      };
    })
  );
};

const getHeaderCellStyle = (col: any, isLastHeaderRow: boolean) => {
  const columnId = col.originalId ?? col.id;

  const siblings = col.parent?.columns;

  const firstSiblingId = siblings?.[0].id;
  const lastSiblingId = siblings?.[siblings.length - 1].id;

  const hasLeftBorder = !col.parent || firstSiblingId === columnId;
  const hasRightBorder = !col.parent || lastSiblingId === columnId;

  return {
    font: {
      ...defaultStyle.font,
      ...col.exportStyle,
    },
    alignment: {
      ...defaultStyle.alignment,
      ...(isLastHeaderRow && { textRotation: 90 }),
    },
    border: {
      top: { style: "medium" },
      bottom: { style: "medium" },
      ...(hasLeftBorder && { left: { style: "medium" } }),
      ...(hasRightBorder && { right: { style: "medium" } }),
    },
  };
};

const getMergedCellPadding = (isLastColumn: boolean): CellObject => ({
  v: "",
  t: "s",
  s: {
    ...defaultStyle,
    border: {
      top: { style: "medium" },
      bottom: { style: "medium" },
      ...(isLastColumn && { right: { style: "medium" } }),
    },
  },
});
