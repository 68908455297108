import { appStrings } from "../app";

export const settingsStrings = {
  global: { ...appStrings },
  header: {
    settings: "Settings",
  },
  labels: {
    save: "Save",
    reset: "Reset",
    edit: "Edit",
    dataDirectory: "Data Directory",
    gpsDevice: "GPS Device",
  },
};
