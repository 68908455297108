import {
  Button,
  Checkbox,
  FormHelperText,
  Grid,
  InputLabel,
  InputLabelProps,
  ListSubheader,
  makeStyles,
  MenuItem,
  Select,
  SelectProps,
} from "@material-ui/core";
import clsx from "clsx";
import { useMemo } from "react";
import { getOptionLabelFromValue } from "../../../helpers";
import { useTagGroup } from "../../../hooks/tags";
import { appStrings as strings } from "../../../resources/strings";
import { DropdownOption } from "../../../types";
import { Loader } from "../Loader";
import type { ChangeHandler, Dropdown as TDropdown } from "../types/Modify";

interface Props<D extends object> {
  config: TDropdown<D>;
  handleChange: ChangeHandler;
  errorMessage?: string;
  labelProps?: InputLabelProps;
  selectProps?: SelectProps;
  buttonDisplay?: JSX.Element;
  onButtonClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const loadingValue = "loading";
const loadingState = [
  { label: strings.labels.loadingTags, value: loadingValue, disabled: true },
];

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: theme.spacing(2 / 0.75),
    fontWeight: "bolder",
    paddingLeft: theme.spacing(3.3),
    //strange way to do it but not sure why the label for this and input are sitting differently
    transform: "translate(0,14px) scale(0.75)",
  },
  dropdown: { width: "100%", marginTop: theme.spacing(4, "!important") },
  button: {
    marginTop: theme.spacing(4, "!important"),
    minWidth: 0,
  },
  disabledLabel: {
    color: theme.palette.text.disabled,
  },
  defaultSelect: {
    "& .MuiSelect-root": {
      color: theme.palette.text.disabled,
      "text-align": "center",
    },
  },
  error: {
    color: theme.palette.error.main,
  },
  dropInput: {
    textAlign: "left",
  },
  dropdownGroup: {
    fontStyle: "italic",
    fontWeight: 700,
    opacity: "1 !important",
  },
}));

export const Dropdown = <D extends object>({
  config: input,
  handleChange,
  errorMessage,
  labelProps,
  selectProps,
}: Props<D>): JSX.Element => {
  const {
    name: dropdownName,
    label,
    loading: optionsLoading = false,
    value = "",
    options: inputOptions = [],
    tagConfig,
    buttonDisplay,
    onButtonClick,
    dependents = [],
    multiple,
    ...config
  } = input;

  const [options, tagsLoading] = useTagGroup(
    inputOptions,
    tagConfig?.endpoint,
    tagConfig?.jwt,
    tagConfig?.tagGroup
  );
  const loading = optionsLoading || tagsLoading;

  const name = useMemo(() => dropdownName, [dropdownName]);

  const classes = useStyles();

  const renderOptionAsHeader = (option: DropdownOption) => {
    return (
      <ListSubheader className={classes.dropdownGroup} component="div">
        {option.label}
      </ListSubheader>
    );
  };

  const renderOption = (
    option: DropdownOption,
    index: number,
    isMultSelect?: boolean
  ) => {
    return (
      <MenuItem
        key={`${option.value}-${index}`}
        value={option.value}
        disabled={option.disabled}
      >
        {isMultSelect ? (
          renderMultiSelectDropdownOption(option, index, value as unknown[])
        ) : (
          <></>
        )}
        {option.label}
      </MenuItem>
    );
  };

  const renderOptions = (dropdownOptions: typeof options) => {
    const isArray = Array.isArray(value);
    return dropdownOptions.map((option, index) => {
      const isMultiSelect = multiple && isArray;
      return option.header
        ? renderOptionAsHeader(option)
        : renderOption(option, index, isMultiSelect);
    });
  };

  const renderMultiSelectDropdownOption = (
    option: DropdownOption,
    index: number,
    values: unknown[]
  ) => {
    return (
      <Checkbox
        key={`${option.label}-checkbox`}
        color="primary"
        checked={values.includes(option.value)}
      />
    );
  };

  const foundLabel = getOptionLabelFromValue(
    value as string | number | string[] | number[],
    loading ? loadingState : options
  );

  const onChange = async (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
      checked?: boolean | undefined;
    }>
  ) => {
    handleChange(event);
    dependents.forEach((dependent) => dependent(event.target.value));
  };

  return (
    <>
      <InputLabel
        className={clsx(
          classes.label,
          config.disabled && classes.disabledLabel,
          !!errorMessage && classes.error
        )}
        shrink={true}
        id={name}
        required={config.required}
        htmlFor={name}
        {...labelProps}
      >
        {label}
      </InputLabel>
      <Grid container>
        <Grid item style={{ flexGrow: 1 }}>
          <Loader active={loading}>
            <Select
              disableUnderline
              className={clsx(
                classes.dropdown,
                !foundLabel && classes.defaultSelect
              )}
              {...config}
              labelId={name}
              inputProps={{
                id: name,
                "data-testid": `select-input-${name}`,
                className: classes.dropInput,
              }}
              value={value}
              displayEmpty={true}
              renderValue={(value: any) =>
                value ? foundLabel : strings.labels.defaultTagSelect
              }
              title={name}
              onChange={onChange}
              name={name}
              SelectDisplayProps={{
                // this is a valid prop but typescript doesnt seem to like it
                //@ts-ignore
                "data-testid": `select-${name}`,
              }}
              multiple={multiple}
              {...selectProps}
            >
              {renderOptions(loading ? loadingState : options)}
            </Select>
          </Loader>
        </Grid>
        {buttonDisplay && (
          <Grid item>
            <Button
              className={classes.button}
              disabled={config.disabled}
              onClick={onButtonClick}
            >
              {buttonDisplay}
            </Button>
          </Grid>
        )}
      </Grid>
      {errorMessage ? (
        <FormHelperText error>{errorMessage}</FormHelperText>
      ) : null}
    </>
  );
};
