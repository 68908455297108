import {
  Box,
  Grid,
  IconButton,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { DescriptionOutlined } from "@material-ui/icons";
import ClearIcon from "@material-ui/icons/Clear";
import clsx from "clsx";
import {
  ListChildComponentProps,
  VariableSizeList as List,
} from "react-window";
import { DefectData } from "../../types/documents";
import { useDefectsListStyles } from "./styles/defectsList";

interface Props {
  defects: DefectData[];
  showDefectInfoModal: (defectId: string) => void;
  showClearOrDeleteModal: (defectId: string, assetId: string) => void;
  header: string;
  height: number;
}

type Component = (props: Props) => JSX.Element;

export const DefectsList: Component = ({
  defects,
  showClearOrDeleteModal,
  showDefectInfoModal,
  header,
  height,
}) => {
  const classes = useDefectsListStyles();

  const listItem = ({ data: items, index, style }: ListChildComponentProps) => {
    const defect = items[index];
    return (
      <ListItem key={defect.id} style={style}>
        <Grid container wrap="nowrap" spacing={0}>
          <Grid item xs={10}>
            <ListItemText
              style={{ fontSize: "16px" }}
              primary={`${defect.defectType.name}`}
            />
          </Grid>
          <Grid item xs zeroMinWidth>
            <IconButton
              size={"small"}
              aria-label="info"
              className={classes.button}
              onClick={() => showDefectInfoModal(defect.id)}
            >
              <DescriptionOutlined />
            </IconButton>
          </Grid>
          <Grid item xs zeroMinWidth>
            <IconButton
              size={"small"}
              aria-label="clear"
              className={classes.button}
              onClick={() => showClearOrDeleteModal(defect.id, defect.assetId)}
            >
              <ClearIcon />
            </IconButton>
          </Grid>
        </Grid>
      </ListItem>
    );
  };

  return (
    <Box className={clsx(classes.root)}>
      <div className={classes.headerContainer}>
        <h3 className={classes.header}>
          {header} ({defects.length})
        </h3>
      </div>
      {defects && (
        <List
          className={classes.list}
          itemSize={() => 40}
          itemCount={defects.length}
          height={height}
          width={"100%"}
          itemData={defects}
        >
          {listItem}
        </List>
      )}
    </Box>
  );
};
