import { Mode, Section } from "../../../components/general/types/Modify";
import { mapStrings as strings } from "../../../resources/strings";
import { AvoidanceZoneData } from "../../../types/documents";
import { DateTime } from "luxon";
import { DatePicker } from "./DatePicker";
import { TAGS_ENDPOINT } from "../../../libs/config";

export const initialData = (geometry: object, properties: object | null) => {
  return {
    code: "",
    status: "",
    notes: "",
    date: DateTime.now().toISODate(),
    geometry,
    properties,
  };
};

export const constraints = {
  code: {
    presence: {
      allowEmpty: false,
      message: `^${strings.avoidanceZone.code} is required`,
    },
  },
  status: {
    presence: {
      allowEmpty: false,
      message: `^${strings.avoidanceZone.status} is required`,
    },
  },
  date: {
    presence: {
      allowEmpty: false,
    },
  },
};

export const configInputs = (mode: Mode, jwt: string) => {
  const config: Section<AvoidanceZoneData> = {
    key: "details",
    content: [
      {
        controltype: "header",
        text:
          mode === "create"
            ? strings.avoidanceZone.create
            : strings.avoidanceZone.edit,
        align: "left",
        variant: "h5",
      },
      {
        controltype: "dropdown",
        name: "code",
        label: strings.avoidanceZone.code,
        required: true,
        tagConfig: {
          endpoint: TAGS_ENDPOINT,
          jwt: jwt,
          tagGroup: "avoidanceZoneCode",
        },
      },
      {
        controltype: "dropdown",
        name: "status",
        label: strings.avoidanceZone.status,
        required: true,
        tagConfig: {
          endpoint: TAGS_ENDPOINT,
          jwt: jwt,
          tagGroup: "avoidanceZoneStatus",
        },
      },
      {
        controltype: "input",
        name: "notes",
        label: strings.avoidanceZone.notes,
        multiline: true,
      },
      {
        controltype: "custom",
        name: "date",
        label: "Date",
        required: true,
        Component: DatePicker,
      },
    ],
  };

  return config;
};

export const configSubmit: Section<AvoidanceZoneData> = {
  key: "submitButtons",
  content: [
    {
      controltype: "button",
      modes: ["create", "update"],
      label: strings.buttons.save,
      color: "primary",
      variant: "contained",
    },
    {
      controltype: "button",
      modes: ["update"],
      label: strings.buttons.delete,
      color: "primary",
      variant: "outlined",
      type: "button",
      buttonaction: "delete",
    },
  ],
};
