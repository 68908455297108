import { useCallback, useEffect, useState } from "react";

import { Feature } from "ol";
import { Point } from "ol/geom";
import { Draw } from "ol/interaction";
import Map from "ol/Map";
import {
  getFeaturesInExtent,
  getIntersectingFeatures,
  setClickedStatus,
} from "../../components/map/helpers/LayerFunctions";

type Props = {
  map: Map | undefined;
  layers: string[];
  enabled: boolean;
};

type Result = [
  () => void,
  (onDrawEnd: (features: Feature<Point>[]) => void) => void
];

export const useDrawingTool = ({ map, layers, enabled }: Props): Result => {
  const [drawingTool, setDrawingTool] = useState<Draw | null>(null);

  const clearSelected = useCallback(() => {
    if (!map) return;

    const extent = map.getView().calculateExtent();
    const features = getFeaturesInExtent(map, extent, layers);

    setClickedStatus(features, 0);
  }, [map, layers]);

  const handleDrawingTool = useCallback(
    (onDrawEnd: (features: Feature<Point>[]) => void) => {
      if (!map) return;

      const draw = new Draw({
        type: "Polygon",
        freehand: true,
      });

      draw.on("drawend", (event) => {
        const features = getIntersectingFeatures(map, event.feature, layers);
        setClickedStatus(features, 1);

        onDrawEnd(features);
      });

      draw.on("drawabort", () => {
        clearSelected();
      });

      setDrawingTool(draw);
    },
    [map, layers, clearSelected]
  );

  useEffect(() => {
    if (!map || !drawingTool) return;

    if (enabled) {
      map.addInteraction(drawingTool);
    } else {
      map.removeInteraction(drawingTool);
      clearSelected();
    }

    return () => {
      map.removeInteraction(drawingTool);
      clearSelected();
    };
  }, [map, drawingTool, clearSelected, enabled]);

  return [clearSelected, handleDrawingTool];
};
