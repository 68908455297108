import {
  Box,
  Checkbox as UICheckbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  makeStyles,
} from "@material-ui/core";
import clsx from "clsx";
import { ChangeEventHandler } from "react";
import { Loader } from "../";
import { useTagGroup } from "../../../hooks/tags";
import type { ChangeHandler, Checkbox as TCheckbox } from "../types/Modify";
interface Props<D extends object> {
  config: TCheckbox<D>;
  handleChange: ChangeHandler;
  checkData: Array<unknown>;
  setFormData: React.Dispatch<React.SetStateAction<D>>;
  errorMessage?: string;
}

const useStyles = makeStyles((theme) => ({
  checkbox: {
    color: theme.border.primary,
    "&.Mui-checked": {
      color: theme.palette.primary.main,
    },
    "& .MuiSvgIcon-root": {
      fontSize: theme.spacing(4),
    },
  },
  checkboxes: { paddingTop: theme.spacing(1) },
  label: {
    fontSize: theme.spacing(2),
    fontWeight: "bolder",
    paddingTop: theme.spacing(0.25),
  },
  disabledLabel: {
    color: theme.palette.text.disabled,
  },
}));

export const Checkbox = <D extends object>({
  config: input,
  setFormData,
  checkData = [],
  errorMessage,
}: Props<D>): JSX.Element => {
  const {
    label,
    tagConfig,
    options: inputOptions = [],
    multiple,
    ...config
  } = input;

  const classes = useStyles();

  const [options, loading] = useTagGroup(
    inputOptions,
    tagConfig?.endpoint,
    tagConfig?.jwt,
    tagConfig?.tagGroup
  );
  // the following was necessary because checkData was null despite the initialization
  // and the fact that was null causes an error and the page doesn't load
  if (!checkData || typeof checkData === "boolean") checkData = [];
  // define a custom on change
  // we want to group any checkboxes provided as a single value
  // rather than Material UI default which would set a boolean for each checkbox
  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value, checked } = e.target;

    // changed the way the value of a checkbox is saved: Aurora does not handle arrays,
    // so for single selection checkboxes we are only sending back the selected value and not an array
    let newValue: any = null;
    if (multiple) {
      newValue = [...checkData];
      const valueExists = checkData.includes(value);
      if (checked && !valueExists) {
        newValue.push(value);
      } else if (!checked && valueExists) {
        newValue = newValue.filter((v: any) => v !== value);
      }
    } else {
      newValue = value;
    }
    setFormData((prev) => ({
      ...prev,
      [config.name]: newValue,
    }));
  };

  return (
    <Box>
      <FormControl
        component="fieldset"
        error={!!errorMessage}
        fullWidth
        title={config.name}
      >
        <Box display="flex" flexDirection="row">
          <FormLabel
            component="legend"
            required={config.required}
            focused={false}
            className={clsx(
              classes.label,
              config.disabled && classes.disabledLabel
            )}
            htmlFor={config.name}
          >
            {label}
          </FormLabel>
        </Box>
        <FormGroup className={classes.checkboxes}>
          <Loader active={loading} inline>
            <>
              {options.map((option, index) => (
                <FormControlLabel
                  key={`${option.value}-${index}`}
                  control={
                    <UICheckbox
                      id={config.name}
                      {...config}
                      value={option.value}
                      checked={checkData.includes(option.value)}
                      onChange={onChange}
                      className={clsx(classes.checkbox)}
                      //@ts-ignore
                      inputProps={{ "data-testid": `checkbox-${option.label}` }}
                    />
                  }
                  label={option.label}
                />
              ))}
            </>
          </Loader>
        </FormGroup>
        {errorMessage ? (
          <FormHelperText error>{errorMessage}</FormHelperText>
        ) : null}
      </FormControl>
    </Box>
  );
};
