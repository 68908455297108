import { IFormsDesignerApi } from "@pulsion/forms-designer-2";
import {
  createControlTemplate,
  createSectionTemplate,
  createSubSectionTemplate,
  createTemplate,
  getControls,
  getFormSection,
  getFormSectionDetails,
  getFormSectionNames,
  getFormSubSection,
  getNewControlId,
  getTemplate,
  removeSectionTemplate,
  removeSubSectionTemplate,
  reorderSections,
  reorderSubSections,
  updateControlTemplate,
  updateSectionTemplate,
  updateSubSectionTemplate,
  updateTemplate,
} from "./formDesigner";

export const formDesignerApi: IFormsDesignerApi = {
  createForm: createTemplate,
  deleteSectionTemplate: removeSectionTemplate,
  deleteSubsectionTemplate: removeSubSectionTemplate,
  updateForm: updateTemplate,
  getFormDetails: getTemplate,
  getFormSection,
  getFormSectionDetails,
  getFormSectionNames,
  getFormSubSection,
  updateControlTemplate,
  updateSubSectionTemplate,
  reorderSections,
  reorderSubSections,
  updateSectionTemplate,
  createControlTemplate,
  createSubSectionTemplate,
  createSectionTemplate,
  getNewControlId,
  getControls,
};
