/// <reference path='./olgm.d.ts'>
import { Grid, Typography } from "@material-ui/core";
import "ol-layerswitcher/dist/ol-layerswitcher.css";
import "ol/ol.css";
import "olgm/olgm.css";
import { ChangeEvent, useState } from "react";
import { isOfflineApp } from "../../helpers";
import { useClients } from "../../hooks/clients/useClients";
import { useCancelToken } from "../../hooks/general";
import { useMapData } from "../../hooks/map/useMapData";
import { mapStrings as strings } from "../../resources/strings";
import { RouteProps } from "../../types";
import { FlightData } from "../../types/documents/Flight";
import "../../types/google";
import { ShowIfAuthorised } from "../authentication";
import { Flight } from "../flight/flight";
import { Loader } from "../general";
import { googleMapsTerrainStyle } from "./helpers/Style";
import { AvoidanceZoneLayer } from "./layers/AvoidanceZoneLayer";
import { ClientAssetsLayer } from "./layers/ClientAssetsLayer";
import { ClientLinesLayer } from "./layers/ClientLinesLayer";
import { ClientTileLayer } from "./layers/ClientTileLayer";
import { FeatureSelectorLayer } from "./layers/FeatureSelectorLayer";
import { GoogleBaseLayer } from "./layers/GoogleBaseLayer";
import { GPSLayer } from "./layers/GPSLayer";
import { GpsPositionLayer } from "./layers/GpsPositionLayer";
import { MarkupLayer } from "./layers/MarkupLayer";
import { PackageLayer } from "./layers/PackageLayer";
import { PointOfInterestLayer } from "./layers/PointOfInterestLayer";
import { SearchLayer } from "./layers/SearchLayer";
import { MapContainer } from "./MapContainer";
import { ClientNavItem } from "./navItems/ClientNavItem";
import { LayerNavItem } from "./navItems/LayerNavItem";
import { useStyles } from "./styles";
import { DrawToolBar } from "./toolbars/DrawToolBar";
import { GPSToolBar } from "./toolbars/GPSToolBar";
import { ViewToolBar } from "./toolbars/ViewToolBar";

interface Props extends RouteProps {
  flight?: FlightData;
  packageId?: string;
  packageClientId?: string;
}

type Component = (props: Props) => JSX.Element;

export const MapComponent: Component = ({
  flight,
  packageId,
  packageClientId,
  ...routeProps
}) => {
  const { jwt, user, permissions } = routeProps;
  const classes = useStyles();
  const cancelToken = useCancelToken();

  const userClientId = user?.clientId;
  const layerDropdown = [
    ...(flight ? [strings.layers.gps] : []),
    ...(!userClientId
      ? [strings.layers.avoidanceZone, strings.layers.pointOfInterest]
      : []),
    strings.layers.markup,
  ];

  const [gpxPosition, setGPXPosition] = useState(0);

  const [assetSelectType, setAssetSelectType] = useState("");
  const [drawTool, setDrawTool] = useState("");

  const [assetsLoading, setAssetsLoading] = useState(false);
  const [avoidanceZonesLoading, setAvoidanceZonesLoading] = useState(false);
  const [pointsOfInterestLoading, setPointsOfInterestLoading] = useState(false);
  const [flightId, setFlightId] = useState<string | null>(null);
  const [clients, clientsLoading] = useClients({
    userClientId,
    jwt,
    cancelToken,
  });

  const [
    selectedClient,
    gpxParser,
    selectedLayer,
    handleSelectClient,
    _handleSelectLayer,
  ] = useMapData({
    flight,
    cancelToken,
    clients,
    clientId: userClientId || packageClientId,
    layerDropdown,
  });

  const handleSelectLayer = (event: ChangeEvent<{ value: unknown }>) => {
    _handleSelectLayer(event);
    setDrawTool("");
  };

  const handleFlight = (flightId: string) => {
    setFlightId(flightId);
  };

  const loading =
    assetsLoading || pointsOfInterestLoading || avoidanceZonesLoading;

  const renderToolBar = () => {
    if (flight && selectedLayer === strings.layers.gps) {
      return gpxParser ? (
        <GPSToolBar
          position={gpxPosition}
          setPosition={setGPXPosition}
          gpx={gpxParser}
          {...routeProps}
        />
      ) : (
        <Typography>{strings.gps.noTrace}</Typography>
      );
    } else {
      return (
        <DrawToolBar
          selectedLayer={selectedLayer}
          drawTool={drawTool}
          setDrawTool={setDrawTool}
        />
      );
    }
  };

  const getAuthorisedMapLayers = () => {
    return (
      <>
        <PointOfInterestLayer
          name={strings.layers.pointOfInterest}
          selectedLayer={selectedLayer}
          drawTool={drawTool}
          setDrawTool={setDrawTool}
          setLoading={setPointsOfInterestLoading}
          {...routeProps}
        />
        <AvoidanceZoneLayer
          name={strings.layers.avoidanceZone}
          selectedLayer={selectedLayer}
          drawTool={drawTool}
          setDrawTool={setDrawTool}
          setLoading={setAvoidanceZonesLoading}
          {...routeProps}
        />
      </>
    );
  };

  return (
    <>
      <MapContainer header={!!flight}>
        <Grid
          className={classes.header}
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          wrap="nowrap"
          style={{ overflowX: "auto", overflowY: "hidden" }}
        >
          {!packageId && (
            <ClientNavItem
              flight={flight}
              clients={clients}
              clientsLoading={clientsLoading}
              handleSelectClient={handleSelectClient}
              selectedClient={selectedClient}
              userClientId={userClientId}
            ></ClientNavItem>
          )}
          <LayerNavItem
            handleSelectLayer={handleSelectLayer}
            layerDropdown={layerDropdown}
            selectedLayer={selectedLayer}
          ></LayerNavItem>
          <Grid item style={{ display: "flex", alignItems: "center" }}>
            <Loader active={loading} inline={true}>
              {renderToolBar()}
            </Loader>
          </Grid>
          <Grid item style={{ flexGrow: 1, minWidth: "20px" }} />
          <Grid item style={{ display: "flex", alignItems: "center" }}>
            <ViewToolBar
              assetSelectType={assetSelectType}
              handleAssetSelectTypeChange={(val) => setAssetSelectType(val)}
            />
          </Grid>
        </Grid>

        {packageId && (
          <Flight
            packageId={packageId}
            handleFlight={handleFlight}
            {...routeProps}
          />
        )}

        {!isOfflineApp() && (
          <>
            <SearchLayer clientId={selectedClient} {...routeProps} />
            <GoogleBaseLayer
              name={strings.layers.googleTerrain}
              mapType={window.google.maps.MapTypeId.TERRAIN}
              defaultVisible={true}
              styles={googleMapsTerrainStyle}
            />
            <GoogleBaseLayer
              name={strings.layers.googleSatellite}
              mapType={window.google.maps.MapTypeId.SATELLITE}
            />
          </>
        )}

        {isOfflineApp() && selectedClient && (
          <ClientTileLayer
            name={clients[selectedClient]?.name || ""}
            clientId={selectedClient}
          />
        )}

        {selectedClient && (
          <ClientLinesLayer
            name={clients[selectedClient]?.name || ""}
            clientId={selectedClient}
            jwt={jwt}
          />
        )}
        {selectedClient && (
          <ClientAssetsLayer
            jwt={jwt}
            name={clients[selectedClient]?.name || "Assets"}
            id={selectedClient}
            setLoading={setAssetsLoading}
          />
        )}

        {packageId && selectedClient && (
          <PackageLayer
            jwt={jwt}
            name={clients[selectedClient]?.name || "Assets"}
            id={packageId}
            setLoading={setAssetsLoading}
            flightId={flightId}
          />
        )}
        {gpxParser && (
          <GPSLayer
            name={strings.layers.gps}
            position={gpxPosition}
            gpx={gpxParser}
          />
        )}

        {isOfflineApp() ? (
          <>{getAuthorisedMapLayers()}</>
        ) : (
          <ShowIfAuthorised
            entity={strings.global.entities.mapFeatures}
            permission={strings.global.permissions.read}
            userPermissions={permissions}
          >
            {getAuthorisedMapLayers()}
          </ShowIfAuthorised>
        )}

        <MarkupLayer
          name={strings.layers.markup}
          selectedLayer={selectedLayer}
          drawTool={drawTool}
          setDrawTool={setDrawTool}
        />
        {isOfflineApp() && <GpsPositionLayer />}
        <FeatureSelectorLayer
          {...routeProps}
          assetSelectType={assetSelectType}
          clientName={clients[selectedClient ?? ""]?.name ?? "Assets"}
          flightId={flightId}
          packageId={packageId}
        />
      </MapContainer>
    </>
  );
};
