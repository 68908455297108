import { IEntityListApi } from "@pulsion/forms-designer-2";
import log from "loglevel";
import { removeTemplate, searchTemplates } from "../../services/formDesigner";

export const buttonConfigListApi: IEntityListApi = {
  deleteRow: (
    rowObject: any,
    abortSignal: AbortSignal,
    appendHistory: (url: string) => void
  ) => {
    log.debug("Deleting Row", rowObject);
    return removeTemplate(rowObject.id, abortSignal, appendHistory);
  },
  getEntityRows: async (
    searchCriteria: {
      page: number;
      size: number;
    },
    abortSignal: AbortSignal,
    filters?: object,
    sortDirection?: string,
    sortColumnName?: string
  ) => {
    log.debug("Getting entity rows", {
      searchCriteria,
      filters,
      sortDirection,
      sortColumnName,
    });

    const results = await searchTemplates(abortSignal, searchCriteria);
    log.debug("Results", results);

    return results;
  },
};
