import { Permissions } from "../../types";
import ClearSaveButtons from "./ClearSaveButtons";
import DeleteButton from "./DeleteButton";
import EditButton from "./EditButton";
import type { Mode } from "./types/Modify";

interface Props {
  entity: string;
  deleteFunction?: () => void;
  deleteLoading: boolean;
  mode: Mode;
  handleModeSwitch: () => void;
  permissions: Permissions | null;
}

export default function DeleteEditClearSaveButtons({
  mode,
  entity,
  permissions,
  deleteFunction,
  deleteLoading,
  handleModeSwitch,
}: Props) {
  return mode === "view" ? (
    <>
      {deleteFunction !== undefined && (
        <DeleteButton
          entity={entity}
          permissions={permissions}
          deleteFunction={deleteFunction as () => void}
          deleteLoading={deleteLoading}
        />
      )}
      <EditButton
        entity={entity}
        permissions={permissions}
        handleModeSwitch={handleModeSwitch}
      />
    </>
  ) : (
    <ClearSaveButtons />
  );
}
