import { FormTemplate, FormTemplateV2 } from "@pulsion/forms-designer-2";
import log from "loglevel";
import { paths } from "../../navigation";
import config from "./config";

export interface IFormTemplateApi {
  create(formTemplate: FormTemplate): Promise<any>;
  remove(id: string, removeJobs: boolean): Promise<any>;
  update(id: string, formTemplate: FormTemplate): Promise<any>;
  get(id: string, formTemplate: FormTemplate): Promise<FormTemplate>;
}

const create = async <D>(formTemplate: FormTemplateV2 & D): Promise<any> => {
  return await config
    .axiosInstance({
      url: config.apiEndPoints.formTemplate.create,
      method: "post",
      data: formTemplate,
    })
    .then((res: { data: any }) => {
      log.debug(res);
      return res.data;
    })
    .catch((err: any) => {
      log.error(err);
      throw err;
    });
};

const get = async <D>(id: string): Promise<FormTemplateV2 & D> => {
  return await axiosGet(
    config.apiEndPoints.formTemplate.get(id),
    "Form template not found"
  );
};

const search = async (
  abortSignal: AbortSignal,
  params: { page: number; size: number }
): Promise<any> => {
  return await config
    .axiosInstance({
      url: config.apiEndPoints.formTemplate.search(),
      method: "get",
      signal: abortSignal,
      params,
    })
    .then((res: { data: any }) => {
      log.debug(res);
      const data = res.data;
      if (!data) {
        throw new Error("Search failed");
      }

      return data;
    })
    .catch((err: any) => {
      log.error(err);
      throw err;
    });
};

const remove = async (
  id: string,
  abortSignal: AbortSignal,
  appendHistory: (url: string) => void
): Promise<any> => {
  return await config
    .axiosInstance({
      url: config.apiEndPoints.formTemplate.delete(id),
      method: "delete",
      signal: abortSignal,
    })
    .catch((err: any) => {
      log.error(err);
      throw err;
    })
    .finally(() => {
      appendHistory(paths.formDesigner.list);
    });
};

const update = async <D>(
  id: string,
  formTemplate: FormTemplateV2 & D
): Promise<any> => {
  return await config
    .axiosInstance({
      url: config.apiEndPoints.formTemplate.update(id),
      method: "put",
      data: formTemplate,
    })
    .then((res: any) => {
      log.debug(res);
    })
    .catch((err: any) => {
      log.error(err);
      throw err;
    });
};

const getFormSectionNames = async (id: string): Promise<any> => {
  return await axiosGet(
    config.apiEndPoints.formTemplate.getwithSectionNames(id),
    "Sections not found"
  );
};

const getFormSection = async (id: string): Promise<any> => {
  return await axiosGet(
    config.apiEndPoints.formTemplate.getFormSection(id),
    "Section not found"
  );
};

const getFormSectionDetails = async (id: string): Promise<any> => {
  return await axiosGet(
    config.apiEndPoints.formTemplate.getFormSectionDetails(id),
    "Section not found"
  );
};

const getFormSubSection = async (id: string): Promise<any> => {
  return await config
    .axiosInstance({
      url: config.apiEndPoints.formTemplate.getFormSubSection(id),
      method: "get",
    })
    .then((res: { data: any }) => {
      log.debug(res);
      const data = res.data;

      return data;
    })
    .catch((err: any) => {
      log.error(err);
      throw err;
    });
};

const axiosGet = async (url: string, error: string): Promise<any> => {
  return await config
    .axiosInstance({
      url: url,
      method: "get",
    })
    .then((res: { data: any }) => {
      log.debug(res);
      const data = res.data;
      if (!data || data.length) {
        throw new Error(error);
      }

      return data;
    })
    .catch((err: any) => {
      log.error(err);
      throw err;
    });
};

export {
  get,
  update,
  remove,
  create,
  getFormSectionNames,
  getFormSection,
  getFormSectionDetails,
  getFormSubSection,
  search,
};
