import { Feature } from "ol";
import { Geometry } from "ol/geom";
import LayerGroup from "ol/layer/Group";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { HooksLogger } from "../../../hooks/hooks-logger";
import { useMapFeatureData } from "../../../hooks/map/useMapFeatureData";
import { mapStrings as strings } from "../../../resources/strings";
import { RouteProps } from "../../../types";
import { MapFeatureGeoJSON } from "../../../types/documents";
import { getDrawTool } from "../helpers/DrawTools";
import { useGeoJSON } from "../helpers/GeoJSON";
import { PointOfInterestModal } from "../helpers/PointOfInterestModal";
import {
  pointsOfInterestLabelStyle,
  pointsOfInterestPointStyle,
} from "../helpers/Style";
import MapContext from "../MapContext";

const logger = new HooksLogger("Map/PointsOfInterestLayer");

interface Props extends RouteProps {
  name: string;
  selectedLayer: string;
  drawTool: string;
  setDrawTool: Dispatch<SetStateAction<string>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
}

type Component = (props: Props) => JSX.Element;

export const PointOfInterestLayer: Component = ({
  name,
  selectedLayer,
  drawTool,
  setDrawTool,
  setLoading,
  ...routeProps
}) => {
  const map = useContext(MapContext);
  const format = useGeoJSON();

  const [feature, setFeature] = useState<Feature<Geometry>>();
  const [vectorSource] = useState(new VectorSource<Geometry>({}));

  const addDataToSource = useCallback(
    ({ data }: MapFeatureGeoJSON) => {
      if (!format || !map) return;

      const features = format.readFeatures(data);
      vectorSource.addFeatures(features);
      logger.info("Loaded Points of Interest", { count: features.length });
    },
    [format, map, vectorSource]
  );

  const [loading] = useMapFeatureData({
    type: "point-of-interest",
    addDataToSource,
  });

  useEffect(() => setLoading(loading), [loading, setLoading]);

  useEffect(() => {
    if (!map || !format) return;

    const pointsLayer = new VectorLayer({
      properties: {
        title: name,
        layerType: strings.layerTypes.vector,
        olgmWatch: false,
      },
      source: vectorSource,
      style: pointsOfInterestPointStyle,
    });

    const labelsLayer = new VectorLayer({
      properties: {
        layerType: strings.layerTypes.vector,
        olgmWatch: false,
      },
      source: vectorSource,
      declutter: true,
      style: pointsOfInterestLabelStyle,
    });

    const layers = new LayerGroup({
      properties: {
        title: name,
      },
      layers: [pointsLayer, labelsLayer],
    });

    map.addLayer(layers);

    return () => {
      map.removeLayer(layers);
    };
  }, [name, vectorSource, map, format]);

  useEffect(() => {
    if (!map || selectedLayer !== name || !drawTool) return;

    const onDrawEnd = (feature: Feature<Geometry>) => {
      const geom = feature.getGeometry();
      if (!geom) return;

      setFeature(feature);
      setDrawTool("");
    };

    const draw = getDrawTool(drawTool, vectorSource, onDrawEnd);
    map.addInteraction(draw);

    return () => {
      map.removeInteraction(draw);
    };
  }, [selectedLayer, drawTool, name, map, vectorSource, setDrawTool]);

  const onSuccess = () => {
    if (!feature || vectorSource.hasFeature(feature)) return;

    vectorSource.addFeature(feature);
  };

  return (
    <PointOfInterestModal
      mode={"create"}
      pointOfInterest={feature}
      onSuccess={onSuccess}
      {...routeProps}
    />
  );
};
