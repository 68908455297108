import { makeStyles } from "@material-ui/core";
import { useMachine } from "@xstate/react";
import clsx from "clsx";
import { FlightState } from "../../enums/flightState";
import { useFlightButtons } from "../../hooks/flights/useFlightButtons";
import { useCancelToken } from "../../hooks/general";
import { useForm } from "../../hooks/general/useForm";
import {
  flightMachine,
  stateMapper,
} from "../../machines/flights/flightMachine";
import { createFlight, updateFlight } from "../../services/flights.service";
import { RouteProps } from "../../types";
import { FlightButtonState } from "./flightButtonState";
import { FlightDetails } from "./flightDetails";
import { FlightProgressIndictator } from "./FlightProgressIndicator";

export interface Props extends RouteProps {
  packageId: string;
  handleFlight: (flightId: string) => void;
}

type Component = (props: Props) => JSX.Element;

const useStyles = makeStyles((theme) => ({
  container: {
    position: "absolute",
    top: "84px",
    left: "19px",
    width: "212px",
    zIndex: 999,
    backgroundColor: "white",
    border: "1px solid #d8d8d8",
    padding: "16px",
  },
  label: {
    lineHeight: "21px",
    fontWeight: 600,
    color: "#333333",
    fontSize: "16px",
  },
}));

export type FlightDetailProps = {
  type: string;
  registration: string;
  techLogNumber: string;
  pilotName: string;
  observerName: string;
  reference: string;
};

export const Flight: Component = ({
  packageId,
  handleFlight,
  ...routeProps
}) => {
  const { jwt } = routeProps;
  const cancelToken = useCancelToken();
  const [formData, handleChange] = useForm<FlightDetailProps>({
    type: "",
    registration: "",
    techLogNumber: "",
    pilotName: "",
    observerName: "",
    reference: "",
  });
  const [state, send] = useMachine(flightMachine, {
    services: {
      starting: async (context, event) => {
        var flightId = await createFlight(jwt, cancelToken, {
          packageId: packageId,
          ...formData,
        });
        handleFlight(flightId);

        return flightId;
      },
      transittingToSite: async (context, event) => {
        console.log("Transitting to site", { context, jwt });
        return updateFlight(
          {
            id: context.flightId as string,
            state: FlightState.TransittedToSite,
          },
          jwt,
          cancelToken
        );
      },
      startingAssessment: async (context, event) =>
        updateFlight(
          {
            id: context.flightId as string,
            state: FlightState.StartedAssessment,
          },
          jwt,
          cancelToken
        ),
      finishingAssessment: async (context, event) =>
        updateFlight(
          {
            id: context.flightId as string,
            state: FlightState.FinishedAssessment,
          },
          jwt,
          cancelToken
        ),
      transittingToBase: async (context, event) =>
        updateFlight(
          {
            id: context.flightId as string,
            state: FlightState.TransittedToBase,
          },
          jwt,
          cancelToken
        ),
      finishing: async (context, event) =>
        updateFlight(
          {
            id: context.flightId as string,
            state: FlightState.Finished,
          },
          jwt,
          cancelToken
        ),
      aborting: async (context, event) =>
        updateFlight(
          {
            id: context.flightId as string,
            state: FlightState.Aborted,
          },
          jwt,
          cancelToken
        ),
    },
  });
  const flightButtons = useFlightButtons(state, send);
  const classes = useStyles();

  const stateMap = stateMapper[state.value as keyof typeof stateMapper];
  const stateLabel = stateMap === undefined ? "" : stateMap.label;

  return (
    <div className={clsx(classes.container)}>
      <p className={clsx(classes.label)}>{stateLabel}</p>
      <FlightDetails
        state={state.value}
        formData={formData}
        handleChange={handleChange}
      />
      <FlightButtonState flightButtons={flightButtons} />
      <FlightProgressIndictator state={state.value} />
    </div>
  );
};
