import { ChangeEventHandler, useState } from "react";
import { HooksLogger } from "../hooks-logger";
import axios, { CancelToken } from "axios";
import { FileUploadResult } from "../../types/FileUpload";

export const useS3Upload = (
  logName: string,
  getUploadUrls: (fileNames: string[]) => Promise<FileUploadResult>,
  logger: HooksLogger,
  cancelToken: CancelToken,
  onSuccess?: () => void,
  onError?: (error: string) => void,
): [ChangeEventHandler<HTMLInputElement>, boolean, number | undefined] => {
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState<number>();

  const uploadHandler: ChangeEventHandler<HTMLInputElement> = (event) => {
    if (uploading) return;

    const input = event.target;
    if (!input?.files) return;

    const selectedFiles = Array.from(input.files);
    if (selectedFiles.length === 0) return;

    setUploading(true);

    const fileNames: string[] = [];
    for (const selectedFile of selectedFiles) {
      fileNames.push(selectedFile.name);
    }

    const query = async () => {
      logger.request(logName, "Getting presigned urls");
      try {
        const urls = await getUploadUrls(fileNames);
        logger.success(logName, urls);

        let baseProgress = 0;
        const onUploadProgress = (event: ProgressEvent) => {
          logger.info("progress", event);
          if (event.lengthComputable) {
            const fileCompletion = event.loaded * 100 / event.total;
            setUploadProgress(baseProgress + fileCompletion / selectedFiles.length);
          }
        };

        const config = { onUploadProgress };

        let i = 0;
        for (const selectedFile of selectedFiles) {
          i += 1;
          baseProgress = (i - 1) * 100 / selectedFiles.length;

          const fileUrl = urls[selectedFile.name];
          if (!fileUrl) {
            logger.error(
              logName,
              "url for " + selectedFile.name + " is missing"
            );
            continue;
          }

          logger.request(logName, "Uploading " + selectedFile.name);

          await axios.put(fileUrl.url, selectedFile, config);

          logger.success(logName, "Putted " + selectedFile.name);

        }

        setUploading(false);
        setUploadProgress(undefined);
        onSuccess && onSuccess();

      } catch (e: any) {
        if (cancelToken.reason) return;
        const error = e.message ? e.message : e;

        setUploading(false);
        logger.error(logName, error);
        onError && onError(error);
      }
    };

    query();

  };

  return [uploadHandler, uploading, uploadProgress];
};
