import { useEffect, useMemo, useState } from "react";
import { API } from "../../../API";
import type { DropdownOptions } from "../../../types";
import { useCancelToken } from "../../general";

export const useTagGroup = (
  defaultOptions: DropdownOptions = [],
  endpoint?: string,
  jwt?: string,
  tagGroup?: string
) => {
  const cancelToken = useCancelToken();
  const [options, setOptions] = useState(() => defaultOptions);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const APIFunctions = useMemo(
    () => new API(cancelToken, jwt ?? ""),
    [cancelToken, jwt]
  );
  useEffect(() => {
    const query = async (group: string) => {
      setLoading(true);
      try {
        const tagOptions = await APIFunctions.getTags(group);
        if (!tagOptions) {
          console.error("CancelToken");
          return;
        }
        setOptions(tagOptions);
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    };
    // pass these into the query function since this check ensures "tagConfig" is defined
    if (endpoint && jwt && tagGroup) query(tagGroup);
  }, [endpoint, jwt, tagGroup, cancelToken, APIFunctions]);
  const returnOptions = options.length ? options : defaultOptions;
  return [returnOptions, loading, error] as const;
};
