import { CancelToken } from "axios";
import { ChangeEvent, useEffect, useState } from "react";
import { GPXParser } from "../../components/map/helpers/GPXParser";
import { getNested } from "../../helpers";
import { mapStrings as strings } from "../../resources/strings";
import * as gpxApi from "../../services/gpxTrace.service";
import { FlightData } from "../../types/documents";
import { HooksLogger } from "../hooks-logger";

const logger = new HooksLogger("Use Flight Gpx.ts");

type Props = {
  flight?: FlightData;
  cancelToken: CancelToken;
  clients: any;
  clientId?: string;
  layerDropdown: any[];
};

type Result = [
  string | null,
  GPXParser | null,
  string,
  (event: ChangeEvent<{ value: unknown }>) => void,
  (event: ChangeEvent<{ value: unknown }>) => void
];

export const useMapData = ({
  flight,
  clientId,
  cancelToken,
  clients,
  layerDropdown,
}: Props): Result => {
  const [selectedClient, setSelectedClient] = useState<string | null>(
    clientId || null
  );
  const [gpxParser, setGPXParser] = useState<GPXParser | null>(null);
  const [selectedLayer, setSelectedLayer] = useState(layerDropdown[0]);

  const flightClientId = getNested(["package", "project", "clientId"])(flight);
  const gpxKey = flight?.gpxKey;

  useEffect(() => {
    if (gpxKey) {
      const fetchData = async () => {
        logger.request("Getting GPX trace");
        try {
          const data = await gpxApi.getGPXData(cancelToken, gpxKey);

          const gpx = new GPXParser(data);
          setGPXParser(gpx);

          logger.success("Loaded GPX trace");
        } catch (e) {
          if (cancelToken.reason) return;
          logger.error(e);
        }
      };

      if (clients[flightClientId]) {
        setSelectedClient(flightClientId);
        setSelectedLayer(strings.layers.gps);
        fetchData();
      }
    }
  }, [cancelToken, clients, flightClientId, gpxKey]);

  const handleSelectClient = (event: ChangeEvent<{ value: unknown }>) => {
    const clientId = event.target.value as string;
    setSelectedClient(clientId);
  };

  const handleSelectLayer = (event: ChangeEvent<{ value: unknown }>) => {
    setSelectedLayer(event.target.value as string);
  };

  return [
    selectedClient,
    gpxParser,
    selectedLayer,
    handleSelectClient,
    handleSelectLayer,
  ];
};
