import { Box, Grid, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { Fragment, useMemo } from "react";
import { Loader } from ".";
import { Permissions } from "../../types";
import { ShowIfAuthorised } from "../authentication";
import { Button, Checkbox, Dropdown, Header, Input, Link } from "./controls";
import { BaseControl } from "./controls/BaseControl";
import TransferList from "./controls/TransferList";
import type {
  AltChangeHandler,
  ChangeHandler,
  Checkbox as TCheckbox,
  Config,
  Content,
  Dropdown as TDropdown,
  FormInput,
  Mode,
  Section,
  TransferList as TTransferList,
} from "./types/Modify";

export interface Props<D extends object> {
  data: D;
  componentConfiguration: Config<D> | ((data: D) => Config<D>);
  handleChange: ChangeHandler;
  altHandleChange: AltChangeHandler<D>;
  handleReset: () => void;
  mode: Mode;
  handleModeSwitch: () => void;
  validateForm: () => void;
  loading?: boolean;
  permissions: Permissions | null;
  setFormData: React.Dispatch<React.SetStateAction<D>>;
  validationResults: { [key: string]: string[] } | null;
  jwt: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    background: theme.palette.background.paper,
  },
  control: {
    padding: "0.5rem",
  },
}));

export const ModifyComponent = <D extends object>(
  props: Props<D>
): JSX.Element => {
  const {
    data,
    componentConfiguration: inputConfiguration,
    handleChange,
    altHandleChange,
    handleReset,
    validateForm,
    loading,
    mode,
    permissions,
    validationResults,
    setFormData,
  } = props;
  const classes = useStyles();

  const componentConfiguration =
    typeof inputConfiguration === "function"
      ? inputConfiguration(data)
      : inputConfiguration;

  const configuration = useMemo(() => {
    return componentConfiguration.filter((input) => {
      let result = true;
      // not sure if there should be rules on these taking
      // precedence over each other (probably should be)

      if (input.modes) result = input.modes.includes(mode);
      // will be completely static, use with caution, won't be able to react
      // to state updates
      if (input.filters) result = input.filters.some((f) => f);

      return result;
    });
  }, [componentConfiguration, mode]);

  const renderComponent = (config: Content<D>) => {
    if (config.modes) {
      const isCorrectMode = config.modes.includes(mode);
      if (!isCorrectMode) return null;
    }

    const disabled = mode === "view";
    let errorMessage = "";

    switch (config.controltype) {
      case "input":
        const inputConfig: FormInput<D> = {
          value: data[config.name],
          disabled,
          ...config,
        };
        if (validationResults?.[config.name]) {
          inputConfig.errorMessage = validationResults[config.name].join(", ");
        }
        return <Input<D> config={inputConfig} handleChange={handleChange} />;
      case "header":
        return <Header config={config} />;
      case "link":
        return <Link config={config} />;
      case "dropdown":
        errorMessage = "";
        const dropdownConfig: TDropdown<D> = {
          value: data[config.name],
          disabled,
          ...config,
        };
        if (validationResults?.[config.name]) {
          errorMessage = validationResults[config.name].join(", ");
        }

        return (
          <Dropdown<D>
            config={dropdownConfig}
            handleChange={handleChange}
            errorMessage={errorMessage}
          />
        );
      case "transferlist":
        errorMessage = "";
        const { getOptions, ...tListConfig } = config;
        const transferListConfig: TTransferList<D> = {
          disabled,
          ...tListConfig,
          ...config,
          value: data[config.name],
        };
        if (validationResults?.[config.name]) {
          errorMessage = validationResults[config.name].join(", ");
        }

        const getOptionsValues: Partial<D> = {};
        getOptions[1].forEach((key) => (getOptionsValues[key] = data[key]));

        return (
          <TransferList<D>
            config={transferListConfig}
            handleChange={altHandleChange}
            getOptions={getOptions[0]}
            getOptionsParams={getOptionsValues}
          />
        );
      case "button":
        return <Button config={config} />;
      case "checkbox":
        errorMessage = "";
        const checkboxConfig: TCheckbox<D> = {
          disabled,
          ...config,
        };
        if (validationResults?.[config.name]) {
          errorMessage = validationResults[config.name].join(", ");
        }

        return (
          <Checkbox
            config={checkboxConfig}
            setFormData={setFormData}
            //@ts-ignore
            checkData={data[config.name]}
            errorMessage={errorMessage}
          />
        );
      case "custom":
        const { Component } = config;
        return <Component {...props} disabled={disabled} />;
    }
  };

  const renderConfiguration = (
    { key, content, userAccess }: Section<D>,
    index: number
  ) => {
    const section = (
      <Box key={key} display="flex" flexDirection="row">
        <Grid key={`${key}-${index}`} container>
          {content.map((config, idx) => {
            let { xs = 12, md = 6 } = config;
            const layoutProps = {
              sm: config.sm,
              xl: config.xl,
              lg: config.lg,
            };

            if (config.controltype === "header") md = 12;
            return (
              <Grid
                key={`${key}-${index}-${idx}`}
                {...layoutProps}
                item
                xs={xs}
                md={md}
              >
                <BaseControl
                  padded={!config.hasOwnProperty("fullwidth")}
                  control={
                    config.controltype !== "button" &&
                    !config.hasOwnProperty("fullwidth")
                  }
                  noTopMargin={!config.hasOwnProperty("label")}
                >
                  {renderComponent(config)}
                </BaseControl>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    );

    return userAccess ? (
      <ShowIfAuthorised userPermissions={permissions} {...userAccess}>
        {section}
      </ShowIfAuthorised>
    ) : (
      <>{section}</>
    );
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    validateForm();
  };

  return (
    <Loader active={loading}>
      <div className={clsx(classes.root)}>
        <form onSubmit={handleSubmit} onReset={handleReset} noValidate>
          {configuration.map((section, index) => (
            <Fragment key={`configuration-${section.key}`}>
              {renderConfiguration(section, index)}
            </Fragment>
          ))}
        </form>
      </div>
    </Loader>
  );
};
