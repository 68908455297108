import { makeStyles } from "@material-ui/core";
import { ChangeEvent, useState } from "react";
import { useCancelToken } from "../../../hooks/general";
import { notifications } from "../../../libs/notifications";
import { appStrings as strings } from "../../../resources/strings";
import { uploadImages } from "../../../services/images.service";
import { RouteProps } from "../../../types";
import BreadcrumbHeader from "../BreadcrumbHeader";
import { Loader } from "../Loader";
import { BucketGallery } from "./BucketGallery";
import { BucketSelect } from "./Components/Upload/BucketSelect";

interface Props extends RouteProps {}
type Component = (props: Props) => JSX.Element;

export const ImageUpload: Component = ({ jwt }) => {
  const classes = useStyles();
  const cancelToken = useCancelToken();

  const [clientId, setClientId] = useState("");
  const [flightId, setFlightId] = useState("");

  const [loadingText, setLoadingText] = useState("");
  const [loadingValue, setLoadingValue] = useState<number | undefined>();

  const setProgress = (message: string, complete?: number, total?: number) => {
    setLoadingText(message);
    setLoadingValue(complete && total ? (complete / total) * 100 : undefined);
  };

  const handleUploads = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files) throw new Error("No files in upload event");

    try {
      await uploadImages(
        files,
        { clientId, flightId },
        cancelToken,
        setProgress
      );
    } catch (e) {
      notifications.error(strings.notifications.messages.submitError);
    }

    setProgress("");
  };

  const handleClientId = (clientId: string) => {
    setClientId(clientId);
    setFlightId("");
  };

  const handleFlightId = (flightId: string) => setFlightId(flightId);

  return (
    <>
      <BreadcrumbHeader crumbs={[{ name: strings.menuLabels.imageUploader }]} />

      <BucketSelect
        imageParameters={{ clientId, flightId }}
        handleClientId={handleClientId}
        handleFlightId={handleFlightId}
        jwt={jwt}
        className={classes.bucketSelect}
      />

      <Loader
        active={!!loadingText}
        text={loadingText}
        value={loadingValue}
        inline
      >
        <BucketGallery
          imageParameters={{ clientId, flightId }}
          pageSize={12}
          handleUploads={handleUploads}
          showInfoBar
        />
      </Loader>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  bucketSelect: {
    borderBottom: `${theme.spacing(0.1)}px solid ${theme.palette.divider}`,
    background: theme.backgroundShades.white,
  },
}));
