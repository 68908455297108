import type { Mode } from "../../components/general/types/Modify";
import { useCancelToken } from "../../hooks/general";
import { FLIGHTS_ENDPOINT } from "../../libs/config";
import { paths } from "../../navigation";
import { RouteProps } from "../../types";
import type { FlightData } from "../../types/documents";
import { ModifyContainer } from "../general";
import { componentConfigurations, constraints } from "./resources";

const putEndpoint = `${FLIGHTS_ENDPOINT}/submit`;
const queryEndpoint = `${FLIGHTS_ENDPOINT}`;
const initialData = {
  gpxKey: "",
  aircraftId: "",
  observerName: "",
  start: "",
  transitToSite: "",
  startAssessment: "",
  finishAssessment: "",
  transitToBase: "",
  finish: "",
  abort: "",
  state: 0,
  packageId: "",
  techLogNumber: "",
  reference: "",
};

export interface Props extends RouteProps {
  mode: Mode;
}
type Component = (props: Props) => JSX.Element;

export const FlightModify: Component = ({ mode, ...routeProps }) => {
  const cancelToken = useCancelToken();

  return (
    <ModifyContainer<FlightData>
      {...routeProps}
      mode={mode}
      putEndpoint={putEndpoint}
      queryEndpoint={queryEndpoint}
      initialData={initialData}
      componentConfiguration={[
        componentConfigurations.details(mode, routeProps.jwt, cancelToken, {}),
      ]}
      keysToRemove={[
        "createdById",
        "updatedById",
        "createdDate",
        "updatedDate",
        "gpxKey",
        "reference",
        "package",
        "aircraft",
        "start",
        "finish",
        "transitToSite",
        "startAssessment",
        "finishAssessment",
        "transitToBase",
        "abort",
        "state",
      ]}
      constraints={constraints}
      redirectPath={(id) => paths.packages.view(id)}
    />
  );
};
