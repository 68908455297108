import { CancelToken } from "axios";
import {
  Content,
  LayoutProps,
  Mode,
  Section,
} from "../components/general/types/Modify";
import { PDG_ROLE_TYPE } from "../libs/config";

export interface CognitoUser {
  username: string;
  tenantId: string;
  clientId?: string;
  userId: string;
  roleId: string;
  roleType: string;
  email: string;

  isSuperTenant(): boolean;
  isSuperAdmin(): boolean;
}

export class CognitoUser implements CognitoUser {
  constructor(userDetails: { [key: string]: any }) {
    this.username = userDetails["cognito:username"];
    this.tenantId = userDetails["custom:tenantId"];
    this.clientId = userDetails["custom:clientId"];
    this.roleId = userDetails["custom:roleId"];
    this.roleType = userDetails["custom:roleType"];
    this.email = userDetails["email"];
    this.userId = userDetails["sub"];
  }

  isSuperAdmin(): boolean {
    return this.roleId.toLowerCase() === "10d742ed-0c45-45d9-97c3-8b9afdc04bf8";
  }

  isSuperTenant(): boolean {
    return this.roleType.toLowerCase() === PDG_ROLE_TYPE?.toLowerCase();
  }
}

export interface Permissions {
  [key: string]: string[];
}

export interface RouteProps {
  permissions: Permissions | null;
  user: CognitoUser | null;
  jwt: string;
}

export interface UserAccess {
  entity: string;
  permission: string;
  override?: (user: CognitoUser) => boolean;
}

export interface TagConfig {
  endpoint: string;
  jwt: string;
  tagGroup: string;
}

export interface ComponentConfigurations<T extends object> {
  details(
    mode: Mode,
    roles: DropdownOptions,
    jwt: string,
    otherContent?: Content<any> & LayoutProps
  ): Section<T>;
}

export interface BasicComponentConfigurations<T extends object> {
  details(
    mode: Mode,
    jwt: string,
    cancelToken: CancelToken,
    data: any,
    configExtraData?: any
  ): Section<T>;
}

export type DropdownOption = {
  label: string;
  value: string;
  disabled?: boolean;
  header?: boolean;
};

export type DropdownOptions = Array<DropdownOption>;

export type DropdownOptionsWithCounts = Array<
  DropdownOption & { totalCount: number }
>;

export type DropdownMap = {
  name: string;
  index: Number;
  key: string;
  text: string;
};

export type DropdownMappings = Array<DropdownMap>;
export type { TDateFormat } from "./types";
