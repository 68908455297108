import { FormControl, makeStyles } from "@material-ui/core";
import clsx from "clsx";

interface Props {
  children: JSX.Element | null;
  noTopMargin?: boolean;
  control?: boolean;
  padded?: boolean;
  direction?: "column" | "row";
}
type Component = (props: Props) => JSX.Element;

const useStyles = makeStyles((theme) => ({
  noTopMargin: {
    "& .MuiInputBase-root": {
      marginTop: "0 !important",
    },
  },
  control: {
    "& .MuiInput-input": {
      background: theme.palette.background.default,
      border: theme.spacing(0.125, "solid", `${theme.border.primary}`),
      padding: theme.spacing(1),
    },
    "& .MuiFormGroup-root": {
      flexDirection: "row",
    },
  },
  padded: {
    padding: theme.spacing(1.5, 2.5),
  },
  row: {
    flexDirection: "row",
  },
}));

export const BaseControl: Component = ({
  children,
  noTopMargin,
  control,
  padded = true,
  direction = "column",
}) => {
  const classes = useStyles();

  return (
    <FormControl
      className={clsx(
        control && classes.control,
        padded && classes.padded,
        noTopMargin && classes.noTopMargin,
        direction === "row" && classes.row
      )}
      fullWidth
      color="primary"
    >
      {children}
    </FormControl>
  );
};
