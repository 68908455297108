import { Auth } from "./amplify";

export const signOut = async () => {
  try {
    await Auth.signOut();
  } catch (e) {}
};

export const hostedSignIn = async (_type?: string) => {
  try {
    await Auth.federatedSignIn();
  } catch (e: any) {
    console.log(e.message ? e.message : e);
  }
};

export const getUser = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    return Promise.resolve(user);
  } catch (e: any) {
    return Promise.reject(e);
  }
};

export const getIdToken = async () => {
  try {
    // getting the current session should keep the tokens fresh
    const session = await Auth.currentSession();
    const token = session.getIdToken().payload;
    return Promise.resolve(token);
  } catch (e: any) {
    return Promise.reject(e);
  }
};

export const getToken = async () => {
  try {
    // getting the current session should keep the tokens fresh
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    return Promise.resolve(token);
  } catch (e: any) {
    return Promise.reject(e);
  }
};

export const signInWithDetails = async (email: string, password: string) => {
  try {
    const response = await Auth.signIn({ username: email, password });
    return Promise.resolve(response);
  } catch (e: any) {
    const error = e.message ? e.message : e;
    console.log(error);
    return Promise.reject(error);
  }
};

export const completeNewPassword = async (
  user: object,
  newPassword: string
) => {
  try {
    await Auth.completeNewPassword(user, newPassword);
    return Promise.resolve();
  } catch (e: any) {
    const error = e.message ? e.message : e;
    console.log(error);
    return Promise.reject(error);
  }
};

export const forgotPassword = async (email: string) => {
  try {
    await Auth.forgotPassword(email);
    return Promise.resolve();
  } catch (e: any) {
    const error = e.message ? e.message : e;
    console.log(error);
    return Promise.reject(error);
  }
};

export const forgotPasswordSubmit = async (
  email: string,
  code: string,
  newPassword: string
) => {
  try {
    await Auth.forgotPasswordSubmit(email, code, newPassword);
    return Promise.resolve();
  } catch (e: any) {
    const error = e.message ? e.message : e;
    console.log(error);
    return Promise.reject(error);
  }
};
