import React from "react";
import ReactDOM from "react-dom";
import "./assets/index.css";
import {App, OfflineApp} from "./components/App";
import reportWebVitals from "./utils/reportWebVitals";

import {Wrappers} from "./components";

/*
  See readme for comment on strict mode Material UI error
*/

ReactDOM.render(
  <React.StrictMode>
    <Wrappers>
      {!!process.env.REACT_APP_OFFLINE ? <OfflineApp/> : <App/>}
    </Wrappers>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
