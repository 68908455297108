export const paths = {
  root: "/",
  auth: {
    login: "/login",
    passwordReset: "/passwordReset",
  },
  help: "/help",
  dailyInfoLog: "/dailyInfoLog",
  map: "/map",
  bookings: {
    list: "/bookings",
    create: "/bookings/create",
    view: (id: string) => `/bookings/view/${id}`,
  },
  schedules: {
    list: "/schedules",
    view: (id: string) => `/schedules/view/${id}`,
  },
  transportBookings: {
    list: "/transportBookings",
    create: "/transportBookings/create",
    view: (id: string) => `/transportBookings/view/${id}`,
  },
  projects: {
    list: "/projects",
    create: "/projects/create",
    view: (id: string) => `/projects/view/${id}`,
    gradingReport: (projectId: string) =>
      `/projects/gradingReport/${projectId}`,
  },
  packages: {
    create: (id: string) => `/projects/${id}/packages/create`,
    view: (id: string) => `/packages/view/${id}`,
    viewFlights: (pkgId: string) => `/packages/flights/${pkgId}`,
    gradingForm: (pkgId: string) => `/packages/gradingForm/${pkgId}`,
  },
  flights: {
    view: (flightId: string) => `/flights/${flightId}`,
    viewGpx: (flightId: string, id?: string) => {
      return id
        ? `/flights/view/${flightId}/${id}`
        : `/flights/view/${flightId}`;
    },
    createFlight: (clientId: string, packageId: string, featureId?: string) =>
      featureId
        ? `/flights/create/${clientId}/${packageId}/${featureId}`
        : `/flights/create/${clientId}/${packageId}`,
  },
  users: {
    list: "/users",
    create: "/users/create",
    view: (id: string) => `/users/view/${id}`,
  },
  settings: {
    list: "/settings",
  },
  tags: {
    list: "/tags",
    view: (id: string) => `/tags/view/${id}`,
  },
  clients: {
    list: "/clients",
    create: "/clients/create",
    view: (id: string) => `/clients/view/${id}`,
    lines: {
      list: (clientId: string) => `/clients/lines/${clientId}`,
      create: (clientId: string) => `/clients/lines/${clientId}/create`,
      view: (
        clientId: string,
        lineId: string
      ) => `/clients/lines/${clientId}/view/${lineId}`,
    },
  },
  assets: {
    upload: (id: string) => `/assets/upload/${id}`,
  },
  mapFeature: (id: string) => `${paths.map}/${id}`,
  images: {
    view: "/Images/View",
    viewInline: "/Images/Inline",
  },
  formDesigner: {
    create: `/formDesigner/create`,
    edit: (id: string) => `/formDesigner/edit/${id}`,
    list: "/formDesigner",
  },
  offline: {
    settings: (id: string) => `/Settings/${id}`,
    sync: "/Sync",
  },
};
