import { useCallback, useContext, useEffect, useMemo } from "react";
import { Loading, usePointsData } from "../../../hooks/map/usePointsData";
import { useWebGlPointsLayer } from "../../../hooks/map/useWebGlPointsLayer";
import { mapStrings as strings } from "../../../resources/strings";
import { AssetGeoJson } from "../../../types/documents/Assets";
import { useGeoJSON } from "../helpers/GeoJSON";
import { assetVoltageStyle } from "../helpers/Style";
import MapContext from "../MapContext";

interface Props {
  id: string;
  jwt: string;
  name: string;
  setLoading?: (v: boolean) => void;
}

type Component = (props: Props) => JSX.Element;

export const ClientAssetsLayer: Component = ({ jwt, name, id, setLoading }) => {
  const voltageLayerProperties = useMemo(() => {
    return {
      title: strings.layers.assets(name),
      layerType: strings.layerTypes.webgl,
    };
  }, [name]);

  const voltageStyle = useMemo(() => {
    return assetVoltageStyle;
  }, []);

  const map = useContext(MapContext);
  const format = useGeoJSON();
  const [, , voltageAddDataToSource] = useWebGlPointsLayer({
    map,
    format,
    style: voltageStyle,
    properties: voltageLayerProperties,
  });

  const addDataToSource = useCallback(
    (data: AssetGeoJson) => {
      voltageAddDataToSource(data);
    },
    [voltageAddDataToSource]
  );

  const [loadingPointData] = usePointsData({
    id,
    jwt,
    addDataToSource,
  });

  useEffect(() => {
    if (!setLoading) return;
    setLoading(loadingPointData === Loading.Loading);
  }, [loadingPointData, setLoading]);

  return <></>;
};
