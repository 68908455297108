import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React, { useCallback, useRef } from "react";
import { Loader } from "./Loader";
import { FileListRow } from "./FileListRow";
import { useS3Upload } from "../../hooks/general/useS3Upload";
import { HooksLogger } from "../../hooks/hooks-logger";
import { useCancelToken } from "../../hooks/general";
import { notifications } from "../../libs/notifications";
import { File, FileUploadResult } from "../../types/FileUpload";

const logger = new HooksLogger("ShapeFileList");

export interface Props<F extends File> {
  processing: boolean,
  loading: boolean,
  files: readonly F[],
  acceptFileTypes?: string,

  onProcessFile?: (file: F) => () => void,

  onDeleteFile(object: string): void,

  getUploadUrls(fileNames: string[]): Promise<FileUploadResult>;

  onFilesUploaded(): void;
}

type Component = <F extends File, >(props: Props<F>) => JSX.Element;

export const FileList: Component = <F extends File, >(props: Props<F>) => {
  const {
    files,
    acceptFileTypes,
    onProcessFile,
    getUploadUrls,
    processing,
    onDeleteFile,
    onFilesUploaded,
    loading
  } = props;
  const classes = useStyles();
  const cancelToken = useCancelToken();
  const setError = useCallback(e => notifications.error(e), []);

  const uploadFile = useRef<HTMLInputElement | null>(null);
  const [uploadHandler, uploading, uploadProgress] = useS3Upload(
    "Uploading Files",
    getUploadUrls,
    logger,
    cancelToken,
    onFilesUploaded,
    error => {
      setError(error);
    }
  );

  const disableButtons = uploading || processing;

  return <>
    <Grid
      className={clsx(classes.header)}
      container
      direction="row"
      justifyContent="space-between"
    >
      <Grid item>
        <Typography variant="h6" className={classes.headerCrumb}>
          Uploaded Files
        </Typography>
      </Grid>
      <Grid item>
        <input type="file" ref={uploadFile} multiple onChange={uploadHandler}
               style={{ display: "none" }}
               accept={acceptFileTypes || ".csv,.shp,.shx,.dbf"}/>
        <Button color="primary" variant="contained"
                onClick={() => uploadFile.current?.click()}
                disabled={disableButtons}>
          Upload Files
          {uploading &&
           <CircularProgress variant="determinate" value={uploadProgress}/>}
        </Button>
      </Grid>
    </Grid>

    <Loader active={loading} inline={true}>
      <TableContainer>
        <Table size="small" component={Paper} className={classes.table}>
          <TableHead className={classes.tableHeader}>
            <TableRow>
              <TableCell>File Name</TableCell>
              <TableCell>File Type</TableCell>
              <TableCell>Size</TableCell>
              <TableCell/>
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            {files.map(file => <FileListRow key={file.name} file={file}
                                            onDeleteFile={onDeleteFile}
                                            disableButtons={disableButtons}
                                            onProcessFile={onProcessFile?.(file)}/>)}
          </TableBody>
        </Table>
      </TableContainer>
    </Loader>
  </>;
};

const useStyles = makeStyles((theme) => ({
  header: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    backgroundColor: theme.palette.background.default,
    borderBottom: theme.spacing(0.125, "solid", `${theme.border.primary}`),
  },
  headerCrumb: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    textTransform: "uppercase",
    fontSize: theme.spacing(2.3),
  },
  table: {
    tableLayout: "fixed",
    "& tr > *:nth-child(1)": {
      width: "20%",
    },
    "& tr > *:nth-child(2)": {
      width: "10%",
    },
    "& tr > *:nth-child(3)": {
      width: "10%",
    }
  },
  tableHeader: {
    background: theme.palette.background.default,
    "& th": { fontWeight: 600 }
  },
  tableBody: {
    "& > *:nth-child(even)": {
      background: theme.palette.background.default,
    },
  },
}));
