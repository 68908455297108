import { Grid, Typography } from "@material-ui/core";
import { MapContainer } from "../map/MapContainer";
import React from "react";
import { ClientLinesLayer } from "../map/layers/ClientLinesLayer";
import { ClientTileLayer } from "../map/layers/ClientTileLayer";
import { OSMLayer } from "../map/layers/OSMLayer";


export interface Props {
  data: {
    id: string,
    clientId: string,
  },
  jwt: string,
}

export const LineLayerPreview = ({ data, jwt }: Props) => {
  const { id: lineId, clientId } = data;

  return <>
    <Grid
      container
      direction="row"
      justifyContent="space-between"
    >
      <Grid item>
        <Typography variant="h6">Preview</Typography>
      </Grid>
    </Grid>
    <div style={{ height: "720px" }}>
      <MapContainer header={false}>
        <OSMLayer name={"OpenStreetMap"} defaultVisible={true}/>
        <ClientTileLayer name={"Client Tiles"} clientId={clientId}
                         zoomToExtent={false}/>
        <ClientLinesLayer name={"Client Lines"} clientId={clientId}
                          lineId={lineId} jwt={jwt}/>
      </MapContainer>
    </div>
  </>
}
