import {
  Avatar,
  Box,
  CardMedia,
  Grid,
  styled,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import bgImage from "../../assets/pdg_fleet.jpeg";
import logo from "../../assets/svgs/pdg-logo-colour.svg";
import { authStrings as strings } from "../../resources/strings/auth";

interface Props {
  children: JSX.Element | JSX.Element[];
}
type Component = (props: Props) => JSX.Element;

interface LayoutProps {
  children: JSX.Element;
  bgImage: string;
  logo: string;
  legalText: string;
}

const useStyles = makeStyles((theme) => ({
  top: {
    flexGrow: 1,
    height: "100vh",
    backgroundColor: "white",
  },
  image: {
    height: "100%",
    width: "auto",
  },
  logo: {
    height: "150px",
    width: "auto",
    padding: "30px 0",
    "& img": {
      objectFit: "contain",
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignContent: "center",
    height: "100%",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
}));

const Div = styled("div")({});

const PageLayout = ({ children, bgImage, logo, legalText }: LayoutProps) => {
  const classes = useStyles();

  return (
    <Div className={clsx(classes.top)}>
      <Box display="flex" flexDirection="row" height="100vh" maxHeight="100vh">
        <Grid container>
          <Grid item sm={12} md={4} className={clsx(classes.container)}>
            <div />
            <Grid item xs={9} className={clsx(classes.content)}>
              <Avatar
                src={logo}
                variant="square"
                className={clsx(classes.logo)}
              />
              {children}
            </Grid>
            <div />
            <Typography variant="caption">{legalText}</Typography>
          </Grid>
          <Grid item md={8}>
            <CardMedia image={bgImage} className={clsx(classes.image)} />
          </Grid>
        </Grid>
      </Box>
    </Div>
  );
};

export const AuthWrapper: Component = ({ children }) => {
  return (
    <PageLayout bgImage={bgImage} logo={logo} legalText={strings.text.legal}>
      <Box display="flex" flexDirection="column" justifyContent={"center"}>
        {children}
      </Box>
    </PageLayout>
  );
};
