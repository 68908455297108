import { makeStyles } from "@material-ui/core";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { useImageUrls } from "../../../../../hooks/images/useImageUrls";
import { ImageMetadata } from "../../../../../types/documents/Images";
import { ZoomableImageControls } from "./ZoomableImageControls";

interface Props {
  selectedImage: ImageMetadata;
  hasPrev: boolean;
  hasNext: boolean;
  onPrev: () => void;
  onNext: () => void;
}

type Component = (props: Props) => JSX.Element;
export const ZoomableImage: Component = ({
  selectedImage,
  hasPrev,
  hasNext,
  onPrev,
  onNext,
}) => {
  const classes = useStyles();

  const urls = useImageUrls({ type: "main", imageMetadata: selectedImage });
  const imageUrl = urls[selectedImage.fileName];

  return (
    <TransformWrapper minScale={0.01} centerOnInit>
      {(handlers) => (
        <>
          <TransformComponent wrapperClass={classes.imageWrapper}>
            <img
              id="img-element"
              src={imageUrl}
              alt={selectedImage.fileName}
              onLoad={() => handlers.zoomToElement("img-element", undefined, 0)}
            />
          </TransformComponent>

          <ZoomableImageControls
            {...handlers}
            hasPrev={hasPrev}
            hasNext={hasNext}
            onPrev={onPrev}
            onNext={onNext}
            timestamp={selectedImage.taken}
          />
        </>
      )}
    </TransformWrapper>
  );
};

const useStyles = makeStyles(() => ({
  imageWrapper: {
    maxHeight: "calc(80vh - 50px)",
    width: "100%",
    background: "#dddddd",
  },
}));
