// Cognito config
export const COGNITO_DOMAIN = process.env.REACT_APP_COGNITO_DOMAIN;
export const COGNITO_REGION = process.env.REACT_APP_COGNITO_REGION;
export const COGNITO_USER_POOL_ID = process.env.REACT_APP_COGNITO_USER_POOL_ID;
export const COGNITO_IDENTITY_POOL_ID =
  process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID;
export const COGNITO_APP_CLIENT_ID =
  process.env.REACT_APP_COGNITO_APP_CLIENT_ID;
export const COGNITO_SIGNIN_URL = process.env.REACT_APP_COGNITO_SIGNIN_URL;
export const COGNITO_SIGNOUT_URL = process.env.REACT_APP_COGNITO_SIGNOUT_URL;

//Parameters
export const PDG_ROLE_TYPE = process.env.REACT_APP_PDG_ROLE_TYPE;

// Endpoints
export const PERMISSIONS_ENDPOINT = `${process.env.REACT_APP_PERMISSIONS_ENDPOINT}/permissions`;
export const USERS_ENDPOINT = `${process.env.REACT_APP_USERS_ENDPOINT}/users`;
export const TAGS_ENDPOINT = `${process.env.REACT_APP_TAGS_ENDPOINT}/tags`;
export const BOOKINGS_ENDPOINT = `${process.env.REACT_APP_BOOKINGS_ENDPOINT}/bookings`;
export const TRANSPORTS_ENDPOINT = `${process.env.REACT_APP_TRANSPORTS_ENDPOINT}/transportBookings`;
export const CLIENTS_ENDPOINT = `${process.env.REACT_APP_CLIENTS_ENDPOINT}/clients`;
export const LINES_ENDPOINT = `${process.env.REACT_APP_LINES_ENDPOINT}/lines`;
export const PROJECTS_ENDPOINT = `${process.env.REACT_APP_PROJECTS_ENDPOINT}/projects`;
export const PACKAGES_ENDPOINT = `${process.env.REACT_APP_PACKAGES_ENDPOINT}/packages`;
export const MAP_FEATURES_ENDPOINT = `${process.env.REACT_APP_MAP_FEATURES_ENDPOINT}/map-features`;
export const FLIGHTS_ENDPOINT = `${process.env.REACT_APP_FLIGHTS_ENDPOINT}/flights`;
export const GPX_ENDPOINT = `${process.env.REACT_APP_FLIGHTS_ENDPOINT}/gpx`;
export const GRADINGS_ENDPOINT = `${process.env.REACT_APP_GRADINGS_ENDPOINT}/gradings`;
export const ASSETS_ENDPOINT = `${process.env.REACT_APP_ASSETS_ENDPOINT}/assets`;
export const ASSET_FILTERS_ENDPOINT = `${process.env.REACT_APP_ASSETS_ENDPOINT}/asset-filter`;
export const JOBS_ENDPOINT = `${process.env.REACT_APP_JOBS_ENDPOINT}`;
export const IMAGES_ENDPOINT = `${process.env.REACT_APP_IMAGES_ENDPOINT}/images`;
export const DEFECT_TYPES_ENDPOINT = `${process.env.REACT_APP_DEFECT_TYPES_ENDPOINT}`;
export const MAP_TILES_ENDPOINT = `${process.env.REACT_APP_MAP_TILES_ENDPOINT}/map-tiles`;
export const GPS_ENDPOINT = process.env.REACT_APP_GPS_ENDPOINT
  ? `${process.env.REACT_APP_GPS_ENDPOINT}/gps`
  : "";
export const SYNC_ENDPOINT = process.env.REACT_APP_SYNC_ENDPOINT
  ? `${process.env.REACT_APP_SYNC_ENDPOINT}/sync`
  : "";

// Offline Endpoints
export const OFFLINE = process.env.REACT_APP_OFFLINE;
export const OFFLINE_SETTINGS_ENDPOINT =
  process.env.REACT_APP_OFFLINE &&
  `${process.env.REACT_APP_MAP_TILES_ENDPOINT}/settings`;

// App config
export const APP_DOMAIN = process.env.REACT_APP_DOMAIN;
