import { v4 as uuid } from "uuid";
import { buildFqn } from "./fqnHelper";
import { SectionRecord, SectionType } from "./interfaces";
import { transformSectionJson } from "./sectionHelper";

export async function generateJob(
  form: any,
  assetId: string,
  userId: string | null,
  tenantId: string | null,
  flightId: string
) {
  const jobType = form.jobType;

  //Add the fields used in the database Jobs table that are not provided in the request to create a job
  const newFields = {
    id: uuid(),
    name: jobType.name,
    defectTypeId: jobType.id,
    defectTypeGroupId: jobType.defectTypeGroupId,
    tenantId: tenantId ? tenantId : null,
    createdById: userId ? userId : null,
    updatedById: userId ? userId : null,
    contactId: null,
    active: true,
    stateId: "c9d91ba9-2d2e-4684-a102-469d93c4360e", //InProgress
    parentJobId: null,
    isGroupJob: false,
    deviceId: null,
    syncToDevice: false,
    assetId: assetId.toUpperCase(),
    jobRecurrenceId: null,
    rejectionReasonId: null,
    numericCode: 0,
    completedDate: "",
    monitoringRegimeId: null,
    flightId: flightId,
  };

  const allFields = { ...jobType, ...newFields };
  const job = Object.assign({}, allFields);
  return job;
}

export function generateSections(
  jobId: string,
  userId: string | null,
  tenantId: string | null,
  jobType: any
): [SectionRecord] | [] {
  const { sectionTypes }: { sectionTypes: SectionType[]; templates: any } =
    jobType; //The templates item is normally blank
  console.log("sectionTypes", sectionTypes);

  const sectionData: any = sectionTypes.map((sectionType) => {
    //Set a section id for the new section
    const sectionId = uuid();
    const sectionFqn = buildFqn({ sectionId, sectionTypeId: sectionType.id });

    //Update the section JSON from a "form" format to a "job" format
    const sectionJson = transformSectionJson(sectionType.jsonData, sectionId);

    return {
      sectionId: sectionId,
      sectionName: sectionType.name,
      tenantId: tenantId ? tenantId : null,
      jobId: jobId,
      sectionTypeId: sectionType.id,
      sectionFormData: sectionJson,
      repeatable: sectionType.repeatable,
      template: sectionType.template,
      active: sectionType.active,
      sectionCaption: sectionType.caption,
      order: sectionType.order,
      fqn: sectionFqn,
      visible: sectionType.visible,
      gridInput: sectionType.gridInput,
      internalSectionTypeId: sectionType.internalId,
      siteVisit: sectionType.siteVisit,
      state: "c9d91ba9-2d2e-4684-a102-469d93c4360e", //InProgress
      createdById: userId ? userId : null,
      updatedById: userId ? userId : null,
    };
  });

  return sectionData;
}
