import { CancelToken } from "axios";
import { API } from "../API";
import axios from "../libs/axios/axios-instance";
import { GRADINGS_ENDPOINT } from "../libs/config";
import { GradingForm } from "../types/gradingForm";

const baseUrl = GRADINGS_ENDPOINT;
const axiosInstance = axios(baseUrl);

export const getGradingForm = async (
  jwt: string,
  cancelToken: CancelToken,
  packageId: string,
  assetContainerId: string
) => {
  const api = new API<GradingForm | undefined>(cancelToken, jwt);

  return api.get(`${baseUrl}/packageAsset/${packageId}/${assetContainerId}`);
};

export const submitGradingForm = async (
  jwt: string,
  cancelToken: CancelToken,
  gradingForm: GradingForm
) => {
  const config = { cancelToken };
  await axiosInstance.post("/packageAsset/", gradingForm, config);
};

export const getProjectGradings = async (
  jwt: string,
  cancelToken: CancelToken,
  projectId: string
) => {
  const api = new API<any>(cancelToken);

  return api.list({}, `${baseUrl}/project/${projectId}`);
};

export const getLatestAssetGradingData = async (
  jwt: string,
  cancelToken: CancelToken,
  assetId: string
) => {
  const api = new API<any>(cancelToken);

  const result = await api.get(`${baseUrl}/asset/${assetId}`);
  console.log("API RESULT", result, "for asset", assetId);

  return result;
};
