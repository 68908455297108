import { Button, Dialog, DialogContent, makeStyles } from "@material-ui/core";
import { Feature, Map } from "ol";
import { Fragment, useMemo } from "react";
import { useFeatureSelector } from "../../../hooks/map/useFeatureSelector";
import { assetDetailsStrings, mapStrings } from "../../../resources/strings";

const MODAL_WIDTH = 200;
const MODAL_HEIGHT = 150;
const MODAL_OFFSET = 16;

type Props = {
  clientName: string;
  map: Map | undefined;
  handleFeature: (layer: string, feature: Feature) => void;
  container: HTMLElement | null;
};

export const FeatureSelectorModal = ({
  clientName,
  map,
  handleFeature,
  container,
}: Props) => {
  const classes = useStyles();

  const layers = useMemo(
    () => [
      mapStrings.layers.search,
      mapStrings.layers.status(clientName),
      mapStrings.layers.assets(clientName),
      mapStrings.layers.avoidanceZone,
      mapStrings.layers.pointOfInterest,
    ],
    [clientName]
  );

  const [groups, open, setOpen, coordinate] = useFeatureSelector({
    map,
    layers,
  });

  const [x, y] = useMemo(
    () => (coordinate.length < 2 ? [0, 0] : coordinate),
    [coordinate]
  );

  const viewport = map?.getViewport();

  const height = viewport?.clientHeight;
  const width = viewport?.clientWidth;

  // Adjust position if dialog would be too low or too far right
  const top =
    height && y > height - MODAL_HEIGHT - MODAL_OFFSET
      ? y - MODAL_HEIGHT - MODAL_OFFSET * 2
      : y;

  const left =
    width && x > width - MODAL_WIDTH - MODAL_OFFSET
      ? x - MODAL_WIDTH - MODAL_OFFSET * 2
      : x;

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      container={container}
      style={{
        position: "absolute",
        top: "64px",
      }}
      PaperProps={{
        style: {
          position: "absolute",
          top,
          left,
          margin: MODAL_OFFSET,
          height: MODAL_HEIGHT,
          width: MODAL_WIDTH,
          borderRadius: "0",
        },
      }}
      BackdropProps={{ style: { backgroundColor: "transparent" } }}
    >
      <DialogContent className={classes.dialogContent}>
        {groups.map((group) => (
          <Fragment key={group.title}>
            <h3 className={classes.featureHeader}>{group.title}</h3>

            {group.features.map((feature, index) => (
              <Button
                key={`$asset-selector-${group.title}-${index}`}
                className={classes.feature}
                onClick={() => {
                  handleFeature(group.title, feature);
                  setOpen(false);
                }}
              >
                {feature.get(assetDetailsStrings.propertyNames.displayName)}
              </Button>
            ))}
          </Fragment>
        ))}
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  dialogContent: { padding: "0 !important", textAlign: "left" },
  featureHeader: {
    marginTop: "0",
    backgroundColor: theme.palette.primary.main,
    color: theme.backgroundShades.white,
    fontWeight: 500,
    fontFamily: theme.typography.fontFamily,
    padding: "0px 10px",
    textTransform: "uppercase",
  },
  feature: {
    width: "100%",
    padding: "6px 10px",
    borderBottom: "1px solid #d6d6d6",
    justifyContent: "flex-start",
  },
}));
