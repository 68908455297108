import type { Axios } from "../libs/axios";
import axios from "../libs/axios/axios-instance";
import { LINES_ENDPOINT } from "../libs/config";
import { LineGeojson, LineLayerLoad } from "../types/documents/LineLayer";
import { FileUploadResult, ShapeFile } from "../types/FileUpload";

const baseUrl = LINES_ENDPOINT + "/";
const axiosInstance = axios(baseUrl);

export const listFiles = async (
  jwt: string,
  cancelToken: Axios.CancelToken,
  clientId: string
) => {
  let body = { clientId };
  const config = { cancelToken };
  const urlPath = `files`;
  const { data } = await axiosInstance.post<ShapeFile[]>(urlPath, body, config);
  return data;
};

export const listLinesGeojson = async (
  jwt: string,
  cancelToken: Axios.CancelToken,
  clientId: string
) => {
  let body = {};
  const config = { cancelToken };
  const urlPath = `geojson/${clientId}`;
  const { data } = await axiosInstance.post<LineGeojson[]>(
    urlPath,
    body,
    config
  );
  return data;
};

export const listLoads = async (
  jwt: string,
  cancelToken: Axios.CancelToken,
  clientId: string
) => {
  let body = { clientId };
  const config = { cancelToken };
  const urlPath = `files/loads`;
  const { data } = await axiosInstance.post<LineLayerLoad[]>(
    urlPath,
    body,
    config
  );
  return data;
};

export const getUploadUrls = async (
  jwt: string,
  cancelToken: Axios.CancelToken,
  clientId: string,
  files: string[]
): Promise<FileUploadResult> => {
  let body = {
    clientId,
    files,
  };
  const config = { cancelToken };
  const urlPath = `files/upload`;
  const { data } = await axiosInstance.post<FileUploadResult>(
    urlPath,
    body,
    config
  );
  return data;
};

export const deleteFile = async (jwt: string, file: string): Promise<void> => {
  let body = {
    file,
  };
  const config = {};
  const urlPath = `files/delete`;
  await axiosInstance.post<void>(urlPath, body, config);
};

export const processFile = async (
  jwt: string,
  cancelToken: Axios.CancelToken,
  clientId: string,
  file: ShapeFile
) => {
  try {
    let body = {
      clientId,
      fileName: file.name,
    };
    const config = { cancelToken };
    const urlPath = `files/process`;
    await axiosInstance.post<any>(urlPath, body, config);
  } catch (e: any) {
    console.log(e);
    throw e.message ?? e;
  }
};
