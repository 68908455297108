import { appStrings } from "./app";

export const searchStrings = {
  global: { ...appStrings },
  header: {},
  labels: {
    search: "Search",
    loadingSearchTypes: "Loading Search Types...",
    selectAClient: "Please select a client to search on",
    dateRange: "Date Range",
    to: "To:",
    from: "From:",
    circuitIds: "Circuit Ids",
    groupByKey: "Group By Key",
    groupByValues: "Group By Values",
    assetRange: "Asset Range",
    componentType: "Component Type",
    gradings: "Gradings",
  },
  notifications: {},
};
