import { useHistory } from "react-router-dom";
import { Column } from "react-table";
import {
  createdDateTableCellRenderer,
  updatedDateTableCellRenderer,
} from "../../components/general/table/DateTableCell";
import { isOfflineApp } from "../../helpers";
import { useIsAuthorised } from "../../hooks/authentication";
import { PROJECTS_ENDPOINT } from "../../libs/config";
import { paths } from "../../navigation";
import { projectStrings as strings } from "../../resources/strings";
import { RouteProps } from "../../types";
import type { ProjectData } from "../../types/documents";
import { TableContainer } from "../general";

interface Props extends RouteProps {}
type Component = (props: Props) => JSX.Element;

const endpoint = `${PROJECTS_ENDPOINT}/list-projects`;

export const ProjectList: Component = ({ jwt, ...routeProps }) => {
  const history = useHistory();

  const showClients =
    useIsAuthorised(
      routeProps.permissions,
      strings.global.entities.clients,
      strings.global.permissions.read
    ) || isOfflineApp();

  const clickHandler = ({ id }: ProjectData) => {
    history.push(paths.projects.view(id));
  };

  const viewReport = ({ id }: ProjectData) => {
    history.push(paths.projects.gradingReport(id));
  };

  return (
    <div>
      <TableContainer<ProjectData>
        {...routeProps}
        endpoint={endpoint}
        jwt={jwt}
        header={strings.header.projects}
        tableColumns={[
          {
            id: "jobCode",
            Header: strings.labels.jobCode,
            accessor: "jobCode",
          },
          ...(showClients
            ? [
                {
                  id: "client_name",
                  Header: "Client",
                  accessor: ({ client }) => client?.name,
                } as Column<ProjectData>,
              ]
            : []),
          {
            id: "numberOfPackages",
            Header: strings.labels.numberOfPackages,
            accessor: "numberOfPackages",
          },
          {
            id: "description",
            Header: strings.labels.description,
            accessor: "description",
          },
          {
            id: "createdDate",
            Header: strings.labels.dateCreated,
            accessor: "createdDate",
            Cell: createdDateTableCellRenderer,
          },
          {
            id: "updatedDate",
            Header: strings.labels.lastUpdated,
            accessor: "updatedDate",
            Cell: updatedDateTableCellRenderer,
          },
        ]}
        createButtonConfig={{
          label: strings.labels.createProject,
          userAccess: {
            entity: strings.global.entities.projects,
            permission: strings.global.permissions.create,
          },
          path: paths.projects.create,
        }}
        clickHandler={clickHandler}
        finalColumnButtonConfig={(defaultBtn) => {
          const buttons = [defaultBtn];
          if (!isOfflineApp()) {
            buttons.push({
              label: "Report",
              handler: (_event, row) => viewReport(row.original),
            });
          }

          return buttons;
        }}
        manualPagination={!isOfflineApp()}
      />
    </div>
  );
};
