import { useCallback } from "react";
import { useComponentList } from "../../../containers/gradingReport/hooks/useComponentList";
import {
  useAssetGroups,
  useAssetGroupValues,
  useAssetsByGroupAndValues,
} from "../../../hooks/assets";
import { useAssetGroupOptions } from "../../../hooks/assets/useAssetGroupOptions";
import { useAssetGroupValueOptions } from "../../../hooks/assets/useAssetGroupValueOptions";
import { useAssetOptions } from "../../../hooks/assets/useAssetOptions";
import { useCircuitOptions } from "../../../hooks/assets/useCircuitOptions";
import { useSearchAssetsRange } from "../../../hooks/assets/useSearchAssetsRange";
import { useSearchCircuitsByTowerSide } from "../../../hooks/assets/useSearchCircuitIds";
import { useConditionSearchData } from "../../../hooks/assets/useSearchData";
import { useGradingColumns } from "../../../hooks/gradings";
import { DropdownOptions, RouteProps } from "../../../types";
import { AssetDetails, AssetGeoJson } from "../../../types/documents/Assets";
import { ConditionSearchForm } from "./ConditionSearchForm";
import { ConditionSearch } from "./SearchPanel";
import { useAssetConditionSearch } from "./useAssetSearch";

interface ConditionSearchFormContainerProps extends RouteProps {
  clientId: string;
  projectType: string;
  handleSearchResults: (searchResults: AssetGeoJson) => void;
}
type ConditionSearchFormComponent = (
  props: ConditionSearchFormContainerProps
) => JSX.Element;

const initData: ConditionSearch = {
  dateFrom: "",
  dateTo: "",
  groupByKey: "",
  groupByValue: [],
  groupByValueFrom: "",
  groupByValueTo: "",
  circuitId: [],
  components: "",
  gradings: [],
};
const handleSelectedRow = () => {};
const hideCircuitIfNoIds = true;
const displayTooltip = false;

export const ConditionSearchFormContainer: ConditionSearchFormComponent = ({
  clientId,
  projectType,
  handleSearchResults,
  ...otherProps
}: ConditionSearchFormContainerProps) => {
  const { jwt } = otherProps;

  const [searchData, changeHandler] = useConditionSearchData(initData);
  const [, assetGroups] = useAssetGroups({ clientId, jwt });
  const assetGroupOptions = useAssetGroupOptions(assetGroups);

  const [, assetGroupValues] = useAssetGroupValues({
    clientId,
    jwt,
    groupedBy: searchData.groupByKey,
  });
  const assetGroupValueOptions = useAssetGroupValueOptions(assetGroupValues);

  const assetSorter = useCallback((a: AssetDetails, b: AssetDetails) => {
    if (a.assetContainer.name > b.assetContainer.name) return 1;
    if (a.assetContainer.name < b.assetContainer.name) return -1;
    return 0;
  }, []);

  const [, assets] = useAssetsByGroupAndValues({
    clientId,
    jwt,
    groupedBy: searchData.groupByKey,
    values: searchData.groupByValue,
    sort: assetSorter,
  });

  const [handleSearch] = useAssetConditionSearch(clientId, projectType);

  const assetOptions = useAssetOptions(assets);
  const assetFilteredRange = useSearchAssetsRange(
    searchData.groupByValueFrom,
    searchData.groupByValueTo,
    assets
  );
  const circuitIdsBySide = useSearchCircuitsByTowerSide(
    searchData.circuitId,
    assetFilteredRange
  );
  const circuitIdOptions = useCircuitOptions(assetFilteredRange);

  const columns = useGradingColumns(
    handleSelectedRow,
    circuitIdsBySide.left,
    circuitIdsBySide.right,
    hideCircuitIfNoIds,
    displayTooltip
  );
  const { componentList, gradingsMasterList } = useComponentList(columns);

  const getGradingOptions = (): DropdownOptions => {
    const hasComponentInGradings =
      searchData.components && searchData.components in gradingsMasterList;
    if (!hasComponentInGradings) return [];

    return gradingsMasterList[searchData.components].map(
      (grading: { caption: string }) => {
        return {
          label: grading.caption,
          value: grading.caption,
        };
      }
    );
  };

  const componentOptions: DropdownOptions = componentList.map((component) => {
    return {
      header: component.isHeader,
      label: component.title,
      value: component.accessor,
    };
  });

  const onClickSearch = async () => {
    const results = await handleSearch(searchData);
    if (!results) return;
    handleSearchResults(results);
  };

  return (
    <ConditionSearchForm
      assetOptions={assetOptions}
      assetGroupOptions={assetGroupOptions}
      assetGroupValueOptions={assetGroupValueOptions}
      circuitIdOptions={circuitIdOptions}
      componentOptions={componentOptions}
      gradingOptions={getGradingOptions()}
      handleSearchChange={changeHandler}
      handleSearchClicked={onClickSearch}
      searchData={searchData}
    ></ConditionSearchForm>
  );
};
