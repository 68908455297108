import { appStrings } from "./app";

export const mapStrings = {
  global: { ...appStrings },
  gps: {
    noTrace: "No GPS trace available",
  },
  avoidanceZone: {
    code: "Code",
    status: "Status",
    notes: "Notes",
    date: "Date",
    create: "New Avoidance Zone",
    edit: "Edit Avoidance Zone",
    statusList: {
      permanent: "860bd437-032d-4d2c-a1e3-25b023a8383f",
      temporary: "b5b3b86c-de26-4d7b-953e-86c343d6d290",
      client: "19c3823f-2c4e-46e3-8191-d618f8b0e001",
    },
  },
  pointOfInterest: {
    name: "Name",
    create: "New Point of Interest",
    edit: "Edit Point of Interest",
  },
  buttons: {
    save: "Save",
    edit: "Edit",
    delete: "Delete",
  },
  layers: {
    avoidanceZone: "Avoidance Zones",
    pointOfInterest: "Points of Interest",
    markup: "Markup",
    googleTerrain: "GMaps (Terrain)",
    googleSatellite: "GMaps (Satellite)",
    gps: "GPS Trace",
    gpsPosition: "GPS Position",
    search: "Search Results",
    assets: (clientName: string) => `${clientName} - Assets`,
    status: (clientName: string) => `${clientName} - Status`,
  },
  layerTypes: {
    vector: "vector",
    webgl: "webgl",
    webglcustom: "webglcustom",
    tile: "tile",
    google: "google",
    osm: "osm",
  },
  drawTools: {
    pencil: "Pencil",
    ruler: "Ruler",
    line: "Line",
    arrow: "Arrow",
    polygon: "Polygon",
    freehand: "Freehand",
    circle: "Circle",
    square: "Square",
    point: "Point",
    text: "Text",
    delete: "Delete",
    clear: "Clear All Markup",
    divider: "divider",
  },
  viewTools: {
    selectMultipleAssets: "Select Multiple Assets",
    queryAsset: "Query Asset",
    panXY: "Pan to XY",
    panGridRef: "Pan to Grid Reference",
    magnify: "Magnify",
    overview: "Overview",
    previousView: "Previous",
    previousViewTooltip: "Jump to Previous View",
    nextView: "Next",
    nextViewTooltip: "Jump to Next View",
  },
  projections: {
    EPSG_3857: "EPSG:3857", // Pseudo-Mercator
    EPSG_4326: "EPSG:4326", // WGS84
    EPSG_27700: "EPSG:27700", // British National Grid
    EPSG_29903: "EPSG:29903", // Irish Grid
  },
};
