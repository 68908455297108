import { replaceSectionInFqn } from "./fqnHelper";

export function processExpressions(sectionJson: string) {
  try {
    const original_section = JSON.parse(sectionJson);
    let triggerControls = new Map<string, string[]>();
    if (
      Array.isArray(original_section.controls) &&
      original_section.controls.length > 0
    ) {
      triggerControls = findTriggerControls(original_section.controls);
      original_section.controls = replaceFQNs(
        original_section.controls,
        triggerControls
      );
    }
    if (
      Array.isArray(original_section.subsections) &&
      original_section.subsections.length > 0
    ) {
      original_section.subsections.forEach((subsection: any) => {
        const newTriggerControls = findTriggerControls(subsection.controls);
        newTriggerControls.forEach((value, key) =>
          triggerControls.set(key, value)
        );
      });
      original_section.subsections.forEach((subsection: any) => {
        subsection.controls = replaceFQNs(subsection.controls, triggerControls);
      });
    }
    const newJsonData = JSON.stringify(original_section);
    return newJsonData;
  } catch (e) {
    console.error(e);
    return sectionJson;
  }
}

export function regenerateExpressions(
  sectionJson: string,
  newSectionId: string
) {
  try {
    const original_section = JSON.parse(sectionJson);
    if (
      Array.isArray(original_section.controls) &&
      original_section.controls.length > 0
    ) {
      original_section.controls = replaceExpressions(
        original_section.controls,
        newSectionId
      );
    }
    if (
      Array.isArray(original_section.subsections) &&
      original_section.subsections.length > 0
    ) {
      original_section.subsections.forEach((subsection: any) => {
        subsection.controls = replaceExpressions(
          subsection.controls,
          newSectionId
        );
      });
    }
    const newJsonData = JSON.stringify(original_section);
    return newJsonData;
  } catch (e) {
    console.error(e);
    return sectionJson;
  }
}

function findTriggerControls(controls: any[]) {
  const triggerControls = new Map<string, string[]>();
  controls.forEach((control: any) => {
    if (control.expressionTypes !== undefined) {
      control.expressionTypes.forEach((expressionType: any) => {
        expressionType.triggerControls.forEach((triggerControl: any) => {
          const triggerControlFQN = triggerControl.value;
          var expressions: string[] =
            triggerControls.get(triggerControlFQN) || [];
          expressions.push(expressionType.expression);
          triggerControls.set(triggerControlFQN, expressions);
        });
      });
    }
  });
  return triggerControls;
}

function replaceFQNs(controls: any[], triggerControls: Map<string, string[]>) {
  controls.forEach((control: any) => {
    triggerControls.forEach((value: string[], key: string) => {
      if (key === control.fqn) {
        control.expressions = value;
      }
    });
  });
  return controls;
}

function replaceExpressions(controls: any[], newSectionId: string) {
  controls.forEach((control: any) => {
    if (control.expressionTypes !== undefined) {
      control.expressionTypes.forEach((expressionType: any) => {
        expressionType.triggerControls.forEach((triggerControl: any) => {
          triggerControl.value = replaceSectionInFqn(
            triggerControl.value,
            newSectionId
          );
        });
        expressionType.expression = replaceSectionInFqn(
          expressionType.expression,
          newSectionId
        );
      });
    }
  });
  return controls;
}
