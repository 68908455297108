import { Button, Menu, MenuItem } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import clsx from "clsx";
import { useState } from "react";
import { gradingsStrings as strings } from "../../resources/strings/gradings";
import { exportTable } from "./export";
import { ExportData } from "./GradingReportList";
import { useStyles } from "./styles";

interface Props {
  projectName: string;
  exportData: ExportData;
}

export const ExportButton = ({ projectName, exportData }: Props) => {
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const classes = useStyles();

  const exportGradings = (format: string) => {
    exportTable(exportData, `${projectName}.${format}`);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setAnchor(null);
  };

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        className={clsx(classes.button, classes.headerButton)}
        endIcon={<KeyboardArrowDownIcon />}
        onClick={handleClick}
      >
        {strings.report.exportButton}
      </Button>
      <Menu
        anchorEl={anchor}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        keepMounted
        open={Boolean(anchor)}
        onClose={handleClose}
      >
        {strings.report.exportFormats.map((format) => (
          <MenuItem key={format} onClick={() => exportGradings(format)}>
            As .{format.toUpperCase()}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
