import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import { CancelToken } from "axios";
import { Mode, Section } from "../../../components/general/types/Modify";
import { packageStrings as strings } from "../../../resources/strings";
import { BasicComponentConfigurations } from "../../../types";
import { PackageData } from "../../../types/documents";
import PackagesBreadcrumbHeader from "./PackageBreadcrumbHeader";

export const constraints = {
  name: {
    presence: {
      allowEmpty: false,
      message: `^${strings.labels.packageName} is required`,
    },
  },
  groupedBy: {
    presence: {
      allowEmpty: false,
      message: `^${strings.labels.groupedBy} is required`,
    },
  },
};

export const componentConfigurations: BasicComponentConfigurations<PackageData> =
  {
    details: (
      _mode: Mode,
      _jwt: string,
      _cancelToken: CancelToken,
      {
        groupedBy,
        groupedByOptions,
        filter,
        filterOptions,
        filteredValues,
        setOpen,
        getGroupedAssets,
      }: any
    ): Section<PackageData> => {
      return {
        key: "details",
        content: [
          {
            controltype: "custom",
            Component: PackagesBreadcrumbHeader,
            md: 12,
            fullwidth: true,
          },
          {
            controltype: "input",
            name: "name",
            label: strings.labels.packageName,
            required: true,
          },
          {
            controltype: "dropdown",
            name: "groupedBy",
            label: strings.labels.groupedBy,
            required: true,
            options: groupedByOptions,
            ...(groupedBy && { value: groupedBy }),
            disabled: true,
          },
          {
            controltype: "dropdown",
            name: "filter",
            label: strings.labels.filter,
            modes: ["create", "update"],
            options: filterOptions,
            value: filter,
            buttonDisplay: filter ? (
              <SettingsOutlinedIcon />
            ) : (
              <AddOutlinedIcon />
            ),
            onButtonClick: () => {
              setOpen(true);
            },
          },
          {
            controltype: "transferlist",
            name: "selectedGroups",
            md: 12,
            getOptions: [getGroupedAssets, []],
            filteredValues,
          },
        ],
      };
    },
  };
