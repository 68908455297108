import { Button, Divider, Tooltip, Typography } from "@material-ui/core";
import {
  EditLocationOutlined as SelectIcon,
  GridOnOutlined as PanGridIcon,
  MyLocationOutlined as PanXYIcon,
  NotListedLocationOutlined as QueryIcon,
  PageviewOutlined as MagnifyIcon,
  PhotoSizeSelectLargeOutlined as OverviewIcon,
} from "@material-ui/icons";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import debounce from "lodash.debounce";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import useUndo from "use-undo";
import { mapStrings as strings } from "../../../resources/strings";
import { convertPoint, gridRefToCoords } from "../helpers/Coordinates";
import { CoordsInputDialog } from "../helpers/CoordsInputDialog";
import { GridRefInputDialog } from "../helpers/GridRefInputDialog";
import { syncLayerChanges } from "../helpers/LayerFunctions";
import Magnifier from "../magnifier/Magnifier";
import MapContext from "../MapContext";
import { OverviewDialog } from "../overview/OverviewDialog";
import { useStyles } from "./styles";

interface Props {
  assetSelectType: string;
  handleAssetSelectTypeChange: (newValue: string) => void;
}

type Component = (props: Props) => JSX.Element;

export const ViewToolBar: Component = ({
  assetSelectType,
  handleAssetSelectTypeChange,
}) => {
  const map = useContext(MapContext);
  const classes = useStyles();

  const [selected, setSelected] = useState("");
  const [enabledToggles, setEnabledToggles] = useState<string[]>([]);

  const assetSelectTools = [
    { name: strings.viewTools.selectMultipleAssets, icon: <SelectIcon /> },
    { name: strings.viewTools.queryAsset, icon: <QueryIcon /> },
  ];

  const panTools = [
    { name: strings.viewTools.panXY, icon: <PanXYIcon /> },
    { name: strings.viewTools.panGridRef, icon: <PanGridIcon /> },
  ];

  const toggleTools = [
    { name: strings.viewTools.overview, icon: <OverviewIcon /> },
    { name: strings.viewTools.magnify, icon: <MagnifyIcon /> },
  ];

  const [magnify, setMagnify] = useState<Magnifier>();

  const [
    { present: currentMapView },
    {
      set: setCurrentMapView,
      reset: resetMapView,
      undo: undoMapViewChange,
      redo: redoMapViewChange,
      canUndo: canUndoMapViewChange,
      canRedo: canRedoMapViewChange,
    },
  ] = useUndo<{ centre: number[]; zoom: number } | undefined>(undefined);

  const addMapState = useCallback(() => {
    if (!map) return;

    const centre = map.getView().getCenter();
    const zoom = map.getView().getZoom();
    if (centre && zoom) {
      setCurrentMapView({ centre, zoom });
    }
  }, [map, setCurrentMapView]);

  const debouncedAddMapState = useMemo(
    () => debounce(addMapState, 1000),
    [addMapState]
  );

  useEffect(() => {
    if (!map) return;

    const projection = map.getView().getProjection().getCode();
    const magnify = new Magnifier(projection);
    map.addOverlay(magnify);
    syncLayerChanges(map, magnify.getMagMap());
    setMagnify(magnify);

    const centre = map.getView().getCenter();
    const zoom = map.getView().getZoom();
    if (centre && zoom) {
      resetMapView({ centre, zoom });
    }
    map.getView().on("change", debouncedAddMapState);

    return () => {
      map.getView().un("change", debouncedAddMapState);
      debouncedAddMapState.cancel();
    };
  }, [map, resetMapView, debouncedAddMapState]);

  useEffect(() => {
    if (!map || !currentMapView) return;

    map.getView().setZoom(currentMapView.zoom);
    map.getView().setCenter(currentMapView.centre);
  }, [map, currentMapView]);

  useEffect(() => {
    if (!magnify) return;

    magnify.setActive(enabledToggles.includes(strings.viewTools.magnify));
  }, [enabledToggles, magnify]);

  const handleGridRef = (gridRef: string) => {
    if (!map) return;

    const { type, coords } = gridRefToCoords(gridRef);
    const source =
      type === "British"
        ? strings.projections.EPSG_27700
        : strings.projections.EPSG_29903;

    const target = map.getView().getProjection().getCode();
    const point = convertPoint(source, target, coords);

    panTo(point);
  };

  const handleCoords = (coords: number[]) => {
    if (!map) return;

    const target = map.getView().getProjection().getCode();
    const point = convertPoint(strings.projections.EPSG_4326, target, coords);

    panTo(point);
  };

  const panTo = (coords: number[]) => {
    if (!map) return;
    const view = map.getView();

    view.animate({
      center: coords,
      duration: 1000,
      zoom: 16,
    });
  };

  const onClose = () => {
    setSelected("");
  };

  const renderButton = (tool: { name: string; icon?: JSX.Element }) => (
    <ToggleButton
      value={tool.name}
      key={tool.name}
      className={classes.toggleButton}
    >
      <Tooltip title={<Typography>{tool.name}</Typography>}>
        {tool.icon || <span>{tool.name}</span>}
      </Tooltip>
    </ToggleButton>
  );

  const renderDivider = () => (
    <Divider
      orientation="vertical"
      flexItem
      classes={{ root: classes.divider }}
    />
  );

  return (
    <>
      <ToggleButtonGroup
        exclusive
        value={assetSelectType}
        onChange={(_event, newValue) => handleAssetSelectTypeChange(newValue)}
      >
        {assetSelectTools.map(renderButton)}
      </ToggleButtonGroup>

      {renderDivider()}

      <ToggleButtonGroup
        exclusive
        value={selected}
        onChange={(_event, newValue) => setSelected(newValue)}
      >
        {panTools.map(renderButton)}
      </ToggleButtonGroup>

      {renderDivider()}

      <ToggleButtonGroup
        value={enabledToggles}
        onChange={(_event, newValues) => setEnabledToggles(newValues)}
      >
        {toggleTools.map(renderButton)}
      </ToggleButtonGroup>

      {renderDivider()}

      <Tooltip
        title={<Typography>{strings.viewTools.previousViewTooltip}</Typography>}
      >
        <span>
          <Button
            disabled={!canUndoMapViewChange}
            onClick={undoMapViewChange}
            classes={{ root: classes.button }}
          >
            {strings.viewTools.previousView}
          </Button>
        </span>
      </Tooltip>

      <Tooltip
        title={<Typography>{strings.viewTools.nextViewTooltip}</Typography>}
      >
        <span>
          <Button
            disabled={!canRedoMapViewChange}
            onClick={redoMapViewChange}
            classes={{ root: classes.button }}
          >
            {strings.viewTools.nextView}
          </Button>
        </span>
      </Tooltip>

      <CoordsInputDialog
        open={selected === strings.viewTools.panXY}
        onClose={onClose}
        onSubmit={handleCoords}
      />
      <GridRefInputDialog
        open={selected === strings.viewTools.panGridRef}
        onClose={onClose}
        onSubmit={handleGridRef}
      />

      <OverviewDialog
        open={enabledToggles.includes(strings.viewTools.overview)}
      />
    </>
  );
};
