import { Tooltip, Typography } from "@material-ui/core";
import Status from "@material-ui/icons/FiberManualRecord";
import { Link, useHistory } from "react-router-dom";
import { Cell, Row } from "react-table";
import {
  createdDateTableCellRenderer,
  updatedDateTableCellRenderer,
} from "../../components/general/table/DateTableCell";
import { FlightState } from "../../enums/flightState";
import { formatDate, isOfflineApp } from "../../helpers";
import { FLIGHTS_ENDPOINT } from "../../libs/config";
import { paths } from "../../navigation";
import { infoLogStrings as strings } from "../../resources/strings";
import { RouteProps } from "../../types";
import type { FlightData } from "../../types/documents";
import { TableContainer } from "../general";
import { ITableContainerProps } from "../general/TableContainer";

interface Props
  extends RouteProps,
    Partial<
      Omit<ITableContainerProps<FlightData>, "user" | "jwt" | "permissions">
    > {}

type Component = (props: Props) => JSX.Element;

const endpoint = `${FLIGHTS_ENDPOINT}/list-flights`;

export const FlightList: Component = ({ jwt, permissions, user, ...props }) => {
  const routeProps = { jwt, permissions, user };
  const history = useHistory();

  const clickHandler = ({ id }: FlightData) => {
    id && history.push(paths.flights.view(id));
  };

  const flightReferenceRenderer = ({ value, row }: Cell<FlightData>) => {
    const status = row.original.state;
    const colour =
      status === FlightState.Finished
        ? "green"
        : status === FlightState.Aborted
        ? "red"
        : "orange";

    return (
      <span style={{ display: "flex", alignItems: "center" }}>
        <Tooltip title={<Typography>{FlightState[status]}</Typography>}>
          <Status fontSize="small" htmlColor={colour} />
        </Tooltip>

        <span
          style={{
            paddingLeft: "2px",
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
        >
          {value}
        </span>
      </span>
    );
  };

  return (
    <div>
      <TableContainer<FlightData>
        {...routeProps}
        endpoint={endpoint}
        jwt={jwt}
        header={strings.header.flightInfoLog}
        tableColumns={[
          {
            id: "reference",
            Header: strings.labels.reference,
            accessor: "reference",
            Cell: flightReferenceRenderer,
          },
          {
            id: "start",
            Header: strings.labels.start,
            accessor: ({ start }) => formatDate(start),
          },
          {
            id: "observerName",
            Header: strings.labels.observerName,
            accessor: "observerName",
          },
          {
            id: "package_name",
            Header: strings.labels.packageName,
            accessor: ({ package: pkg, packageId }) => (
              <Link to={pkg ? paths.packages.view(packageId) : ""}>
                {pkg && pkg.name}
              </Link>
            ),
          },
          {
            id: "aircraft_registration",
            Header: strings.labels.aircraftRegistration,
            accessor: ({ aircraft }) => aircraft && aircraft.registration,
          },
          {
            id: "aircraft_type",
            Header: strings.labels.aircraftType,
            accessor: ({ aircraft }) => aircraft && aircraft.type,
          },
          {
            id: "flightDuration",
            Header: strings.labels.flightDuration,
            accessor: "flightDuration",
          },
          {
            id: "taskDuration",
            Header: strings.labels.taskDuration,
            accessor: "taskDuration",
          },
          {
            id: "techLogNumber",
            Header: strings.labels.techLogNumber,
            accessor: "techLogNumber",
          },
          {
            id: "createdDate",
            Header: strings.labels.dateCreated,
            accessor: "createdDate",
            Cell: createdDateTableCellRenderer,
          },
          {
            id: "updatedDate",
            Header: strings.labels.lastUpdated,
            accessor: "updatedDate",
            Cell: updatedDateTableCellRenderer,
          },
        ]}
        {...props}
        finalColumnButtonConfig={(defaultBtn) => {
          if (isOfflineApp()) {
            return [defaultBtn];
          }
          return [
            defaultBtn,
            {
              label: strings.labels.trace,
              handler: (
                _event: React.MouseEvent<HTMLButtonElement>,
                row: Row<FlightData>
              ) =>
                row.original.id &&
                history.push(paths.flights.viewGpx(row.original.id)),
            },
          ];
        }}
        viewButtonText={strings.labels.view}
        clickHandler={clickHandler}
        manualPagination={!isOfflineApp()}
      />
    </div>
  );
};
