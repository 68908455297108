import { useEffect, useMemo, useState } from "react";
import * as api from "../../services/assets.service";
import { AssetGeoJson } from "../../types/documents/Assets";
import { useCancelToken } from "../general";

export enum Loading {
  Idle,
  Loading,
  Complete,
  Failed,
}

type Props = {
  id: string;
  jwt: string;
  addDataToSource: (data: AssetGeoJson) => void;
};

type Result = [Loading];

export const usePointsData = ({ id, jwt, addDataToSource }: Props): Result => {
  const clientCancelToken = useCancelToken();
  const [loadingStatus, setloadingStatus] = useState<Loading>(Loading.Idle);

  const loading = useMemo(() => loadingStatus, [loadingStatus]);

  useEffect(() => {
    let stop = false;

    const fetchClientData = async (clientId: string) => {
      try {
        let offsetId: string | null = null;
        while (true) {
          const data: AssetGeoJson = await api.getAssetGeoJson(
            jwt,
            clientCancelToken,
            clientId.toLowerCase(),
            offsetId
          );
          if (data.offsetId === offsetId) {
            break;
          }
          offsetId = data.offsetId;
          if (!data || !data.data || stop) break;
          addDataToSource(data);
        }
      } catch (e) {
        if (clientCancelToken.reason) return;
        setloadingStatus(Loading.Failed);
      }
    };

    const fetchData = async () => {
      setloadingStatus(Loading.Loading);

      await fetchClientData(id);
      setloadingStatus(Loading.Complete);
    };

    fetchData();

    return () => {
      stop = true;
    };
  }, [id, jwt, clientCancelToken, addDataToSource]);

  return [loading];
};

type PackagePointProps = {
  id: string;
  jwt: string;
  addDataToSource: (data: AssetGeoJson) => void;
};

type PackagePointResult = [Loading];

export const usePackagePointsData = ({
  id,
  jwt,
  addDataToSource,
}: PackagePointProps): PackagePointResult => {
  const packageCancelToken = useCancelToken();
  const [loadingStatus, setloadingStatus] = useState<Loading>(Loading.Idle);

  const loading = useMemo(() => loadingStatus, [loadingStatus]);

  useEffect(() => {
    let stop = false;

    const fetchPackageData = async (packageId: string) => {
      try {
        let offsetId: string | null = null;
        while (true) {
          const data: AssetGeoJson = await api.getPackageAssetGeoJson(
            jwt,
            packageCancelToken,
            packageId,
            offsetId
          );
          if (data.offsetId === offsetId) {
            break;
          }
          offsetId = data.offsetId;
          if (!data || !data.data || stop) break;
          addDataToSource(data);
        }
      } catch (e) {
        if (packageCancelToken.reason) return;
        setloadingStatus(Loading.Failed);
      }
    };

    const fetchData = async () => {
      setloadingStatus(Loading.Loading);
      await fetchPackageData(id);

      setloadingStatus(Loading.Complete);
    };

    fetchData();

    return () => {
      stop = true;
    };
  }, [id, jwt, packageCancelToken, addDataToSource]);

  return [loading];
};
