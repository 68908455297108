import { Button, Grid } from "@material-ui/core";
import { searchStrings as strings } from "../../../resources/strings";
import { DropdownOptions } from "../../../types";
import { Dropdown } from "../../general/controls";
import { BaseControl } from "../../general/controls/BaseControl";
import { ChangeHandler } from "../../general/types/Modify";
import { ConditionSearch } from "./SearchPanel";
import { StandardSearchForm } from "./StandardSearchForm";
import { useStyles } from "./styles";

type ConditionSearchFormProps = {
  searchData: ConditionSearch;
  assetOptions: DropdownOptions;
  assetGroupOptions: DropdownOptions;
  assetGroupValueOptions: DropdownOptions;
  circuitIdOptions: DropdownOptions;
  componentOptions: DropdownOptions;
  gradingOptions: DropdownOptions;
  handleSearchChange: ChangeHandler;
  handleSearchClicked: () => void;
};

export const ConditionSearchForm = ({
  searchData,
  assetOptions,
  assetGroupOptions,
  assetGroupValueOptions,
  circuitIdOptions,
  componentOptions,
  gradingOptions,
  handleSearchChange,
  handleSearchClicked,
}: ConditionSearchFormProps) => {
  const classes = useStyles();

  return (
    <>
      <StandardSearchForm
        assetGroupOptions={assetGroupOptions}
        assetGroupValueOptions={assetGroupValueOptions}
        assetOptions={assetOptions}
        handleSearchChange={handleSearchChange}
        searchData={searchData}
      ></StandardSearchForm>
      <Grid item>
        <BaseControl>
          <Dropdown<ConditionSearch>
            config={{
              controltype: "dropdown",
              name: "circuitId",
              label: strings.labels.circuitIds,
              options: circuitIdOptions,
              value: searchData.circuitId,
              multiple: true,
            }}
            labelProps={{ className: classes.label }}
            selectProps={{ className: classes.dropdown }}
            handleChange={handleSearchChange}
          />
        </BaseControl>
      </Grid>
      <Grid item>
        <BaseControl>
          <Dropdown<ConditionSearch>
            config={{
              controltype: "dropdown",
              name: "components",
              label: strings.labels.componentType,
              options: componentOptions,
              value: searchData.components,
            }}
            labelProps={{ className: classes.label }}
            selectProps={{ className: classes.dropdown }}
            handleChange={handleSearchChange}
          />
        </BaseControl>
      </Grid>
      <Grid item>
        <BaseControl>
          <Dropdown<ConditionSearch>
            config={{
              controltype: "dropdown",
              name: "gradings",
              label: strings.labels.gradings,
              options: gradingOptions,
              value: searchData.gradings,
              multiple: true,
            }}
            labelProps={{ className: classes.label }}
            selectProps={{ className: classes.dropdown }}
            handleChange={handleSearchChange}
          />
        </BaseControl>
      </Grid>
      <Grid item>
        <BaseControl>
          <Button
            onClick={handleSearchClicked}
            color="primary"
            fullWidth
            variant="contained"
          >
            {strings.labels.search}
          </Button>
        </BaseControl>
      </Grid>
    </>
  );
};
