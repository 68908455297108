import { AxiosInstance, AxiosRequestConfig, CancelToken } from "axios";
import { Mode } from "./components/general/types/Modify";
import { createDropdownOptions } from "./helpers";
import { HooksLogger } from "./hooks/hooks-logger";
import axios from "./libs/axios/axios-instance";
import { ASSETS_ENDPOINT, TAGS_ENDPOINT } from "./libs/config";
import { TagItem } from "./types/documents";

const listLogger = new HooksLogger("List");
const deleteLogger = new HooksLogger("Delete");
const submitLogger = new HooksLogger("Submit");
const retrieveLogger = new HooksLogger("Retrieve");
const groupingsLogger = new HooksLogger("Groups");
const groupedAssetsLogger = new HooksLogger("Groupings");
const tagsLogger = new HooksLogger("Tags");

interface TGetResponse<TData> {
  item: TData;
}

interface TListReponse<TData> {
  items: TData[];
  totalCount: number;
}

interface Response {
  item: TagItem;
}

interface GroupResponse {
  groups: any[];
  totalCount: number;
}

export interface GroupedAssetGrouping {
  value: any;
  totalCount: number;
}

export interface GroupedAssetsResponse {
  groupings: GroupedAssetGrouping[];
  totalCount: number;
}

export class API<D> {
  cancelToken: CancelToken;
  config: AxiosRequestConfig;
  axiosInstance: AxiosInstance;

  constructor(cancelToken: CancelToken, jwt?: string) {
    this.cancelToken = cancelToken;
    this.config = { cancelToken };
    this.axiosInstance = axios();
  }

  async get(path: string) {
    retrieveLogger.request("Getting item data");
    try {
      const {
        data: { item },
      } = await this.axiosInstance.get<TGetResponse<D>>(path, this.config);

      retrieveLogger.success(item);
      return item as D;
    } catch (e: any) {
      if (this.cancelToken.reason) return;

      const error = e.message ? e.message : e;
      retrieveLogger.error(error);
      throw error;
    }
  }

  async submitData(
    formDataForSubmit: Partial<D>,
    eventType: Mode,
    putEndpoint: string
  ) {
    const body = {
      eventType,
      payload: formDataForSubmit,
    };

    submitLogger.request("Submitting form data");

    try {
      let result = await this.axiosInstance.put<{ id: string }>(
        putEndpoint,
        body,
        this.config
      );
      const {
        data: { id },
      } = result;
      submitLogger.success();
      return id;
    } catch (e: any) {
      const error = e.message ? e.message : e;
      submitLogger.error(error);
      throw error;
    }
  }

  async list(
    body: {
      page?: number;
      size?: number;
      query?: object;
    },
    url: string
  ) {
    listLogger.request("Getting table data");
    try {
      const {
        data: { items = [], totalCount },
      } = await this.axiosInstance.post<TListReponse<D>>(
        url,
        body,
        this.config
      );

      listLogger.success(items, totalCount);
      return { items, totalCount } as TListReponse<D>;
    } catch (e: any) {
      if (this.cancelToken.reason) return;
      const error = e.message ? e.message : e;
      listLogger.error(error);
      throw error;
    }
  }

  async delete(endpoint: string, id: string) {
    deleteLogger.request("Submitting delete request");

    try {
      await this.axiosInstance.delete<{ id: string }>(
        `${endpoint}/${id}`,
        this.config
      );
      deleteLogger.success();
    } catch (e) {
      if (this.cancelToken.reason) return;
      const error = e.message ? e.message : e;
      deleteLogger.error(error);
      throw error;
    }
  }

  async getAssetGroups(clientId: string) {
    groupingsLogger.request("Getting asset groups");
    try {
      const {
        data: { groups },
      } = await this.axiosInstance.get<GroupResponse>(
        `${ASSETS_ENDPOINT}/getGroups/${clientId}`,
        this.config
      );
      groupingsLogger.success(groups);
      return groups;
    } catch (e) {
      if (this.cancelToken.reason) return;
      const error = e.message ? e.message : e;
      groupingsLogger.error(error);
      throw error;
    }
  }

  async getGroupedAssets(group: string, clientId: string) {
    groupedAssetsLogger.request("Getting grouped assets");
    try {
      const {
        data: { groupings },
      } = await this.axiosInstance.get<GroupedAssetsResponse>(
        `${ASSETS_ENDPOINT}/getGroupedAssets/${clientId}/${group}`,
        this.config
      );
      groupedAssetsLogger.success(groupings);
      return groupings;
    } catch (e) {
      if (this.cancelToken.reason) return;

      const error = e.message ? e.message : e;
      groupedAssetsLogger.error(e.response);
      groupedAssetsLogger.error(error);
      throw error;
    }
  }

  async getTags(group: string) {
    tagsLogger.request(`Getting tag data for ${group}`);
    try {
      const path = `${TAGS_ENDPOINT}/${group}`;

      const {
        data: { item },
      } = await this.axiosInstance.get<Response>(path, this.config);
      const dropdownOptions = createDropdownOptions(item.tags, "id", "text");
      tagsLogger.success(item);
      return dropdownOptions;
    } catch (e: any) {
      if (this.cancelToken.reason) return;

      const error = e.message ? e.message : e;

      tagsLogger.error(error);
    }
  }
}
