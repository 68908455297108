import { ButtonProps } from "@material-ui/core";
import {
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import type { Cell, Column, Row, SortingRule } from "react-table";
import { API } from "../../API";
import { Table } from "../../components/general";
import { useCancelToken } from "../../hooks/general";
import { HooksLogger } from "../../hooks/hooks-logger";
import { RouteProps, UserAccess } from "../../types";

const logger = new HooksLogger("TableContainer/useEffect");

type CreateButtonConfig = { label: string; userAccess: UserAccess } & (
  | { path: string }
  | { handler: MouseEventHandler }
);

export interface ITableContainerProps<D extends object> extends RouteProps {
  endpoint: string;
  jwt: string;
  header?: string | null;
  altHeaderStyle?: boolean;
  tableColumns: Column<D>[];
  createButtonConfig?: CreateButtonConfig;
  finalColumnButtonConfig?: (
    defaultBtn: TFinalColumnButtonConfig<D>
  ) => TFinalColumnButtonConfig<D>[];
  clickHandler?: (data: D) => void;
  manualPagination?: boolean;
  filters?: { [index: string]: any };
  rowHighlightStyling?: boolean;
  children?: JSX.Element | Array<JSX.Element>;
  viewButtonText?: string;
  extraFinalColumnComponent?: (row: Row<D>, className: string) => JSX.Element;
  hidePagination?: boolean;
  theme?: object;
  getCellStyle?: (cell: Cell) => object | undefined;
  getHeaderCellStyle?: (column: Column) => object;
  useFlexTable?: boolean;
}

type TFinalColumnButtonConfig<T extends object> = {
  label: string;
  handler: (event: React.MouseEvent<HTMLButtonElement>, row: Row<T>) => void;
} & ButtonProps;

type SearchBody = {
  page?: number;
  size?: number;
  query?: object;
};

export const TableContainer = <D extends Record<string, unknown>>({
  permissions,
  endpoint,
  jwt,
  header,
  altHeaderStyle,
  tableColumns,
  createButtonConfig,
  finalColumnButtonConfig,
  clickHandler,
  manualPagination,
  filters,
  rowHighlightStyling = true,
  children,
  viewButtonText,
  hidePagination,
  extraFinalColumnComponent,
  theme,
  getCellStyle,
  getHeaderCellStyle,
  useFlexTable,
}: ITableContainerProps<D>) => {
  const token = useMemo(() => jwt, [jwt]);
  const url = useMemo(() => endpoint, [endpoint]);

  const [tableData, setTableData] = useState<D[]>(() => []);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(() => false);
  const [, setError] = useState("");
  const cancelToken = useCancelToken();
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(25);
  const [sort, setSort] = useState<SortingRule<D>[]>([]);

  const query = useMemo(() => filters, [filters]);

  const searchCritera = useMemo(
    () => ({
      page,
      size,
      query,
      sort,
    }),
    [page, size, query, sort]
  );

  const APIFunctions = useMemo(
    () => new API<D>(cancelToken, jwt),
    [cancelToken, jwt]
  );

  useEffect(() => {
    const body: SearchBody = manualPagination
      ? searchCritera
      : { query: filters };

    const query = async () => {
      setLoading(true);
      try {
        const list = await APIFunctions.list(body, url);
        if (!list) throw new Error("List issue!");
        const { items, totalCount } = list;
        if (!items) return;
        setTableData(items);
        setTotalCount(totalCount);
        setLoading(false);
      } catch (e: any) {
        if (cancelToken.reason) return;
        const error = e.message ? e.message : e;
        setError(error);
        setLoading(false);
        logger.error(error);
      }
    };

    query();
  }, [
    manualPagination,
    cancelToken,
    token,
    url,
    searchCritera,
    filters,
    APIFunctions,
  ]);

  const onSearchParameterChange = useCallback(
    (pageIndex: number, pageSize: number, sort: SortingRule<D>[]) => {
      setPage(pageIndex);
      setSize(pageSize);
      setSort(sort);
    },
    []
  );

  return (
    <Table<D>
      // custom props
      permissions={permissions}
      header={header}
      altHeaderStyle={altHeaderStyle}
      createButtonConfig={createButtonConfig}
      finalColumnButtonConfig={finalColumnButtonConfig}
      loading={loading}
      clickHandler={clickHandler}
      onSearchParameterChange={onSearchParameterChange}
      theme={theme}
      getCellStyle={getCellStyle}
      getHeaderCellStyle={getHeaderCellStyle}
      useFlexTable={useFlexTable}
      // react table props
      data={tableData}
      columns={tableColumns}
      size={size}
      rowsPerPageOptions={[10, 25, 50, 100]}
      manualPagination={manualPagination}
      totalCount={totalCount}
      rowHighlightStyling={rowHighlightStyling}
      children={children}
      viewButtonText={viewButtonText}
      extraFinalColumnComponent={extraFinalColumnComponent}
      hidePagination={hidePagination}
    />
  );
};
