import { Button, Grid, Tooltip } from "@material-ui/core";
import {
  CheckOutlined,
  ClearOutlined,
  DirectionsWalkOutlined,
} from "@material-ui/icons";
import { defectStrings as strings } from "../../resources/strings";
import { useDefectsToolbarStyles } from "./styles/defectsToolbar";

type Props = {
  clearAllDefects: () => void;
  setAssetsCompleted: () => void;
  markAsFootPatrol: () => void;
};

export const DefectsToolbarButtons = ({
  clearAllDefects,
  setAssetsCompleted,
  markAsFootPatrol,
}: Props) => {
  const classes = useDefectsToolbarStyles();

  const assetStatuses = [
    {
      tooltip: strings.tooltips.complete,
      element: <CheckOutlined className={classes.icon}></CheckOutlined>,
      onClick: () => setAssetsCompleted(),
      activeState: () => false,
    },
    {
      tooltip: strings.tooltips.footPatrol,
      element: (
        <DirectionsWalkOutlined
          className={classes.icon}
        ></DirectionsWalkOutlined>
      ),
      onClick: () => markAsFootPatrol(),
      activeState: () => false,
    },
    {
      tooltip: strings.tooltips.clearAllDefects,
      element: <ClearOutlined className={classes.icon}></ClearOutlined>,
      onClick: () => clearAllDefects(),
      activeState: () => false,
    },
  ];

  return (
    <Grid container spacing={2} className={classes.list}>
      {assetStatuses.map(
        ({ tooltip, element, onClick, activeState }, index) => (
          <Grid key={tooltip} item className={classes.listItem}>
            <Tooltip title={tooltip}>
              <Button
                className={`${classes.button} ${activeState() ? "active" : ""}`}
                onClick={onClick}
              >
                {element}
              </Button>
            </Tooltip>
          </Grid>
        )
      )}
    </Grid>
  );
};
