import { SyncType } from "../../../types/documents/offline/Sync";
import { appStrings } from "../app";

export const syncStrings = {
  global: { ...appStrings },
  header: {
    sync: "Sync",
    [SyncType.Clients]: "Clients",
    [SyncType.MapTiles]: "Map Tiles",
    [SyncType.Assets]: "Assets",
    [SyncType.MapFeatures]: "Map Features",
    [SyncType.Jobs]: "Defects",
    [SyncType.Flights]: "Flights",
    [SyncType.Projects]: "Projects",
    [SyncType.Tags]: "Tags",
    [SyncType.Lines]: "Lines",
  },
  labels: {
    save: "Save",
    reset: "Reset",
    edit: "Edit",
  },
};

export const syncItems = [
  SyncType.Clients,
  SyncType.MapTiles,
  SyncType.Assets,
  SyncType.MapFeatures,
  SyncType.Jobs,
  SyncType.Flights,
  SyncType.Projects,
  SyncType.Tags,
  SyncType.Lines,
];
