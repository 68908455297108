import {
  Content,
  LayoutProps,
  Mode,
  Section,
} from "../../components/general/types/Modify";
import { userStrings as strings } from "../../resources/strings";
import {
  CognitoUser,
  ComponentConfigurations,
  DropdownMappings,
  DropdownOptions,
} from "../../types";
import { UserData } from "../../types/documents/";
import UserBreadcrumbHeader from "./UserBreadcrumbHeader";

export const constraints = {
  username: {
    presence: {
      allowEmpty: false,
      message: `^${strings.labels.username} is required`,
    },
  },
  email: {
    presence: {
      allowEmpty: false,
      message: `^${strings.labels.email} is required`,
    },
  },
  roleId: {
    presence: {
      allowEmpty: false,
      message: `^${strings.labels.roleId} is required`,
    },
  },
};

export const getDropdownMappings = (
  user: CognitoUser | null
): DropdownMappings => {
  const mappings = [
    {
      name: "roles",
      index: 0,
      key: "roleId",
      text: "name",
    },
  ];

  if (user?.isSuperTenant()) {
    mappings.push({
      name: "clients",
      index: 1,
      key: "id",
      text: "name",
    });
  }
  return mappings;
};

export const componentConfigurations: ComponentConfigurations<UserData> = {
  details: (
    mode: Mode,
    roles: DropdownOptions,
    jwt: string,
    otherContent?: Content<any> & LayoutProps
  ): Section<UserData> => {
    let content: Section<UserData> = {
      key: "details",
      content: [
        {
          controltype: "custom",
          Component: UserBreadcrumbHeader,
          md: 12,
          fullwidth: true,
        },
        {
          controltype: "header",
          text: strings.header.signInInfo,
          align: "left",
          variant: "subtitle1",
        },
        {
          controltype: "input",
          name: "username",
          label: strings.labels.username,
          required: true,
          disabled: mode !== "create",
        },
        {
          controltype: "input",
          name: "email",
          label: strings.labels.email,
          required: true,
        },
        {
          controltype: "header",
          text: strings.header.userInfo,
          align: "left",
          variant: "subtitle1",
        },
        {
          controltype: "input",
          name: "forename",
          label: strings.labels.forename,
          required: true,
        },
        {
          controltype: "input",
          name: "surname",
          label: strings.labels.surname,
          required: true,
        },
        {
          controltype: "dropdown",
          name: "roleId",
          label: strings.labels.roleId,
          options: roles,
          required: true,
        },
      ],
    };

    if (otherContent) {
      content.content.push(
        otherContent as unknown as Content<UserData> & LayoutProps
      );
    }
    return content;
  },
};
