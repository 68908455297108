import { useCallback } from "react";
import { API } from "../../API";
import { ASSET_FILTERS_ENDPOINT } from "../../libs/config";
import { notifications } from "../../libs/notifications";
import { appStrings as strings } from "../../resources/strings";
import { RouteProps } from "../../types";
import { AssetFilter } from "../../types/documents/Assets";
import { ModifyContainer } from "../general";
import {
  configInputs,
  configSubmit,
  constraints,
  initialData,
} from "./resources";

export interface Props extends RouteProps {
  id: string;
  clientId: string;
  groupedBy: string;
  onSuccess: any;
}
type Component = (props: Props) => JSX.Element;

export const AssetFilterModify: Component = ({
  id,
  clientId,
  groupedBy,
  onSuccess,
  ...routeProps
}) => {
  const putEndpoint = ASSET_FILTERS_ENDPOINT + "/submit";
  const queryEndpoint = ASSET_FILTERS_ENDPOINT;

  const { jwt } = routeProps;

  const mode = id ? "update" : "create";

  const getGroupedAssets = useCallback(
    async (_data, cancelToken) => {
      try {
        const APIFunctions = new API(cancelToken, jwt);
        const groupedAssets = await APIFunctions.getGroupedAssets(
          groupedBy,
          clientId
        );

        return groupedAssets?.map(({ value, totalCount }) => ({
          value,
          label: value,
          totalCount,
        }));
      } catch (e: any) {
        notifications.error(strings.notifications.messages.retrieveError);
      }
    },
    [jwt, groupedBy, clientId]
  );

  return (
    <ModifyContainer<AssetFilter>
      {...routeProps}
      putEndpoint={putEndpoint}
      queryEndpoint={queryEndpoint}
      mode={mode}
      initialData={{ ...initialData, clientId, groupedBy }}
      componentConfiguration={[
        configInputs(mode, getGroupedAssets),
        configSubmit,
      ]}
      constraints={constraints}
      keysToRemove={[
        "createdById",
        "updatedById",
        "createdDate",
        "updatedDate",
      ]}
      redirectPath={""}
      manualQueryId={id}
      onSuccess={onSuccess}
      isDialog
    />
  );
};
