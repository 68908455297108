import { Dropdown, Section } from "../../components/general/types/Modify";
import { assetLoadStrings } from "../../resources/strings/assets";
import { DropdownOptions } from "../../types";
import { AssetTypeMap, ColumnSelections } from "../../types/documents/Assets";

export const columnSelectionConstraints = {
  name: {
    presence: {
      allowEmpty: false,
      message: `^${assetLoadStrings.labels.name} is required`,
    },
  },
};

export const columnSelectionComponentConfigurations = {
  general(columnOptions: DropdownOptions): Section<ColumnSelections> {
    return {
      key: "general",
      content: [
        {
          controltype: "header",
          text: assetLoadStrings.labels.general,
          align: "left",
          variant: "subtitle1",
        },
        {
          controltype: "dropdown",
          options: columnOptions,
          name: "name",
          label: assetLoadStrings.labels.name,
        },
        {
          controltype: "dropdown",
          options: columnOptions,
          name: "displayName",
          label: assetLoadStrings.labels.displayName,
        },
        {
          controltype: "dropdown",
          options: columnOptions,
          name: "transmissionVoltage",
          label: assetLoadStrings.labels.transmissionVoltage,
        },
        {
          controltype: "dropdown",
          options: columnOptions,
          name: "containerName",
          label: assetLoadStrings.labels.containerName,
        },
        {
          controltype: "dropdown",
          options: columnOptions,
          name: "containerKeys",
          label: assetLoadStrings.labels.containerKeys,
          multiple: true,
        },
        {
          controltype: "dropdown",
          options: columnOptions,
          name: "assetType",
          label: assetLoadStrings.labels.assetType,
        },
        {
          controltype: "dropdown",
          options: columnOptions,
          name: "circuitId",
          label: assetLoadStrings.labels.circuitId,
        },
        {
          controltype: "dropdown",
          options: columnOptions,
          name: "towerSide",
          label: assetLoadStrings.labels.circuitSide,
        },
      ],
    };
  },

  coordinates(columnOptions: DropdownOptions): Section<ColumnSelections> {
    return {
      key: "coordinates",
      content: [
        {
          controltype: "header",
          text: assetLoadStrings.labels.coordinates,
          align: "left",
          variant: "subtitle1",
        },
        {
          controltype: "dropdown",
          options: columnOptions,
          name: "x",
          label: assetLoadStrings.labels.xCoordinate,
        },
        {
          controltype: "dropdown",
          options: columnOptions,
          name: "y",
          label: assetLoadStrings.labels.yCoordinate,
        },
        {
          controltype: "dropdown",
          options: [
            {
              label: assetLoadStrings.labels.EPSG_4326,
              value: "EPSG:4326",
            },
            {
              label: assetLoadStrings.labels.EPSG_27700,
              value: "EPSG:27700",
            },
            {
              label: assetLoadStrings.labels.EPSG_29903,
              value: "EPSG:29903",
            },
          ],
          name: "coordinateSystem",
          label: assetLoadStrings.labels.coordinateSystem,
        },
      ],
    };
  },
};

export const typeMapConstraints = {
  "<Default>": {
    presence: {
      allowEmpty: false,
      message: `^Default is required`,
    },
  },
};

export const typeMapComponentConfigurations = {
  general(
    typeMap: Record<string, string>,
    typeOptions: DropdownOptions
  ): Section<AssetTypeMap> {
    const dropdowns = Object.keys(typeMap).map(
      (key) =>
        ({
          controltype: "dropdown",
          options: typeOptions,
          name: key,
          label: key,
        } as Dropdown<AssetTypeMap>)
    );

    return {
      key: "general",
      content: [
        {
          controltype: "header",
          text: assetLoadStrings.labels.defaultType,
          align: "left",
          variant: "subtitle1",
        },
        {
          controltype: "dropdown",
          options: typeOptions,
          name: "<Default>",
          label: "Default",
        },
        {
          controltype: "header",
          text: assetLoadStrings.labels.typeMap,
          align: "left",
          variant: "subtitle1",
        },
        ...dropdowns,
      ],
    };
  },
};

export const submitButtons = {
  key: "submitButtons",
  modes: ["create", "update"],
  content: [
    {
      controltype: "button",
      label: assetLoadStrings.labels.save,
      color: "primary",
      variant: "contained",
    },
    {
      controltype: "button",
      label: assetLoadStrings.labels.reset,
      color: "primary",
      variant: "outlined",
      type: "reset",
    },
  ],
} as Section<any>;
