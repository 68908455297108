import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { API } from "../../API";
import { useCancelToken } from "../../hooks/general";
import { FLIGHTS_ENDPOINT } from "../../libs/config";
import { RouteProps } from "../../types";
import { FlightData } from "../../types/documents/Flight";
import { Loader } from "../general";
import { MapComponent } from "../map";
import { GPSViewBreadcrumb } from "./GPSViewBreadcrumb";

interface Props extends RouteProps {}
type Component = (props: Props) => JSX.Element;

export const GPSView: Component = (props) => {
  const { flightId } = useParams<{ flightId: string }>();

  const { jwt } = props;
  const cancelToken = useCancelToken();

  const [flight, setFlight] = useState<FlightData>();

  const api = useMemo(
    () => new API<FlightData>(cancelToken, jwt),
    [cancelToken, jwt]
  );

  useEffect(() => {
    const loadFlightData = async () => {
      const flight = await api.get(`${FLIGHTS_ENDPOINT}/${flightId}`);
      setFlight(flight);
    };

    loadFlightData();
  }, [api, flightId]);

  return (
    <Loader active={!flight}>
      {flight && (
        <>
          <GPSViewBreadcrumb flight={flight} />
          <MapComponent flight={flight} {...props} />
        </>
      )}
    </Loader>
  );
};
