import { useCallback, useContext, useEffect } from "react";
import { HooksLogger } from "../../../hooks/hooks-logger";
import MapContext from "../MapContext";
import { useServiceData } from "../../../hooks/general/useServiceData";
import * as linesApi from "../../../services/lines.service";
import { useCancelToken } from "../../../hooks/general";
import { notifications } from "../../../libs/notifications";
import VectorSource from "ol/source/Vector";
import GeoJSON from "ol/format/GeoJSON";
import { LineGeojson } from "../../../types/documents/LineLayer";
import axios from "axios";
import { Map as OLMap } from "ol";
import { mapStrings as strings } from "../../../resources/strings";
import { WebGLLinesLayer } from "./webgl/WebGLLinesLayer";

const logger = new HooksLogger("Map/ClientLinesLayer");

interface Props {
  name: string;
  clientId: string;
  lineId?: string;
  jwt: string;
}

type Component = (props: Props) => JSX.Element;

export const ClientLinesLayer: Component = ({
  name,
  clientId,
  lineId,
  jwt,
}) => {
  const map = useContext(MapContext);
  const cancelToken = useCancelToken();
  const setError = useCallback(e => notifications.error(e), []);

  const fetchLineLayers = useCallback(() => linesApi.listLinesGeojson(
    jwt,
    cancelToken,
    clientId
  ), [
    jwt,
    cancelToken,
    clientId
  ]);
  const [lines] = useServiceData(
    "Fetching Line Layers",
    fetchLineLayers,
    logger,
    cancelToken,
    setError
  );

  useEffect(() => {
    if (!map || !lines) return;

    const lineSource = new VectorSource();
    const lineLayer = new WebGLLinesLayer({
      properties: {
        title: `${name} - Lines`,
        displayTitle: `${name} - Lines`,
        layerType: strings.layerTypes.webglcustom,
      },
      source: lineSource,
    });
    map.addLayer(lineLayer);
    fetchLines(
      map,
      lineSource,
      lineId ? lines.filter(l => l.id === lineId) : lines
    );

    return () => {
      map.removeLayer(lineLayer);
    };
  }, [name, map, lines, lineId]);
  return <></>;
};

async function fetchLines(
  map: OLMap,
  source: VectorSource,
  lines: LineGeojson[]
) {
  await Promise.all(lines.map(async line => {
    const format = new GeoJSON({
      dataProjection: line.coordinateSystem,
      featureProjection: map.getView().getProjection(),
    });
    const features = await fetchLine(format, line);
    for (const feature of features) {
      line.minResolution && feature.set('minResolution', line.minResolution);
      line.maxResolution && feature.set('maxResolution', line.maxResolution);
    }
    source.addFeatures(features);
  }));
}

async function fetchLine(format: GeoJSON, line: LineGeojson) {
  try {
    const response = await axios.get(line.url);
    return format.readFeatures(response.data);
  } catch (e) {
    logger.error(e);
    return [];
  }
}

