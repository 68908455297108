import {
  conductorOptions,
  GradingOption,
  missing,
  notApplicable,
  num1to4,
  num1to5,
  poleCondition,
  poleVerticality,
  spacingType,
  unableToInspect,
  yesNoInvertedOptions,
  yesNoOptions,
  yesNoUnstyled,
} from "./testGradingForm";

export type ColumnMap = { [key: string]: ColumnMapSection };
export type ColumnMapSection = ColumnMapControls | ColumnMapSubsections;

export type ColumnMapControls = {
  type: "controls";
  controls: {
    [key: string]: {
      dbColumnName: string;
      options?: GradingOption[];
      exportStyle?: object;
    };
  };
};

export type ColumnMapSubsections = {
  type: "subsections";
  subsections: {
    [key: string]: ColumnMapSection;
  };
  tooltip?: string;
};

export const columnNames = {
  name: "assetContainer.name",
  groupings: "assetContainer.groupings",
  towerType: "assetContainer.towerType",
};

const colours = {
  blue: "0000FF",
};

const getShortenedList = (list: string[], length: number) => {
  let shortenedList = list.slice(0, length).join(", ");
  if (list.length > length) {
    shortenedList += `, (${list.length - length} more)`;
  }
  return shortenedList;
};

const getCircuitSide = (
  circuitIds: string[],
  towerSide: "left" | "right"
): ColumnMapSection => {
  const circuitIdsShown = 3;
  const circuitShortNames = getShortenedList(circuitIds, circuitIdsShown);

  return {
    type: "subsections",
    subsections: {
      [`Circuit Identification Colours: ${circuitShortNames}`]: {
        type: "subsections",
        tooltip: circuitIds.join(", "),
        subsections: {
          "Insulators And Fittings": {
            type: "controls",
            controls: {
              "Insulators - Electrical (Sheds)": {
                dbColumnName: `${towerSide}_insulators_and_fittings_insulators_electrical_sheds`,
                options: [...num1to4, missing, notApplicable, unableToInspect],
                exportStyle: { color: { rgb: colours.blue } },
              },
              "Insulators - Mechanical (Rust)": {
                dbColumnName: `${towerSide}_insulators_and_fittings_insulators_mechanical_rust`,
                options: [...num1to5, missing, notApplicable, unableToInspect],
              },
              "Arcing Horns": {
                dbColumnName: `${towerSide}_insulators_and_fittings_arcing_horns`,
                options: [...num1to4, missing, notApplicable, unableToInspect],
                exportStyle: { color: { rgb: colours.blue } },
              },
              Jumpers: {
                dbColumnName: `${towerSide}_insulators_and_fittings_jumpers`,
                options: [...num1to4, missing, notApplicable, unableToInspect],
                exportStyle: { color: { rgb: colours.blue } },
              },
              "Vibration Dampers": {
                dbColumnName: `${towerSide}_insulators_and_fittings_vibration_dampers`,
                options: [...num1to5, missing, notApplicable, unableToInspect],
              },
              "U bolts or Tower Attachments": {
                dbColumnName: `${towerSide}_insulators_and_fittings_u_bolts_or_tower_attachments`,
                options: [...num1to5, missing, notApplicable, unableToInspect],
              },
              "Shackles and Links": {
                dbColumnName: `${towerSide}_insulators_and_fittings_shackles_and_links`,
                options: [...num1to5, missing, notApplicable, unableToInspect],
              },
              "Conductor Suspension Clamps": {
                dbColumnName: `${towerSide}_insulators_and_fittings_conductor_suspension_clamps`,
                options: [...num1to5, missing, notApplicable, unableToInspect],
              },
              "Conductor Tension Clamps": {
                dbColumnName: `${towerSide}_insulators_and_fittings_conductor_tension_clamps`,
                options: [...num1to5, missing, notApplicable, unableToInspect],
              },
              "Conductors at Clamps": {
                dbColumnName: `${towerSide}_insulators_and_fittings_conductors_at_clamps`,
                options: [...num1to4, missing, notApplicable, unableToInspect],
                exportStyle: { color: { rgb: colours.blue } },
              },
            },
          },
          Conductor: {
            type: "controls",
            controls: {
              Conductors: {
                dbColumnName: `${towerSide}_conductor_conductors`,
                options: [...conductorOptions, unableToInspect],
                exportStyle: { color: { rgb: colours.blue } },
              },
              "Spacer Type": {
                dbColumnName: `${towerSide}_conductor_spacer_type`,
                options: [
                  ...spacingType,
                  missing,
                  notApplicable,
                  unableToInspect,
                ],
              },
              "Spacer - Condition": {
                dbColumnName: `${towerSide}_conductor_spacer_condition`,
                options: [...num1to4, missing, notApplicable, unableToInspect],
                exportStyle: { color: { rgb: colours.blue } },
              },
              "Midspan Joints/Repair Present? (Y/N)": {
                dbColumnName: `${towerSide}_conductor_midspan_jointsrepair_present_yn`,
                options: [...yesNoInvertedOptions, unableToInspect],
              },
            },
          },
          "Termination Towers": {
            type: "controls",
            controls: {
              Decking: {
                dbColumnName: `${towerSide}_termination_towers_decking`,
                options: [...num1to4, missing, notApplicable, unableToInspect],
                exportStyle: { color: { rgb: colours.blue } },
              },
              Stoneguards: {
                dbColumnName: `${towerSide}_termination_towers_stoneguards`,
                options: [...num1to4, missing, notApplicable, unableToInspect],
                exportStyle: { color: { rgb: colours.blue } },
              },
              "Downlead Insulators Bottom": {
                dbColumnName: `${towerSide}_termination_towers_downlead_insulators_bottom`,
                options: [...num1to4, missing, notApplicable, unableToInspect],
              },
              "Cable Sealing Ends": {
                dbColumnName: `${towerSide}_termination_towers_cable_sealing_ends`,
                options: [...num1to4, missing, notApplicable, unableToInspect],
                exportStyle: { color: { rgb: colours.blue } },
              },
            },
          },
        },
      },
    },
  };
};

export const getGradingReportColumns = (
  leftCircuitIds: string[],
  rightCircuitIds: string[],
  hideCicuitIfNoIds = false
): ColumnMap => {
  const hideLeftSideOfTower =
    hideCicuitIfNoIds === true && leftCircuitIds.length === 0;
  const hideRightSideOfTower =
    hideCicuitIfNoIds === true && rightCircuitIds.length === 0;

  return {
    Muffs: {
      type: "controls",
      controls: {
        "Concrete Condition": {
          dbColumnName: "muffs_concrete_condition",
          options: [...num1to4, unableToInspect],
          exportStyle: { color: { rgb: colours.blue } },
        },
        "Painted? (Y/N)": {
          dbColumnName: "muffs_painted_yn",
          options: [...yesNoOptions, unableToInspect],
        },
        "Paint Condition": {
          dbColumnName: "muffs_paint_condition",
          options: [...num1to4, notApplicable, unableToInspect],
          exportStyle: { color: { rgb: colours.blue } },
        },
      },
    },
    Steelwork: {
      type: "controls",
      controls: {
        "Tower Legs": {
          dbColumnName: "tower_steelwork_tower_legs",
          options: [...num1to5, notApplicable, unableToInspect],
        },
        Bracings: {
          dbColumnName: "tower_steelwork_bracings",
          options: [...num1to5, notApplicable, unableToInspect],
        },
        Crossarms: {
          dbColumnName: "tower_steelwork_crossarms",
          options: [...num1to5, notApplicable, unableToInspect],
        },
        Peak: {
          dbColumnName: "tower_steelwork_peak",
          options: [...num1to5, notApplicable, unableToInspect],
        },
        Paintwork: {
          dbColumnName: "tower_steelwork_paintwork",
          options: [...num1to4, notApplicable, unableToInspect],
          exportStyle: { color: { rgb: colours.blue } },
        },
      },
    },
    Earthwire: {
      type: "controls",
      controls: {
        "U Bolts or Tower Attachments": {
          dbColumnName: "earth_wire_u_bolts_or_tower_attachments",
          options: [...num1to5, missing, notApplicable, unableToInspect],
        },
        "Shackles or Links": {
          dbColumnName: "earth_wire_shackles_or_links",
          options: [...num1to5, missing, notApplicable, unableToInspect],
        },
        "Earthwire Suspension Clamps": {
          dbColumnName: "earth_wire_earthwire_suspension_clamps",
          options: [...num1to5, missing, notApplicable, unableToInspect],
        },
        "Earthwire Tension Clamps": {
          dbColumnName: "earth_wire_earthwire_tension_clamps",
          options: [...num1to5, missing, notApplicable, unableToInspect],
        },
        "Earthwire Jumper": {
          dbColumnName: "earth_wire_earthwire_jumper",
          options: [...num1to4, missing, notApplicable, unableToInspect],
          exportStyle: { color: { rgb: colours.blue } },
        },
        "Earthwire Bonds": {
          dbColumnName: "earth_wire_earthwire_bonds",
          options: [...num1to4, missing, notApplicable, unableToInspect],
          exportStyle: { color: { rgb: colours.blue } },
        },
        Dampers: {
          dbColumnName: "earth_wire_dampers",
          options: [...num1to5, missing, notApplicable, unableToInspect],
        },
        "Earthwire At Earth Attachment": {
          dbColumnName: "earth_wire_earthwire_at_earth_attachment",
          options: [...num1to4, missing, notApplicable, unableToInspect],
          exportStyle: { color: { rgb: colours.blue } },
        },
        "Midspan Joints/Repair Present? (Y/N)": {
          dbColumnName: "earth_wire_midspan_jointsrepair_present_yn",
          options: [...yesNoUnstyled, unableToInspect],
        },
      },
    },
    Pole: {
      type: "controls",
      controls: {
        "Pole Condition": {
          dbColumnName: "pole_condition",
          options: [...poleCondition, notApplicable, unableToInspect],
        },
        "Pole Verticality": {
          dbColumnName: "pole_verticality",
          options: [...poleVerticality, notApplicable, unableToInspect],
        },
        "Stay Wire": {
          dbColumnName: "pole_stay_wire",
          options: [...num1to4, notApplicable, unableToInspect],
          exportStyle: { color: { rgb: colours.blue } },
        },
        "Stay Fittings/Wraps/Rods": {
          dbColumnName: "pole_stay_fittings_wraps_rods",
          options: [...num1to5, missing, notApplicable, unableToInspect],
        },
        "Stay Insulators": {
          dbColumnName: "pole_stay_insulators",
          options: [...num1to4, notApplicable, unableToInspect],
          exportStyle: { color: { rgb: colours.blue } },
        },
      },
    },
    ...(hideLeftSideOfTower === false
      ? { "Left Circuit": { ...getCircuitSide(leftCircuitIds, "left") } }
      : {}),
    ...(hideRightSideOfTower === false
      ? { "Right Circuit": { ...getCircuitSide(rightCircuitIds, "right") } }
      : {}),
  };
};
