import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { Feature } from "ol";
import { Circle, Geometry, Point } from "ol/geom";
import GeometryType from "ol/geom/GeometryType";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";

import { HooksLogger } from "../../../hooks/hooks-logger";
import { useMapFeatureData } from "../../../hooks/map/useMapFeatureData";
import { mapStrings as strings } from "../../../resources/strings";
import { RouteProps } from "../../../types";
import { MapFeatureGeoJSON } from "../../../types/documents/";
import { AvoidanceZoneModal } from "../helpers/AvoidanceZoneModal";
import {
  circleToPoint,
  getDrawTool,
  pointToCircle,
} from "../helpers/DrawTools";
import { useGeoJSON } from "../helpers/GeoJSON";
import { avoidanceZoneLayerStyle } from "../helpers/Style";
import MapContext from "../MapContext";

const logger = new HooksLogger("Map/AvoidanceZoneLayer");

interface Props extends RouteProps {
  name: string;
  selectedLayer: string;
  drawTool: string;
  setDrawTool: Dispatch<SetStateAction<string>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
}

type Component = (props: Props) => JSX.Element;

export const AvoidanceZoneLayer: Component = ({
  name,
  selectedLayer,
  drawTool,
  setDrawTool,
  setLoading,
  ...routeProps
}) => {
  const map = useContext(MapContext);
  const format = useGeoJSON();

  const [feature, setFeature] = useState<Feature<Geometry>>();
  const [vectorSource] = useState(new VectorSource({}));

  const addDataToSource = useCallback(
    ({ data }: MapFeatureGeoJSON) => {
      if (!format || !map) return;

      const features = format.readFeatures(data);

      // Convert points to circles
      features
        .filter((feature) => {
          const geom = feature.getGeometry();
          return geom?.getType() === GeometryType.POINT;
        })
        .forEach((feature) => {
          const circle = pointToCircle(feature as Feature<Point>);
          feature.setGeometry(circle);
        });

      vectorSource.addFeatures(features);
      logger.info("Loaded Avoidance Zones", { count: features.length });
    },
    [format, map, vectorSource]
  );

  const [loading] = useMapFeatureData({
    type: "avoidance-zone",
    addDataToSource,
  });

  useEffect(() => setLoading(loading), [loading, setLoading]);

  useEffect(() => {
    if (!map || !format) return;

    const layer = new VectorLayer({
      properties: {
        title: name,
        layerType: strings.layerTypes.vector,
        olgmWatch: false,
      },
      source: vectorSource,
      style: avoidanceZoneLayerStyle,
    });

    map.addLayer(layer);

    return () => {
      map.removeLayer(layer);
    };
  }, [name, vectorSource, map, format]);

  useEffect(() => {
    if (!map || selectedLayer !== name || !drawTool) return;

    const onDrawEnd = (feature: Feature<Geometry>) => {
      const geom = feature.getGeometry();
      if (!geom) return;

      if (geom.getType() === GeometryType.CIRCLE) {
        const point = circleToPoint(feature as Feature<Circle>);
        setFeature(point);
      } else {
        setFeature(feature);
      }

      setDrawTool("");
    };

    const draw = getDrawTool(drawTool, vectorSource, onDrawEnd);
    map.addInteraction(draw);

    return () => {
      map.removeInteraction(draw);
    };
  }, [selectedLayer, drawTool, name, map, vectorSource, setDrawTool]);

  const onSuccess = () => {
    if (!feature || vectorSource.hasFeature(feature)) return;

    if (feature.getGeometry()?.getType() === GeometryType.POINT) {
      const circle = pointToCircle(feature as Feature<Point>);
      feature.setGeometry(circle);
    }

    vectorSource.addFeature(feature);
  };

  return (
    <AvoidanceZoneModal
      mode="create"
      avoidanceZone={feature}
      onSuccess={onSuccess}
      {...routeProps}
    />
  );
};
