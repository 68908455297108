import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAuthUser } from ".";
import { paths } from "../../navigation";

export const useAuth = () => {
  const { user, checkedAuth } = useAuthUser();
  const history = useHistory();

  useEffect(() => {
    if (process.env.REACT_APP_OFFLINE === "1") return;
    if (!checkedAuth) return;
    if (!user) history.push(paths.auth.login);
  }, [checkedAuth, history, user]);

  // returning these shouldn't really be this hooks job but to prevent
  // needing to call useAuthUser again when its already use in here
  return { user, checkedAuth };
};
