import { Grid, Tab, Tabs } from "@material-ui/core";
import { ChangeEvent, useState } from "react";
import { useTagGroup } from "../../../hooks/tags";
import { RouteProps } from "../../../types";
import { AssetGeoJson } from "../../../types/documents/Assets";
import { ConditionSearchFormContainer } from "./ConditionSearchFormContainer";
import { tagsEndpoint } from "./SearchContainer";
import { PanelSize } from "./SearchPanel";
import { useStyles } from "./styles";

export interface SearchFormProps extends RouteProps {
  clientId: string;
  panelSize: PanelSize;
  handleSearchResults: (searchResults: AssetGeoJson) => void;
}
type SearchFormComponent = (props: SearchFormProps) => JSX.Element;

export const SearchForm: SearchFormComponent = ({
  clientId,
  panelSize,
  ...routeProps
}: SearchFormProps) => {
  const classes = useStyles();
  const { jwt } = routeProps;
  const [projectType, setProjectType] = useState("");
  const [options] = useTagGroup([], tagsEndpoint, jwt, "type");
  const handleProjectType = (event: ChangeEvent<{}>, value: string) => {
    setProjectType(value);
  };

  const getSearchPanel = () => {
    const option = options.find((option) => {
      return option.value === projectType;
    });

    if (!option) return <></>;
    return (
      <ConditionSearchFormContainer
        clientId={clientId}
        projectType={projectType}
        {...routeProps}
      ></ConditionSearchFormContainer>
    );
  };

  return (
    <>
      <form noValidate autoComplete="off" className={classes.form}>
        <Grid container direction="column">
          <Grid item style={{ borderBottom: `1px solid #D1D1D1` }}>
            <Tabs
              value={projectType}
              onChange={handleProjectType}
              textColor="primary"
              indicatorColor="primary"
              aria-label="Project Type Tabs"
              orientation={
                panelSize === PanelSize.Min ? "vertical" : "horizontal"
              }
            >
              {options.map((option) => {
                return (
                  <Tab
                    label={option.label}
                    id={`project-type-${option.value}`}
                    aria-controls={`project-typepanel-${option.value}`}
                    value={option.value}
                    style={{ fontWeight: 700 }}
                    key={`project-type-${option.value}`}
                  ></Tab>
                );
              })}
            </Tabs>
          </Grid>
          <Grid item>{getSearchPanel()}</Grid>
        </Grid>
      </form>
    </>
  );
};
