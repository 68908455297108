import { Container, Grid, IconButton, Typography } from "@material-ui/core";
import { CloseOutlined, DoubleArrow } from "@material-ui/icons";
import clsx from "clsx";
import { useState } from "react";
import { searchStrings as strings } from "../../../resources/strings";
import { RouteProps } from "../../../types";
import { AssetGeoJson } from "../../../types/documents/Assets";
import { SearchForm } from "./SearchForm";
import { useStyles } from "./styles";

interface SearchPanelProps extends RouteProps {
  clientId: string | null;
  showSearch: boolean;
  handleShowSearch: (showSearch: boolean) => void;
  handleSearchResults: (searchResults: AssetGeoJson) => void;
}
type SearchPanelComponent = (props: SearchPanelProps) => JSX.Element;

export type StandardSearch = {
  dateFrom: string;
  dateTo: string;
  groupByKey: string;
  groupByValue: string[];
  groupByValueFrom: string;
  groupByValueTo: string;
};
export type ConditionSearch = StandardSearch & {
  circuitId: string[];
  components: string;
  gradings: [];
};
export type SearchKeys = keyof ConditionSearch;

export enum PanelSize {
  Min,
  Max,
}

export const SearchPanel: SearchPanelComponent = ({
  clientId,
  showSearch,
  handleShowSearch,
  ...routeProps
}: SearchPanelProps) => {
  const classes = useStyles();

  const [panelSize, setPanelSize] = useState(PanelSize.Max);

  const handlePanelSize = () => {
    setPanelSize((prev) => {
      return prev === PanelSize.Min ? PanelSize.Max : PanelSize.Min;
    });
  };

  return showSearch ? (
    <Container
      className={clsx(
        classes.searchPanelPlacement,
        classes.panel,
        panelSize === PanelSize.Min ? classes.minPanel : classes.maxPanel
      )}
    >
      <Grid container justifyContent="space-between" className={classes.header}>
        <Grid item>
          <h3>{strings.labels.search}</h3>
        </Grid>
        <Grid item>
          <IconButton
            size="small"
            aria-label="close"
            className={clsx(
              panelSize === PanelSize.Min ? "" : classes.minPanelIcon
            )}
            onClick={() => {
              handlePanelSize();
            }}
          >
            <DoubleArrow />
          </IconButton>
          <IconButton
            size="small"
            aria-label="close"
            onClick={() => {
              handleShowSearch(false);
            }}
          >
            <CloseOutlined />
          </IconButton>
        </Grid>
      </Grid>
      {clientId ? (
        <SearchForm
          clientId={clientId}
          panelSize={panelSize}
          {...routeProps}
        ></SearchForm>
      ) : (
        <Grid item>
          <Typography>{strings.labels.selectAClient}</Typography>
        </Grid>
      )}
    </Container>
  ) : (
    <></>
  );
};
