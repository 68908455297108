import { useMemo } from "react";
import { AssetDetails } from "../../types/documents/Assets";

export const useSearchAssetsRange = (
  groupByValueFrom: string,
  groupByValueTo: string,
  assets: AssetDetails[]
): AssetDetails[] => {
  return useMemo(() => {
    let fromIndex = assets.findIndex(
      (asset) => asset.assetContainer.name === groupByValueFrom
    );
    let toIndex = assets.findIndex(
      (asset) => asset.assetContainer.name === groupByValueTo
    );
    if (fromIndex === -1) fromIndex = 0;
    if (toIndex === -1 && assets.length > 0) toIndex = assets.length - 1;

    return assets.slice(fromIndex, toIndex);
  }, [groupByValueFrom, groupByValueTo, assets]);
};
