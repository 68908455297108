import { Grid, IconButton, makeStyles, Typography } from "@material-ui/core";
import PrevIcon from "@material-ui/icons/NavigateBefore";
import NextIcon from "@material-ui/icons/NavigateNext";
import FitIcon from "@material-ui/icons/SettingsOverscan";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import ZoomOutMapIcon from "@material-ui/icons/ZoomOutMap";
import { ReactZoomPanPinchHandlers } from "react-zoom-pan-pinch";

interface Props extends ReactZoomPanPinchHandlers {
  hasPrev: boolean;
  hasNext: boolean;

  onPrev: () => void;
  onNext: () => void;

  timestamp: string;
}

type Component = (props: Props) => JSX.Element;

export const ZoomableImageControls: Component = ({
  hasPrev,
  hasNext,
  onPrev,
  onNext,
  timestamp,

  zoomIn,
  zoomOut,
  resetTransform,
  zoomToElement,
}) => {
  const classes = useStyles();

  const renderNav = () => (
    <>
      <IconButton
        size="small"
        onClick={onPrev}
        disabled={!hasPrev}
        className={classes.navButton}
      >
        <PrevIcon />
      </IconButton>

      <Typography className={classes.timestamp}>
        {new Date(timestamp).toLocaleString()}
      </Typography>

      <IconButton
        size="small"
        onClick={onNext}
        disabled={!hasNext}
        className={classes.navButton}
      >
        <NextIcon />
      </IconButton>
    </>
  );

  const renderControls = () => (
    <>
      <IconButton size="small" onClick={() => zoomIn()}>
        <ZoomInIcon />
      </IconButton>

      <IconButton size="small" onClick={() => zoomOut()}>
        <ZoomOutIcon />
      </IconButton>

      <IconButton size="small" onClick={() => resetTransform()}>
        <ZoomOutMapIcon />
      </IconButton>

      <IconButton size="small" onClick={() => zoomToElement("img-element")}>
        <FitIcon />
      </IconButton>
    </>
  );

  return (
    <Grid container wrap="nowrap" className={classes.container}>
      <Grid item xs={4} />

      <Grid item xs={4}>
        <Grid
          container
          wrap="nowrap"
          justifyContent="center"
          alignItems="center"
        >
          {renderNav()}
        </Grid>
      </Grid>

      <Grid item xs={4}>
        <Grid container className={classes.controls} justifyContent="flex-end">
          {renderControls()}
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(0.5),
  },

  navButton: {
    borderRadius: 0,
    background: theme.palette.primary.main,
    color: theme.palette.common.white,

    "&:hover": {
      background: theme.palette.primary.dark,
    },

    "&:disabled": {
      background: theme.palette.primary.light,
      opacity: "50%",
      color: theme.palette.common.white,
    },
  },
  controls: {
    "& > button": {
      color: theme.palette.primary.main,
    },
  },
  timestamp: {
    width: theme.spacing(25),

    textAlign: "center",
    fontWeight: 600,
    fontSize: "1.2rem",
  },
}));
