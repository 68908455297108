import Overview from "ol-ext/control/Overview";
import OLGoogleMaps from "olgm/OLGoogleMaps";

export class OverviewMap extends Overview {
  constructor(options: any) {
    super(options);

    const ovmap = this.getOverviewMap();

    const cacheLayer = ovmap.getAllLayers().find((layer) => {
      return layer.get("name") === "Cache extent";
    });
    cacheLayer && cacheLayer.set("olgmWatch", false);

    const olGM = new OLGoogleMaps({ map: ovmap });
    olGM.activate();
  }
}
