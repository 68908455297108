import { Typography } from "@material-ui/core";
import { Redirect, Route, Switch } from "react-router-dom";
import { PrivateRoute } from "../components/authentication";
import { MapWithFlight } from "../components/flight/mapWithFlight";

import { ImageUpload } from "../components/general/BucketGallery/ImageUpload";
import { GPSView } from "../components/gpsView/GPSView";
import { MapComponent } from "../components/map";
import { AssetUpload } from "../containers/assets";
import { CustomLogin as Login } from "../containers/authentication/CustomLogin";
import { PasswordReset } from "../containers/authentication/PasswordReset";
import { BookingList } from "../containers/bookings/BookingList";
import { ButtonConfigurationList } from "../containers/buttonConfiguration/ButtonConfigurationList";
import { ButtonConfigurationModify } from "../containers/buttonConfiguration/ButtonConfigurationModify";
import { ClientList, ClientModify } from "../containers/clients";
import { FlightList } from "../containers/flights/FlightList";
import { FlightModify } from "../containers/flights/FlightModify";
import { GradingReportList } from "../containers/gradingReport/GradingReportList";
import { LineLayerList } from "../containers/lines/LineLayerList";
import { LineLayerModify } from "../containers/lines/LineLayerModify";
import { Settings } from "../containers/offline/Settings";
import { Sync } from "../containers/offline/Sync";
import { ProjectList, ProjectModify } from "../containers/projects";
import { GradingFormContainer } from "../containers/projects/packages/GradingFormContainer";
import { PackageModify } from "../containers/projects/packages/PackageModify";
import { ViewFlights } from "../containers/projects/packages/ViewFlights";
import { UserList, UserModify } from "../containers/users";
import { appStrings as strings } from "../resources/strings";
import { CognitoUser, RouteProps } from "../types";
import { paths } from "./paths";

type Component = (props: RouteProps) => JSX.Element;
type TRoutes = JSX.Element[];

export const Routes: Component = (props) => {
  const appRoutes: TRoutes = [
    <Route key="login" exact path={paths.auth.login}>
      <Login user={props.user} />
    </Route>,
    <Route key="passwordReset" exact path={paths.auth.passwordReset}>
      <PasswordReset user={props.user} />
    </Route>,
    <Route key="root" exact path={paths.root}>
      <Redirect to={paths.map} />
    </Route>,
    <Route key="map" exact path={paths.mapFeature(":id?")}>
      <MapComponent {...props} />
    </Route>,
  ];
  const bookingRoutes: TRoutes = [
    <PrivateRoute
      {...props}
      key="viewBooking"
      exact
      path={paths.bookings.view(":id")}
      userAccess={{
        entity: strings.entities.bookings,
        permission: strings.permissions.read,
      }}
    >
      {null}
    </PrivateRoute>,
    <PrivateRoute
      {...props}
      key="createBooking"
      exact
      path={paths.bookings.create}
      userAccess={{
        entity: strings.entities.bookings,
        permission: strings.permissions.create,
      }}
    >
      {null}
    </PrivateRoute>,
    <PrivateRoute
      {...props}
      key="listBookings"
      exact
      path={paths.bookings.list}
      userAccess={{
        entity: strings.entities.bookings,
        permission: strings.permissions.read,
      }}
    >
      <BookingList {...props} />
    </PrivateRoute>,
  ];
  const scheduleRoutes: TRoutes = [
    <PrivateRoute
      {...props}
      key="viewSchedule"
      exact
      path={paths.schedules.view(":id")}
      userAccess={{
        entity: strings.entities.schedules,
        permission: strings.permissions.read,
      }}
    >
      {null}
    </PrivateRoute>,
    <PrivateRoute
      {...props}
      key="listSchedules"
      exact
      path={paths.schedules.list}
      userAccess={{
        entity: strings.entities.schedules,
        permission: strings.permissions.read,
      }}
    >
      {null}
    </PrivateRoute>,
  ];
  const userRoutes: TRoutes = [
    <PrivateRoute
      {...props}
      key="viewUser"
      exact
      path={paths.users.view(":id")}
      userAccess={{
        entity: strings.entities.users,
        permission: strings.permissions.read,
      }}
    >
      <UserModify {...props} mode="view" />
    </PrivateRoute>,
    <PrivateRoute
      {...props}
      key="createUser"
      exact
      path={paths.users.create}
      userAccess={{
        entity: strings.entities.users,
        permission: strings.permissions.create,
      }}
    >
      <UserModify {...props} mode="create" />
    </PrivateRoute>,
    <PrivateRoute
      {...props}
      key="userList"
      exact
      path={paths.users.list}
      userAccess={{
        entity: strings.entities.users,
        permission: strings.permissions.read,
      }}
    >
      <UserList {...props} />
    </PrivateRoute>,
  ];
  const clientRoutes: TRoutes = [
    <PrivateRoute
      {...props}
      key="viewClient"
      exact
      path={paths.clients.view(":id")}
      userAccess={{
        entity: strings.entities.clients,
        permission: strings.permissions.read,
      }}
    >
      <ClientModify {...props} mode="view" />
    </PrivateRoute>,
    <PrivateRoute
      {...props}
      key="createClient"
      exact
      path={paths.clients.create}
      userAccess={{
        entity: strings.entities.clients,
        permission: strings.permissions.create,
      }}
    >
      <ClientModify {...props} mode="create" />
    </PrivateRoute>,
    <PrivateRoute
      {...props}
      key="listClients"
      exact
      path={paths.clients.list}
      userAccess={{
        entity: strings.entities.clients,
        permission: strings.permissions.read,
      }}
    >
      <ClientList {...props} />
    </PrivateRoute>,
    <PrivateRoute
      {...props}
      key="listClientLines"
      exact
      path={paths.clients.lines.list(":clientId")}
      userAccess={{
        entity: strings.entities.clients,
        permission: strings.permissions.update,
      }}
    >
      <LineLayerList {...props} />
    </PrivateRoute>,
    <PrivateRoute
      {...props}
      key="viewClientLine"
      exact
      path={paths.clients.lines.view(":clientId", ":id")}
      userAccess={{
        entity: strings.entities.clients,
        permission: strings.permissions.update,
      }}
    >
      <LineLayerModify {...props} mode="view" />
    </PrivateRoute>,
  ];
  const projectRoutes: TRoutes = [
    <PrivateRoute
      {...props}
      key="viewProject"
      exact
      path={paths.projects.view(":id")}
      userAccess={{
        entity: strings.entities.projects,
        permission: strings.permissions.read,
      }}
    >
      <ProjectModify {...props} mode="view" />
    </PrivateRoute>,
    <PrivateRoute
      {...props}
      key="createProject"
      exact
      path={paths.projects.create}
      userAccess={{
        entity: strings.entities.projects,
        permission: strings.permissions.create,
      }}
    >
      <ProjectModify {...props} mode="create" />
    </PrivateRoute>,
    <PrivateRoute
      {...props}
      key="listProjects"
      exact
      path={paths.projects.list}
      userAccess={{
        entity: strings.entities.projects,
        permission: strings.permissions.read,
      }}
    >
      <ProjectList {...props} />
    </PrivateRoute>,
  ];

  const packageRoutes: TRoutes = [
    <PrivateRoute
      {...props}
      key="viewPackage"
      exact
      path={paths.packages.view(":id")}
      userAccess={{
        entity: strings.entities.packages,
        permission: strings.permissions.read,
      }}
    >
      <PackageModify {...props} mode="view" />
    </PrivateRoute>,
    <PrivateRoute
      {...props}
      key="createPackage"
      exact
      path={paths.packages.create(":projId")}
      userAccess={{
        entity: strings.entities.packages,
        permission: strings.permissions.create,
      }}
    >
      <PackageModify {...props} mode="create" />{" "}
    </PrivateRoute>,
    <PrivateRoute
      {...props}
      key="gradingForm"
      exact
      path={paths.packages.gradingForm(":packageId")}
      userAccess={{
        entity: strings.entities.packages,
        permission: strings.permissions.update,
      }}
    >
      <GradingFormContainer {...props} />
    </PrivateRoute>,
    <PrivateRoute
      {...props}
      key="gradingReport"
      exact
      path={paths.projects.gradingReport(":projectId")}
      userAccess={{
        entity: strings.entities.projects,
        permission: strings.permissions.read,
      }}
    >
      <GradingReportList {...props} />
    </PrivateRoute>,
  ];
  const assetRoutes = [
    <PrivateRoute
      {...props}
      key="uploadAssets"
      exact
      path={paths.assets.upload(":id?")}
      userAccess={{
        entity: strings.entities.assets,
        permission: strings.permissions.create,
      }}
    >
      <AssetUpload {...props} />
    </PrivateRoute>,
  ];

  const viewImagesRoute = [
    <PrivateRoute
      {...props}
      key="viewImages"
      exact
      path={paths.images.view}
      userAccess={{
        entity: strings.entities.images,
        permission: strings.permissions.create,
      }}
    >
      <ImageUpload {...props} />
    </PrivateRoute>,
  ];

  const flightRoutes = [
    <PrivateRoute
      {...props}
      key="dailyInfoLog"
      exact
      path={paths.dailyInfoLog}
      userAccess={{
        entity: strings.entities.flights,
        permission: strings.permissions.read,
        override: (user: CognitoUser) => user.roleType !== "Client",
      }}
    >
      <FlightList {...props} />
    </PrivateRoute>,
    <PrivateRoute
      {...props}
      key="dailyInfoLog"
      exact
      path={paths.packages.viewFlights(":id")}
      userAccess={{
        entity: strings.entities.flights,
        permission: strings.permissions.read,
      }}
    >
      <ViewFlights {...props} />
    </PrivateRoute>,
    <PrivateRoute
      {...props}
      key="editFlight"
      exact
      path={paths.flights.view(":id")}
      userAccess={{
        entity: strings.entities.flights,
        permission: strings.permissions.read,
      }}
    >
      <FlightModify {...props} mode="view" />
    </PrivateRoute>,
    <PrivateRoute
      {...props}
      key="viewGpx"
      exact
      path={paths.flights.viewGpx(":flightId", ":id?")}
      userAccess={{
        entity: strings.entities.flights,
        permission: strings.permissions.read,
      }}
    >
      <GPSView {...props} />
    </PrivateRoute>,
  ];

  const formDesignerRoutes = [
    <PrivateRoute
      {...props}
      key="formDesigner"
      exact
      path={paths.formDesigner.create}
      userAccess={{
        entity: strings.entities.defectTypes,
        permission: strings.permissions.create,
      }}
    >
      <ButtonConfigurationModify
        {...props}
        mode="create"
      ></ButtonConfigurationModify>
    </PrivateRoute>,
    <PrivateRoute
      {...props}
      key="formDesigner"
      exact
      path={paths.formDesigner.edit(":formId")}
      userAccess={{
        entity: strings.entities.defectTypes,
        permission: strings.permissions.create,
      }}
    >
      <ButtonConfigurationModify
        {...props}
        mode="update"
      ></ButtonConfigurationModify>
    </PrivateRoute>,
    <PrivateRoute
      {...props}
      key="formDesigner"
      exact
      path={paths.formDesigner.list}
      userAccess={{
        entity: strings.entities.defectTypes,
        permission: strings.permissions.read,
      }}
    >
      <ButtonConfigurationList></ButtonConfigurationList>
    </PrivateRoute>,
  ];

  return (
    <Switch>
      {appRoutes}
      {bookingRoutes}
      {clientRoutes}
      {packageRoutes}
      {projectRoutes}
      {flightRoutes}
      {scheduleRoutes}
      {userRoutes}
      {assetRoutes}
      {viewImagesRoute}
      {flightRoutes}
      {formDesignerRoutes}
      <Route key="notFound">
        <Typography>page not found</Typography>
      </Route>
    </Switch>
  );
};

export const OfflineRoutes: Component = (props) => {
  const appRoutes: TRoutes = [
    <Route key="login" exact path={paths.auth.login}>
      <Login user={props.user} />
    </Route>,
    <Route key="root" exact path={paths.root}>
      <Redirect to={paths.map} />
    </Route>,
    <Route key="map" exact path={paths.map}>
      <MapComponent {...props} />
    </Route>,
    <Route key="map" exact path={paths.mapFeature(":id?")}>
      <MapComponent {...props} />
    </Route>,
    <Route key="settings" exact path={paths.offline.settings(":id")}>
      <Settings {...props} />
    </Route>,
    <Route key="sync" exact path={paths.offline.sync}>
      <Sync {...props} />
    </Route>,
    <Route key="projects" exact path={paths.projects.list}>
      <ProjectList {...props} />
    </Route>,
    <Route key="viewProject" exact path={paths.projects.view(":id")}>
      <ProjectModify {...props} mode="view" />
    </Route>,
    <Route key="packageFlights" exact path={paths.packages.viewFlights(":id")}>
      <ViewFlights {...props} />
    </Route>,
    <Route
      key="createFlight"
      exact
      path={paths.flights.createFlight(":clientId", ":packageId", ":id?")}
    >
      <MapWithFlight {...props} />
    </Route>,
    <Route {...props} key="viewPackage" exact path={paths.packages.view(":id")}>
      <PackageModify {...props} mode="view" />
    </Route>,
  ];
  return (
    <Switch>
      {appRoutes}
      <Route key="notFound">
        <Typography>page not found</Typography>
      </Route>
    </Switch>
  );
};
