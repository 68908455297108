import { CancelToken } from "axios";
import { API } from "../API";
import { PACKAGES_ENDPOINT } from "../libs/config";
import { AssetContainer, PackageAsset, PackageData } from "../types/documents";

const baseUrl = PACKAGES_ENDPOINT;

export const getPackage = async (
  jwt: string,
  cancelToken: CancelToken,
  id: string
) => {
  const api = new API<PackageData>(cancelToken, jwt);

  return api.get(`${baseUrl}/${id}`);
};

export const getPackageAssetContainers = async (
  jwt: string,
  cancelToken: CancelToken,
  id: string
) => {
  const api = new API<AssetContainer[]>(cancelToken, jwt);

  const assetContainers = await api.get(`${baseUrl}/${id}/assetContainers`);
  console.log({ assetContainers });
  return assetContainers ?? [];
};

type SetPackageAssetsStatus = {
  jobId: string | null;
  packageAssets: PackageAsset[];
};

export const updatePackageAssets = async (
  jwt: string,
  cancelToken: CancelToken,
  id: string,
  packageAssets: PackageAsset[],
  jobId: string | null = null
) => {
  const api = new API<SetPackageAssetsStatus>(cancelToken, jwt);

  return api.submitData(
    { jobId, packageAssets },
    "update",
    `${baseUrl}/${id}/packageAssets`
  );
};
