import { useContext, useEffect } from "react";

import MapContext from "../MapContext";
import GoogleLayer from "olgm/layer/Google";
import MapTypeStyle from "google/maps/MapTypeStyle";
import { mapStrings as strings } from "../../../resources/strings";

interface Props {
  name: string;
  defaultVisible?: boolean;
  mapType: string;
  styles?: MapTypeStyle[];
}

type Component = (props: Props) => JSX.Element;

export const GoogleBaseLayer: Component = ({
  name,
  defaultVisible,
  mapType,
  styles,
}) => {
  const map = useContext(MapContext);

  useEffect(() => {
    if (!map) return;

    const googleLayer = new GoogleLayer({
      properties: {
        title: name,
        layerType: strings.layerTypes.google,
        type: "base",
      },
      visible: !!defaultVisible,
      mapTypeId: mapType,
      styles,
    });

    map.addLayer(googleLayer);

    return () => {
      map.removeLayer(googleLayer);
    };
  }, [map, defaultVisible, mapType, name, styles]);

  return <></>;
};
