import { NotificationManager } from "react-notifications";
import { appStrings } from "../resources/strings";

const DEFAULT_TIMEOUT = 4000;
const { notifications: notiStrings } = appStrings;

const error = (
  body = "",
  title = notiStrings.type.error,
  timeout = DEFAULT_TIMEOUT
) => NotificationManager.error(body, title, timeout);

const info = (
  body = "",
  title = notiStrings.type.info,
  timeout = DEFAULT_TIMEOUT
) => NotificationManager.info(body, title, timeout);

const success = (
  body = "",
  title = notiStrings.type.success,
  timeout = DEFAULT_TIMEOUT
) => NotificationManager.success(body, title, timeout);

export const notifications = { error, info, success };
