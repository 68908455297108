import axios, { CancelToken } from "axios";
import axiosInstance from "../libs/axios/axios-instance";
import { GPX_ENDPOINT } from "../libs/config";

const gpxAxios = axiosInstance(GPX_ENDPOINT ?? "");

export const getGPXURL = async (
  cancelToken: CancelToken,
  key: string,
  type: "upload" | "download"
) => {
  try {
    const path = `/${type}/${key}`;
    const config = { cancelToken };

    const { data: url } = await gpxAxios.get<string>(path, config);
    return url;
  } catch (e: any) {
    console.log(e);
    throw e.message ? e.message : e;
  }
};

export const getGPXData = async (cancelToken: CancelToken, key: string) => {
  try {
    const path = await getGPXURL(cancelToken, key, "download");
    const config = { cancelToken };

    const { data } = await axios.get<string>(path, config);
    return data;
  } catch (e: any) {
    console.log(e);
    throw e.message ? e.message : e;
  }
};

export const putGPXData = async (
  cancelToken: CancelToken,
  key: string,
  file: File
) => {
  try {
    const path = await getGPXURL(cancelToken, key, "upload");
    await axios.put(path, file);
  } catch (e: any) {
    console.log(e);
    throw e.message ? e.message : e;
  }
};
