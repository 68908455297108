import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";

import { Feature } from "ol";
import { Geometry } from "ol/geom";
import { Draw, Snap } from "ol/interaction";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";

import MapContext from "../MapContext";
import { markupLayerStyle } from "../helpers/Style";
import { mapStrings as strings } from "../../../resources/strings";
import { getDrawTool } from "../helpers/DrawTools";
import { getSelect } from "../helpers/Select";
import { useGeoJSON } from "../helpers/GeoJSON";

interface Props {
  name: string;
  selectedLayer: string;
  drawTool: string;
  setDrawTool: Dispatch<SetStateAction<string>>;
}

type Component = (props: Props) => JSX.Element;

export const MarkupLayer: Component = ({
  name,
  selectedLayer,
  drawTool,
  setDrawTool,
}) => {
  const map = useContext(MapContext);
  const format = useGeoJSON();

  const [open, setOpen] = useState(false);
  const [feature, setFeature] = useState<Feature<Geometry>>();
  const [text, setText] = useState("");

  const [vectorSource] = useState(new VectorSource());

  useEffect(() => {
    if (!map) return;

    const layer = new VectorLayer({
      properties: {
        title: name,
        layerType: strings.layerTypes.vector,
        olgmWatch: false,
      },
      source: vectorSource,
      style: markupLayerStyle,
    });

    map.addLayer(layer);

    return () => {
      map.removeLayer(layer);
    };
  }, [name, vectorSource, map]);

  useEffect(() => {
    if (!map || selectedLayer !== name) return;

    const onSelect = (selected: Feature<Geometry>) => {
      if (drawTool === strings.drawTools.text || !drawTool) {
        const text = selected.get("name");
        if (text) {
          setFeature(selected);
          setText(text);
          setOpen(true);
        }
      } else if (drawTool === strings.drawTools.delete) {
        vectorSource.removeFeature(selected);
      }

      select.getFeatures().clear();
    };

    const select = getSelect(name, onSelect);
    map.addInteraction(select);

    // Snap allows clicking on features to be less precise for delete
    const snap = new Snap({ source: vectorSource });
    if (drawTool === strings.drawTools.delete) {
      map.addInteraction(snap);
    }

    return () => {
      map.removeInteraction(select);
      map.removeInteraction(snap);
    };
  }, [selectedLayer, drawTool, name, vectorSource, map]);

  useEffect(() => {
    if (!map || !format || selectedLayer !== name || !drawTool) return;
    let draw: Draw | undefined;

    switch (drawTool) {
      case strings.drawTools.clear:
        vectorSource.clear();
        setDrawTool("");
        break;

      case strings.drawTools.delete:
        // Do nothing, delete is handled by the select interaction
        break;

      case strings.drawTools.ruler:
        draw = getDrawTool(drawTool, vectorSource);
        break;

      case strings.drawTools.text:
        draw = getDrawTool(drawTool, vectorSource, (feature) => {
          setFeature(feature);
          setOpen(true);
          vectorSource.addFeature(feature);
        });
        break;

      default:
        draw = getDrawTool(drawTool, vectorSource, (feature) => {
          vectorSource.addFeature(feature);
        });
        break;
    }

    if (draw) map.addInteraction(draw);
    return () => {
      if (draw) map.removeInteraction(draw);
    };
  }, [selectedLayer, drawTool, name, vectorSource, map, format, setDrawTool]);

  const closeDialog = () => {
    setOpen(false);
    setText("");

    // Remove point if text is empty
    if (feature && !feature.getProperties().name) {
      vectorSource.removeFeature(feature);
    }
  };

  const submitDialog = () => {
    if (feature) feature.setProperties({ name: text });
    closeDialog();
  };

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      fullWidth={true}
      BackdropProps={{ style: { backgroundColor: "transparent" } }}
    >
      <DialogTitle>Enter Text</DialogTitle>
      <DialogContent>
        <TextField
          label="Text"
          fullWidth
          multiline
          value={text}
          autoFocus
          onChange={(event) => setText(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button onClick={submitDialog} color="primary" variant="contained">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
