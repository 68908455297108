import {
  CircularProgress,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import {
  CheckCircleOutline,
  ErrorOutline,
  WarningTwoTone,
} from "@material-ui/icons";
import clsx from "clsx";
import { RouteProps } from "../../types";
import { MemoizedLoadDetails } from "./LineLayerLoadDetails";
import {
  LineLayerLoad,
  LineLayerLoadStatus
} from "../../types/documents/LineLayer";

export interface Props extends RouteProps {
  loads: LineLayerLoad[];
  selectedLoad?: LineLayerLoad;
  onSelectLoad: (id: string) => void;
}

type Component = (props: Props) => JSX.Element;

export const LineLayerLoadHistory: Component = ({
  loads,
  selectedLoad,
  onSelectLoad,
  ...routeProps
}) => {
  const classes = useStyles();

  const renderLoadButton = (load: LineLayerLoad) => {
    const isSelectedClass =
      selectedLoad?.id === load.id ? classes.selectedLoad : undefined;
    return (
      <span
        onClick={() => onSelectLoad(load.id)}
        key={load.id}
        className={clsx(classes.loadHistoryListItem, isSelectedClass)}
      >
        <span className={classes.loadStatusIcon}>
          {iconForLoadStatus(load.status)}
        </span>
        {load.name}
      </span>
    );
  };

  return (
    <>
      <Grid
        className={classes.header}
        container
        direction="row"
        justifyContent="flex-start"
      >
        <Grid item>
          <Typography variant="h6" align="left" className={classes.headerCrumb}>
            Executions
          </Typography>
        </Grid>
      </Grid>
      <div className={classes.loadHistory}>
        <div className={classes.loadHistoryList}>
          {loads.map(renderLoadButton)}
        </div>
        <div className={classes.loadHistoryDetails}>
          {selectedLoad !== undefined && (
            <MemoizedLoadDetails
              key={selectedLoad.id}
              {...routeProps}
              load={selectedLoad}
            />
          )}
        </div>
      </div>
    </>
  );
};

const iconForLoadStatus = (loadStatus: LineLayerLoadStatus): JSX.Element => {
  switch (loadStatus) {
    case LineLayerLoadStatus.CheckingInput:
    case LineLayerLoadStatus.Processing:
      return <CircularProgress variant="indeterminate" size={20} />;
    case LineLayerLoadStatus.AwaitingColumnSelection:
      return <WarningTwoTone htmlColor="#FF9A00" />;
    case LineLayerLoadStatus.Succeeded:
      return <CheckCircleOutline htmlColor="#99CB01" />;
    case LineLayerLoadStatus.Failed:
      return <ErrorOutline color="error" />;
  }
};

const useStyles = makeStyles((theme) => ({
  header: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    backgroundColor: theme.palette.background.paper,
    borderBottom: theme.spacing(0.125, "solid", `${theme.border.primary}`),
  },
  headerCrumb: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    textTransform: "uppercase",
    fontSize: theme.spacing(2.3),
  },
  loadHistory: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  loadHistoryList: {
    borderRight: theme.spacing(0.125, "solid", `${theme.border.primary}`),
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FAFAFA",
  },
  loadHistoryListItem: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingTop: "10px",
    paddingBottom: "10px",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  selectedLoad: {
    backgroundColor: "#EEEEEE",
  },
  loadStatusIcon: {
    marginRight: "6px",
    display: "flex",
  },
  loadHistoryDetails: {
    borderLeft: 0,
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));
