import { Button } from "@material-ui/core";
import clsx from "clsx";
import { useCallback, useState } from "react";
import { Cell, Filters } from "react-table";
import { Table } from "../../components/general";
import { useGradingColumns } from "../../hooks/gradings";
import { columnNames } from "../../resources/GradingReportTemplate";
import { gradingsStrings as strings } from "../../resources/strings/gradings";
import { GradingOption } from "../../resources/testGradingForm";
import { RouteProps } from "../../types";
import { ExportButton } from "./ExportButton";
import { GradingReportBreadcrumbHeader } from "./GradingReportBreadcrumbHeader";
import { GradingReportFilterPanel } from "./GradingReportFilterPanel";
import { GradingReportModal } from "./GradingReportModal";
import { useProjectTableData } from "./hooks/useProjectTableData";
import { useStyles } from "./styles";

interface Props extends RouteProps {}
type Component = (props: Props) => JSX.Element;

export type ExportData = {
  headers: any[][];
  rows: string[][];
};

export const GradingReportList: Component = (props) => {
  const classes = useStyles();

  const [showFilterPanel, setShowFilterPanel] = useState(false);
  const [filters, setFilters] = useState<Filters<any>>([]);

  const [exportData, setExportData] = useState<ExportData | undefined>();

  const [selectedRow, setSelectedRow] = useState<any>();

  const { jwt } = props;
  const { project, reportDate, tableData, circuitIds, loading } =
    useProjectTableData(jwt);

  const handleSelectedRow = useCallback((row: any) => {
    setSelectedRow(row);
  }, []);

  const columns = useGradingColumns(
    handleSelectedRow,
    circuitIds.left,
    circuitIds.right
  );
  const getHeaderCellStyle = (column: any) => {
    if (column.placeholderOf) return { borderWidth: "0 0 0 0" };

    if (!column.columns) {
      const columns = column.parent.columns;
      return {
        borderWidth: columns[0] === column ? "1px 0 1px 1px" : "1px 0",
      };
    }

    return {};
  };

  const getCellStyle = (cell: Cell) => {
    const options: GradingOption[] = (cell.column as any).options;
    if (!options) return {};

    const style = options
      .filter((v) => v.caption === cell.value)
      .map((v) => v.style)[0];

    return style;
  };

  const renderHeader = () => (
    <>
      {!showFilterPanel && (
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setShowFilterPanel(true)}
          className={clsx(classes.button, classes.headerButton)}
        >
          {strings.report.filterButton}
        </Button>
      )}

      {exportData && project && (
        <ExportButton exportData={exportData} projectName={project?.jobCode} />
      )}

      <div>
        <span className={classes.headerField}>{strings.report.name} </span>
        {project ? project.jobCode : ""}
      </div>

      <div>
        <span className={classes.headerField}>{strings.report.date} </span>
        {reportDate ? reportDate : ""}
      </div>
    </>
  );

  return (
    <>
      {project && <GradingReportBreadcrumbHeader project={project} />}
      <div
        className={clsx(
          classes.gradingForm,
          !showFilterPanel && classes.hideFilterPanel
        )}
      >
        {project && (
          <div
            className={clsx(
              classes.filterPanel,
              !showFilterPanel && classes.hide
            )}
          >
            <GradingReportFilterPanel
              setShowFilterPanel={setShowFilterPanel}
              filters={filters}
              setFilters={setFilters}
              columns={columns}
              tableData={tableData}
              project={project}
            />
          </div>
        )}
        <div className={clsx(classes.reportDetails, classes.header)}>
          {renderHeader()}
        </div>
        <div className={classes.gradingControls}>
          <Table<any>
            columns={columns}
            theme={classes}
            data={tableData}
            getCellStyle={getCellStyle}
            getHeaderCellStyle={getHeaderCellStyle}
            useFlexTable={false}
            localFilters={filters}
            onPageChange={() => {}}
            onSizeChange={() => {}}
            loading={loading}
            hiddenColumns={[columnNames.groupings]}
            sortBy={[{ id: columnNames.name }]}
            setExportData={setExportData}
            hideSortIndicator
            {...props}
          />
        </div>
      </div>
      <GradingReportModal
        {...props}
        open={selectedRow ? true : false}
        assetContainer={selectedRow?.assetContainer}
        packageId={selectedRow?.packageId}
        onClose={() => setSelectedRow(undefined)}
      />
    </>
  );
};
