import { makeStyles } from "@material-ui/core";

export const useDefectTypeListStyles = makeStyles((theme) => ({
  root: {
    minHeight: "200px",
  },
  headerContainer: {
    borderBottom: `1px solid ${theme.border.primary}`,
    paddingBottom: "10px",
  },
  header: {
    color: theme.palette.primary.main,
    fontFamily: theme.typography.fontFamily,
    textTransform: "uppercase",
    fontWeight: 700,
  },
}));
