import { Theme } from "@material-ui/core";

export const defaultButtonStyle = (theme: Theme) => ({
  fontWeight: theme.spacing(80),
  boxShadow: theme.spacing(0, 0, 0, 0),
  height: theme.spacing(5),
  width: theme.spacing(20),
  borderRadius: 0,
  margin: theme.spacing(0.5),
});

export const deleteButtonStyle = (theme: Theme) => ({
  background: theme.palette.background.default,
});
