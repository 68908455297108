export type WebGLProgramAttributeType = "float" | "vec2" | "vec4";

export type WebGLProgramAttributeInfo = {
  name: string,
  type: WebGLProgramAttributeType,
};

export type WebGLProgramUniformInfo = {
  name: string,
  type: WebGLProgramAttributeType,
};

export type WebGLProgramInfo = {
  attributes: WebGLProgramAttributeInfo[],
  uniforms: WebGLProgramUniformInfo[],

  vertexShader: string,
  fragmentShader: string,
};

type WebGLUniformDescriptors = {
  [Name in string]?: {
    location: WebGLUniformLocation,
  }
};

export abstract class WebGLRenderProgram {
  protected readonly vertexShader: WebGLShader;
  protected readonly fragmentShader: WebGLShader;
  protected readonly program: WebGLProgram;
  protected readonly vertexBuffer: WebGLBuffer;
  protected readonly indexBuffer: WebGLBuffer;
  protected readonly vertexArray: WebGLVertexArrayObject;
  protected readonly uniforms: WebGLUniformDescriptors;

  protected constructor(
    protected webgl: WebGL2RenderingContext,
    programInfo: WebGLProgramInfo,
  ) {
    let vertexShader: WebGLShader | undefined;
    let fragmentShader: WebGLShader | undefined;
    let program: WebGLProgram | undefined;
    let vertexBuffer: WebGLBuffer | undefined;
    let indexBuffer: WebGLBuffer | undefined;
    let vertexArrayObject: WebGLVertexArrayObject | undefined;
    let uniforms: WebGLUniformDescriptors | undefined;

    try {
      vertexShader =
        createShader(webgl, webgl.VERTEX_SHADER, programInfo.vertexShader);
      fragmentShader =
        createShader(webgl, webgl.FRAGMENT_SHADER, programInfo.fragmentShader);
      program = createProgram(webgl, vertexShader, fragmentShader);
      vertexBuffer = createBuffer(webgl);
      indexBuffer = createBuffer(webgl);
      vertexArrayObject = createVertexArrayObject(
        webgl,
        program,
        vertexBuffer,
        indexBuffer,
        programInfo.attributes
      );
      uniforms = createUniformDescriptors(webgl, program, programInfo.uniforms);
    } catch (e) {
      vertexArrayObject && webgl.deleteVertexArray(vertexArrayObject);
      indexBuffer && webgl.deleteBuffer(indexBuffer);
      vertexBuffer && webgl.deleteBuffer(vertexBuffer);
      program && webgl.deleteProgram(program);
      fragmentShader && webgl.deleteShader(fragmentShader);
      vertexShader && webgl.deleteShader(vertexShader);
      throw e;
    }

    this.vertexShader = vertexShader;
    this.fragmentShader = fragmentShader;
    this.program = program;
    this.vertexBuffer = vertexBuffer;
    this.indexBuffer = indexBuffer;
    this.vertexArray = vertexArrayObject;
    this.uniforms = uniforms;
  }

  public dispose() {
    this.webgl.deleteVertexArray(this.vertexArray);
    this.webgl.deleteBuffer(this.indexBuffer);
    this.webgl.deleteBuffer(this.vertexBuffer);
    this.webgl.deleteProgram(this.program);
    this.webgl.deleteShader(this.fragmentShader);
    this.webgl.deleteShader(this.vertexShader);
  }
}

function createProgram(
  webgl: WebGL2RenderingContext,
  vertexShader: WebGLShader,
  fragmentShader: WebGLShader,
): WebGLProgram {
  const program = webgl.createProgram();
  if (!program) {
    throw new Error("Failed to crete program");
  }

  webgl.attachShader(program, vertexShader);
  webgl.attachShader(program, fragmentShader);
  webgl.linkProgram(program);
  const success = webgl.getProgramParameter(program, webgl.LINK_STATUS);

  if (!success) {
    const error = webgl.getProgramInfoLog(program);
    webgl.deleteProgram(program);
    throw new Error("Failed to link program: " + error);
  }

  return program;
}

function createShader(
  webgl: WebGL2RenderingContext,
  type: GLenum,
  source: string
): WebGLShader {
  const shader = webgl.createShader(type);
  if (!shader) {
    throw new Error("Failed to create shader");
  }

  webgl.shaderSource(shader, source);
  webgl.compileShader(shader);
  const success = webgl.getShaderParameter(shader, webgl.COMPILE_STATUS);

  if (!success) {
    const error = webgl.getShaderInfoLog(shader);
    webgl.deleteShader(shader);
    throw new Error("Failed to compile shader: " + error);
  }

  return shader;
}

function createUniformDescriptors(
  webgl: WebGL2RenderingContext,
  program: WebGLProgram,
  uniformInfo: WebGLProgramUniformInfo[],
): WebGLUniformDescriptors {
  const setters: WebGLUniformDescriptors = {};

  for (const uniform of uniformInfo) {
    const location = webgl.getUniformLocation(program, uniform.name);
    if (location === null) {
      throw new Error("Failed to lookup position for " + uniform.name);
    }

    setters[uniform.name] = { location };
  }

  return setters;
}

function createBuffer(webgl: WebGL2RenderingContext): WebGLBuffer {
  const buffer = webgl.createBuffer();
  if (!buffer) {
    throw new Error("Failed to create buffer");
  }
  return buffer;
}

function createVertexArrayObject(
  webgl: WebGL2RenderingContext,
  program: WebGLProgram,
  vertexBuffer: WebGLBuffer,
  indexBuffer: WebGLBuffer,
  attributeInfo: WebGLProgramAttributeInfo[],
) {
  const vertexArray = webgl.createVertexArray();
  if (!vertexArray) {
    throw new Error("Failed to create vertex array");
  }

  try {
    initVertexArrayObject(
      webgl,
      vertexArray,
      program,
      vertexBuffer,
      indexBuffer,
      attributeInfo
    );
  } catch (e) {
    webgl.deleteVertexArray(vertexArray);
    throw e;
  }

  return vertexArray;
}

function initVertexArrayObject(
  webgl: WebGL2RenderingContext,
  vertexArray: WebGLVertexArrayObject,
  program: WebGLProgram,
  vertexBuffer: WebGLBuffer,
  indexBuffer: WebGLBuffer,
  attributeInfo: WebGLProgramAttributeInfo[],
) {
  // The Vertex Array Object (VAO) contains the mappings for attributes.
  // While the VAO is bound, vertexAttribPointer will store the attribute
  // mappings into the VAO together with the bound ARRAY_BUFFER.
  webgl.bindVertexArray(vertexArray);
  webgl.bindBuffer(webgl.ARRAY_BUFFER, vertexBuffer);
  webgl.bindBuffer(webgl.ELEMENT_ARRAY_BUFFER, indexBuffer);

  // Calculate the total size of the attributes in bytes.
  let stride = 0;
  for (const attrib of attributeInfo) {
    stride += getAttributeSize(attrib.type) * Float32Array.BYTES_PER_ELEMENT;
  }

  // Setup each attribute to be sequential in the vertexBuffer.
  let offset = 0;
  for (const attrib of attributeInfo) {
    const location = webgl.getAttribLocation(program, attrib.name);
    if (location === -1) {
      throw new Error("Could not find position for " + attrib.name);
    }

    const size = getAttributeSize(attrib.type);

    webgl.enableVertexAttribArray(location);
    webgl.vertexAttribPointer(
      location,
      size,
      webgl.FLOAT,
      false,
      stride,
      offset
    );
    offset += size * Float32Array.BYTES_PER_ELEMENT;
  }
  webgl.bindVertexArray(null);
}

const getAttributeSize = (type: WebGLProgramAttributeType): number => {
  switch (type) {
    case "float":
      return 1;
    case "vec2":
      return 2;
    case "vec4":
      return 4;
  }
}
