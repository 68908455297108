import { appStrings } from "./app";

export const tagStrings = {
  global: { ...appStrings },
  header: {
    tagGroups: "Tag Groups",
    tagDetails: "Tag Details",
  },
  labels: {
    name: "Name",
    save: "Save",
    reset: "Reset",
    edit: "Edit",
    addTag: "Add Tag",
    tags: "tags",
  },
  placeholders: {
    tagText: "Tag Text...",
  },
};
