import { Feature } from "ol";
import { Point } from "ol/geom";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { Circle, Fill, Style } from "ol/style";
import { useContext, useEffect, useState } from "react";
import useWebSocket from "react-use-websocket";
import useSound from "use-sound";
import { alarm } from "../../../assets/alarm";
import { HooksLogger } from "../../../hooks/hooks-logger";
import { mapStrings as strings } from "../../../resources/strings";
import { gpsWebSocketUrl } from "../../../services/offline/gps.service";
import { convertPoint } from "../helpers/Coordinates";
import MapContext from "../MapContext";

const logger = new HooksLogger("Map/GpsStatus");

interface Props {}

type Component = (props: Props) => JSX.Element;

export const GpsPositionLayer: Component = (_: Props) => {
  const map = useContext(MapContext);
  const [vectorSource] = useState(new VectorSource());

  const [avoidanceZoneSource, setAvoidanceZoneSource] =
    useState<VectorSource>();

  const [playAlarm, { stop: stopAlarm }] = useSound(alarm, { loop: true });
  const [alarmActive, setAlarmActive] = useState(false);

  const { sendMessage, lastJsonMessage } = useWebSocket(gpsWebSocketUrl, {
    shouldReconnect: (_) => true,
  });

  // Ping server to keep connection alive.
  useEffect(() => {
    const interval = setInterval(() => sendMessage("ping"), 5000);
    return () => clearInterval(interval);
  }, [sendMessage]);

  useEffect(() => {
    if (alarmActive) playAlarm();
    else stopAlarm();

    return () => stopAlarm();
  }, [alarmActive, playAlarm, stopAlarm]);

  useEffect(() => {
    if (!map) return;

    const vectorLayer = new VectorLayer({
      properties: {
        title: strings.layers.gpsPosition,
        layerType: strings.layerTypes.vector,
        olgmWatch: false,
      },
      source: vectorSource,
      style: new Style({
        image: new Circle({
          radius: 5,
          fill: new Fill({
            color: "red",
          }),
        }),
      }),
    });
    map.addLayer(vectorLayer);

    return () => {
      map.removeLayer(vectorLayer);
    };
  }, [map, vectorSource]);

  useEffect(() => {
    if (!map) return;
    if (!lastJsonMessage?.latitude || !lastJsonMessage?.longitude) return;

    const target = map.getView().getProjection().getCode();

    const latitude = lastJsonMessage.latitude;
    const longitude = lastJsonMessage.longitude;
    const coords = convertPoint(strings.projections.EPSG_4326, target, [
      longitude,
      latitude,
    ]);
    logger.info(coords);

    if (!avoidanceZoneSource) {
      const layer = map
        .getAllLayers()
        .find((layer) => layer.get("title") === strings.layers.avoidanceZone);

      setAvoidanceZoneSource(layer?.getSource() as VectorSource);
    }

    const features = avoidanceZoneSource?.getFeaturesAtCoordinate(coords) ?? [];
    setAlarmActive(features.length > 0);

    vectorSource.clear();
    vectorSource.addFeature(new Feature(new Point(coords)));
  }, [map, vectorSource, avoidanceZoneSource, lastJsonMessage]);

  return <></>;
};
