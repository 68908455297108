import { appStrings } from "./app";

export const imageStrings = {
  global: { ...appStrings },
  labels: {
    client: "Client",
    package: "Package",
    flight: "Flight",
    selectClientFlight: "Select Client & Flight",
  },
  headers: {
    noImages: "No Images",
  },
  noImages: {
    flight: "No images have been uploaded to this flight",
    asset: "No images have been uploaded to this asset",
    zone: "No images have been uploaded to this zone",
  },
};
