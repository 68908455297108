import {
  ControlTemplate,
  UpdateControlTemplateData,
} from "@pulsion/forms-designer-2";
import log from "loglevel";
import config from "./config";

export interface IControlTemplateApi {
  create(controlTemplate: ControlTemplate): Promise<unknown>;
  remove(id: string, removeJobs: boolean): Promise<unknown>;
  update(
    id: string,
    controlTemplate: UpdateControlTemplateData
  ): Promise<unknown>;
  get(id: string): Promise<unknown>;
}

const create = async (controlTemplate: ControlTemplate): Promise<unknown> => {
  return await config
    .axiosInstance({
      url: config.apiEndPoints.controlTemplate.create,
      method: "post",
      data: controlTemplate,
    })
    .then((res) => log.debug(res))
    .catch((err) => {
      log.error(err);
      throw err;
    });
};

const get = async (id: string): Promise<unknown> => {
  return await config
    .axiosInstance({
      url: config.apiEndPoints.controlTemplate.get(id),
      method: "get",
    })
    .then((res) => {
      log.debug(res);
      const data = res.data;
      if (!data || data.length) {
        throw new Error("Form Template not found");
      }

      return data;
    })
    .catch((err) => {
      log.error(err);
      throw err;
    });
};

const remove = async (id: string, removeJobs: boolean): Promise<unknown> => {
  return await config
    .axiosInstance({
      url: config.apiEndPoints.controlTemplate.delete(id, removeJobs),
      method: "delete",
    })
    .then((res) => {
      log.debug(res);
    })
    .catch((err) => {
      log.error(err);
      throw err;
    });
};

const update = async (
  updateControlTemplateData: UpdateControlTemplateData,
  id: string,
  subSectionId?: string
): Promise<unknown> => {
  const url = config.apiEndPoints.controlTemplate.update(id, subSectionId);
  return await config
    .axiosInstance({
      url: url,
      method: "put",
      data: updateControlTemplateData,
    })
    .then((res) => {
      log.debug(res);
    })
    .catch((err) => {
      log.error(err);
      throw err;
    });
};

const getNewControlId = async (): Promise<unknown> => {
  return await config
    .axiosInstance({
      url: config.apiEndPoints.controlTemplate.getNewControlId(),
      method: "get",
    })
    .then((res) => {
      log.debug(res);
      const data = res.data;

      return data;
    })
    .catch((err) => {
      log.error(err);
      throw err;
    });
};

const getControls = async (
  id: string,
  subsectionId: string
): Promise<unknown> => {
  return await config
    .axiosInstance({
      url: config.apiEndPoints.controlTemplate.getControls(id, subsectionId),
      method: "get",
    })
    .then((res) => {
      log.debug(res);
      const data = res.data;

      return data;
    })
    .catch((err) => {
      log.error(err);
      throw err;
    });
};

export { get, update, remove, create, getNewControlId, getControls };
