import { useEffect, useState } from "react";
import { DropdownOptions } from "../../types";

export const useAssetGroupOptions = (assetGroups: any[]) => {
  const [assetGroupOptions, setAssetGroupOptions] = useState<DropdownOptions>(
    []
  );

  useEffect(() => {
    const groupOptions: DropdownOptions = assetGroups.map((group) => ({
      label: group,
      value: group,
    }));
    setAssetGroupOptions(() => [...groupOptions]);
  }, [assetGroups]);

  return assetGroupOptions;
};
