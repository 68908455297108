import { Map } from "ol";
import { Pointer } from "ol/interaction";
import { useEffect, useState } from "react";
import { getMapFeaturesByLayer } from "../../components/map/helpers/LayerFunctions";
import { FeatureGroup } from "../../types/FetureGroup";

type Props = {
  map: Map | undefined;
  layers: string[];
};

type Result = [
  FeatureGroup[],
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>,
  number[]
];

export const useFeatureSelector = ({ map, layers }: Props): Result => {
  const [features, setFeatures] = useState<FeatureGroup[]>([]);
  const [coordinates, setCoordinates] = useState<number[]>([]);
  const [showFeatures, setShowFeatures] = useState<boolean>(false);

  useEffect(() => {
    if (!map) return;

    const pointer = new Pointer({
      handleEvent: (event) => {
        const eventType = event.type;
        if (eventType === "singleclick") {
          const resolution = (event.frameState?.viewState.resolution ?? 0) * 6;
          const [x, y] = event.coordinate;

          const extent = [
            x - resolution,
            y - resolution,
            x + resolution,
            y + resolution,
          ];

          const layerFeatures = getMapFeaturesByLayer(map, extent, layers);
          console.log("Layer Features", layerFeatures);

          setFeatures(layerFeatures);
          setShowFeatures(layerFeatures.length > 0);
          setCoordinates(event.pixel);
        }

        return true;
      },
    });

    map.addInteraction(pointer);

    return () => {
      map.removeInteraction(pointer);
    };
  });

  return [features, showFeatures, setShowFeatures, coordinates];
};
