export enum FlightState {
  NotStarted = 0,
  Started = 1,
  TransittedToSite = 2,
  StartedAssessment = 3,
  FinishedAssessment = 4,
  TransittedToBase = 5,
  Finished = 6,
  Aborted = 7,
  Errored = 8,
}
