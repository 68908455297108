import { makeStyles } from "@material-ui/core/styles";
import { MousePosition, ScaleLine } from "ol/control";
import Map from "ol/Map";
import { register } from "ol/proj/proj4";
import View from "ol/View";
import { defaults } from "olgm/interaction";
import OLGoogleMaps from "olgm/OLGoogleMaps";
import proj4 from "proj4";
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useResizeDetector } from "react-resize-detector";
import { HooksLogger } from "../../hooks/hooks-logger";
import { mapStrings as strings } from "../../resources/strings";
import { getFormattedCoords } from "./helpers/Coordinates";
import LayerSwitcher from "./layerSwitcher/LayerSwitcher";
import MapContext from "./MapContext";

const logger = new HooksLogger("MapContainer");

// UK National Grid
proj4.defs(
  strings.projections.EPSG_27700,
  "+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +datum=OSGB36 +units=m +no_defs"
);
// Irish National Grid
proj4.defs(
  strings.projections.EPSG_29903,
  "+proj=tmerc +lat_0=53.5 +lon_0=-8 +k=1.000035 +x_0=200000 +y_0=250000 +ellps=mod_airy +towgs84=482.5,-130.6,564.6,-1.042,-0.214,-0.631,8.15 +units=m +no_defs"
);
register(proj4);

interface Props {
  header: boolean;
}

type Component = FunctionComponent<Props>;

const initialZoom = 8;
const initialCenter = [-382464.2285140679, 7524153.042616495];

export const MapContainer: Component = ({ header, children }) => {
  const [map, setMap] = useState<Map>();

  const mapElement = useRef<HTMLDivElement>(null);

  const onResize = useCallback(() => map?.updateSize(), [map]);
  useResizeDetector({
    // @ts-ignore
    targetRef: mapElement,
    onResize,
  });

  const classes = useStyles();

  const [baseLayerOpacity, setBaseLayerOpacity] = useState(1);

  useEffect(() => {
    if (mapElement.current === null) {
      logger.error("mapElement is null");
      return;
    }

    const initialMap = new Map({
      interactions: defaults(),
      target: mapElement.current,
      view: new View({
        maxZoom: 19,
        center: initialCenter,
        zoom: initialZoom,
        constrainResolution: true,
        smoothResolutionConstraint: false,
        projection: strings.projections.EPSG_3857,
      }),
      controls: [],
    });

    const olGM = new OLGoogleMaps({
      map: initialMap,
      gmapOptions: {
        tilt: 0,
        animatedZoom: false,
      },
    });
    olGM.activate();

    const layerSwitcher = new LayerSwitcher(setBaseLayerOpacity, {
      reverse: true,
    });
    initialMap.addControl(layerSwitcher);

    const scale = new ScaleLine({ className: classes.scaleLine });
    initialMap.addControl(scale);

    const projection = strings.projections.EPSG_4326;
    const cursorCoords = new MousePosition({
      coordinateFormat: getFormattedCoords(projection),
      projection: projection,
      placeholder: false,
      className: classes.mousePosition,
    });
    initialMap.addControl(cursorCoords);

    setMap(initialMap);

    return function cleanup() {
      olGM.deactivate();
      initialMap.setTarget(undefined);
      setMap(undefined);
    };
  }, [classes]);

  const css = `
  .gm-style {
    background: #f0f0f0;
  }
  .gm-style > div[role="region"] {
    opacity: ${baseLayerOpacity};
  }
  `;

  const headerOffset = header ? 198 : 128;

  return (
    <MapContext.Provider value={map}>
      <div
        style={{
          width: "100%",
          height: `calc(100vh - ${headerOffset}px)`,
          position: "relative",
        }}
      >
        <style>{css}</style>
        <div ref={mapElement} style={{ width: "100%", height: "100%" }}>
          {children}
        </div>
      </div>
    </MapContext.Provider>
  );
};

const useStyles = makeStyles(() => ({
  scaleLine: {
    position: "absolute",
    left: "auto",
    right: "4px",
    bottom: "16px",
    borderRadius: "4px",
    padding: "2px",
    background: "rgba(0,60,136,.5)",
    "& div": {
      border: "1px solid #eee",
      borderTop: "none",
      color: "#eee",
      font: "12px Rajdhani, Arial, sans-serif",
      textAlign: "center",
      margin: "1px",
      willChange: "contents,width",
      transition: "all .25s",
    },
  },
  mousePosition: {
    font: "12px Roboto, Arial, sans-serif",
    color: "#eee",
    background: "rgba(0,60,136,.5)",
    borderRadius: "4px",
    padding: "4px",
    margin: "4px",
    float: "right",
  },
}));
