import {
  Breadcrumbs,
  makeStyles,
  Typography,
  TypographyProps,
} from "@material-ui/core";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { NextPageIcon } from "../../assets";
import { Crumb } from "../general/types/Modify";
import { ReactNode } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3.6875, 2.5),
    background: theme.palette.background.default,
    borderBottom: theme.spacing(0.125, "solid", `${theme.border.primary}`),
    fontSize: theme.spacing(0.125),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: theme.spacing(8.75),
  },
  buttons: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
    "&:hover": {
      cursor: "pointer",
    },
  },
  text: {
    fontWeight: 600,
  },
}));

interface IProps {
  crumbs: Crumb[];
  children?: ReactNode;
  className?: string;
  crumbProps?: TypographyProps;
}

export default function BreadcrumbHeader({
  crumbs,
  children,
  className,
  crumbProps,
}: IProps) {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, className)}>
      <Breadcrumbs
        separator={<NextPageIcon fontSize="medium" />}
        aria-label="breadcrumb"
        role="heading"
      >
        {crumbs?.map((crumb: Crumb, index: number) =>
          crumb.link ? (
            <Link className={clsx(classes.link)} key={index} to={crumb.link}>
              <Typography variant="h6" className={classes.text} {...crumbProps}>
                {crumb.name}
              </Typography>
            </Link>
          ) : (
            <Typography
              key={index}
              variant="h6"
              className={classes.text}
              {...crumbProps}
            >
              {crumb.name}
            </Typography>
          )
        )}
      </Breadcrumbs>
      <div
        className={classes.buttons}
        data-testid={`header-buttons${
          crumbs[0].name ? "-" + crumbs[0].name : ""
        }`}
      >
        {children}
      </div>
    </div>
  );
}
