import {
  Dialog,
  DialogTitle,
  Paper,
  PaperProps,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useContext, useEffect, useRef } from "react";
import Draggable from "react-draggable";
import { syncLayerChanges } from "../helpers/LayerFunctions";
import { overviewBoxStyle } from "../helpers/Style";
import MapContext from "../MapContext";
import { OverviewMap } from "./OverviewMap";

interface Props {
  open: boolean;
}

type Component = (props: Props) => JSX.Element;

export const OverviewDialog: Component = ({ open }) => {
  const map = useContext(MapContext);

  const overviewDiv = useRef(null);
  const classes = useStyles();

  useEffect(() => {
    if (!map) return;

    const overview = new OverviewMap({
      target: overviewDiv.current,
      style: overviewBoxStyle,
    });
    map.addControl(overview);
    syncLayerChanges(map, overview.getOverviewMap());

    return () => {
      map.removeControl(overview);
    };
  }, [map]);

  return (
    <Dialog
      open={open}
      keepMounted
      disableEnforceFocus
      hideBackdrop
      PaperComponent={PaperComponent}
      style={{
        top: "25%",
        left: "25%",
        height: "fit-content",
        width: "fit-content",
      }}
    >
      <DialogTitle
        disableTypography
        className={classes.dialogTitle}
        id="drag-handle"
      >
        <Typography variant="subtitle2">Overview</Typography>
      </DialogTitle>
      <div ref={overviewDiv} className={classes.overviewMap} />
    </Dialog>
  );
};

const PaperComponent = (props: PaperProps) => {
  const dialogRef = useRef(null);
  return (
    <Draggable nodeRef={dialogRef} handle={"#drag-handle"}>
      <Paper {...props} ref={dialogRef} style={{ margin: "0" }} />
    </Draggable>
  );
};

const useStyles = makeStyles(() => ({
  dialogTitle: {
    padding: "4px 12px",
    cursor: "move",
  },
  overviewMap: {
    width: "25vmin",
    height: "25vmin",
    margin: "3px",
    "& .gm-style-cc": {
      display: "none !important",
    },
    '& .gm-style a[href^="https://maps.google.com/maps"]': {
      display: "none !important",
    },
  },
}));
