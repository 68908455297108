import { appStrings } from "./app";

export const infoLogStrings = {
  global: { ...appStrings },
  header: {
    flightInfoLog: "Flight Info Log",
  },
  labels: {
    reference: "Flight",
    observerName: "Observer Name",
    aircraftRegistration: "Aircraft Registration",
    packageName: "Package Name",
    aircraftType: "Aircraft Type",
    start: "Date",
    dateCreated: "Date Created",
    lastUpdated: "Last Updated",
    flightDuration: "Flight Duration",
    taskDuration: "Task Duration",
    techLogNumber: "Tech Log Number",
    view: "View",
    trace: "GPX Trace",
  },
  messages: {},
  notifications: {},
};
