import { makeStyles, Typography } from "@material-ui/core";
import { imageStrings as strings } from "../../../../resources/strings";
import { ImageParameters } from "../../../../types/documents";

interface Props {
  imageParameters: ImageParameters;
}

type Component = (props: Props) => JSX.Element;
export const NoImages: Component = ({ imageParameters }) => {
  const classes = useStyles();

  let message = "";

  if (imageParameters.zoneId) message = strings.noImages.zone;
  else if (imageParameters.assetId) message = strings.noImages.asset;
  else if (imageParameters.flightId) message = strings.noImages.flight;

  return (
    <>
      {message && (
        <div className={classes.noImages}>
          <img src="/DefaultImage.png" alt={strings.headers.noImages} />

          <h2>{strings.headers.noImages}</h2>

          <Typography>{message}</Typography>
        </div>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  noImages: {
    padding: theme.spacing(2),
  },
}));
