import { FileList } from "../../components/general/FileList";
import { useCallback, useState } from "react";
import * as linesApi from "../../services/lines.service";
import { useCancelToken } from "../../hooks/general";
import { HooksLogger } from "../../hooks/hooks-logger";
import { notifications } from "../../libs/notifications";
import { useServiceData } from "../../hooks/general/useServiceData";
import { ShapeFile } from "../../types/FileUpload";

const logger = new HooksLogger('LineLayerUpload');

interface Props {
  clientId: string;
  jwt: string;
}

export const LineLayerUpload = (props: Props): JSX.Element => {
  const { clientId, jwt } = props;
  const cancelToken = useCancelToken();
  const setError = useCallback(e => notifications.error(e), []);

  const listFiles = useCallback(async () => await linesApi.listFiles(
    jwt,
    cancelToken,
    clientId
  ), [jwt, cancelToken, clientId]);
  const [files, filesLoading, refreshFiles] = useServiceData(
    "Getting uploaded files",
    listFiles,
    logger,
    cancelToken,
    setError
  );

  const getUploadUrls = useCallback(
    (fileNames: string[]) => linesApi.getUploadUrls(
      jwt,
      cancelToken,
      clientId,
      fileNames
    ),
    [clientId, jwt, cancelToken]
  );
  const deleteFile = useCallback(
    (object: string) => {
      const sendDelete = async (object: string) => {
        try {
          await linesApi.deleteFile(jwt, object);
        } catch (e) {
          setError(e);
        } finally {
          refreshFiles();
        }
      };

      sendDelete(object);
    },
    [jwt, refreshFiles, setError]
  );

  const [processing, setProcessing] = useState(false);
  const processFile = (file: ShapeFile) => () => {
    const query = async () => {
      setProcessing(true);
      try {
        await linesApi.processFile(
          jwt,
          cancelToken,
          clientId,
          file
        );
        logger.success();
        setProcessing(false);
      } catch (e: any) {
        if (cancelToken.reason) return;
        const error = e.message ? e.message : e;
        setProcessing(false);
        setError(error);
        logger.error(error);
      }
    };

    query();
  };

  return <>
    <FileList processing={processing}
              loading={filesLoading} files={files || []}
              onDeleteFile={deleteFile}
              getUploadUrls={getUploadUrls}
              onFilesUploaded={refreshFiles}
              onProcessFile={processFile}
              acceptFileTypes=".shp,.shx,.dbf"/>
  </>;
}
