import {
  SubSectionTemplate,
  UpdateSubSectionTemplateData,
} from "@pulsion/forms-designer-2";
import log from "loglevel";
import config from "./config";

export interface ISubSectionTemplateApi {
  create(id: string, controlTemplate: SubSectionTemplate): Promise<any>;
  remove(id: string, subSectionId: string, removeJobs: boolean): Promise<any>;
  update(
    id: string,
    subSectionId: string,
    subSectionTemplate: UpdateSubSectionTemplateData
  ): Promise<any>;
  get(id: string, subSectionId: string): Promise<any>;
  reorder(id: string, sections: Array<string>): Promise<any>;
}

const create = async (
  id: string,
  controlTemplate: SubSectionTemplate
): Promise<any> => {
  const url = config.apiEndPoints.subSectionTemplate.createWithId(id);
  return await config
    .axiosInstance({
      url,
      method: "post",
      data: controlTemplate,
    })
    .then((res) => {
      log.debug(res);
      return res.data;
    })
    .catch((err) => {
      log.error(err);
      throw err;
    });
};

const get = async (id: string): Promise<any> => {
  return await config
    .axiosInstance({
      url: config.apiEndPoints.subSectionTemplate.get(id),
      method: "get",
    })
    .then((res) => {
      log.debug(res);
      const data = res.data;
      if (!data || data.length) {
        throw new Error("Form Template not found");
      }

      return data;
    })
    .catch((err) => {
      log.error(err);
      throw err;
    });
};

const remove = async (id: string, subsectionId: string): Promise<any> => {
  return await config
    .axiosInstance({
      url: config.apiEndPoints.subSectionTemplate.delete(id, subsectionId),
      method: "delete",
    })
    .then((res) => {
      log.debug(res);
    })
    .catch((err) => {
      log.error(err);
      throw err;
    });
};

const update = async (
  subSection: UpdateSubSectionTemplateData,
  id: string,
  subSectionId: string
): Promise<any> => {
  const url = config.apiEndPoints.subSectionTemplate.update(id, subSectionId);
  return await config
    .axiosInstance({
      url: url,
      method: "put",
      data: subSection,
    })
    .then((res) => {
      log.debug(res);
    })
    .catch((err) => {
      log.error(err);
      throw err;
    });
};

const reorder = async (
  id: string,
  subSections: Array<string>
): Promise<any> => {
  const url = config.apiEndPoints.subSectionTemplate.reorder(id);
  return await config
    .axiosInstance({
      url: url,
      method: "put",
      data: subSections,
    })
    .then((res) => {
      log.debug(res);
    })
    .catch((err) => {
      log.error(err);
      throw err;
    });
};

export { get, update, remove, create, reorder };
