import { FlightState } from "../../enums/flightState";

export type FlightButton = {
  name: string;
  render: () => boolean;
  onClick: () => void;
  state: FlightState;
};

export const useFlightButtons = (state: any, send: any): FlightButton[] => {
  return [
    {
      name: "Start",
      render: () => state.matches("notStarted"),
      onClick: () => {
        send("START");
      },
      state: FlightState.NotStarted,
    },
    {
      name: "Transit To Site",
      render: () => state.matches("started"),
      onClick: () => {
        send("TRANSIT_TO_SITE");
      },
      state: FlightState.Started,
    },
    {
      name: "Start Assessment",
      render: () => state.matches("transittedToSite"),
      onClick: () => {
        send("START_ASSESSMENT");
      },
      state: FlightState.StartedAssessment,
    },
    {
      name: "End Assessment",
      render: () => state.matches("assessmentStarted"),
      onClick: () => {
        send("FINISH_ASSESSMENT");
      },
      state: FlightState.FinishedAssessment,
    },
    {
      name: "Transit To Base",
      render: () => state.matches("assessmentFinished"),
      onClick: () => {
        send("TRANSIT_TO_BASE");
      },
      state: FlightState.TransittedToBase,
    },
    {
      name: "Finish",
      render: () => state.matches("transittedToBase"),
      onClick: () => {
        send("FINISH");
      },
      state: FlightState.Finished,
    },
    {
      name: "Abort",
      render: () => {
        const states = ["notStarted", "finished", "aborted", "errored"];
        return !states.some((s) => state.matches(s));
      },
      onClick: () => {
        send("ABORT");
      },
      state: FlightState.Aborted,
    },
  ];
};
