import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { API } from "../../../API";
import BreadcrumbHeader from "../../../components/general/BreadcrumbHeader";
import DeleteEditClearSaveButtons from "../../../components/general/DeleteEditClearSaveButtons";
import { Props } from "../../../components/general/ModifyComponent";
import { useCancelToken } from "../../../hooks/general";
import { PACKAGES_ENDPOINT, PROJECTS_ENDPOINT } from "../../../libs/config";
import { notifications } from "../../../libs/notifications";
import { paths } from "../../../navigation";
import { packageStrings as strings } from "../../../resources/strings";
import { PackageData, ProjectData } from "../../../types/documents";

export default function PackagesBreadcrumbHeader({
  mode,
  permissions,
  data,
  jwt,
  handleModeSwitch,
}: Props<PackageData>) {
  const cancelToken = useCancelToken();
  const history = useHistory();
  const [projectJobCode, setProjectJobCode] = useState("");
  const [deleteLoading, setDeleteLoading] = useState(false);

  const APIFunctions = useMemo(
    () => new API<PackageData | ProjectData>(cancelToken, jwt),
    [cancelToken, jwt]
  );

  useEffect(() => {
    async function getJobCode() {
      if (!data.projectId) return;
      const project = (await APIFunctions.get(
        `${PROJECTS_ENDPOINT}/${data.projectId}`
      )) as ProjectData;
      if (!project) return;
      setProjectJobCode(project.jobCode);
    }
    getJobCode();
  }, [APIFunctions, data.projectId]);

  const getHeaderFromMode = () => {
    switch (mode) {
      case "view":
        return strings.header.viewPackage;
      case "create":
        return strings.header.createPackage;
      case "update":
        return strings.header.editPackage;
    }
  };

  const crumbs = [
    {
      name: projectJobCode,
      link: paths.projects.view(data.projectId),
    },
    {
      name: getHeaderFromMode(),
    },
  ];

  const deletePackage = async () => {
    setDeleteLoading(true);
    try {
      await APIFunctions.delete(PACKAGES_ENDPOINT, data.id);
      notifications.success(strings.notifications.deleted);
      history.push(paths.projects.view(data.projectId));
    } catch (error) {
      notifications.error(strings.notifications.failedDelete);
    }
    setDeleteLoading(false);
  };

  return (
    <BreadcrumbHeader crumbs={crumbs}>
      <DeleteEditClearSaveButtons
        entity={strings.global.entities.packages}
        mode={mode}
        permissions={permissions}
        deleteFunction={deletePackage}
        deleteLoading={deleteLoading}
        handleModeSwitch={handleModeSwitch}
      />
    </BreadcrumbHeader>
  );
}
