import { Button, CssBaseline } from "@material-ui/core";
import { ExitToApp as SignOutIcon } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import logo from "../../assets/svgs/pdg-logo-white.svg";
import { signOut } from "../../libs/auth";
import { paths } from "../../navigation";
import { appStrings as strings } from "../../resources/strings";
import { CognitoUser, Permissions } from "../../types";
import { menuItems, offlineMenuItems } from "./listItems";
import { MenuComponent } from "./MenuComponent";

interface Props {
  children: JSX.Element;
  user: CognitoUser | null;
  permissions: Permissions | null;
}

type Component = (props: Props) => JSX.Element;

export const Layout: Component = ({ children, user, permissions }) => {
  const LoggedInLayout = () => {
    const handleSignOut = () => signOut();
    const additionalContent = (
      <Button color="inherit" onClick={handleSignOut}>
        {strings.labels.signOut}
        <SignOutIcon />
      </Button>
    );

    return (
      <MenuComponent
        menuItems={menuItems}
        permissions={permissions}
        logo={logo}
        additionalContent={additionalContent}
        user={user}
      >
        {children}
      </MenuComponent>
    );
  };
  const LoggedOutLayout = () => <>{children}</>;

  return (
    <>
      <CssBaseline />
      {user ? <LoggedInLayout /> : <LoggedOutLayout />}
    </>
  );
};

export const OfflineLayout: Component = ({ children, user, permissions }) => {
  const history = useHistory();
  const Layout = () => {
    const handleSignOut = () => {
      signOut();
      handleSignIn();
    };
    const handleSignIn = () => history.push(paths.auth.login);
    const additionalContent = user ? (
      <Button color="inherit" onClick={handleSignOut}>
        {strings.labels.signOut}
        <SignOutIcon />
      </Button>
    ) : (
      <Button color="inherit" onClick={handleSignIn}>
        {strings.labels.signIn}
      </Button>
    );

    return (
      <MenuComponent
        menuItems={offlineMenuItems}
        permissions={permissions}
        logo={logo}
        additionalContent={additionalContent}
        user={user}
      >
        {children}
      </MenuComponent>
    );
  };

  return (
    <>
      <CssBaseline />
      <Layout />
    </>
  );
};
