import { Button as UIButton } from "@material-ui/core";
import { useModifyContext } from "../../../context/ModifyContext";
import type { Button as TButton } from "../types/Modify";

interface Props {
  config: TButton;
}
type Component = (props: Props) => JSX.Element;

export const Button: Component = ({ config: input }) => {
  const { handleModeSwitch, handleDelete } = useModifyContext();

  const { label, buttonaction, ...config } = input;
  const buttonConfig = Object.assign({}, config);
  if (buttonaction) {
    switch (buttonaction) {
      case "switch":
        buttonConfig.type = "button";
        buttonConfig.onClick = () => {
          handleModeSwitch();
        };
        break;
      case "delete":
        buttonConfig.type = "button";
        buttonConfig.onClick = () => {
          handleDelete && handleDelete();
        };
        break;
      default:
        break;
    }
  }

  return (
    <UIButton type="submit" {...buttonConfig}>
      {label}
    </UIButton>
  );
};
