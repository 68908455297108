import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { StateValue } from "xstate";
import { FlightState } from "../../enums/flightState";
import { stateMapper } from "../../machines/flights/flightMachine";

const useStyles = makeStyles((theme) => ({
  progressContainer: {
    display: "flex",
    flexDirection: "row",
    padding: "0 16px",
    justifyContent: "space-between",
  },
  progressIndicator: {
    width: "8px",
    height: "8px",
    backgroundColor: "#d8d8d8",
    borderRadius: "50%",
  },
  progressed: {
    backgroundColor: theme.palette.primary.main,
  },
}));

type Props = {
  state: StateValue;
};

export const FlightProgressIndictator = ({ state }: Props) => {
  const classes = useStyles();

  const stateKey = Object.keys(stateMapper).find((key) => key === state);
  const flightState =
    stateKey !== undefined
      ? stateMapper[stateKey as keyof typeof stateMapper]
      : undefined;

  let results: JSX.Element[];

  if (!flightState) {
    results = [];
  } else {
    results = Object.values(FlightState)
      .filter(
        (value) =>
          typeof value !== "string" &&
          value !== FlightState.Errored &&
          value !== FlightState.Aborted
      )
      .map((flightStateEnum, index) => {
        const hasProgressed = flightStateEnum <= flightState.enum;
        return (
          <div
            className={clsx(
              classes.progressIndicator,
              hasProgressed && classes.progressed
            )}
            aria-label={flightState.toString()}
          ></div>
        );
      });
  }

  return <div className={clsx(classes.progressContainer)}> {results}</div>;
};
