import BreadcrumbHeader from "../../components/general/BreadcrumbHeader";
import { paths } from "../../navigation/paths";
import { appStrings as strings } from "../../resources/strings";
import { ProjectData } from "../../types/documents/Project";

interface Props {
  project: ProjectData;
}

type Component = (props: Props) => JSX.Element;

export const GradingReportBreadcrumbHeader: Component = ({ project }) => {
  const crumbs = [
    {
      name: strings.menuLabels.projects,
      link: paths.projects.list,
    },
    {
      name: project.jobCode,
      link: paths.projects.view(project.id),
    },
    {
      name: "Condition Assessment Report",
    },
  ];

  return <BreadcrumbHeader crumbs={crumbs} />;
};
