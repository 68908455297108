import { RefreshOutlined } from "@material-ui/icons";
import { Section } from "../../components/general/types/Modify";
import { settingsStrings as strings } from "../../resources/strings/offline/settings";
import { BasicComponentConfigurations } from "../../types";
import { SettingsData } from "../../types/documents/offline/Settings";
import {
  SyncItemParameter,
  SyncType,
} from "../../types/documents/offline/Sync";
import SettingsBreadcrumbHeader from "./SettingsBreadcrumbHeader";

export const settingsConstraints = {
  dataDirectory: {
    presence: {
      allowEmpty: false,
      message: `^${strings.labels.dataDirectory} is required`,
    },
  },
};

export const settingsComponents: BasicComponentConfigurations<SettingsData> = {
  details(
    _mode,
    jwt: string,
    cancelToken,
    { deviceOptions, devicesLoading, refreshDevices }: any
  ): Section<SettingsData> {
    return {
      key: "details",
      content: [
        {
          controltype: "custom",
          Component: SettingsBreadcrumbHeader,
          md: 12,
          fullwidth: true,
        },
        {
          controltype: "input",
          name: "dataDirectory",
          label: strings.labels.dataDirectory,
          required: true,
        },
        {
          controltype: "dropdown",
          name: "gpsDevice",
          label: strings.labels.gpsDevice,
          required: true,
          options: deviceOptions,
          loading: devicesLoading,
          buttonDisplay: <RefreshOutlined />,
          onButtonClick: refreshDevices,
        },
      ],
    };
  },
};

export const syncItemParameters: { [syncType in SyncType]: SyncItemParameter } =
  {
    [SyncType.Tags]: null,
    [SyncType.Clients]: null,
    [SyncType.MapTiles]: "clientId",
    [SyncType.Assets]: "clientId",
    [SyncType.MapFeatures]: null,
    [SyncType.Jobs]: "clientId",
    [SyncType.Flights]: "clientId",
    [SyncType.Projects]: "clientId",
    [SyncType.Lines]: "clientId",
  };
