// import type { SxProps, Theme } from "@mui/material";
import { Button, FormHelperText, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { BaseControl } from "../../components/general/controls/BaseControl";
import { Input } from "../../components/general/controls/Input";
import { Link } from "../../components/general/controls/Link";
import { Loader } from "../../components/general/Loader";
import { useForm } from "../../hooks/general/useForm";
import { forgotPassword, forgotPasswordSubmit } from "../../libs/auth";
import { paths } from "../../navigation/paths";
import { authStrings as strings } from "../../resources/strings/auth";
import type { CognitoUser } from "../../types";
import { AuthWrapper } from "./AuthWrapper";

interface Props {
  user: CognitoUser | null;
}
type Component = (props: Props) => JSX.Element;

const useStyles = makeStyles((theme) => ({
  spacer: {
    margin: theme.spacing(2, "0"),
  },
  button: { borderRadius: 0, fontWeight: "bolder" },
  header: {
    fontWeight: "bolder",
    marginBottom: "5rem",
    fontSize: "1.2rem",
  },
  link: {
    textDecoration: "underline",
  },
}));

export const PasswordReset: Component = ({ user }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const [codeSent, setCodeSent] = useState(false);

  const [formData, handleChange] = useForm({
    username: "",
    code: "",
    newPassword: "",
    confirmNewPassword: "",
  });
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    if (user) history.push(paths.root);
    if (success) history.push(paths.auth.login);
  }, [user, success, history]);

  const onRequestCode: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      await forgotPassword(formData.username);

      setCodeSent(true);
      setLoading(false);
      setError("");
    } catch (e) {
      // Continue on the "normal" flow even if account/email address doesn't exist
      // to help limit brute force attempts
      setCodeSent(true);
      setLoading(false);
      setSuccess(false);
    }
  };

  const onSubmitPassword: React.FormEventHandler<HTMLFormElement> = async (
    e
  ) => {
    e.preventDefault();

    try {
      setLoading(true);
      await forgotPasswordSubmit(
        formData.username,
        formData.code,
        formData.newPassword
      );

      setLoading(false);
      setError("");
      setSuccess(true);
    } catch (e) {
      setCodeSent(false);
      setLoading(false);
      setSuccess(false);
      setError(strings.errors.changePassword);
    }
  };

  const renderControl = (control: JSX.Element) => {
    return <BaseControl control={true}>{control}</BaseControl>;
  };

  const renderPasswordForm = () => {
    const formControls = [
      <Input
        config={{
          value: formData.code,
          name: "code",
          label: strings.labels.code,
          type: "password",
          fullWidth: true,
          autoComplete: "code",
          controltype: "input",
        }}
        handleChange={handleChange}
      />,
      <Input
        config={{
          value: formData.newPassword,
          name: "newPassword",
          label: strings.labels.newPassword,
          type: "password",
          fullWidth: true,
          autoComplete: "new-password",
          controltype: "input",
        }}
        handleChange={handleChange}
      />,
      <Input
        config={{
          value: formData.confirmNewPassword,
          name: "confirmNewPassword",
          label: strings.labels.confirmNewPassword,
          type: "password",
          fullWidth: true,
          autoComplete: "confirm-password",
          controltype: "input",
        }}
        handleChange={handleChange}
      />,
      <Button
        type="submit"
        children={strings.labels.confirm}
        color="primary"
        variant="contained"
        fullWidth
        disabled={[
          !formData.code,
          !formData.newPassword,
          !formData.confirmNewPassword,
          formData.newPassword !== formData.confirmNewPassword,
        ].includes(true)}
        className={clsx(classes.spacer, classes.button)}
      />,
    ];
    return (
      <>
        <Typography
          variant="button"
          color="primary"
          className={clsx(classes.header)}
        >
          {strings.headers.completePassword}
        </Typography>
        <form noValidate onSubmit={onSubmitPassword} autoComplete="off">
          {formControls.map((control) => renderControl(control))}
        </form>
      </>
    );
  };

  const renderRequestCodeForm = () => {
    const formControls = [
      <Input
        config={{
          value: formData.username,
          name: "username",
          label: strings.labels.username,
          fullWidth: true,
          autoComplete: "username",
          controltype: "input",
        }}
        handleChange={handleChange}
      />,
      <Button
        type="submit"
        children={strings.labels.submit}
        color="primary"
        variant="contained"
        fullWidth
        disabled={!formData.username}
        className={clsx(classes.spacer, classes.button)}
      />,
    ];
    return (
      <>
        <Typography
          variant="button"
          color="primary"
          className={clsx(classes.header)}
        >
          {strings.labels.resetPassword}
        </Typography>
        <Typography>{strings.text.receiveCode}</Typography>
        <form noValidate onSubmit={onRequestCode} autoComplete="off">
          {formControls.map((control) => renderControl(control))}

          {error ? <FormHelperText error>{error}</FormHelperText> : null}
        </form>
        <Link
          key="login"
          config={{
            text: strings.labels.returnToLogin,
            to: paths.auth.login,
            className: clsx(classes.spacer, classes.link),
          }}
        />
      </>
    );
  };

  const renderContent = () => {
    if (loading) {
      return <Loader active inline />;
    }

    if (codeSent) return renderPasswordForm();
    return renderRequestCodeForm();
  };

  return <AuthWrapper>{renderContent()}</AuthWrapper>;
};
