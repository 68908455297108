import React from "react";
import {
  columnSelectionComponentConfigurations,
  columnSelectionConstraints,
  submitButtons,
} from "../../containers/lines/resources";
import { ModifyContainer } from "../../containers/general";
import { LINES_ENDPOINT } from "../../libs/config";
import { paths } from "../../navigation";
import { RouteProps } from "../../types";
import {
  LineLayerColumnSelections,
  LineLayerLoad,
  LineLayerLoadStatus
} from "../../types/documents/LineLayer";

const putColumnSelectionEndpoint = `${LINES_ENDPOINT}/files/submit-column-selection`;
const queryEndpoint = `${LINES_ENDPOINT}/files/loads`;

const checkingInput = () => <>Checking input file</>;

const awaitingColumnSelection = (
  routeProps: RouteProps,
  load: LineLayerLoad
) => {
  const initialSelections: LineLayerColumnSelections = {
    id: load.id,
    displayName: "",
    transmissionVoltage: "",
    coordinateSystem: "EPSG:4326",
  };

  const columnOptions =
    load.availableColumns?.map((column) => {
      return {
        label: column,
        value: column,
      };
    }) ?? [];

  return (
    <ModifyContainer<LineLayerColumnSelections>
      {...routeProps}
      putEndpoint={putColumnSelectionEndpoint}
      queryEndpoint={queryEndpoint}
      mode="update"
      initialData={initialSelections}
      componentConfiguration={[
        columnSelectionComponentConfigurations.general(columnOptions),
        submitButtons,
      ]}
      constraints={columnSelectionConstraints}
      redirectPath={paths.clients.view}
      loading={false}
    />
  );
};

const processing = () => <>Processing</>;

const success = () => <>Success</>;

const failed = (load: LineLayerLoad) => {
  if (!load.error) {
    return <>Unknown error</>;
  }

  let formattedError;
  try {
    const parsedError = JSON.parse(load.error);
    if (parsedError.message) {
      formattedError = parsedError.message;
    } else {
      formattedError = JSON.stringify(parsedError, null, 2);
    }
  } catch (e) {
    formattedError = load.error;
  }

  return <>Error: {formattedError}</>;
}

interface DetailsProps extends RouteProps {
  load: LineLayerLoad;
}

type DetailsComponent = (props: DetailsProps) => JSX.Element;
export const LoadDetails: DetailsComponent = ({ load, ...routeProps }) => {
  switch (load.status) {
    case LineLayerLoadStatus.CheckingInput:
      return checkingInput();
    case LineLayerLoadStatus.AwaitingColumnSelection:
      return awaitingColumnSelection(routeProps, load)
    case LineLayerLoadStatus.Processing:
      return processing();
    case LineLayerLoadStatus.Succeeded:
      return success();
    case LineLayerLoadStatus.Failed:
    default:
      return failed(load);
  }
};

export const MemoizedLoadDetails = React.memo(
  LoadDetails,
  (a, b) => a.load.id === b.load.id && a.load.status === b.load.status
);
