import { useEffect, useState } from "react";
import * as api from "../../../services/permissions.service";
import { CognitoUser, Permissions } from "../../../types";
import { useCancelToken } from "../../general";
import { HooksLogger } from "../../hooks-logger";

const logger = new HooksLogger("useUserPermissions.ts");

export const useUserPermissions = (user: CognitoUser | null) => {
  const [permissions, setPermissions] = useState<Permissions | null>(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const cancelToken = useCancelToken();

  useEffect(() => {
    const callAPI = async () => {
      setLoading(true);
      logger.request("Fetching user permissions");

      try {
        const data = await api.getUserPermissions(cancelToken)();
        setError("");
        setPermissions(data);
        setLoading(false);
        logger.success("Fetched permissions", data);
      } catch (e: any) {
        if (cancelToken.reason) return;

        const error = e.message ? e.message : e;
        setPermissions(null);
        setError(error);
        setLoading(false);
        logger.error(error);
      }
    };

    if (user) {
      callAPI();
    }
  }, [cancelToken, user]);

  return { permissions, loading, error };
};
