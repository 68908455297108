import { useIsAuthorised } from "../../hooks/authentication";
import { CognitoUser, Permissions } from "../../types";

interface Props {
  children: JSX.Element | JSX.Element[];
  // permissions may end up coming from store rather than
  // passing in each time
  userPermissions: Permissions | null;
  entity: string;
  permission: string;
  override?: (user: CognitoUser) => boolean;
  user?: CognitoUser | null;
}
type Component = (props: Props) => JSX.Element;

export const ShowIfAuthorised: Component = ({
  children,
  userPermissions,
  entity,
  permission,
  override,
  user,
}) => {
  const isAuthorised = useIsAuthorised(
    userPermissions,
    entity,
    permission,
    user,
    override
  );
  return <>{isAuthorised ? children : null}</>;
};
