import { makeStyles } from "@material-ui/core/styles";
import { RouteProps } from "../../../types";
import { JobData } from "../../../types/documents";
import { AssetContainer } from "../../../types/documents/Assets";
import {
  Control,
  GradingForm,
  Section,
  SubSection,
} from "../../../types/gradingForm";
import { Loader } from "../../general";
import { GradingAssetDetails } from "./GradingAssetDetails";
import { GradingComponents } from "./GradingComponents";
import { GradingImages } from "./GradingImages";
import { GradingSectionList } from "./GradingSectionList";

interface Props extends RouteProps {
  assetContainers?: AssetContainer[];
  selectedAssetContainer?: AssetContainer;

  onSelectTower(tower: string): void;

  job?: GradingForm;
  selectedSectionId?: string;
  selectedSection?: Section | SubSection;
  selectedControls?: Control[];

  conditionAssessmentJobs?: JobData[];

  onSelectControlSection(id: string): void;

  onSelectPrev?: () => void;
  onSelectNext?: () => void;

  updateSelectedControls(index: number, value: string): void;

  readOnly?: boolean;
}

type Component = (props: Props) => JSX.Element;

export const GradingFormComponent: Component = ({
  assetContainers,
  selectedAssetContainer,
  onSelectTower,
  job,
  selectedSectionId,
  selectedSection,
  selectedControls,
  conditionAssessmentJobs,
  onSelectControlSection,
  onSelectPrev,
  onSelectNext,
  updateSelectedControls,
  readOnly,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Loader active={!assetContainers}>
      <div className={classes.gradingForm}>
        <div className={classes.sectionList}>
          <GradingSectionList
            assetContainers={assetContainers}
            selectedAssetContainer={selectedAssetContainer}
            onSelectTower={onSelectTower}
            job={job}
            selectedSectionId={selectedSectionId}
            onSelectControlSection={onSelectControlSection}
            readOnly={readOnly}
          />
        </div>
        <div className={classes.assetDetails}>
          <GradingAssetDetails
            selectedAssetContainer={selectedAssetContainer}
            selectedSection={selectedSection}
            conditionAssessmentJobs={conditionAssessmentJobs}
          />
        </div>
        <div className={classes.gradingControls}>
          {job && selectedControls && (
            <GradingComponents
              controls={selectedControls}
              onSelectPrev={onSelectPrev}
              onSelectNext={onSelectNext}
              updateSelectedControls={updateSelectedControls}
              readOnly={readOnly}
            />
          )}
        </div>
        <div className={classes.images}>
          {selectedAssetContainer && conditionAssessmentJobs && (
            <GradingImages
              selectedAssetContainer={selectedAssetContainer}
              conditionAssessmentJobs={conditionAssessmentJobs}
              {...props}
            />
          )}
        </div>
      </div>
    </Loader>
  );
};

const useStyles = makeStyles((theme) => ({
  gradingForm: {
    display: "grid",
    gridTemplateAreas: `
      'section-list tower-details tower-details'
      'section-list gradings      images'
    `,
    gridTemplateRows: "auto 1fr",
    gridTemplateColumns: "minmax(275px, 1fr) 2fr minmax(420px, 2fr)",
    height: "100%",
    background: theme.backgroundShades.white,
  },
  sectionList: {
    gridArea: "section-list",
    borderRight: "1px solid #D8D8D8",
  },
  assetDetails: {
    gridArea: "tower-details",
    borderBottom: "1px solid #D8D8D8",
    paddingTop: "25px",
    paddingBottom: "25px",
    paddingLeft: "20px",
    paddingRight: "20px",
    fontSize: "18px",
    lineHeight: "23px",
  },
  gradingControls: {
    gridArea: "gradings",
    borderRight: "1px solid #D8D8D8",
  },
  images: {
    gridArea: "images",
    textAlign: "center",
  },
}));
