import { assign, createMachine } from "xstate";
import { FlightState } from "../../enums/flightState";

export type FlightContext = {
  flightId: string | undefined;
  errorMessage: string | undefined;
};

type StateMap = {
  label: string;
  enum: FlightState;
};

export type StateMapper = {
  notStarted: StateMap;
  started: StateMap;
  transittedToSite: StateMap;
  assessmentStarted: StateMap;
  assessmentFinished: StateMap;
  transittedToBase: StateMap;
  finished: StateMap;
  aborted: StateMap;
  errored: StateMap;
};

export const stateMapper: StateMapper = {
  notStarted: { label: "Not Started", enum: FlightState.NotStarted },
  started: { label: "Starting Flight", enum: FlightState.Started },
  transittedToSite: {
    label: "Transitting to Site",
    enum: FlightState.TransittedToSite,
  },
  assessmentStarted: {
    label: "Starting Assessment",
    enum: FlightState.StartedAssessment,
  },
  assessmentFinished: {
    label: "Finishing Assessment",
    enum: FlightState.FinishedAssessment,
  },
  transittedToBase: {
    label: "Transitting to Base",
    enum: FlightState.TransittedToBase,
  },
  finished: { label: "Finished", enum: FlightState.Finished },
  aborted: { label: "Flight Aborted", enum: FlightState.Aborted },
  errored: { label: "Error In Flight", enum: FlightState.Errored },
};

export const flightMachine = createMachine(
  {
    tsTypes: {} as import("./flightMachine.typegen").Typegen0,
    schema: {
      services: {} as {
        starting: {
          data: string;
        };
        transittingToSite: {
          data: string;
        };
        startingAssessment: {
          data: string;
        };
        finishingAssessment: {
          data: string;
        };
        transittingToBase: {
          data: string;
        };
        finishing: {
          data: string;
        };
        aborting: {
          data: string;
        };
      },
    },
    context: {
      flightId: "",
      errorMessage: undefined,
    } as FlightContext,
    id: "flightMacine",
    initial: "notStarted",
    states: {
      notStarted: {
        on: {
          START: {
            target: "start",
          },
        },
      },
      start: {
        invoke: {
          src: "starting",
          onDone: [
            {
              target: "started",
              actions: "assignFlightIdToContext",
            },
          ],
          onError: [
            {
              target: "errored",
              actions: "assignErrorToContext",
            },
          ],
        },
      },
      started: {
        on: {
          TRANSIT_TO_SITE: { target: "transitToSite" },
          ABORT: { target: "abort" },
        },
      },
      transitToSite: {
        invoke: {
          src: "transittingToSite",
          onDone: [
            {
              target: "transittedToSite",
            },
          ],
          onError: {
            target: "errored",
            actions: "assignErrorToContext",
          },
        },
      },
      transittedToSite: {
        on: {
          START_ASSESSMENT: { target: "startAssessment" },
          ABORT: { target: "abort" },
        },
      },
      startAssessment: {
        invoke: {
          src: "startingAssessment",
          onDone: [
            {
              target: "assessmentStarted",
            },
          ],
          onError: {
            target: "errored",
            actions: "assignErrorToContext",
          },
        },
      },
      assessmentStarted: {
        on: {
          FINISH_ASSESSMENT: { target: "finishAssessment" },
          ABORT: { target: "abort" },
        },
      },
      finishAssessment: {
        invoke: {
          src: "finishingAssessment",
          onDone: [
            {
              target: "assessmentFinished",
            },
          ],
          onError: {
            target: "errored",
            actions: "assignErrorToContext",
          },
        },
      },
      assessmentFinished: {
        on: {
          TRANSIT_TO_BASE: { target: "transitToBase" },
          ABORT: { target: "abort" },
        },
      },
      transitToBase: {
        invoke: {
          src: "transittingToBase",
          onDone: [
            {
              target: "transittedToBase",
            },
          ],
          onError: {
            target: "errored",
            actions: "assignErrorToContext",
          },
        },
      },
      transittedToBase: {
        on: {
          FINISH: { target: "finish" },
          ABORT: { target: "abort" },
        },
      },
      finish: {
        invoke: {
          src: "finishing",
          onDone: [
            {
              target: "finished",
            },
          ],
          onError: {
            target: "errored",
            actions: "assignErrorToContext",
          },
        },
      },
      finished: {
        type: "final",
      },
      abort: {
        invoke: {
          src: "aborting",
          onDone: [
            {
              target: "aborted",
            },
          ],
          onError: {
            target: "errored",
            actions: "assignErrorToContext",
          },
        },
      },
      aborted: {
        type: "final",
      },
      errored: {
        type: "final",
      },
    },
  },
  {
    actions: {
      assignFlightIdToContext: assign((context, event) => {
        console.log("assign flight to context", event);
        return {
          flightId: event.data,
        };
      }),
      assignErrorToContext: assign((context, event) => {
        console.log("assign flight to context", event.data);

        return {
          errorMessage: (event.data as Error).message,
        };
      }),
    },
  }
);
