import BreadcrumbHeader from "../../components/general/BreadcrumbHeader";
import { settingsStrings as strings } from "../../resources/strings/offline/settings";
import ClearSaveButtons from "../../components/general/ClearSaveButtons";

export default function SettingsBreadcrumbHeader() {
  const crumbs = [
    {
      name: strings.header.settings,
    },
  ];

  return <BreadcrumbHeader crumbs={crumbs}>
    <ClearSaveButtons />
  </BreadcrumbHeader>;
}
