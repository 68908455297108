import type { Mode } from "../../components/general/types/Modify";
import { useCancelToken } from "../../hooks/general";
import { PROJECTS_ENDPOINT } from "../../libs/config";
import { paths } from "../../navigation";
import { RouteProps } from "../../types";
import type { ProjectData } from "../../types/documents";
import { ModifyContainer } from "../general";
import { PackageList } from "./packages/PackageList";
import { useProjectConfigurations } from "./useProjectConfigurations";

const putEndpoint = `${PROJECTS_ENDPOINT}/submit`;
const queryEndpoint = `${PROJECTS_ENDPOINT}`;

export interface Props extends RouteProps {
  mode: Mode;
}
type Component = (props: Props) => JSX.Element;

export const ProjectModify: Component = ({ mode, ...routeProps }) => {
  const cancelToken = useCancelToken();
  const [constraints, componentConfiguration, setClientId] =
    useProjectConfigurations(routeProps);

  const selectedPackageList = ({ id }: ProjectData) => (
    <PackageList {...routeProps} projectId={id} />
  );

  return (
    <ModifyContainer<ProjectData>
      {...routeProps}
      mode={mode}
      putEndpoint={putEndpoint}
      queryEndpoint={queryEndpoint}
      initialData={{
        id: "",
        jobCode: "",
        type: "",
        createdDate: "0000-00-00T00:00:00.000A",
        createdById: "",
        clientId: "",
        numberOfPackages: 0,
        description: "",
        groupedBy: "",
        selectedGroups: [],
      }}
      componentConfiguration={(data: ProjectData) => {
        setClientId(data.clientId);
        return [componentConfiguration.details(mode, "", cancelToken, "")];
      }}
      keysToRemove={[
        "createdById",
        "updatedById",
        "createdDate",
        "updatedDate",
        "numberOfPackages",
        { mode: "create", attribute: "id" },
      ]}
      constraints={constraints}
      redirectPath={paths.projects.list}
      childrenWithData={mode !== "create" ? selectedPackageList : undefined}
    />
  );
};
