import { useEffect, useState } from "react";
import { DropdownOptions } from "../../types";
import { AssetDetails } from "../../types/documents/Assets";

export const useCircuitOptions = (assets: AssetDetails[]) => {
  const [circuitIdOptions, setCircuitIdOptions] = useState<DropdownOptions>([]);

  useEffect(() => {
    const circuitIds = assets.map((asset) => asset.circuitId);
    const options: DropdownOptions = Array.from(new Set(circuitIds))
      .map((circuitId) => {
        return {
          label: circuitId,
          value: circuitId,
        };
      })
      .sort((a, b) => {
        if (a.label > b.label) return 1;
        if (a.label < b.label) return -1;
        return 0;
      });

    setCircuitIdOptions(() => [...options]);
  }, [assets]);

  return circuitIdOptions;
};
