import { useCallback, useReducer, useRef } from "react";

export const useRefreshToken = (): [number, () => void] => {
  const state = useRef({ token: 0 });
  const rerender = useReducer(() => ({}), {})[1];
  const refresh = useCallback(() => {
    state.current.token += 1;
    rerender();
  }, [state, rerender]);
  return [state.current.token, refresh];
}
