import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

import { HooksLogger } from "../../hooks/hooks-logger";
import { getToken, signOut } from "../auth";

const logger = new HooksLogger("axios/interceptors.ts");

const onRequest = async (
  initialConfig: AxiosRequestConfig
): Promise<AxiosRequestConfig> => {
  const config = Object.assign({}, initialConfig);

  if (config.headers && typeof config.headers?.Authorization !== "string") {
    try {
      config.headers.Authorization = await getToken();
    } catch (e) {
      logger.error(e);
      signOut();
    }
  }

  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

const interceptors = {
  onRequest,
  onRequestError,
  onResponse,
  onResponseError,
};

export default interceptors;
