import {
  AppBar,
  Box,
  CardMedia,
  Container,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Menu as MenuIcon } from "@material-ui/icons";
import clsx from "clsx";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { CognitoUser, Permissions } from "../../types";
import { ShowIfAuthorised } from "../authentication";
import { MenuItem } from "./listItems";

interface Props {
  children: JSX.Element;
  menuItems: MenuItem[];
  permissions: Permissions | null;
  logo: string;
  additionalContent?: JSX.Element;
  user: CognitoUser | null;
}
type Component = (props: Props) => JSX.Element;

interface MenuItemsProps {
  menuItems: MenuItem[];
  permissions: Permissions | null;
  user: CognitoUser | null;
}
type MenuItemsComponent = (props: MenuItemsProps) => JSX.Element;

const drawerWidth = 240;
const smallDrawerWidth = 0;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: "1.5rem", // keep right padding when drawer closed
  },
  toolbarHeader: {
    [theme.breakpoints.up("xs")]: {
      maxHeight: "64px",
      minHeight: "64px",
    },
    [theme.breakpoints.up("md")]: {
      maxHeight: "64px",
      minHeight: "64px",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 0.5rem",
    margin: "0",
    backgroundColor: theme.palette.primary.main,
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: "none",
  },
  appBarShift: {
    [theme.breakpoints.up("xs")]: {
      marginLeft: smallDrawerWidth,
      width: `calc(100% - ${smallDrawerWidth}px)`,
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
    },
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: "2.25rem",
  },
  menuButtonHidden: {
    display: "none",
  },
  flexSpacer: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    [theme.breakpoints.up("xs")]: {
      width: smallDrawerWidth,
    },
    [theme.breakpoints.up("md")]: {
      width: drawerWidth + 1,
    },
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up("xs")]: {
      width: drawerWidth,
    },
    [theme.breakpoints.up("md")]: {
      width: theme.spacing(7),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingBottom: theme.spacing(4),
    paddingLeft: 0,
    paddingRight: 0,
    height: "calc(100vh - 64px)",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: "15rem",
  },
  media: {
    height: "100%",
    width: "100%",
    padding: "1rem",
    objectFit: "contain",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  hideImage: {
    display: "none",
  },
  itemRoot: {
    "&$selected": {
      backgroundColor: "inherit",
    },
  },
  selected: {},
}));

const MenuItems: MenuItemsComponent = ({ menuItems, permissions, user }) => {
  const [selected, setSelected] = useState(-1);
  const classes = useStyles();
  const history = useHistory();

  return (
    <List>
      <div>
        {menuItems.map(({ label, Icon, itemPermission, link }, index) => {
          const isSelected = selected === index;
          const handleClick = () => {
            setSelected(index);
            history.push(link);
          };
          const Content = (
            <ListItem
              classes={{
                root: classes.itemRoot,
                selected: classes.selected,
              }}
              button
              selected={isSelected}
              onClick={handleClick}
            >
              <ListItemIcon>
                <Icon color={isSelected ? "primary" : undefined} />
              </ListItemIcon>
              <ListItemText primary={label} />
            </ListItem>
          );

          return (
            <React.Fragment key={`${label}-${index}`}>
              {itemPermission ? (
                <ShowIfAuthorised
                  user={user}
                  userPermissions={permissions}
                  {...itemPermission}
                >
                  {Content}
                </ShowIfAuthorised>
              ) : (
                <>{Content}</>
              )}
            </React.Fragment>
          );
        })}
      </div>
    </List>
  );
};

export const MenuComponent: Component = ({
  children,
  menuItems,
  permissions,
  logo,
  additionalContent,
  user,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const handleToggle = () => {
    setOpen((prev) => !prev);
  };

  return (
    <div className={classes.root} role="menu">
      <AppBar
        position="absolute"
        color="primary"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleToggle}
            className={clsx(classes.menuButton)}
            data-testid="toggle-button"
          >
            <MenuIcon />
          </IconButton>
          <Box color="inherit" className={classes.flexSpacer}></Box>
          {additionalContent ? additionalContent : null}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarHeader}>
          <CardMedia
            className={clsx(classes.media, !open && classes.hideImage)}
            image={logo}
            component="img"
          />
        </div>
        <Divider hidden />
        <MenuItems
          menuItems={menuItems}
          permissions={permissions}
          user={user}
        />
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container
          disableGutters={true}
          maxWidth={false}
          className={classes.container}
        >
          {children}
        </Container>
      </main>
    </div>
  );
};
