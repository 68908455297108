import { Button } from "@material-ui/core";
import { useState } from "react";
import { TAGS_ENDPOINT } from "../../../libs/config";
import { searchStrings as strings } from "../../../resources/strings";
import { RouteProps } from "../../../types";
import { AssetGeoJson } from "../../../types/documents/Assets";
import { SearchPanel } from "./SearchPanel";
import { useStyles } from "./styles";

export const tagsEndpoint = `${TAGS_ENDPOINT}`;

interface SearchContainerProps extends RouteProps {
  clientId: string | null;
  handleClearSearch: () => void;
  handleSearchResults: (searchResults: AssetGeoJson) => void;
}

type SearchContainerComponent = (props: SearchContainerProps) => JSX.Element;

export const SearchContainer: SearchContainerComponent = ({
  clientId,
  handleClearSearch,
  handleSearchResults,
  ...routeProps
}: SearchContainerProps) => {
  const classes = useStyles();

  const [showSearch, setShowSearch] = useState(false);

  const handleShowSearch = (showSearch: boolean) => {
    setShowSearch(showSearch);
    handleClearSearch();
  };

  return (
    <>
      {!showSearch && (
        <Button
          color="primary"
          variant="contained"
          className={classes.searchButtonPlacement}
          onClick={() => {
            handleShowSearch(true);
          }}
        >
          {strings.labels.search}
        </Button>
      )}
      <SearchPanel
        clientId={clientId}
        showSearch={showSearch}
        handleShowSearch={handleShowSearch}
        handleSearchResults={handleSearchResults}
        {...routeProps}
      ></SearchPanel>
    </>
  );
};
