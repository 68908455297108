import { CancelToken } from "axios";
import axios from "../libs/axios/axios-instance";
import { PERMISSIONS_ENDPOINT } from "../libs/config";
import { Permissions } from "../types";
import { Role, RoleType } from "../types/documents";

const baseUrl = PERMISSIONS_ENDPOINT + "/";
const axiosInstance = axios(baseUrl ?? "");

type Response = {
  userPermissions: Permissions;
};

export const getUserPermissions = (cancelToken: CancelToken) => async () => {
  try {
    const config = { cancelToken };
    const urlPath = ``;
    const { data } = await axiosInstance.get<Response>(urlPath, config);
    return Promise.resolve(data.userPermissions);
  } catch (e: any) {
    console.log(e);
    return Promise.reject(e.message ? e.message : e);
  }
};

export const getRoles = async (
  cancelToken: CancelToken,
  roleType: RoleType
) => {
  try {
    const config = { cancelToken };
    const urlPath = `roles/${roleType}`;
    const { data } = await axiosInstance.get<{ items: Array<Role> }>(
      urlPath,
      config
    );
    return data.items;
  } catch (e: any) {
    if (cancelToken.reason) return [];

    console.log(e);
    throw e.message ? e.message : e;
  }
};
