import { CancelToken } from "axios";
import querystring from "querystring";
import { ConditionSearch } from "../components/map/search/SearchPanel";
import type { Axios } from "../libs/axios";
import axios from "../libs/axios/axios-instance";
import { ASSETS_ENDPOINT } from "../libs/config";
import {
  AssetDetails,
  AssetGeoJson,
  AssetLoad,
  AssetsFile,
  AssetType,
  ColumnSelections,
} from "../types/documents/Assets";

const baseUrl = ASSETS_ENDPOINT + "/";
const axiosInstance = axios(baseUrl ?? "");

export const listClientFiles = async (
  jwt: string,
  cancelToken: Axios.CancelToken,
  clientId: string
) => {
  try {
    let body = { clientId };
    const config = { cancelToken };
    const urlPath = `list-client-files`;
    const { data } = await axiosInstance.post<AssetsFile[]>(
      urlPath,
      body,
      config
    );
    return data;
  } catch (e: any) {
    console.log(e);
    throw e.message ? e.message : e;
  }
};

export const deleteClientFile = async (
  jwt: string,
  cancelToken: Axios.CancelToken,
  clientId: string,
  objectName: string
) => {
  try {
    let body = { clientId, objectName };
    const config = { cancelToken };
    const urlPath = `delete-client-file`;
    await axiosInstance.post<any>(urlPath, body, config);
  } catch (e: any) {
    console.log(e);
    throw e.message ? e.message : e;
  }
};

export const listDataLoads = async (
  jwt: string,
  cancelToken: Axios.CancelToken
) => {
  try {
    const config = { cancelToken };
    const urlPath = `list-data-loads`;
    const { data } = await axiosInstance.get<AssetLoad[]>(urlPath, config);
    return data;
  } catch (e: any) {
    console.log(e);
    throw e.message ? e.message : e;
  }
};

export const listAssetTypes = async (
  clientId: string,
  jwt: string,
  cancelToken: Axios.CancelToken
) => {
  try {
    const config = { cancelToken };
    const body = { clientId };
    const urlPath = `types/list`;
    const { data } = await axiosInstance.post<{ items: AssetType[] }>(
      urlPath,
      body,
      config
    );
    return data.items;
  } catch (e: any) {
    console.log(e);
    throw e.message ? e.message : e;
  }
};

export const uploadClientFiles = async (
  jwt: string,
  cancelToken: Axios.CancelToken,
  clientId: string,
  fileNames: string[]
) => {
  try {
    let body = { clientId, fileNames };
    const config = { cancelToken };
    const urlPath = `upload-client-files`;
    const { data } = await axiosInstance.post<Record<string, string>>(
      urlPath,
      body,
      config
    );
    return data;
  } catch (e: any) {
    console.log(e);
    throw e.message ? e.message : e;
  }
};

export const processClientFile = async (
  jwt: string,
  cancelToken: Axios.CancelToken,
  clientId: string,
  file: AssetsFile
) => {
  try {
    let body = { clientId, file };
    const config = { cancelToken };
    const urlPath = `process-client-files`;
    await axiosInstance.post<any>(urlPath, body, config);
  } catch (e: any) {
    console.log(e);
    throw e.message ? e.message : e;
  }
};

export const getAssetGeoJson = async (
  jwt: string,
  cancelToken: Axios.CancelToken,
  clientId: string,
  offsetId: string | null
) => {
  let body = { clientId, offsetId };
  return _getAssetGeoJson(jwt, cancelToken, body, "get-asset-geojson");
};

export const getPackageAssetGeoJson = async (
  jwt: string,
  cancelToken: Axios.CancelToken,
  packageId: string,
  offsetId: string | null
) => {
  let body = { packageId, offsetId };
  return _getAssetGeoJson(jwt, cancelToken, body, "package/get-asset-geojson");
};
const _getAssetGeoJson = async (
  jwt: string,
  cancelToken: Axios.CancelToken,
  body: object,
  urlPath: string
) => {
  try {
    const config = { cancelToken };
    const { data } = await axiosInstance.post<AssetGeoJson>(
      urlPath,
      body,
      config
    );
    return data;
  } catch (e: any) {
    console.log(e);
    throw e.message ? e.message : e;
  }
};

export const setColumnSelection = async (
  jwt: string,
  cancelToken: Axios.CancelToken,
  assetLoadId: string,
  columnSelections: ColumnSelections
) => {
  try {
    let body = { assetLoadId, columnSelections };
    const config = { cancelToken };
    const urlPath = `set-column-selection`;
    await axiosInstance.post(urlPath, body, config);
  } catch (e: any) {
    console.log(e);
    throw e.message ? e.message : e;
  }
};

export const getAssetDetails = async (
  jwt: string,
  cancelToken: CancelToken,
  id: string
) => {
  try {
    const config = { cancelToken };

    const { data } = await axiosInstance.get<AssetDetails>(id, config);
    return data;
  } catch (e: any) {
    if (cancelToken.reason) return;

    console.log(e);
    throw e.message ? e.message : e;
  }
};

export const getAssetsByGroup = async (
  cancelToken: CancelToken,
  clientId: string,
  key: string,
  values: string[]
) => {
  try {
    const config: Axios.AxiosRequestConfig<any> = {
      cancelToken,
      params: {
        values: values,
      },
      paramsSerializer: (params) => {
        return querystring.stringify(params);
      },
    };

    const ulrPath = encodeURI(`getGroupedAssetVaues/${clientId}/${key}`);

    const { data } = await axiosInstance.get<AssetDetails[]>(ulrPath, config);
    return data;
  } catch (e: any) {
    if (cancelToken.reason) return;

    console.log(e);
    throw e.message ? e.message : e;
  }
};

export const searchAssets = async (
  cancelToken: CancelToken,
  clientId: string,
  projectType: string,
  searchData: ConditionSearch
) => {
  try {
    let body = { ...searchData };

    const config: Axios.AxiosRequestConfig<any> = {
      cancelToken,
    };

    const ulrPath = `search/${clientId}/${projectType}`;

    const { data } = await axiosInstance.post<AssetGeoJson>(
      ulrPath,
      body,
      config
    );
    return data;
  } catch (e: any) {
    if (cancelToken.reason) return;

    console.log(e);
    throw e.message ? e.message : e;
  }
};
