import {
  Backdrop,
  Button,
  Fade,
  Modal as MuiModal,
  TextField,
} from "@material-ui/core";
import clsx from "clsx";
import { useState } from "react";
import { defectStrings as strings } from "../../resources/strings";
import { Loader } from "../general";
import { useClearDefectModalStyles } from "./styles/clearDefectModal";

interface Iprops {
  confirmButtonLabel: string;
  defectId: string;
  clearDefect: (defectId: any, workOrder: any) => any;
  open: boolean;
  handleClose: () => void;
  loading?: boolean;
}

export const ClearDefectModal = ({
  confirmButtonLabel,
  defectId,
  clearDefect,
  open,
  handleClose,
  loading = false,
}: Iprops) => {
  const classes = useClearDefectModalStyles();
  const [workOrder, setWorkOrder] = useState("");

  const closeModal = () => {
    setWorkOrder("");
    handleClose();
  };

  const clearDefectConfirmed = () => {
    console.log("Clear defect", defectId);
    console.log("Work order", workOrder);
    //Call the clear defect service
    clearDefect(defectId, workOrder);
    setWorkOrder("");
    handleClose();
  };

  return (
    <MuiModal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={closeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <Loader inline active={loading}>
            <h2 id="transition-modal-title">
              {strings.global.labels.headerClearDefect}
            </h2>
            <TextField
              id="outlined-basic"
              label={strings.labels.workOrder}
              value={workOrder}
              onChange={(event) => setWorkOrder(event.target.value)}
            />
            <p></p>
            <div className={classes.buttonContainer}>
              <Button
                className={clsx(classes.all, classes.border, classes.danger)}
                color="primary"
                variant="outlined"
                onClick={clearDefectConfirmed}
              >
                {confirmButtonLabel}
              </Button>
              <Button
                variant="outlined"
                color="primary"
                className={clsx(classes.all, classes.border)}
                onClick={closeModal}
              >
                {strings.global.labels.cancelConfirm}
              </Button>
            </div>
          </Loader>
        </div>
      </Fade>
    </MuiModal>
  );
};
