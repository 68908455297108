import { makeStyles } from "@material-ui/core/styles";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import clsx from "clsx";
import TowerLeft from "../../../assets/svgs/tower-left.svg";
import TowerRight from "../../../assets/svgs/tower-right.svg";
import { ExtractedControlData, JobData } from "../../../types/documents";

interface Props {
  onSelectZone: (job: JobData, zoneId: string) => void;
  side: "left" | "right";
  job?: JobData;
  selectedSection: string;
  selectedZone: string;
}

type Component = (props: Props) => JSX.Element;

export const TowerPanel: Component = ({
  selectedSection,
  selectedZone,
  onSelectZone,
  side,
  job,
}) => {
  const classes = useStyles();

  const header = job ? `${side} - ${job.asset.circuitId}` : side;

  const section = job?.sections.find((s) => s.caption === selectedSection);
  const zones = section
    ? section.extractedControls.reduce(
        (prev, zone) => ({ ...prev, [zone.caption.toLowerCase()]: zone }),
        {} as Record<string, ExtractedControlData>
      )
    : {};

  const renderZoneButton = (
    zone: ExtractedControlData | undefined,
    marginTop?: string,
    size?: "large"
  ) => (
    <ToggleButton
      value={zone?.id ?? ""}
      className={clsx(classes.zoneButton, !zone && classes.hide)}
      size={size}
      style={{ marginTop }}
    >
      {zone?.caption ?? ""}
    </ToggleButton>
  );

  return (
    <div className={classes.container}>
      <span className={classes.header}>{header}</span>
      <div
        className={clsx(
          classes.img,
          side === "left" ? classes.imgLeft : classes.imgRight
        )}
      >
        <ToggleButtonGroup
          value={selectedZone}
          onChange={(_event, value) => value && onSelectZone(job!, value)}
          orientation="vertical"
          exclusive
          className={classes.buttonGroup}
        >
          {renderZoneButton(zones.earthwire)}
          {renderZoneButton(zones.top, "24px", "large")}
          {renderZoneButton(zones.middle, "42px", "large")}
          {renderZoneButton(zones.bottom, "42px", "large")}
          {renderZoneButton(zones.steelwork, "24px", "large")}
        </ToggleButtonGroup>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    height: "485px",
    padding: theme.spacing(0, 2.5),
    border: "lightgrey solid",
    borderWidth: "1px 0",
  },

  header: {
    display: "block",
    padding: theme.spacing(1),
    textTransform: "uppercase",
    fontWeight: 700,
    fontSize: "1.2rem",
  },

  img: {
    height: "100%",
    backgroundRepeat: "no-repeat",
  },
  imgLeft: {
    backgroundImage: `url(${TowerLeft})`,
    backgroundPosition: "right",
  },
  imgRight: {
    backgroundImage: `url(${TowerRight})`,
    backgroundPosition: "left",
  },

  buttonGroup: {
    width: "100%",
    alignItems: "center",
  },

  zoneButton: {
    width: "70%",
    height: "40px",

    fontSize: "1.3rem",
    textTransform: "capitalize",
    fontWeight: 700,

    backgroundColor: "#FAFEF8C0",
    borderRadius: 0,
    border: "2px #B8DAA2 dashed !important",

    "&.MuiToggleButton-sizeLarge": {
      height: "60px",
    },

    "&.Mui-selected": {
      backgroundColor: "#DEEED4C0",
      border: "2px #70B443C0 solid !important",

      "&:hover": {
        backgroundColor: "#DEEED4C0",
      },
    },

    "&:hover": {
      backgroundColor: "#DEEED4C0",
    },
  },
  hide: {
    visibility: "hidden",
  },
}));
