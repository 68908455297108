import { useCallback, useState } from "react";
import { generateJob, generateSections } from "../../containers/jobs/createJob";
import type { Axios } from "../../libs/axios";
import * as defectsApi from "../../services/defects.service";
import * as defectTypesApi from "../../services/defectTypes.service";

type Props = {
  flightId: string | null;
  assetIds: string[];
  jwt: string;
  cancelToken: Axios.CancelToken;
};

type CreateDefect = (defectTypeId: string) => Promise<void>;

type Result = [
  string,
  React.Dispatch<React.SetStateAction<string>>,
  CreateDefect
];

export const useCreateDefect = ({
  flightId,
  jwt,
  cancelToken,
  assetIds,
}: Props): Result => {
  const [selectedDefectId, setSelectedDefectId] = useState("");

  const createDefect = useCallback(
    async (defectTypeId: string) => {
      if (!flightId) return;

      console.log("CREATING NEW DEFECT");
      const form: any = await defectTypesApi.getDefectType(
        defectTypeId,
        cancelToken
      );
      console.log("FORM", form);

      let userId: string | null = null,
        tenantId: string | null = null;
      if (jwt && jwt.split(".").length > 1) {
        const token = JSON.parse(
          Buffer.from(jwt.split(".")[1], "base64").toString()
        );
        const { sub, "custom:tenantId": tokenTenantId } = token;
        userId = sub;
        tenantId = tokenTenantId;
      }

      if (assetIds.length === 0) return;

      //Only create the first job, the rest will be copied once the first asset has been saved
      const assetId = assetIds[0];

      // Generate a new defect from the defect type
      const job = await generateJob(form, assetId, userId, tenantId, flightId);
      const sections = generateSections(job.id, userId, tenantId, form);

      setSelectedDefectId(job.id); //Used by the job renderer

      //SaveNewDefect - the job renderer won't work unless the job data is available from the API
      await defectsApi.createJob(job, jwt, cancelToken); //Call API
      if (sections != null && Array.isArray(sections)) {
        const promises: any[] = [];
        for (const section of sections) {
          promises.push(defectsApi.createSection(section, jwt, cancelToken));
        }
        await Promise.all(promises);
      }
    },
    [assetIds, cancelToken, flightId, jwt]
  );

  return [selectedDefectId, setSelectedDefectId, createDefect];
};
