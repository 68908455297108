export const appStrings = {
  entities: {
    assets: "assets",
    flights: "flights",
    users: "users",
    clients: "clients",
    projects: "projects",
    packages: "packages",
    gradings: "gradings",
    settings: "settings",
    tags: "tags",
    bookings: "bookings",
    schedules: "schedules",
    transportBookings: "transportBookings",
    avoidanceZones: "avoidanceZones",
    mapFeatures: "mapFeatures",
    defectTypes: "defectTypes",
    images: "images",
    lines: "lines",
  },
  permissions: {
    read: "read",
    create: "create",
    update: "update",
    access: "access",
    delete: "delete",
  },
  menuLabels: {
    assetsUpload: "Upload Assets",
    users: "Users",
    clients: "Clients",
    projects: "Projects",
    infoLog: "Flight Info Log",
    settings: "Settings",
    help: "Help",
    tags: "Tags",
    bookings: "Bookings",
    schedules: "Delivery Schedules",
    transportBookings: "Transport",
    formDesigner: "Button Configurations",
    map: "Map View",
    sync: "Sync",
    imageUploader: "Upload Images",
  },
  labels: {
    signOut: "Sign Out",
    signIn: "Login",
    cancelConfirm: "Never Mind",
    headerConfirm: "Are you sure?",
    headerClearDefect: "Clear defect",
    defaultTagSelect: "- select -",
    loadingTags: "Loading...",
  },
  notifications: {
    type: { success: "Success", error: "Error", info: "Info" },
    messages: {
      invalidFields: "Form contains missing or invalid content",
      submitError: "An error occurred while submitting the data.",
      retrieveError: "An error occurred while retrieving the data.",
      submitted: "Data submitted successfully.",
      deleted: "Data deleted successfully.",
      deleteError: "An error occurred while deleting the data.",
      gpxError: "Error loading GPX trace",
    },
  },
};
