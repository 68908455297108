import { Grid, makeStyles, Typography } from "@material-ui/core";
import { ChangeEvent } from "react";
import { UploadButton } from "./UploadButton";

interface Props {
  totalImages?: number;
  lastUpdated?: string;
  handleUploads?: (event: ChangeEvent<HTMLInputElement>) => Promise<void>;
}

type Component = (props: Props) => JSX.Element;
export const ImagesInfoHeader: Component = ({
  totalImages = 0,
  lastUpdated,
  handleUploads,
}) => {
  const classes = useStyles();

  return (
    <Grid container alignItems="center" className={classes.headerBar}>
      <Grid item>
        <Typography className={classes.headerText}>
          <b>Total Images:</b> {totalImages}
        </Typography>
      </Grid>

      <Grid item>
        {lastUpdated && (
          <Typography className={classes.headerText}>
            <b>Last Taken:</b> {new Date(lastUpdated).toLocaleString()}
          </Typography>
        )}
      </Grid>

      <Grid item style={{ flexGrow: 1 }} />

      <Grid item>
        {handleUploads && <UploadButton handleUploads={handleUploads} />}
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  headerBar: {
    borderBottom: `${theme.spacing(0.1)}px solid ${theme.palette.divider}`,
    background: theme.backgroundShades.white,
  },
  headerText: {
    padding: theme.spacing(2),
    fontSize: "1.2rem",
  },
}));
