import { Tooltip, Typography } from "@material-ui/core";
import { Cell } from "react-table";
import { formatDateTime } from "../../../helpers";

type Auditable = { createdById: string; updatedById: string };

interface Props {
  timestamp: string;
  user?: string;
}
const DateTableCell = ({ user, timestamp }: Props) => (
  <Tooltip title={<Typography>By {user || "<unknown>"}</Typography>}>
    <span>{formatDateTime(timestamp)}</span>
  </Tooltip>
);

export const createdDateTableCellRenderer = <T extends Auditable>({
  value,
  row,
}: Cell<T>) => (
  <DateTableCell timestamp={value} user={row.original.createdById} />
);

export const updatedDateTableCellRenderer = <T extends Auditable>({
  value,
  row,
}: Cell<T>) => (
  <DateTableCell timestamp={value} user={row.original.updatedById} />
);
