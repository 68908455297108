export const gradingsStrings = {
  assetDetails: {
    asset: "Asset",
    area: "Area",
    started: "Started",
    finished: "Finished",
  },
  componentsHeaders: {
    component: "Component",
    grading: "Grading",
  },
  filterPanel: {
    panelHeading: "Filter Report",
    assetRange: "Asset Range",
    from: "From:",
    to: "To:",
    component: "Component Type",
    grading: "Grading",
    applyButton: "Apply",
    resetButton: "Reset",
  },
  report: {
    filterButton: "Filter",
    exportButton: "Export",
    exportFormats: ["csv", "xlsx"],
    name: "Report Name:",
    date: "Date of Report:",
  },
};
