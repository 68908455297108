import { useHistory } from "react-router-dom";
import {
  createdDateTableCellRenderer,
  updatedDateTableCellRenderer,
} from "../../components/general/table/DateTableCell";
import { isOfflineApp } from "../../helpers";
import { CLIENTS_ENDPOINT } from "../../libs/config";
import { paths } from "../../navigation";
import { clientStrings as strings } from "../../resources/strings";
import { RouteProps } from "../../types";
import type { ClientData } from "../../types/documents";
import { TableContainer } from "../general";

interface Props extends RouteProps {}
type Component = (props: Props) => JSX.Element;
const endpoint = `${CLIENTS_ENDPOINT}/list-clients`;

export const ClientList: Component = ({ jwt, ...routeProps }) => {
  const history = useHistory();
  const clickHandler = ({ id }: ClientData) => {
    history.push(paths.clients.view(id));
  };

  return (
    <div>
      <TableContainer<ClientData>
        {...routeProps}
        endpoint={endpoint}
        jwt={jwt}
        header={strings.header.clients}
        tableColumns={[
          {
            id: "name",
            Header: strings.labels.name,
            accessor: "name",
          },
          {
            id: "createdDate",
            Header: strings.labels.created,
            accessor: "createdDate",
            Cell: createdDateTableCellRenderer,
          },
          {
            id: "updatedDate",
            Header: strings.labels.updated,
            accessor: "updatedDate",
            Cell: updatedDateTableCellRenderer,
          },
        ]}
        createButtonConfig={{
          label: strings.labels.createClient,
          userAccess: {
            entity: strings.global.entities.clients,
            permission: strings.global.permissions.create,
          },
          path: paths.clients.create,
        }}
        clickHandler={clickHandler}
        manualPagination={!isOfflineApp()}
      />
    </div>
  );
};
