import { makeStyles } from "@material-ui/core/styles";
import { formatDateTime, sortByKey as byKey } from "../../../helpers";
import { gradingsStrings as strings } from "../../../resources/strings/gradings";
import { JobData } from "../../../types/documents";
import { AssetContainer } from "../../../types/documents/Assets";
import { Section, SubSection } from "../../../types/gradingForm";

interface Props {
  selectedAssetContainer?: AssetContainer;
  selectedSection?: Section | SubSection;
  conditionAssessmentJobs?: JobData[];
}

type Component = (props: Props) => JSX.Element;

export const GradingAssetDetails: Component = ({
  selectedAssetContainer,
  selectedSection,
  conditionAssessmentJobs,
}) => {
  const classes = useStyles();

  const sections = conditionAssessmentJobs?.flatMap((job) => job.sections);

  const started = sections?.length
    ? formatDateTime(sections.sort(byKey("createdDate", "asc"))[0].createdDate)
    : "Unknown";
  const finished = sections?.length
    ? formatDateTime(sections.sort(byKey("updatedDate", "desc"))[0].updatedDate)
    : "Unknown";

  const renderLabelValue = (label: string, value: string) => (
    <span>
      <span className={classes.detailsLabel}>{label}:</span>
      {value}
    </span>
  );

  return (
    <div className={classes.detailsList}>
      {selectedAssetContainer && (
        <>
          {renderLabelValue(
            strings.assetDetails.asset,
            selectedAssetContainer.name
          )}
          {selectedSection &&
            renderLabelValue(
              strings.assetDetails.area,
              selectedSection.caption
            )}
          <span className={classes.spacer}></span>
          {renderLabelValue(strings.assetDetails.started, started)}
          {renderLabelValue(strings.assetDetails.finished, finished)}
        </>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  detailsList: {
    display: "flex",
    flexDirection: "row",
    gap: "30px",
  },
  detailsLabel: {
    fontWeight: 600,
    marginRight: "10px",
  },
  spacer: {
    flexGrow: 1,
  },
}));
