import React, { useCallback, useEffect, useState } from "react";
import {
  columnSelectionComponentConfigurations,
  columnSelectionConstraints,
  submitButtons,
  typeMapComponentConfigurations,
  typeMapConstraints,
} from "../../containers/assets/resources";
import { ModifyContainer } from "../../containers/general";
import { useCancelToken } from "../../hooks/general";
import { ASSETS_ENDPOINT } from "../../libs/config";
import { paths } from "../../navigation";
import * as service from "../../services/assets.service";
import { DropdownOption, RouteProps } from "../../types";
import {
  AssetLoad,
  AssetLoadStatus,
  AssetTypeMap,
  ColumnSelections,
} from "../../types/documents/Assets";

const putColumnSelectionEndpoint = `${ASSETS_ENDPOINT}/data-load/submit-column-selection`;
const putTypeMapEndpoint = `${ASSETS_ENDPOINT}/data-load/submit-type-map`;
const queryEndpoint = `${ASSETS_ENDPOINT}/data-load`;

interface DetailsProps extends RouteProps {
  load: AssetLoad;
}

type DetailsComponent = (props: DetailsProps) => JSX.Element;
export const LoadDetails: DetailsComponent = ({ load, ...routeProps }) => {
  const { jwt } = routeProps;
  const cancelToken = useCancelToken();

  const initialSelections: ColumnSelections = {
    id: load.id,
    name: "",
    displayName: "",
    x: "",
    y: "",
    coordinateSystem: "EPSG:27700",
    transmissionVoltage: "",
    containerName: "",
    containerKeys: [],
    assetType: "",
    circuitId: "",
    towerSide: "",
  };

  const initialTypeMap: AssetTypeMap = {
    id: load.id,
    ...load.typeMap,
  };

  const [assetTypeOptions, setAssetTypeOptions] = useState<DropdownOption[]>(
    []
  );

  const listAssetTypes = useCallback(
    () => service.listAssetTypes(load.clientId, jwt, cancelToken),
    [load.clientId, jwt, cancelToken]
  );

  useEffect(() => {
    const query = async () => {
      const assetTypes = await listAssetTypes();
      if (!assetTypes) return;
      const assetOptions = assetTypes.map(({ id, name }) => ({
        value: id,
        label: name,
      }));
      setAssetTypeOptions(assetOptions);
    };
    query();
  }, [listAssetTypes]);

  switch (load.status) {
    case AssetLoadStatus.SplittingInput:
      return <>Checking input file</>;
    case AssetLoadStatus.AwaitingColumnSelection:
      const columnOptions =
        load.availableColumns
          ?.sort((a, b) => a.localeCompare(b))
          .map((column) => {
            return {
              label: column,
              value: column,
            };
          }) ?? [];

      return (
        <ModifyContainer<ColumnSelections>
          {...routeProps}
          putEndpoint={putColumnSelectionEndpoint}
          queryEndpoint={queryEndpoint}
          mode="update"
          initialData={initialSelections}
          componentConfiguration={[
            columnSelectionComponentConfigurations.general(columnOptions),
            columnSelectionComponentConfigurations.coordinates(columnOptions),
            submitButtons,
          ]}
          constraints={columnSelectionConstraints}
          redirectPath={paths.clients.view}
          loading={false}
        />
      );
    case AssetLoadStatus.AwaitingTypeMapSelection:
      const typeMap = load.typeMap ?? {};

      return (
        <ModifyContainer<AssetTypeMap>
          {...routeProps}
          putEndpoint={putTypeMapEndpoint}
          queryEndpoint={queryEndpoint}
          mode="update"
          initialData={initialTypeMap}
          componentConfiguration={[
            typeMapComponentConfigurations.general(typeMap, assetTypeOptions),
            submitButtons,
          ]}
          constraints={typeMapConstraints}
          redirectPath={paths.clients.view}
          loading={false}
        />
      );
    case AssetLoadStatus.Processing:
      return <>Processing</>;
    case AssetLoadStatus.Succeeded:
      return <>Success</>;
    case AssetLoadStatus.Failed:
    default:
      if (!load.error) {
        return <>Unknown error</>;
      }

      let formattedError;
      try {
        const parsedError = JSON.parse(load.error);
        if (parsedError.message) {
          formattedError = parsedError.message;
        } else {
          formattedError = JSON.stringify(parsedError, null, 2);
        }
      } catch (e) {
        formattedError = load.error;
      }

      return <>Error: {formattedError}</>;
  }
};

export const MemoizedLoadDetails = React.memo(
  LoadDetails,
  (a, b) => a.load.id === b.load.id && a.load.status === b.load.status
);
