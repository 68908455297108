import { SectionTemplate } from "@pulsion/forms-designer-2";
import log from "loglevel";
import config from "./config";

export interface ISectionTemplateApi {
  create(sectionTemplate: SectionTemplate): Promise<any>;
  remove(id: string, removeJobs: boolean): Promise<any>;
  update(id: string, sectionTemplate: SectionTemplate): Promise<any>;
  get(id: string): Promise<any>;
  reorder(id: string, sections: Array<string>): Promise<any>;
}

const create = async (sectionTemplate: SectionTemplate): Promise<any> => {
  return await config
    .axiosInstance({
      url: config.apiEndPoints.sectionTemplate.create,
      method: "post",
      data: sectionTemplate,
    })
    .then((res) => {
      log.debug(res);
      return res.data;
    })
    .catch((err) => {
      throwError(err);
    });
};

const get = async (id: string): Promise<any> => {
  return await config
    .axiosInstance({
      url: config.apiEndPoints.sectionTemplate.get(id),
      method: "get",
    })
    .then((res) => {
      log.debug(res);
      const data = res.data;
      if (!data || data.length) {
        throw new Error("Form Template not found");
      }

      return data;
    })
    .catch((err) => {
      throwError(err);
    });
};

const remove = async (id: string): Promise<any> => {
  return await config
    .axiosInstance({
      url: config.apiEndPoints.sectionTemplate.delete(id),
      method: "delete",
    })
    .then((res) => {
      log.debug(res);
    })
    .catch((err) => {
      throwError(err);
    });
};

const update = async (
  id: string,
  sectionTemplate: SectionTemplate
): Promise<any> => {
  return await config
    .axiosInstance({
      url: config.apiEndPoints.sectionTemplate.update(id),
      method: "put",
      data: sectionTemplate,
    })
    .then((res) => {
      log.debug(res);
      return res.data;
    })
    .catch((err) => {
      throwError(err);
    });
};

const reorder = async (id: string, sections: Array<string>): Promise<any> => {
  const url = config.apiEndPoints.sectionTemplate.reorder(id);
  return await config
    .axiosInstance({
      url: url,
      method: "put",
      data: sections,
    })
    .then((res) => {
      log.debug(res);
      return res.data;
    })
    .catch((err) => {
      throwError(err);
    });
};

const throwError = (error: any) => {
  log.error(error);
  throw error;
};

export { get, update, remove, create, reorder };
