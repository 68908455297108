import type { CircularProgressProps } from "@material-ui/core";
import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";

interface Props extends CircularProgressProps {
  active?: boolean;
  children?: JSX.Element | JSX.Element[];
  inline?: boolean;
  text?: string;
}
type Component = (props: Props) => JSX.Element | null;

const useStyles = makeStyles((theme) => ({
  base: {
    display: "flex",
    backgroundColor: "inherit",
    color: theme.palette.primary.main,
  },
  backdrop: {
    flexDirection: "column",
    alignItems: "center",
  },
  inline: {
    alignContent: "center",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  content: {
    margin: "0.5rem",
  },
}));

export const Loader: Component = ({
  active = false,
  children = null,
  inline = false,
  text = "loading...",
  value,
}) => {
  const classes = useStyles();
  const LoaderContent = (props: CircularProgressProps) => (
    <>
      {value !== undefined ? (
        <>
          <Box position="relative" display="inline-flex">
            <CircularProgress variant="determinate" value={value} />
            <Box
              top={0}
              left={0}
              bottom={0}
              right={0}
              position="absolute"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="caption" component="div">{`${Math.round(
                value ?? 0
              )}%`}</Typography>
            </Box>
          </Box>
          <Typography className={clsx(classes.content)}>{text}</Typography>
        </>
      ) : (
        <>
          <CircularProgress
            className={clsx(classes.content)}
            color="inherit"
            {...props}
          />
          <Typography className={clsx(classes.content)}>{text}</Typography>
        </>
      )}
    </>
  );

  const renderLoader = () => {
    return inline ? (
      <Grid container className={clsx(classes.base, classes.inline)}>
        <LoaderContent size="1.25rem" />
      </Grid>
    ) : (
      <Backdrop className={clsx(classes.base, classes.backdrop)} open>
        <LoaderContent />
      </Backdrop>
    );
  };

  return active ? (
    <Box role="progressbar">{renderLoader()}</Box>
  ) : (
    <>{children}</>
  );
};
