import type { Axios } from "../libs/axios";
import axios from "../libs/axios/axios-instance";
import { DefectData, JobData } from "../types/documents";

const baseUrl = process.env.REACT_APP_JOBS_ENDPOINT;
const axiosInstance = axios(baseUrl ?? "");

export type AssetIdDefectsResult = {
  results: DefectData[];
};

export const getDefectsByAssetId = async (
  assetId: string,
  jwt: string,
  cancelToken: Axios.CancelToken
): Promise<AssetIdDefectsResult> => {
  try {
    const config = {
      cancelToken,
      params: new URLSearchParams([["active", "true"]]),
    };
    const urlPath = `/defects/asset/${assetId}`;
    const { data } = await axiosInstance.get<AssetIdDefectsResult>(
      urlPath,
      config
    );
    return data;
  } catch (e: any) {
    console.log(e);
    throw e.message ? e.message : e;
  }
};

export const getDefectsByAssetIds = async (
  assetIds: string[],
  jwt: string,
  cancelToken: Axios.CancelToken
): Promise<AssetIdDefectsResult> => {
  try {
    const config = { cancelToken };
    const urlPath = `/defects/assets`;
    const { data } = await axiosInstance.post<AssetIdDefectsResult>(
      urlPath,
      { ids: assetIds, active: true },
      config
    );
    return data;
  } catch (e: any) {
    console.log(e);
    throw e.message ? e.message : e;
  }
};

export const getExtractedJobControls = async (
  packageId: string,
  assetContainerId: string,
  cancelToken: Axios.CancelToken
): Promise<JobData[]> => {
  try {
    const config = { cancelToken };
    const urlPath = `/jobs/extracted-controls/${packageId}/${assetContainerId}`;

    const { data } = await axiosInstance.get<JobData[]>(urlPath, config);

    return data;
  } catch (e: any) {
    console.log(e);
    throw e.message ? e.message : e;
  }
};

export const clearDefect = async (
  defectId: string,
  workOrder: string,
  jwt: string,
  cancelToken: Axios.CancelToken
): Promise<DefectData[]> => {
  try {
    const config = { cancelToken };
    const body = {
      clearedWorkOrder: workOrder,
      active: false,
    };
    const urlPath = `/jobs/${defectId}`;
    const { data } = await axiosInstance.put<DefectData[]>(
      urlPath,
      body,
      config
    );
    return data;
  } catch (e: any) {
    console.log(e);
    throw e.message ? e.message : e;
  }
};

export const deleteDefect = async (
  defectId: string,
  jwt: string,
  cancelToken: Axios.CancelToken
): Promise<DefectData[]> => {
  try {
    const config = { cancelToken };
    console.log("defectID is ....", defectId);
    //Delete the defect if we're in the offline app
    const urlPath = `/jobs/${defectId}`;
    const { data } = await axiosInstance.delete<DefectData[]>(urlPath, config);
    return data;
  } catch (e: any) {
    console.log(e);
    throw e.message ? e.message : e;
  }
};

export const deleteDefects = async (
  defectIds: string[],
  jwt: string,
  cancelToken: Axios.CancelToken
): Promise<DefectData[]> => {
  try {
    const config = { cancelToken };
    console.log("defectIDs ....", defectIds);
    //Delete the defect if we're in the offline app
    const urlPath = `/jobs/deleteJobs`;
    const { data } = await axiosInstance.post<DefectData[]>(
      urlPath,
      { defectIds },
      config
    );
    return data;
  } catch (e: any) {
    console.log(e);
    throw e.message ? e.message : e;
  }
};

export const createJob = async (
  jobData: any,
  jwt: string,
  cancelToken: Axios.CancelToken
): Promise<unknown> => {
  try {
    const config = { cancelToken };
    const body = jobData;
    const urlPath = `/jobs`;
    const { data } = await axiosInstance.post<DefectData[]>(
      urlPath,
      body,
      config
    );
    return data;
  } catch (e: any) {
    console.log(e);
    throw e.message ? e.message : e;
  }
};

export const createSection = async (
  sectionData: any,
  jwt: string,
  cancelToken: Axios.CancelToken
): Promise<unknown> => {
  try {
    const config = { cancelToken };
    const body = sectionData;
    const urlPath = `/jobs/sections/savesection`;
    const { data } = await axiosInstance.post<any>(urlPath, body, config);
    return data;
  } catch (e: any) {
    console.log(e);
    throw e.message ? e.message : e;
  }
};
