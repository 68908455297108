import type { Axios } from "../../libs/axios";
import axios from "../../libs/axios/axios-instance";
import { GPS_ENDPOINT } from "../../libs/config";
import { GpsDevice } from "../../types/documents/offline/Gps";

const baseUrl = GPS_ENDPOINT + "/";
const axiosInstance = axios(baseUrl ?? "");

export const listDevices = async (cancelToken: Axios.CancelToken) => {
  try {
    const config = {
      cancelToken,
      headers: {
        Authorization: "",
      },
    };
    const urlPath = `devices`;
    const { data } = await axiosInstance.get<GpsDevice[]>(urlPath, config);
    return data;
  } catch (e: any) {
    console.log(e);
    throw e.message ?? e;
  }
};

export const gpsWebSocketUrl =
  baseUrl.replace(/^http?:\/\//, "ws://") + "gps-ws";
