import LayerRenderer from "ol/renderer/Layer";
import { WebGLLinesLayer } from "./WebGLLinesLayer";
import { FrameState } from "ol/PluggableMap";
import VectorSource from "ol/source/Vector";
import { WebGLLinesProgram } from "./WebGLLinesProgram";

export class WebGLLinesRenderer extends LayerRenderer<WebGLLinesLayer> {
  private sourceRevision: number = 0;

  private readonly onContextLostHandler: (e: Event) => void;
  private readonly onContextRestoredHandler: (e: Event) => void;

  private readonly canvas: HTMLCanvasElement;
  private readonly webgl: WebGL2RenderingContext;

  private program: WebGLLinesProgram | null;

  constructor(layer: WebGLLinesLayer) {
    super(layer);

    this.canvas = document.createElement("canvas");
    this.canvas.style.position = "absolute";
    this.canvas.style.left = "0";

    this.onContextLostHandler = this.onContextLost.bind(this);
    this.onContextRestoredHandler = this.initWebGl.bind(this);

    const webgl = this.canvas.getContext("webgl2");
    if (!webgl) throw new Error("No WebGL2 support");
    this.canvas.addEventListener(
      'webglcontextlost',
      this.onContextLostHandler,
      false
    );
    this.canvas.addEventListener(
      "webglcontextrestored",
      this.onContextRestoredHandler,
      false
    );
    this.webgl = webgl;
    console.log(webgl);

    this.program = null;
    this.initWebGl();
  }

  onContextLost(e: Event) {
    e.preventDefault();
    this.program = null;
    this.initWebGl();
  }

  initWebGl() {
    // We'll use the transmission voltage as z index to ensure higher
    // transmission voltages are drawn on top of smaller ones.
    this.webgl.enable(this.webgl.DEPTH_TEST);
    this.webgl.depthFunc(this.webgl.GEQUAL);
    this.webgl.clearDepth(0.0);
    this.webgl.clearColor(0, 0, 0, 0);
    this.program = new WebGLLinesProgram(this.webgl);

    this.sourceRevision = 0;
    this.layer_.changed();
  }

  prepareFrame(frameState: FrameState): boolean {
    const layer = this.getLayer();
    const source = layer.getSource();
    if (!source) {
      return false;
    }

    const sourceChanged = this.sourceRevision < source.getRevision();

    if (sourceChanged) {
      this.rebuildLineBuffer(source);
      this.sourceRevision = source.getRevision();
    }

    return true;
  }

  private rebuildLineBuffer(source: VectorSource) {
    const features = source.getFeatures();
    this.program?.setFeatures(features);
  }

  renderFrame(frameState: FrameState): HTMLElement {
    this.resizeCanvas(frameState);

    this.webgl.clear(this.webgl.COLOR_BUFFER_BIT | this.webgl.DEPTH_BUFFER_BIT);

    this.program?.render(
      frameState.extent ?? [0, 0, 0, 0],
      frameState.viewState.resolution
    );

    return this.canvas;
  }

  private resizeCanvas(frameState: FrameState) {
    const { width, height } = this.canvas;
    const [targetWidth, targetHeight] = frameState.size;

    const needsResize = width !== targetWidth || height !== targetHeight;
    if (needsResize) {
      this.canvas.width = targetWidth;
      this.canvas.height = targetHeight;
      this.webgl.viewport(0, 0, targetWidth, targetHeight);
    }

    return needsResize;
  }

  public dispose() {
    this.canvas.removeEventListener(
      'webglcontextlost',
      this.onContextLostHandler
    );
    this.canvas.removeEventListener(
      'webglcontextrestored',
      this.onContextRestoredHandler
    );
    this.program?.dispose();
    super.dispose();
  }
}

