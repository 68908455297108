import type { Axios } from "../libs/axios";
import axios from "../libs/axios/axios-instance";
import { MAP_TILES_ENDPOINT } from "../libs/config";
import { FileUploadResult } from "../types/FileUpload";

const baseUrl = MAP_TILES_ENDPOINT + "/";
const axiosInstance = axios(baseUrl ?? "");

export type SourceTile = {
  clientId: string;
  name: string;
};

export type StateMachineStatus = "RUNNING" | "FAILED" | "SUCCEEDED";

export const listTiles = async (
  jwt: string,
  cancelToken: Axios.CancelToken,
  clientId: string
) => {
  try {
    let body = { clientId };
    const config = { cancelToken };
    const urlPath = `list-input-tiles`;
    const { data } = await axiosInstance.post<SourceTile[]>(
      urlPath,
      body,
      config
    );
    return data;
  } catch (e: any) {
    console.log(e);
    throw e.message ?? e;
  }
};

export const deleteTile = async (
  jwt: string,
  cancelToken: Axios.CancelToken,
  tile: SourceTile
) => {
  try {
    let body = tile;
    const config = { cancelToken };
    const urlPath = `delete-input-tile`;
    await axiosInstance.post<any>(urlPath, body, config);
  } catch (e: any) {
    console.log(e);
    throw e.message ?? e;
  }
};

export const uploadTiles = async (
  jwt: string,
  cancelToken: Axios.CancelToken,
  clientId: string,
  tileNames: string[]
): Promise<FileUploadResult> => {
  try {
    let body = {
      clientId,
      tileNames,
    };
    const config = { cancelToken };
    const urlPath = `upload-input-tiles`;
    const { data } = await axiosInstance.post<FileUploadResult>(
      urlPath,
      body,
      config
    );
    return data;
  } catch (e: any) {
    console.log(e);
    throw e.message ?? e;
  }
};

export const processTiles = async (
  jwt: string,
  cancelToken: Axios.CancelToken,
  clientId: string
) => {
  try {
    let body = {
      clientId,
    };
    const config = { cancelToken };
    const urlPath = `process-input-tiles`;
    await axiosInstance.post<any>(urlPath, body, config);
  } catch (e: any) {
    console.log(e);
    throw e.message ?? e;
  }
};

export const getProcessingStatus = async (
  jwt: string,
  cancelToken: Axios.CancelToken,
  clientId: string
) => {
  try {
    const body = {
      clientId,
    };
    const config = { cancelToken };
    const urlPath = `get-processing-status`;
    const { data } = await axiosInstance.post<StateMachineStatus | undefined>(
      urlPath,
      body,
      config
    );
    return data;
  } catch (e: any) {
    console.log(e);
    throw e.message ?? e;
  }
};
