import { PluggableMap } from "ol";
import ParentLayerSwitcher, { Options, RenderOptions } from "ol-layerswitcher";
import BaseLayer from "ol/layer/Base";

import "./LayerSwitcher.css";

export default class LayerSwitcher extends ParentLayerSwitcher {
  static setOpacity: (opacity: number) => void;

  constructor(setOpacity: (opacity: number) => void, opt_options?: Options) {
    super(opt_options);
    LayerSwitcher.setOpacity = setOpacity;
    ParentLayerSwitcher.renderLayer_ = LayerSwitcher.renderLayer_;
  }

  protected static renderLayer_(
    map: PluggableMap,
    layer: BaseLayer,
    _idx: number,
    options: RenderOptions,
    render: (changedLayer: BaseLayer) => void
  ): HTMLElement {
    const baseLayer = layer.get("type") === "base";
    const group = layer.get("group");

    const li = document.createElement("li");
    li.className = "layer";

    // Checkbox / radio button
    const input = document.createElement("input");
    input.type = baseLayer || group ? "radio" : "checkbox";
    input.id = LayerSwitcher.uuid();
    input.checked = layer.get("visible");
    input.indeterminate = layer.get("indeterminate");
    if (group) input.name = group;

    input.onchange = (e) => {
      const target = e.target as HTMLInputElement;

      // Hide other layers in radio button group
      if (group) {
        LayerSwitcher.forEachRecursive(map, (layer) => {
          if (layer.get("group") === group) {
            layer.setVisible(false);
          }
        });
      }

      LayerSwitcher.setVisible_(
        map,
        layer,
        target.checked,
        options.groupSelectStyle || "group"
      );

      // Rerender this list item in layer switcher panel
      // (Needed to ensure base layer radio button toggles properly)
      render(layer);

      // Fix for base layer opacity
      if (baseLayer && target.checked) {
        LayerSwitcher.setOpacity(layer.getOpacity());
      }
    };
    li.appendChild(input);

    // Label
    const label = document.createElement("label");
    label.htmlFor = input.id;
    label.innerHTML = layer.get("displayTitle") ?? layer.get("title");
    li.appendChild(label);

    // Opacity slider
    const opacitySlider = document.createElement("input");
    opacitySlider.type = "range";
    opacitySlider.className = "opacity";
    opacitySlider.min = "0";
    opacitySlider.max = "1";
    opacitySlider.step = "0.01";
    opacitySlider.value = layer.getOpacity().toString();

    opacitySlider.oninput = (e) => {
      const target = e.target as HTMLInputElement;
      layer.setOpacity(Number(target.value));

      if (baseLayer && layer.getVisible()) {
        LayerSwitcher.setOpacity(layer.getOpacity());
      }
    };

    li.appendChild(opacitySlider);

    return li;
  }
}
