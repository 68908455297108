import { useEffect, useState } from "react";
import { getMapFeatureGeoJSON } from "../../services/mapFeatures.service";
import { MapFeatureGeoJSON } from "../../types/documents";
import { useCancelToken } from "../general";

interface Props {
  addDataToSource: (data: MapFeatureGeoJSON) => void;
  type: "avoidance-zone" | "point-of-interest";
}

type Hook = (props: Props) => [boolean];

export const useMapFeatureData: Hook = ({ addDataToSource, type }) => {
  const cancelToken = useCancelToken();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let stop = false;

    const fetchData = async () => {
      try {
        let offsetId = "";

        while (true) {
          const data = await getMapFeatureGeoJSON(cancelToken, offsetId, type);
          if (data.offsetId === offsetId) break;

          offsetId = data.offsetId;

          if (!data || !data.data || stop) break;
          addDataToSource(data);
        }
      } catch (e) {
        if (cancelToken.reason) return;
        throw e.message ? e.message : e;
      }
      setLoading(false);
    };

    fetchData();

    return () => {
      stop = true;
    };
  }, [type, cancelToken, addDataToSource]);

  return [loading];
};
