import { useEffect, useState } from "react";
import { useStyles } from "../styles";

type Component = {
  isHeader: boolean;
  title: string;
  accessor: string;
  order: number;
};

export const useComponentList = (columns: any[]) => {
  const [componentList, setComponentList] = useState<Component[]>([]);
  const [gradingsMasterList, setGradingsMasterList] = useState<any>({});

  const classes = useStyles();

  useEffect(() => {
    const buildComponentList = (columns: any[]) => {
      const components: Component[] = [];
      const options: any = {};
      columns.forEach((c, index) => {
        if (c.columns) {
          const [children, _options] = buildComponentList(c.columns);
          const component: Component = {
            isHeader: true,
            accessor: c.Header,
            title: c.Header,
            order: index,
          };

          components.push(component);
          components.push(...children);

          Object.assign(options, _options);
        } else if (c.options) {
          components.push({
            accessor: c.accessor,
            order: index,
            isHeader: false,
            title: c.Header,
          });

          options[c.accessor] = c.options;
        }
      });
      return [components, options];
    };

    const [components, options] = buildComponentList(columns);

    setComponentList(components);
    setGradingsMasterList(options);
  }, [columns, classes.dropdownGroup]);

  return {
    componentList,
    gradingsMasterList,
  };
};
