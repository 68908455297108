import { ReportProblemOutlined } from "@material-ui/icons";
import { defectStrings as strings } from "../../resources/strings";
import { useDefectsToolbarStyles } from "./styles/defectsToolbar";

export const CreateFlightWarning = () => {
  const classes = useDefectsToolbarStyles();

  return (
    <div className={`${classes.header} ${classes.warning}`}>
      <ReportProblemOutlined></ReportProblemOutlined>
      <p className={classes.warningText}>
        {strings.header.createFlightToAddDefect}
      </p>
    </div>
  );
};
