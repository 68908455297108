import type { Axios } from "../libs/axios";
import axios from "../libs/axios/axios-instance";
import { FLIGHTS_ENDPOINT } from "../libs/config";
import { FlightData } from "../types/documents";

const baseUrl = FLIGHTS_ENDPOINT + "/";
const axiosInstance = axios(baseUrl ?? "");

type Response = {
  items: FlightData[];
};

type CreateResponse = {
  flightId: string;
};

type CreateFlightParams = {
  packageId: string;
  type: string;
  registration: string;
  techLogNumber: string;
  pilotName: string;
  observerName: string;
  reference: string;
};

export const createFlight = async (
  jwt: string,
  cancelToken: Axios.CancelToken,
  params: CreateFlightParams
) => {
  try {
    let body = { ...params };
    const config = { cancelToken };
    const { data } = await axiosInstance.post<CreateResponse>("", body, config);
    return data.flightId;
  } catch (e: any) {
    console.log(e);
    throw e.message ? e.message : e;
  }
};

export const updateFlight = async (
  body: {
    id: string;
    state: number;
  },
  jwt: string,
  cancelToken: Axios.CancelToken
) => {
  try {
    const result = await axiosInstance({
      cancelToken: cancelToken,
      url: `/${body.id}`,
      method: "put",
      data: {
        ...body,
      },
    });

    console.log(result);

    return body.id;
  } catch (e: any) {
    console.log(e);
    throw e.message ? e.message : e;
  }
};

export const getFlightsByClient = async (
  cancelToken: Axios.CancelToken,
  client: string
) => {
  try {
    const config = { cancelToken };
    const urlPath = `/client/${client}`;
    const { data } = await axiosInstance.get<Response>(urlPath, config);

    return data.items;
  } catch (e: any) {
    console.log(e);
    throw e.message ? e.message : e;
  }
};
