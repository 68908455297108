import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  header: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    backgroundColor: theme.backgroundShades.white,
    borderBottom: theme.spacing(0.125, "solid", `${theme.border.primary}`),
    height: theme.spacing(8),
  },
  dropdown: {
    width: theme.spacing(22),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
    "& .MuiSelect-root": {
      fontSize: "1rem",
      fontWeight: 500,
      textAlign: "left",
      paddingLeft: theme.spacing(1.5),
      backgroundColor: theme.backgroundShades.grey,
      border: theme.spacing(0.125, "solid", `${theme.border.primary}`),
    },
  },
  font: {
    fontWeight: 600,
  },
}));
