import { LabelDisplayedRowsArgs, TablePagination } from "@material-ui/core";
import { PaginationActions } from "./PaginationActions";

interface Props {
  pageIndex: number;
  pageSize: number;
  count: number;
  handleChangePage: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => void;
}

export const Pagination = ({
  pageIndex,
  count,
  handleChangePage,
  pageSize,
}: Props) => {
  const labelDisplayedRows = ({ from, to, count }: LabelDisplayedRowsArgs) =>
    `${from} to ${to} of ${count}`;

  return (
    <TablePagination
      role="navigation"
      component="div"
      page={pageIndex}
      count={count}
      rowsPerPageOptions={[]}
      rowsPerPage={pageSize}
      onPageChange={handleChangePage}
      labelDisplayedRows={labelDisplayedRows}
      ActionsComponent={PaginationActions}
    />
  );
};
