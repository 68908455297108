import { useMemo } from "react";
import type { Mode } from "../../components/general/types/Modify";
import { CLIENTS_ENDPOINT } from "../../libs/config";
import { paths } from "../../navigation";
import { RouteProps } from "../../types";
import type { ClientUserData } from "../../types/documents";
import { ModifyContainer } from "../general";
import { getDropdownMappings } from "../users";
import { mapToDropdownOptions } from "../users/functions/onLoadModify";
import { useUserData } from "../users/useUserData";
import {
  componentConfigurationSections,
  constraints,
  initialFormData,
} from "./resources";

const putEndpoint = `${CLIENTS_ENDPOINT}/submit`;
const queryEndpoint = `${CLIENTS_ENDPOINT}`;

export interface Props extends RouteProps {
  mode: Mode;
}

type Component = (props: Props) => JSX.Element;

export const ClientModify: Component = ({ mode, ...routeProps }) => {
  const { jwt, user: routeUser } = routeProps;
  const user = useMemo(() => routeUser, [routeUser]);
  const [loading, roles] = useUserData({ jwt, user, roleType: "Client" });

  return (
    <ModifyContainer<ClientUserData>
      {...routeProps}
      putEndpoint={putEndpoint}
      queryEndpoint={queryEndpoint}
      mode={mode}
      initialData={initialFormData}
      componentConfiguration={componentConfigurationSections(
        mode,
        mapToDropdownOptions([roles], getDropdownMappings(user)).roles,
        jwt
      )}
      constraints={constraints}
      redirectPath={paths.clients.view}
      loading={loading}
    />
  );
};
