import {
  Backdrop,
  Button,
  Fade,
  makeStyles,
  Modal as MuiModal
} from "@material-ui/core";
import clsx from "clsx";
import { Loader } from ".";
import { appStrings as strings } from "../../resources/strings";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    maxWidth: theme.spacing(60),
    border: theme.spacing(0.4, "solid", theme.palette.primary.main),
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  all: {
    fontWeight: theme.spacing(100),
    boxShadow: theme.spacing(0, 0, 0, 0),
    height: theme.spacing(5),
    width: theme.spacing(24.375),
    margin: theme.spacing(1),
    borderRadius: 0,
  },
  border: { border: theme.spacing(0.4, "solid", "!important") },
  danger: {
    color: theme.palette.error.main,
    background: theme.palette.background.default,
  },
}));

interface Iprops {
  confirmButtonLabel: string;
  message: string;
  onConfirm: (...args: any[]) => any;
  open: boolean;
  handleClose: () => void;
  loading?: boolean;
}

export const ConfirmModal = ({
  confirmButtonLabel,
  message,
  onConfirm,
  open,
  handleClose,
  loading = false,
}: Iprops) => {
  const classes = useStyles();

  return (
    <MuiModal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <Loader inline active={loading}>
            <h2 id="transition-modal-title">{strings.labels.headerConfirm}</h2>
            <p id="transition-modal-description">{message}</p>
            <div className={classes.buttonContainer}>
              <Button
                className={clsx(classes.all, classes.border, classes.danger)}
                color="primary"
                variant="outlined"
                onClick={onConfirm}
              >
                {confirmButtonLabel}
              </Button>
              <Button
                variant="outlined"
                color="primary"
                className={clsx(classes.all, classes.border)}
                onClick={handleClose}
              >
                {strings.labels.cancelConfirm}
              </Button>
            </div>
          </Loader>
        </div>
      </Fade>
    </MuiModal>
  );
};
