import {
  Button,
  IconButton,
  makeStyles,
  TableCell,
  TableRow
} from "@material-ui/core";
import React from "react";
import { DeleteOutline, Remove } from "@material-ui/icons";
import { File, FileType } from "../../types/FileUpload";

export interface Props {
  file: File,
  disableButtons: boolean,
  onDeleteFile: (object: string) => void;
  onProcessFile?: () => void;
}

type Component = (props: Props) => JSX.Element;

export const FileListRow: Component = ({
  file,
  onDeleteFile,
  disableButtons,
  onProcessFile
}) => {
  const classes = useStyles();
  return <>
    <TableRow>
      <TableCell className={classes.fileNameCell}>{file.name}</TableCell>
      <TableCell>{formatType(file.type)}</TableCell>
      <TableCell>-</TableCell>
      <TableCell align="right" padding="none">
        {onProcessFile &&
         <Button color="primary" variant="outlined"
                 className={classes.boldButton}
                 onClick={onProcessFile}
                 disabled={disableButtons}>
             Process File
         </Button>
        }
      </TableCell>
    </TableRow>
    {file.objects.map(obj =>
      <TableRow key={obj.name}>
        <TableCell>
          <div className={classes.objectNameCell}>
            <Remove color="primary"/>
            <span>{obj.name}</span>
          </div>
        </TableCell>
        <TableCell>-</TableCell>
        <TableCell>{formatSize(obj.size)}</TableCell>
        <TableCell align="right" padding="none">
          <IconButton onClick={() => onDeleteFile(obj.name)}
                      disabled={disableButtons}>
            <DeleteOutline color="error"/>
          </IconButton>
        </TableCell>
      </TableRow>
    )}
  </>
}

function formatSize(size: number): string {
  const sizes = ['B', 'KB', 'MB', 'GB'];
  const sizeOrderOfMagnitude = 1024;

  while (sizes.length > 1 && size > sizeOrderOfMagnitude) {
    size /= sizeOrderOfMagnitude;
    sizes.shift();
  }

  return size.toFixed(2) + sizes[0];
}

function formatType(type: FileType): string {
  switch (type) {
    case "shp":
      return "Shape File";
    case "csv":
      return "CSV";
    case "tile":
      return "Tile";
  }
}

const useStyles = makeStyles(theme => ({
  boldButton: {
    fontWeight: 900,
    borderRadius: 0,
    margin: theme.spacing(1),
    paddingTop: theme.spacing(0.25),
    paddingBottom: theme.spacing(0.25),
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    border: theme.spacing(0.25, "solid", `${theme.palette.primary.main}`),
    color: theme.palette.primary.main,
  },
  fileNameCell: {
    fontWeight: 600,
  },
  objectNameCell: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start"
  }
}));
