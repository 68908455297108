import { useContext, useEffect, useState } from "react";
import MapContext from "../MapContext";
import GeoJSON from "ol/format/GeoJSON";
import VectorSource from "ol/source/Vector";
import { Point } from "ol/geom";

import VectorLayer from "ol/layer/Vector";
import { Feature } from "ol";
import { convertPoint } from "../helpers/Coordinates";
import { gpsLayerStyle } from "../helpers/Style";
import { GPXParser } from "../helpers/GPXParser";
import { mapStrings as strings } from "../../../resources/strings";

interface Props {
  name: string;
  position: number;
  gpx: GPXParser;
}

type Component = (props: Props) => JSX.Element;

export const GPSLayer: Component = ({ name, position, gpx }) => {
  const map = useContext(MapContext);
  const [vectorSource] = useState(new VectorSource({}));

  useEffect(() => {
    if (!map) return;

    const layer = new VectorLayer({
      properties: {
        title: name,
        layerType: strings.layerTypes.vector,
        olgmWatch: false,
      },
      source: vectorSource,
      style: gpsLayerStyle,
    });

    map.addLayer(layer);

    const format = new GeoJSON({
      dataProjection: "EPSG:4326",
      featureProjection: map.getView().getProjection(),
    });

    // The .toGeoJSON() function exists, it's just missing from the library's type declaration
    // @ts-ignore
    vectorSource.addFeatures(format.readFeatures(gpx.toGeoJSON()));

    return () => {
      vectorSource.clear();
      map.removeLayer(layer);
    };
  }, [gpx, name, vectorSource, map]);

  useEffect(() => {
    if (!gpx) return;
    const trackPoints = gpx.trackPoints;
    const point = trackPoints[position];

    const marker = new Feature(
      new Point(convertPoint("EPSG:4326", "EPSG:3857", [point.lon, point.lat]))
    );

    vectorSource.addFeature(marker);

    return () => {
      vectorSource.removeFeature(marker);
    };
  }, [gpx, position, vectorSource]);

  return <></>;
};
