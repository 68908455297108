import { Content, Section } from "../../components/general/types/Modify";
import { LineLayer } from "../../types/documents";
import {
  assetLoadStrings,
  clientStrings as strings
} from "../../resources/strings";
import LineLayerBreadcrumbHeader
  from "../../components/lines/LineLayerBreadcrumbHeader";
import { DropdownOptions } from "../../types";
import { LineLayerColumnSelections } from "../../types/documents/LineLayer";
import { LineLayerPreview } from "../../components/lines/LineLayerPreview";

export const lineInitialFormData = (clientId: string) => ({
  id: "",
  clientId,
  name: "",
  createdDate: "",
  updatedDate: "",
}) as LineLayer;

export const lineConstraints = {
  name: {
    presence: {
      allowEmpty: false,
      message: `^${strings.labels.name} is required`,
    },
  },
  minResolution: {
    numericality: {
      onlyInteger: true,
      message: `^${assetLoadStrings.labels.transmissionVoltage} must be a number`,
    }
  },
  maxResolution: {
    numericality: {
      onlyInteger: true,
      message: `^${assetLoadStrings.labels.transmissionVoltage} must be a number`,
    }
  },
};

const coordinateSystemControl: Content<{ coordinateSystem: string }> = {
  controltype: "dropdown",
  options: [
    {
      label: assetLoadStrings.labels.EPSG_4326,
      value: "EPSG:4326",
    },
    {
      label: assetLoadStrings.labels.EPSG_27700,
      value: "EPSG:27700",
    },
    {
      label: assetLoadStrings.labels.EPSG_29903,
      value: "EPSG:29903",
    },
  ],
  name: "coordinateSystem",
  label: strings.labels.coordinateSystem,
};

export const lineComponentConfigurations: Section<LineLayer>[] = [
  {
    key: "details",
    content: [
      {
        controltype: "custom",
        Component: LineLayerBreadcrumbHeader,
        md: 12,
        fullwidth: true,
      },
      {
        controltype: "header",
        text: strings.header.clientDetails,
        align: "left",
        variant: "subtitle1",
      },
      {
        controltype: "input",
        name: "name",
        label: strings.labels.name,
        required: true,
      },
      coordinateSystemControl,
      {
        controltype: "input",
        name: "minResolution",
        label: strings.labels.minResolution,
        required: false,
      },
      {
        controltype: "input",
        name: "maxResolution",
        label: strings.labels.maxResolution,
        required: false,
      },
      {
        controltype: "custom",
        Component: LineLayerPreview,
        fullwidth: true,
        md: 12,
      }
    ],
  }
  ]
;

export const columnSelectionComponentConfigurations = {
  general(
    columnOptions: DropdownOptions
  ): Section<LineLayerColumnSelections> {
    return {
      key: "general",
      content: [
        {
          controltype: "dropdown",
          options: columnOptions,
          name: "displayName",
          label: strings.labels.name,
        },
        {
          controltype: "dropdown",
          options: columnOptions,
          name: "transmissionVoltage",
          label: strings.labels.transmissionVoltage,
        },
        {
          controltype: "input",
          name: "minResolution",
          label: strings.labels.minResolution,
        },
        {
          controltype: "input",
          name: "maxResolution",
          label: strings.labels.maxResolution,
        },
        coordinateSystemControl
      ],
    };
  },
};

export const columnSelectionConstraints = {
  displayName: {
    presence: {
      allowEmpty: false,
      message: `^${assetLoadStrings.labels.displayName} is required`,
    },
  },
  transmissionVoltage: {
    presence: {
      allowEmpty: false,
      message: `^${assetLoadStrings.labels.transmissionVoltage} is required`,
    },
  },
  minResolution: {
    numericality: {
      onlyInteger: true,
      message: `^${assetLoadStrings.labels.transmissionVoltage} must be a number`,
    }
  },
  maxResolution: {
    numericality: {
      onlyInteger: true,
      message: `^${assetLoadStrings.labels.transmissionVoltage} must be a number`,
    }
  },
};

export const submitButtons = {
  key: "submitButtons",
  modes: ["create", "update"],
  content: [
    {
      controltype: "button",
      label: assetLoadStrings.labels.save,
      color: "primary",
      variant: "contained",
    },
    {
      controltype: "button",
      label: assetLoadStrings.labels.reset,
      color: "primary",
      variant: "outlined",
      type: "reset",
    },
  ],
} as Section<any>;
