import GeoJSON from "ol/format/GeoJSON";
import { useContext, useEffect, useState } from "react";
import { mapStrings as strings } from "../../../resources/strings";
import MapContext from "../MapContext";

export const useGeoJSON = () => {
  const map = useContext(MapContext);
  const [geoJSON, setGeoJSON] = useState<GeoJSON | null>(null);

  useEffect(() => {
    if (!map) return;

    setGeoJSON(
      new GeoJSON({
        dataProjection: strings.projections.EPSG_4326,
        featureProjection: map.getView().getProjection(),
      })
    );
  }, [map]);

  return geoJSON;
};
