import { useEffect, useState } from "react";
import { API, GroupedAssetGrouping } from "../../API";
import { useCancelToken } from "../general";

export type AssetGroupValuesProps = {
  clientId?: string;
  groupedBy?: string;
  jwt: string;
};

type AssetGroupValuesResult = [boolean, GroupedAssetGrouping[]];

export const useAssetGroupValues = ({
  jwt,
  clientId,
  groupedBy,
}: AssetGroupValuesProps): AssetGroupValuesResult => {
  const [loading, setLoading] = useState(false);
  const [assetGroupValues, setAssetGroupValues] = useState<
    GroupedAssetGrouping[]
  >([]);

  const cancelToken = useCancelToken();
  useEffect(() => {
    const getAssetGroups = async () => {
      if (!groupedBy || !clientId) {
        setAssetGroupValues(() => []);
        setLoading(false);
        return;
      }
      try {
        const APIFunctions = new API(cancelToken, jwt);
        const groupedAssets = await APIFunctions.getGroupedAssets(
          groupedBy,
          clientId
        );
        setAssetGroupValues(() => (groupedAssets ? [...groupedAssets] : []));
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
        return;
      }
    };

    setLoading(true);
    getAssetGroups();
  }, [clientId, groupedBy, cancelToken, jwt]);

  return [loading, assetGroupValues];
};
