import BreadcrumbHeader from "../../components/general/BreadcrumbHeader";
import DeleteEditClearSaveButtons
  from "../../components/general/DeleteEditClearSaveButtons";
import { Props } from "../../components/general/ModifyComponent";
import { paths } from "../../navigation";
import { clientStrings as strings } from "../../resources/strings";
import { ClientUserData } from "../../types/documents";
import { ShowIfAuthorised } from "../../components/authentication";
import { Button, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { defaultButtonStyle } from "../../components/styles";
import { useHistory } from "react-router-dom";
import { useCallback } from "react";

const useStyles = makeStyles((theme) => ({
  all: defaultButtonStyle(theme),
}));

export default function ClientBreadcrumbHeader({
  data,
  mode,
  permissions,
  handleModeSwitch,
}: Props<ClientUserData>) {
  const classes = useStyles();

  const getHeaderFromMode = () => {
    switch (mode) {
      case "view":
        return strings.header.viewClient;
      case "create":
        return strings.header.createClient;
      case "update":
        return strings.header.editClient;
    }
  };

  const crumbs = [
    { name: strings.header.clients, link: paths.clients.list },
    {
      name: getHeaderFromMode(),
    },
  ];

  const history = useHistory();
  const showLines = useCallback(() => {
    history.push(paths.clients.lines.list(data.id))
  }, [history, data.id]);

  const linesButton = () =>
    <ShowIfAuthorised
      entity={strings.global.entities.clients}
      permission={strings.global.permissions.update}
      userPermissions={permissions}
    >
      <Button
        className={clsx(classes.all)}
        color="primary"
        variant="contained"
        onClick={showLines}
        title="show lines"
      >
        {strings.labels.showLines}
      </Button>
    </ShowIfAuthorised>;

  return (
    <BreadcrumbHeader crumbs={crumbs}>
      {mode === "view" && linesButton()}
      <DeleteEditClearSaveButtons
        entity={strings.global.entities.clients}
        mode={mode}
        permissions={permissions}
        deleteFunction={undefined} //Not implemented for users
        deleteLoading={false}
        handleModeSwitch={handleModeSwitch}
      />
    </BreadcrumbHeader>
  );
}
