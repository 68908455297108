import {
  Content,
  LayoutProps,
  Mode,
  Section,
} from "../../components/general/types/Modify";
import { clientStrings as strings } from "../../resources/strings";
import { ComponentConfigurations, DropdownOptions } from "../../types";
import { ClientUserData } from "../../types/documents";
import { componentConfigurations as userConfigurations } from "../users/index";
import ClientBreadcrumbHeader from "./ClientBreadcrumbHeader";
import { ShowClientTiles } from "./ShowClientTiles";
import { UploadClientTiles } from "./UploadClientTiles";

export const initialFormData = {
  id: "",
  clientId: "",
  name: "",
  createdDate: "",
  updatedDate: "",
  email: "",
  forename: "",
  roleId: "",
  surname: "",
  userId: "",
  username: "",
  created: "",
  updated: "",
};

export const constraints = {
  name: {
    presence: {
      allowEmpty: false,
      message: `^${strings.labels.name} is required`,
    },
  },
};

export const componentConfigurationSections = (
  mode: Mode,
  roles: DropdownOptions,
  jwt: string
) => {
  const sections = [componentConfigurations.details(mode, roles, jwt)];
  if (mode !== "create") {
    sections.push(componentConfigurations.tileUpload);
  }
  return sections;
};

export interface ClientComponentConfigurations
  extends ComponentConfigurations<ClientUserData> {
  tileUpload: Section<ClientUserData>;
}

export const componentConfigurations: ClientComponentConfigurations = {
  details: (
    mode: Mode,
    roles: DropdownOptions,
    jwt: string,
    otherContent?: Content<any> & LayoutProps
  ): Section<ClientUserData> => {
    const { content } = userConfigurations.details(
      mode,
      roles,
      jwt,
      otherContent
    );
    const userContent = mode === "create" ? content : []; //Only show the user details in create mode
    userContent.shift(); //Remove the first element of the user page, which is the breadcrumbs and buttons

    return {
      key: "details",
      content: [
        {
          controltype: "custom",
          Component: ClientBreadcrumbHeader,
          md: 12,
          fullwidth: true,
        },
        {
          controltype: "header",
          text: strings.header.clientDetails,
          align: "left",
          variant: "subtitle1",
        },
        {
          controltype: "input",
          name: "name",
          label: strings.labels.name,
          required: true,
        },
        ...(userContent as unknown as (Content<ClientUserData> &
          LayoutProps)[]),
      ],
    };
  },
  tileUpload: {
    key: "mapTiles",
    userAccess: {
      entity: strings.global.entities.clients,
      permission: strings.global.permissions.update,
    },
    content: [
      {
        controltype: "header",
        text: strings.header.mapTiles,
        align: "left",
        variant: "h6",
      },
      {
        controltype: "custom",
        modes: ["view", "update"],
        Component: UploadClientTiles,
        xs: 6,
      },
      {
        controltype: "custom",
        modes: ["view", "update"],
        Component: ShowClientTiles,
        xs: 6,
      },
    ],
  },
};
