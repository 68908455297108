import { FormDesignerTheme } from "@pulsion/forms-designer-2";
import { theme } from "../../../styles/theme";

export const formDesignerTheme: FormDesignerTheme = {
  colors: {
    primary: theme.palette.primary.main,
    secondary: theme.palette.primary.main,
    modalHeader: theme.palette.primary.main,
  },
  fonts: [`${theme.typography.fontFamily} !important` as string],
  font: {
    primary: {
      family: `${theme.typography.fontFamily} !important` as string,
      color: "#333333",
    },
    secondary: {
      family: `${theme.typography.fontFamily} !important` as string,
      color: theme.palette.text.primary,
      size: "15px",
    },
  },
  button: {
    fontSize: "16px",
  },
  containers: {
    root: {
      boxShadow: "none",
      border: "none",
      borderRadius: "0px",
      textAlign: "left",
    },
    section: {
      boxShadow: "none",
      border: "1px solid #cccccc",
      borderRadius: "0px",
      textAlign: "left",
      margin: "1rem 0",
    },
  },
  headers: {
    color: theme.palette.primary.main,
    fontWeight: "bold !important",
    textTransform: "uppercase",
    textAlign: "left",
    lineHeight: "1.75",
    fontSize: "1.14rem",
  },
  fields: {
    borderRadius: "0",
    border: `1px solid ${theme.border.primary}`,
    padding: "8px",
    background: theme.backgroundShades.grey,
  },
  submitButtonContainer: {
    position: "absolute",
    top: "60px",
    right: "20px",
    display: "inline-block",
    width: "auto",
  },
  buttons: {
    root: {
      borderRadius: "0",
      boxShadow: "none",
      color: theme.backgroundShades.white,
      backgroundColor: theme.palette.primary.main,
    },
    hover: {
      borderRadius: "0",
      boxShadow: "none",
      backgroundColor: theme.backgroundShades.white,
      color: theme.palette.primary.main,
    },
    dndControl: {
      root: {
        border: "1px solid #d8d8d8",
        borderRadius: "0",
        boxShadow: "none",
        color: theme.palette.text.primary,
        backgroundColor: theme.backgroundShades.grey,
        width: "40px",
        height: "40px",
        marginRight: "10px",
      },
      hover: {
        border: "1px solid #d8d8d8",
        borderRadius: "0",
        boxShadow: "none",
        color: theme.backgroundShades.grey,
        backgroundColor: theme.palette.primary.main,
        width: "40px",
        height: "40px",
        marginRight: "10px",
      },
    },
    icon: {
      verticalAlign: "sub",
    },
  },
  buttonSetControl: {
    margin: "5px",
  },
  sectionContainer: {
    marginLeft: "0px",
    marginRight: "0px",
  },
  sectionMenu: {
    container: {
      marginTop: "0 !important",
      padding: 0,
      border: "none !important",
      borderRadius: 0,
    },
    item: {
      hover: {
        backgroundColor: "#eeeeee",
      },
      root: {
        marginBottom: "20px !important",
        border: "1px solid #cccccc",
        padding: "15px 20px",
      },
      add: {
        border: "1px dashed #cccccc",
        textAlign: "center",
        padding: "15px",
      },
    },
    selected: {
      borderLeft: "none",
      borderTop: `4px solid ${theme.palette.primary.main} !important`,
    },
  },
  subsections: {
    all: {
      borderRadius: "0",
      borderColor: theme.border.primary,
    },
    adjacent: {
      borderRadius: "0",
      borderColor: theme.border.primary,
      marginTop: "14px",
    },
    header: {
      backgroundColor: theme.backgroundShades.grey,
    },
  },
  sectionColumns: {
    container: {
      borderTop: 0,
    },
    sectionDisplay: {
      marginTop: 0,
      borderLeft: 0,
    },
  },
  section: {
    borderRadius: 0,
  },
};
