import React from "react";
import { BookingSearchBar } from "./Filters/SearchBar";

export default function BookingFilterBar() {
  return (
    <>
      <BookingSearchBar />
    </>
  );
}
