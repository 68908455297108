import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  header: {
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2.5),
    backgroundColor: theme.accents.primary,
    borderBottom: theme.spacing(0.125, "solid", `${theme.border.primary}`),
  },
  breadcrumb: { backgroundColor: theme.palette.background.paper },
  button: {
    fontWeight: theme.spacing(80),
    boxShadow: theme.spacing(0, 0, 0, 0),
    height: theme.spacing(5),
    width: theme.spacing(20),
    borderRadius: 0,
    margin: theme.spacing(0.5),
    "&:hover": {
      color: "white",
    },
  },
  headerCrumb: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    textTransform: "uppercase",
    fontSize: "1.3rem",
  },
  paginationIcons: {
    borderRadius: theme.spacing(0.5),
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    margin: theme.spacing("0", 0.25),
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
    "&:disabled": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
      opacity: "50%",
    },
  },
  paginationActions: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  tableRow: {
    "&:nth-child(even)": {
      background: theme.palette.background.default,
    },
  },
  titleRow: {
    background: theme.palette.background.default,
    fontWeight: 600,
    fontSize: "1.1rem",
  },
  tableHead: {},
  tableCell: {
    padding: theme.spacing(0),
    paddingLeft: theme.spacing(1.5),
    display: "flex",
    flex: `${theme.spacing(20)} 0 auto !important`,
    alignItems: "center",
    width: theme.spacing(10, "!important"),
    minHeight: theme.spacing(6.875),
    "&:first-child": {
      paddingLeft: theme.spacing(2.5),
    },
  },
  centered: {
    justifyContent: "center",
  },
  buttonColumn: {
    minWidth: "150px",
  },
  table: {
    boxShadow: theme.spacing(0, 0, 0, 0),
    borderRadius: 0,
  },
  searchBar: {
    display: "flex",
    justifyContent: "space-between",
    height: theme.spacing(8.75),
  },
  boldButton: {
    fontWeight: 900,
    borderRadius: 0,
    margin: theme.spacing(0.25),
    border: theme.spacing(0.375, "solid", `${theme.palette.primary.main}`),
    color: theme.palette.primary.main,
  },
  justButtonHeader: {
    padding: 0,
    border: "none",
    background: `${theme.palette.background.paper} !important`,
  },
}));
