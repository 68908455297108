import { useEffect, useState } from "react";
import { DropdownOptions } from "../../types";
import { AssetDetails } from "../../types/documents/Assets";

export const useAssetOptions = (assets: AssetDetails[]) => {
  const [assetOptions, setAssetOptions] = useState<DropdownOptions>([]);
  useEffect(() => {
    const assetNames = assets.map((asset) => asset.assetContainer.name);
    const uniqueAssetNames = Array.from(new Set(assetNames));
    const assetOptions: DropdownOptions = uniqueAssetNames.map((assetName) => ({
      label: assetName,
      value: assetName,
    }));

    setAssetOptions(() => Array.from(new Set(assetOptions)));
  }, [assets]);

  return assetOptions;
};
