import defaultAxios, { CancelToken } from "axios";
import exif from "exif-js";
import axios from "../libs/axios/axios-instance";
import { IMAGES_ENDPOINT } from "../libs/config";
import { ImageMetadata, ImageParameters } from "../types/documents";

const baseUrl = IMAGES_ENDPOINT;
const axiosInstance = axios(baseUrl ?? "");

export const getImageUrl = async (
  type: "main" | "thumb",
  imageMetadata: ImageMetadata,
  cancelToken: CancelToken
) => {
  const urls = (await getImageUrls(type, [imageMetadata], cancelToken)) ?? {};

  const { fileName, thumbName } = imageMetadata;
  const key = type === "main" ? fileName : thumbName;

  return urls[key];
};

export const getImageUrls = async (
  type: "main" | "thumb",
  imageMetadata: ImageMetadata[],
  cancelToken: CancelToken
) => {
  if (!imageMetadata.length) return;

  try {
    const config = { cancelToken };

    const groupedByPath = imageMetadata.reduce((groups, curr) => {
      groups[curr.path] = groups[curr.path] || [];
      groups[curr.path].push(curr.fileName);

      return groups;
    }, {} as Record<string, string[]>);

    const promises = [];
    for (const [path, keys] of Object.entries(groupedByPath)) {
      const encodedPath = path.split("/").join("&");
      const encodedKeys = keys.join("&");

      const endpoint = `/view/${type}/${encodedPath}/${encodedKeys}`;

      promises.push(axiosInstance.get(endpoint, config));
    }

    const responses = await Promise.all(promises);
    const urls: Record<string, string> = Object.assign(
      {},
      ...responses.map(({ data }) => data)
    );

    return urls;
  } catch (e: any) {
    if (cancelToken.reason) return;

    console.log(e);
    throw e.message ? e.message : e;
  }
};

export const listImages = async (
  { clientId, flightId, assetId, zoneId }: ImageParameters,
  cancelToken: CancelToken
) => {
  if (!clientId || !flightId) return;

  const config = { cancelToken };

  let endpoint = `/list-images/${clientId}/${flightId}`;
  if (assetId) endpoint += `/${assetId}`;
  if (assetId && zoneId) endpoint += `/${zoneId}`;

  try {
    const { data } = await axiosInstance.get<ImageMetadata[]>(endpoint, config);

    return data;
  } catch (e: any) {
    if (cancelToken.reason) return;

    console.log(e);
    throw e.message ? e.message : e;
  }
};

const getExifTag = (file: File, tag: string) => {
  return new Promise<string>((resolve) =>
    //@ts-ignore
    exif.getData(file, () => resolve(exif.getTag(file, tag)))
  );
};

const getFileDetails = async (files: File[]) => {
  const promises = files.map((file) =>
    getExifTag(file, "DateTimeOriginal").then((taken: string) => ({
      name: file.name,
      type: file.type,
      meta: { taken },
    }))
  );

  return await Promise.all(promises);
};

const getUploadUrls = async (
  files: File[],
  { clientId, flightId }: ImageParameters,
  cancelToken: CancelToken
) => {
  const config = { cancelToken };

  const body = {
    clientId,
    flightId,
    fileDetails: await getFileDetails(files),
  };

  const endpoint = "/urls/upload";

  try {
    const { data } = await axiosInstance.post<Record<string, string>>(
      endpoint,
      body,
      config
    );

    return data ?? {};
  } catch (e: any) {
    if (cancelToken.reason) return;

    console.log(e);
    throw e.message ? e.message : e;
  }
};

export const uploadImages = async (
  fileList: FileList,
  imageParameters: ImageParameters,
  cancelToken: CancelToken,
  updateProgress: (message: string, completed?: number, total?: number) => void
) => {
  const files = Array.from(fileList);

  // Get presigned upload URLs
  updateProgress("Preparing upload...");
  const urls = (await getUploadUrls(files, imageParameters, cancelToken)) ?? {};

  // Upload files
  let completedRequests = 0;
  const putRequests = files.map((file) =>
    defaultAxios.put(urls[file.name], file).then(() => {
      updateProgress("Uploading files...", ++completedRequests, files.length);
    })
  );

  try {
    return await Promise.all(putRequests);
  } catch (e: any) {
    if (cancelToken.reason) return;

    console.log(e);
    throw e.message ? e.message : e;
  }
};
