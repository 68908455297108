import { StateValue } from "xstate";
import type { ChangeHandler } from "../../components/general/types/Modify";
import { Input } from "../general/controls/Input";
import { FlightDetailProps } from "./flight";

type Props = {
  state: StateValue;
  formData: FlightDetailProps;
  handleChange: ChangeHandler;
};

export const FlightDetails = ({ state, formData, handleChange }: Props) => {
  if (state !== "notStarted") {
    return <></>;
  }

  return (
    <>
      <Input
        config={{
          value: formData.type,
          name: "type",
          label: "Aircraft Type",
          fullWidth: true,
          controltype: "input",
        }}
        handleChange={handleChange}
      ></Input>
      <Input
        config={{
          value: formData.registration,
          name: "registration",
          label: "Aircraft Number/Reg",
          fullWidth: true,
          controltype: "input",
          style: { textAlign: "left" },
        }}
        handleChange={handleChange}
      ></Input>
      <Input
        config={{
          value: formData.techLogNumber,
          name: "techLogNumber",
          label: "Tech Log",
          fullWidth: true,
          controltype: "input",
        }}
        handleChange={handleChange}
      ></Input>
      <Input
        config={{
          value: formData.pilotName,
          name: "pilotName",
          label: "Pilot Name",
          fullWidth: true,
          controltype: "input",
        }}
        handleChange={handleChange}
      ></Input>
      <Input
        config={{
          value: formData.observerName,
          name: "observerName",
          label: "Observer Name",
          fullWidth: true,
          controltype: "input",
        }}
        handleChange={handleChange}
      ></Input>
      <Input
        config={{
          value: formData.reference,
          name: "reference",
          label: "Reference",
          fullWidth: true,
          controltype: "input",
        }}
        handleChange={handleChange}
      ></Input>
    </>
  );
};
