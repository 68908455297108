import { CancelToken } from "axios";
import axios from "../libs/axios/axios-instance";
import { CLIENTS_ENDPOINT } from "../libs/config";
import { ClientData } from "../types/documents";

const baseUrl = CLIENTS_ENDPOINT + "/";
const axiosInstance = axios(baseUrl ?? "");

type Response = {
  items: ClientData[];
};

export const listClients = async (jwt: string, cancelToken: CancelToken) => {
  try {
    let body = {};
    const config = { cancelToken };
    const urlPath = `list-clients`;
    const { data } = await axiosInstance.post<Response>(urlPath, body, config);
    return data.items;
  } catch (e: any) {
    if (cancelToken.reason) return [];
    console.log(e);
    throw e.message ? e.message : e;
  }
};
