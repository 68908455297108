import { useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { LoadHistory } from "../../components/assets/LoadHistory";
import { Loader } from "../../components/general";
import { useCancelToken } from "../../hooks/general";
import { useServiceData } from "../../hooks/general/useServiceData";
import { HooksLogger } from "../../hooks/hooks-logger";
import { notifications } from "../../libs/notifications";
import { paths } from "../../navigation";
import * as assetsApi from "../../services/assets.service";
import { RouteProps } from "../../types";

const logger = new HooksLogger("Assets/processing");

export interface Props extends RouteProps {}

type Component = (props: Props) => JSX.Element;

export const AssetExecutions: Component = ({ ...routeProps }) => {
  const { jwt } = routeProps;
  const cancelToken = useCancelToken();
  const setError = useCallback((e) => notifications.error(e), []);

  const listDataLoads = useCallback(
    () => assetsApi.listDataLoads(jwt, cancelToken),
    [jwt, cancelToken]
  );

  const [executions, executionsLoading] = useServiceData(
    "Getting data loads",
    listDataLoads,
    logger,
    cancelToken,
    setError,
    1000
  );

  const initialExecutionsLoading =
    executions === undefined && executionsLoading;

  const { id: selectedLoadId } = useParams<{ id?: string }>();
  const selectedLoad =
    selectedLoadId !== undefined
      ? executions?.find((load) => load.id === selectedLoadId)
      : undefined;
  const history = useHistory();
  const onSelectLoad = (id: string) => {
    history.push(paths.assets.upload(id));
  };

  return (
    <Loader active={initialExecutionsLoading}>
      <LoadHistory
        {...routeProps}
        loads={executions || []}
        selectedLoad={selectedLoad}
        onSelectLoad={onSelectLoad}
      />
    </Loader>
  );
};
