import {
  Backdrop, Fade,
  makeStyles,
  Modal as MuiModal
} from "@material-ui/core";
import { JobRenderer } from "../../containers/jobs/JobRenderer";
import { Loader } from "../general";

  const useStyles = makeStyles((theme) => ({
    modal: {
      position:'absolute',
      top:'10%',
      left:'10%',
      alignItems: "top",
      justifyContent: "center",
      overflow:'scroll',
      height:'100%',
      display:'block',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      maxWidth: theme.spacing(150),
      boxShadow: theme.shadows[5],
      padding: theme.spacing(1, 0, 1),
    },
  }));
  
  interface Iprops {
    defectId: string;
    open: boolean;
    handleClose: () => void;
    loading?: boolean;
  }

  export const JobRendererModal = ({
    defectId,
    open,
    handleClose,
    loading = false,
  }: Iprops) => {
    const classes = useStyles();
    const closeModal = () => {
      handleClose();
    };

    return (
      <MuiModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={closeModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Loader inline active={loading}>
              <JobRenderer jobId={defectId}/>
            </Loader>
          </div>
        </Fade>
      </MuiModal>
    );
  };