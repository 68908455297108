import { Section } from "../../components/general/types/Modify";
import { flightStrings as strings } from "../../resources/strings";
import { BasicComponentConfigurations } from "../../types";
import { FlightData } from "../../types/documents";
import FlightBreadcrumbHeader from "./FlightBreadcrumbHeader";

export const constraints = {};

export const componentConfigurations: BasicComponentConfigurations<FlightData> = {
    details: (_mode, jwt, cancelToken): Section<FlightData> => {
      return {
        key: "details",
        content: [
          {
            controltype: "custom",
            Component: FlightBreadcrumbHeader,
            md: 12,
            fullwidth: true,
          },
          {
            controltype: "header",
            text: strings.header.flightDetails,
            align: "left",
            variant: "subtitle1",
          },
          {
            controltype: "input",
            name: "techLogNumber",
            label: strings.labels.techLogNumber,
            required: true,
          },
          {
            controltype: "input",
            name: "observerName",
            label: strings.labels.observerName,
            required: true,
          },
        ],
      };
    },
  };
