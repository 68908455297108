import { Box, Grid } from "@material-ui/core";
import { useCallback, useEffect, useMemo, useState } from "react";
import { SyncStatusRow } from "../../components/offline/SyncStatusRow";
import { useCancelToken } from "../../hooks/general";
import { useServiceAction } from "../../hooks/general/useServiceAction";
import { HooksLogger } from "../../hooks/hooks-logger";
import { syncItems, syncStrings } from "../../resources/strings/offline/sync";
import * as syncApi from "../../services/offline/sync.service";
import { syncStatusWebSocketUrl } from "../../services/offline/sync.service";
import { RouteProps } from "../../types";
import {
  SyncState,
  SyncStatus,
  SyncType
} from "../../types/documents/offline/Sync";
import { useHistory } from "react-router-dom";
import { paths } from "../../navigation";
import useWebSocket from "react-use-websocket";
import { notifications } from "../../libs/notifications";
import { useClients } from "../../hooks/clients/useClients";
import { ClientData } from "../../types/documents";
import { syncItemParameters } from "./resources";

const logger = new HooksLogger("Sync/Status");

interface Props extends RouteProps {
}

type Component = (props: Props) => JSX.Element;

export const Sync: Component = ({ user, jwt }) => {
  const history = useHistory();
  useEffect(() => {
    if (!user) {
      history.push(paths.auth.login);
    }
  }, [history, user]);

  const cancelToken = useCancelToken();

  const [clients, clientsLoading] = useClients({
    userClientId: user?.clientId,
    jwt,
    cancelToken,
  });

  const { lastJsonMessage, sendMessage } = useWebSocket(
    syncStatusWebSocketUrl,
    {
      shouldReconnect: _ => true,
    }
  );

  // Ping server to keep connection alive.
  useEffect(() => {
    const interval = setInterval(() => sendMessage("ping"), 5000);
    return () => clearInterval(interval);
  }, [sendMessage]);

  const syncStatus = lastJsonMessage as SyncStatus | null;
  console.log(syncStatus);

  return (
    <>
      <Box display="flex" flexDirection="row">
        <Grid container spacing={2}>
          {syncItems.map((type) => (
            <SyncItem name={syncStrings.header[type]} syncType={type} status={syncStatus}
                      clients={clients} clientsLoading={clientsLoading} key={type} />
          ))}
        </Grid>
      </Box>
    </>
  );
};

interface SyncItemProps {
  clients: Record<string, ClientData>,
  clientsLoading: boolean,
  name: string;
  syncType: SyncType;
  status: SyncStatus | null;
}

const SyncItem = ({ clients, clientsLoading, name, syncType, status }: SyncItemProps) => {
  const cancelToken = useCancelToken();
  const setError = useCallback(e => notifications.error(e), []);
  const [parameter, setParameter] = useState("");

  const parameters = useMemo(
    () => syncItemParameters[syncType] === "clientId" ? Object.values(clients) : null,
    [syncType, clients]
  );

  const syncNowHandler = useCallback(
    () => syncApi.syncNow(cancelToken, syncType, parameter),
    [cancelToken, syncType, parameter]
  );
  const [syncNow, requestingSync] = useServiceAction<[], void>(
    "Syncing " + name,
    syncNowHandler,
    logger,
    cancelToken,
    () => {
    },
    (error) => {
      setError(error);
    }
  );

  const itemStatus = status?.[`${SyncType[syncType]}-${parameter}`];
  console.log({ status, syncType: SyncType[syncType], parameter, itemStatus });

  const loading =
    !status ||
    requestingSync ||
    itemStatus?.syncState === SyncState.Syncing;

  return (
    <SyncStatusRow
      name={name}
      loading={loading}
      status={itemStatus}
      onSyncNow={syncNow}

      parameters={parameters}
      parametersLoading={clientsLoading}
      selectedParameter={parameter}
      onSelectParameter={setParameter}
    />
  );
};
