import { Dialog, DialogContent } from "@material-ui/core";
import { Feature } from "ol";
import { useEffect, useMemo, useState } from "react";
import { AvoidanceZoneModify } from "../../../containers/mapFeatures";
import { useTagGroup } from "../../../hooks/tags";
import { TAGS_ENDPOINT } from "../../../libs/config";
import { theme } from "../../../styles/theme";
import { RouteProps } from "../../../types";
import { AvoidanceZoneData } from "../../../types/documents";
import { Mode } from "../../general/types/Modify";
import { useGeoJSON } from "./GeoJSON";

interface Props extends RouteProps {
  mode: Mode;
  avoidanceZone?: Feature;
  onSuccess?: (id: string, data: AvoidanceZoneData) => void;
  onClose?: () => void;
}

type Component = (props: Props) => JSX.Element;
export const AvoidanceZoneModal: Component = ({
  avoidanceZone,
  mode,
  onSuccess: _onSuccess,
  onClose,
  ...routeProps
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const format = useGeoJSON();

  const [codes] = useTagGroup(
    [],
    TAGS_ENDPOINT,
    routeProps.jwt,
    "avoidanceZoneCode"
  );
  const [statuses] = useTagGroup(
    [],
    TAGS_ENDPOINT,
    routeProps.jwt,
    "avoidanceZoneStatus"
  );

  const onSuccess = (id: string, data: AvoidanceZoneData) => {
    if (!avoidanceZone) return;

    if (id) {
      const code =
        codes.find((tag) => tag.value === data.code)?.label ?? "Unknown";
      const status =
        statuses.find((tag) => tag.value === data.status)?.label ?? "Unknown";

      avoidanceZone.setProperties({
        id,
        status: data.status,
        displayName: `${code}-${status}`,
      });
    } else {
      avoidanceZone.setGeometry(undefined);
    }

    _onSuccess?.(id, data);
    closeDialog();
  };

  const geometry = useMemo(() => {
    const geom = avoidanceZone?.getGeometry();
    if (!format || !geom) return;

    return format.writeGeometryObject(geom);
  }, [format, avoidanceZone]);

  useEffect(() => {
    const shouldOpenModal = avoidanceZone ? true : false;
    setModalOpen(shouldOpenModal);
  }, [avoidanceZone]);

  const closeDialog = () => {
    onClose?.();
    setModalOpen(false);
  };

  const getPropertiesForSubmit = () => {
    const radius = avoidanceZone?.getProperties().radius;
    return { radius };
  };

  return (
    <Dialog
      open={modalOpen}
      onClose={closeDialog}
      BackdropProps={{ style: { backgroundColor: "transparent" } }}
    >
      <DialogContent style={{ padding: theme.spacing(2) }}>
        {avoidanceZone && geometry && (
          <AvoidanceZoneModify
            mode={mode}
            geometry={geometry}
            properties={getPropertiesForSubmit()}
            id={avoidanceZone.getProperties().id}
            onSuccess={onSuccess}
            {...routeProps}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};
