import { useState } from "react";
import { useHistory } from "react-router-dom";
import BreadcrumbHeader from "../../components/general/BreadcrumbHeader";
import DeleteEditClearSaveButtons from "../../components/general/DeleteEditClearSaveButtons";
import { Props } from "../../components/general/ModifyComponent";
import { useCancelToken } from "../../hooks/general";
import { notifications } from "../../libs/notifications";
import { paths } from "../../navigation";
import { userStrings as strings } from "../../resources/strings";
import { deleteUser as _deleteUser } from "../../services/users.service";
import { UserData } from "../../types/documents";

export default function UserBreadcrumbHeader({
  mode,
  permissions,
  data,
  handleModeSwitch,
}: Props<UserData>) {
  const history = useHistory();
  const cancelToken = useCancelToken();

  const [deleteLoading, setDeleteLoading] = useState(false);

  const getHeaderFromMode = () => {
    switch (mode) {
      case "view":
        return strings.header.viewUser;
      case "create":
        return strings.header.createUser;
      case "update":
        return strings.header.editUser;
    }
  };

  const crumbs = [
    { name: strings.header.users, link: paths.users.list },
    { name: getHeaderFromMode() },
  ];

  const deleteUser = async () => {
    setDeleteLoading(true);
    try {
      await _deleteUser(cancelToken, data.userId);
      notifications.success(strings.notifications.deleted);
      history.push(paths.users.list);
    } catch (error) {
      notifications.error(strings.notifications.failedDelete);
    }
    setDeleteLoading(false);
  };
  return (
    <BreadcrumbHeader crumbs={crumbs}>
      <DeleteEditClearSaveButtons
        mode={mode}
        entity={strings.global.entities.users}
        permissions={permissions}
        deleteFunction={deleteUser}
        deleteLoading={deleteLoading}
        handleModeSwitch={handleModeSwitch}
      />
    </BreadcrumbHeader>
  );
}
