import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";

interface Props {
  fields: Array<{
    label: string;
    value: any;
  }>;
}
type Component = (props: Props) => JSX.Element;

const useStyles = makeStyles((_theme) => ({
  root: { marginBottom: "2rem" },
  label: { fontWeight: "bold" },
  value: {},
}));

export const DetailsView: Component = ({ fields }) => {
  const classes = useStyles();

  const Item: React.FC = ({ children }) => {
    return (
      <Grid item xs={12} md={6}>
        <Box display="flex" justifyContent="space-evenly">
          {children}
        </Box>
      </Grid>
    );
  };

  return (
    <div className={clsx(classes.root)}>
      <Box display="flex" flexDirection="column">
        <Grid container spacing={2} justifyContent="center">
          {fields.map(({ label, value }, index) => (
            <Item key={`${label}-${index}`}>
              <Typography className={clsx(classes.label)}>{label}</Typography>
              <Typography className={clsx(classes.value)}>{value}</Typography>
            </Item>
          ))}
        </Grid>
      </Box>
    </div>
  );
};
