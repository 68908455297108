import {
  Button,
  Checkbox,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Column } from "react-table";
import { columnNames } from "../../resources/GradingReportTemplate";
import { gradingsStrings as strings } from "../../resources/strings/gradings";
import { ProjectData } from "../../types/documents";
import { useComponentList } from "./hooks/useComponentList";
import { useStyles } from "./styles";

interface Props {
  setShowFilterPanel: Dispatch<SetStateAction<boolean>>;
  filters: any;
  setFilters: Dispatch<SetStateAction<any>>;
  columns: Column[];
  project: ProjectData;
  tableData: any[];
}
type Component = (props: Props) => JSX.Element;

export const GradingReportFilterPanel: Component = ({
  setShowFilterPanel,
  setFilters,
  columns,
  project,
  tableData,
}) => {
  const classes = useStyles();

  const [groupings, setGroupings] = useState<string[]>([]);

  const [rangeStart, setRangeStart] = useState("");
  const [rangeEnd, setRangeEnd] = useState("");

  const [component, setComponent] = useState("");
  const [gradings, setGradings] = useState<string[]>([]);

  const { componentList, gradingsMasterList } = useComponentList(columns);

  const applyFilters = () => {
    const filters = [
      { id: columnNames.groupings, value: groupings },
      { id: columnNames.name, value: [rangeStart, rangeEnd] },
      ...(component ? [{ id: component, value: gradings }] : []),
    ];

    setFilters(filters);
  };

  const resetFilters = () => {
    setGroupings([]);
    setRangeStart("");
    setRangeEnd("");
    setComponent("");
    setGradings([]);

    setFilters([]);
  };

  useEffect(() => {
    setGradings([]);
  }, [component]);

  const renderHeading = (heading: string) => (
    <Typography key={heading} className={classes.filterLabel}>
      {heading}
    </Typography>
  );

  const renderDropdown = <T,>(
    value: T,
    setValue: Dispatch<SetStateAction<T>>,
    items: JSX.Element[],
    label?: string
  ) => {
    const multi = Array.isArray(value);
    const selectProps = multi
      ? {
          multiple: true,
          disabled: !items.length,
          renderValue: () =>
            value.length === 0 ? "- select -" : value.join(", "),
        }
      : {};

    return (
      <FormControl className={classes.dropdown}>
        {label && (
          <Typography className={classes.filterLabel}>{label}</Typography>
        )}
        <Select
          value={value}
          onChange={(event) => {
            setValue(event.target.value as T);
          }}
          disableUnderline
          displayEmpty
          IconComponent={ExpandMore}
          {...selectProps}
        >
          <MenuItem disabled={multi} value="">
            - select -
          </MenuItem>

          {items}
        </Select>
      </FormControl>
    );
  };

  const renderMultiSelectDropdownOption = (name: string, values: string[]) => {
    return (
      <MenuItem key={name} value={name}>
        <Checkbox
          key={`${name}-checkbox`}
          color="primary"
          checked={values.includes(name)}
        />
        {name}
      </MenuItem>
    );
  };

  const assetNameList = tableData
    .map((grading) => grading.assetContainer.name)
    .sort()
    .map((name) => (
      <MenuItem key={name} value={name}>
        {name}
      </MenuItem>
    ));

  const groupingsList = project.selectedGroups
    .sort()
    .map((name) => renderMultiSelectDropdownOption(name, groupings));

  const componentOptions = componentList.map((c, index) => {
    return (
      <MenuItem
        disabled={c.isHeader}
        className={c.isHeader ? classes.dropdownGroup : ""}
        key={
          c.isHeader
            ? `component-list-header-${c.title}-${index}`
            : `component-list-option-${c.accessor}-${index}`
        }
        value={c.accessor}
      >
        {c.title}
      </MenuItem>
    );
  });

  const gradingsList =
    component &&
    gradingsMasterList[component].map(({ caption }: { caption: string }) =>
      renderMultiSelectDropdownOption(caption, gradings)
    );

  return (
    <>
      <div className={classes.filterTitle}>
        <span>{strings.filterPanel.panelHeading}</span>
        <IconButton color="primary" onClick={() => setShowFilterPanel(false)}>
          <CloseIcon />
        </IconButton>
      </div>

      {renderHeading(project.groupedBy)}
      {renderDropdown(groupings, setGroupings, groupingsList)}

      {renderHeading(strings.filterPanel.assetRange)}
      {renderDropdown(
        rangeStart,
        setRangeStart,
        assetNameList,
        strings.filterPanel.from
      )}
      {renderDropdown(
        rangeEnd,
        setRangeEnd,
        assetNameList,
        strings.filterPanel.to
      )}

      {renderHeading(strings.filterPanel.component)}
      {renderDropdown(component, setComponent, componentOptions)}

      {renderHeading(strings.filterPanel.grading)}
      {renderDropdown(gradings, setGradings, gradingsList)}

      <Button
        variant="contained"
        color="primary"
        className={clsx(classes.button, classes.applyButton)}
        onClick={() => applyFilters()}
      >
        {strings.filterPanel.applyButton}
      </Button>
      <Button
        variant="outlined"
        color="primary"
        className={clsx(classes.button, classes.resetButton)}
        onClick={() => resetFilters()}
      >
        {strings.filterPanel.resetButton}
      </Button>
    </>
  );
};
