import { makeStyles, Typography } from "@material-ui/core";
import type { Header as THeader } from "../types/Modify";

interface Props {
  config: THeader;
}
type Component = (props: Props) => JSX.Element;

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    fontSize: "1.3rem",
    fontWeight: "bold",
  },
}));

export const Header: Component = ({ config }) => {
  const classes = useStyles();

  const { text } = config;
  return (
    <Typography className={classes.root} {...config}>
      {text}
    </Typography>
  );
};
