import { MapContainer } from "../../components/map/MapContainer";
import { OSMLayer } from "../../components/map/layers/OSMLayer";
import { ClientTileLayer } from "../../components/map/layers/ClientTileLayer";
import { Grid, Typography } from "@material-ui/core";
import React from "react";

export interface Props {
  data: {
    id: string,
  },
}

type Component = (props: Props) => JSX.Element;

export const ShowClientTiles: Component = ({ data }) => {
  const { id: clientId } = data;

  return <>
    <Grid
      container
      direction="row"
      justifyContent="space-between"
    >
      <Grid item>
        <Typography variant="h6">Processed Tiles</Typography>
      </Grid>
    </Grid>
    <div style={{ height: "720px" }}>
      <MapContainer header={false}>
        <OSMLayer name={"OpenStreetMap"} defaultVisible={true}/>
        <ClientTileLayer name={"Client Tiles"} clientId={clientId} zoomToExtent={true}/>
      </MapContainer>
    </div>
  </>
}
