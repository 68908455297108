import { Dialog, DialogContent, makeStyles } from "@material-ui/core";
import { Dispatch, SetStateAction } from "react";
import { ImageMetadata } from "../../../../types/documents";
import { ZoomableImage } from "./ZoomableImage/ZoomableImage";

interface Props {
  open: boolean;
  onClose: () => void;

  imgData: ImageMetadata[];
  selectedIndex: number;
  setSelectedIndex: Dispatch<SetStateAction<number>>;
}

type Component = (props: Props) => JSX.Element;

export const ImageModal: Component = ({
  imgData,
  selectedIndex,
  setSelectedIndex,
  open,
  onClose,
}) => {
  const classes = useStyles();

  const selectedImage = imgData[selectedIndex];

  const hasPrev = selectedIndex > 0;
  const hasNext = selectedIndex < imgData.length - 1;

  const onPrev = () => setSelectedIndex((i) => --i);
  const onNext = () => setSelectedIndex((i) => ++i);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl">
      <DialogContent className={classes.dialog}>
        <ZoomableImage
          selectedImage={selectedImage}
          hasNext={hasNext}
          hasPrev={hasPrev}
          onNext={onNext}
          onPrev={onPrev}
        />
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles(() => ({
  dialog: {
    padding: "4px !important",
  },
}));
