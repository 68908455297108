import axios from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useCancelToken } from "../../hooks/general";
import { HooksLogger } from "../../hooks/hooks-logger";
import { useTagGroup } from "../../hooks/tags";
import { Axios } from "../../libs/axios";
import { CLIENTS_ENDPOINT, TAGS_ENDPOINT } from "../../libs/config";
import { ClientData } from "../../types/documents";

const logger = new HooksLogger("UserModify/getRoles");

const clientsEndpoint = `${CLIENTS_ENDPOINT}/list-clients`;
const assessmentTypesEndpoint = `${TAGS_ENDPOINT}`;

type Option = {
  key: string;
  text: string;
  value: string;
};

type Result = [Option[], Option[], boolean];

export const useFormDesignerData = (jwt: string): Result => {
  const [clients, setClients] = useState<Option[]>([]);
  const [assessmentTypes, setAssessmentTypes] = useState<Option[]>([]);
  const [clientsLoading, setClientsLoading] = useState(false);
  const cancelToken = useCancelToken();

  const [options, tagLoading] = useTagGroup(
    [],
    assessmentTypesEndpoint,
    jwt,
    "type"
  );

  const loading = useMemo(
    () => tagLoading || clientsLoading,
    [tagLoading, clientsLoading]
  );

  const memoOptions = useMemo(() => options, [options]);

  useEffect(() => {
    setAssessmentTypes(
      memoOptions.map((option) => {
        return { key: option.value, text: option.label, value: option.value };
      })
    );
  }, [memoOptions]);

  const getData = useCallback((config: Axios.AxiosRequestConfig) => {
    return onloadData(config);
  }, []);

  useEffect(() => {
    const config: Axios.AxiosRequestConfig = {
      cancelToken,
      headers: {
        Authorization: jwt,
      },
    };

    const query = async () => {
      try {
        setClientsLoading(true);
        logger.request("Getting data");
        const results = await getData(config);
        setClients(results);
        logger.success();
        setClientsLoading(false);
      } catch (e: any) {
        if (cancelToken.reason) return;
        const error = e.message ? e.message : e;
        logger.error(error);
        setClientsLoading(false);
      }
    };

    query();
  }, [jwt, cancelToken, getData]);

  const onloadData = async (
    config: Axios.AxiosRequestConfig
  ): Promise<Option[]> => {
    const clientData = await axios.post<{ items: Array<ClientData> }>(
      clientsEndpoint,
      {},
      config
    );

    return clientData.data.items.map((client) => {
      return { key: client.id, text: client.name, value: client.id };
    });
  };

  return [clients, assessmentTypes, loading];
};
