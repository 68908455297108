import { useHistory } from "react-router-dom";

import { USERS_ENDPOINT } from "../../libs/config";
import { RouteProps } from "../../types";
import type { UserData } from "../../types/documents";
import { TableContainer } from "../general";

import { paths } from "../../navigation";
import { userStrings as strings } from "../../resources/strings";

import { Column } from "react-table";
import { formatDateTime } from "../../helpers";
import { useIsAuthorised } from "../../hooks/authentication";

interface Props extends RouteProps {}
type Component = (props: Props) => JSX.Element;

const endpoint = `${USERS_ENDPOINT}/list-users`;

export const UserList: Component = ({ jwt, ...routeProps }) => {
  const history = useHistory();

  const showClients = useIsAuthorised(
    routeProps.permissions,
    strings.global.entities.clients,
    strings.global.permissions.read
  );

  const clickHandler = ({ userId }: UserData) => {
    history.push(paths.users.view(userId));
  };

  return (
    <div>
      <TableContainer<UserData>
        {...routeProps}
        endpoint={endpoint}
        jwt={jwt}
        header={strings.header.users}
        tableColumns={[
          {
            id: "username",
            Header: strings.labels.username,
            accessor: "username",
          },
          ...(showClients
            ? [
                {
                  id: "client",
                  Header: strings.labels.client,
                  accessor: "clientName",
                } as Column<UserData>,
              ]
            : []),
          {
            id: "role",
            Header: strings.labels.role,
            accessor: "roleName",
          },
          {
            id: "forename",
            Header: strings.labels.forename,
            accessor: "forename",
          },
          {
            id: "surname",
            Header: strings.labels.surname,
            accessor: "surname",
          },
          {
            id: "email",
            Header: strings.labels.email,
            accessor: "email",
          },
          {
            id: "created",
            Header: strings.labels.created,
            accessor: ({ created }) => formatDateTime(created),
          },
          {
            id: "updated",
            Header: strings.labels.updated,
            accessor: ({ updated }) => formatDateTime(updated),
          },
        ]}
        createButtonConfig={{
          label: strings.labels.createUser,
          userAccess: {
            entity: strings.global.entities.users,
            permission: strings.global.permissions.create,
          },
          path: paths.users.create,
        }}
        clickHandler={clickHandler}
      />
    </div>
  );
};
