import { Button, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { ShowIfAuthorised } from "../../components/authentication";
import BreadcrumbHeader from "../../components/general/BreadcrumbHeader";
import { Props } from "../../components/general/ModifyComponent";
import { defaultButtonStyle, deleteButtonStyle } from "../../components/styles";
import { flightStrings as strings } from "../../resources/strings";
import { FlightData } from "../../types/documents";

const useStyles = makeStyles((theme) => ({
  all: defaultButtonStyle(theme),
  border: { border: theme.spacing(0.3, "solid") },
  delete: deleteButtonStyle(theme),
}));

export default function FlightBreadcrumbHeader({
  mode,
  permissions,
  data,
  jwt,
  handleModeSwitch,
}: Props<FlightData>) {
  const classes = useStyles();

  const getHeaderFromMode = () => {
    switch (mode) {
      case "view":
        return strings.header.viewFlight;
      case "create":
        return strings.header.createFlight;
      case "update":
        return strings.header.editFlight;
    }
  };

  const crumbs = [
    {
      name: getHeaderFromMode(),
    },
  ];

  const renderSaveButtons = () => {
    return (
      <>
        <Button
          type="reset"
          className={clsx(classes.all, classes.border)}
          color="primary"
          variant="outlined"
          title="clear"
        >
          {strings.labels.clear}
        </Button>
        <Button
          color="primary"
          className={clsx(classes.all)}
          variant="contained"
          type="submit"
          title="save"
        >
          {strings.labels.save}
        </Button>
      </>
    );
  };

  const renderEditButtons = () => {
    return (
      <>
        <ShowIfAuthorised
          entity={strings.global.entities.flights}
          permission={strings.global.permissions.update}
          userPermissions={permissions}
        >
          <Button
            className={clsx(classes.all)}
            color="primary"
            variant="contained"
            onClick={handleModeSwitch}
            title="edit"
          >
            {strings.labels.edit}
          </Button>
        </ShowIfAuthorised>
      </>
    );
  };

  const renderContent = () => {
    return mode === "view" ? renderEditButtons() : renderSaveButtons();
  };

  return <BreadcrumbHeader crumbs={crumbs}>{renderContent()}</BreadcrumbHeader>;
}
