import { Feature } from "ol";
import { Extent } from "ol/extent";
import { LineString } from "ol/geom";
import { WebGLRenderProgram } from "../../helpers/webgl/WebGLProgram";

/**
 * This program draws LineString features using WebGL Lines.
 *
 * Conceptually, WebGL is a pipeline of the shape:
 * InputVertices -> ClipSpaceCoordinates -> PixelCoordinates -> Image
 *
 * Input vertices are a collection of attributes. Each attribute has a descriptor,
 * which describes which buffer to pull the data out of and from which index.
 * In this program, all attributes are written into a single buffer sequentially.
 *
 * The vertex shader is then executed for each input vertex to produce clipspace
 * coordinates (by writing to gl_Position). Clipspace coordinates should be
 * in range the range -1 to 1. Points outside of this range are outside of the
 * screen.
 * In addition the vertex shader can output attributes that will be passed
 * along to later stages of the pipeline.
 *
 *
 * Determining from the clip space coordinates to pixels that need to be drawn
 * is done by the Driver/GPU based on the drawing mode. This program uses the
 * LINES drawing mode, which groups the vertices into pairs and draws a 1px line
 * between the points in each pair.
 *
 * Here we make use of indexed vertices, which store the attributes in one buffer
 * and indexes for the points in a second buffer. This avoids needing to
 * duplicate points in the middle of a LineString.
 * For example, for a line string connecting A--B--C
 * instead of using a vertex buffer [...A, ...B, ...B, ...C],
 * we can use a vertex buffer [...A, ...B, ...C] with an index buffer [0, 1, 1, 2].
 *
 * This part also uses a depth buffer to decide for each pixel if a line should
 * draw over another line or not.
 *
 *
 * Finally, for every pixel the fragment shader is run. It receives as input the
 * output attributes from the vertex shader and has to assign the outColor
 * output attribute. For pixels in the middle of the line, the inputs are interpolated
 * between the outputs for the 2 vertices that formed the line.
 */

// language=GLSL
export const vertexShader = `#version 300 es

in vec2 position;
in float transmissionVoltage;

// [minResolution, maxResolution]
in vec2 minMaxResolution;

out float fragmentTransmissionVoltage;

// [minX, minY, maxX, maxY]
uniform vec4 extent;
uniform float resolution;

void main() {
    vec2 extentMin = extent.xy;
    vec2 extentMax = extent.zw;

    vec2 extentSize = extentMax - extentMin;

    // Calculate the position within the extent.
    // Points inside the extent end up in the range 0..1
    vec2 positionInExtent = (position - extentMin) / extentSize;
    // Map the 0..1 range to -1..1
    vec2 positionInClipSpace = positionInExtent * 2.0 - 1.0;

    bool shouldHide = resolution < minMaxResolution.x || resolution > minMaxResolution.y;

    // Use transmissionVoltage as z index.
    // Z is expected to go from 0 to 1.0
    float zIndex = transmissionVoltage / 4096.0;
    if (shouldHide) {
        // zIndex outisde -1..1 are not drawn.
        zIndex = -10.0;
    }

    gl_Position = vec4(positionInClipSpace, zIndex, 1);

    fragmentTransmissionVoltage = transmissionVoltage;
}
`;

// language=GLSL
export const fragmentShader = `#version 300 es

precision highp float;

in float fragmentTransmissionVoltage;

out vec4 outColor;

void main() {
    vec3 voltageColor;
    if (fragmentTransmissionVoltage <= 20.0) {
        voltageColor = vec3(0x15, 0x65, 0xc0);
    } else if (fragmentTransmissionVoltage <= 33.0) {
        voltageColor = vec3(0x4c, 0xaf, 0x50);
    } else if (fragmentTransmissionVoltage <= 132.0) {
        voltageColor = vec3(0xff, 0xa7, 0x26);
    } else if (fragmentTransmissionVoltage <= 400.0) {
        voltageColor = vec3(0xb7, 0x1c, 0x1c);
    } else {
        voltageColor = vec3(0x00, 0x00, 0x00);
    }
    outColor = vec4(voltageColor, 0xFF) / 255.0;
}
`;

export class WebGLLinesProgram extends WebGLRenderProgram {
  private lineVertexCount: number = 0;

  constructor(webgl: WebGL2RenderingContext) {
    super(webgl, {
      attributes: [
        { name: "position", type: "vec2" },
        { name: "transmissionVoltage", type: "float" },
        { name: "minMaxResolution", type: "vec2" },
      ],
      uniforms: [
        { name: "extent", type: "vec4" },
        { name: "resolution", type: "float" },
      ],
      vertexShader,
      fragmentShader,
    });
  }

  public setFeatures(features: Feature[]) {
    let numPoints = 0;
    let numLines = 0;
    for (const feature of features) {
      const geometry = feature.getGeometry();
      if (geometry instanceof LineString) {
        // Each LineString consists of n points and (n-1) lines connecting them.
        const coordinates = geometry.getCoordinates();
        if (coordinates.length < 2) continue;
        numPoints += coordinates.length;
        numLines += coordinates.length - 1;
      } else {
        // ignore.
      }
    }

    // Allocate 5 floats per point.
    const pointData = new Float32Array(numPoints * 5);
    // Allocate 2 indexes into pointData per line.
    const lineIndexes = new Uint32Array(numLines * 2);
    this.lineVertexCount = numLines * 2;

    let pointIndex = 0;
    let lineIndexesIndex = 0;
    for (const feature of features) {
      const geometry = feature.getGeometry();

      let transmissionVoltage = this.getNumberFeatureProperty(
        feature,
        "transmissionVoltage"
      );
      if (transmissionVoltage > 4096) {
        // Some shape files contain the voltage as kV, some as V.
        // Assuming there won't be any lines over 5MV, we can assume any value
        // larger than 5000 is given in V and should be divided by 1000
        // for colour / z-index.
        transmissionVoltage /= 1000;
      }

      const minResolution = this.getNumberFeatureProperty(
        feature,
        "minResolution"
      );
      const maxResolution = this.getNumberFeatureProperty(
        feature,
        "maxResolution"
      );

      if (geometry instanceof LineString) {
        const coordinates = geometry.getCoordinates();
        if (coordinates.length < 2) continue;

        let firstPoint = true;
        for (const point of coordinates) {
          if (firstPoint) {
            firstPoint = false;
          } else {
            lineIndexes[lineIndexesIndex * 2] = pointIndex - 1;
            lineIndexes[lineIndexesIndex * 2 + 1] = pointIndex;
            lineIndexesIndex += 1;
          }

          pointData[pointIndex * 5] = point[0];
          pointData[pointIndex * 5 + 1] = point[1];
          pointData[pointIndex * 5 + 2] = transmissionVoltage;
          pointData[pointIndex * 5 + 3] = minResolution;
          pointData[pointIndex * 5 + 4] = maxResolution;
          pointIndex += 1;
        }
      } else {
        // ignore.
      }
    }

    this.webgl.bindBuffer(this.webgl.ARRAY_BUFFER, this.vertexBuffer);
    this.webgl.bufferData(
      this.webgl.ARRAY_BUFFER,
      pointData,
      this.webgl.STATIC_DRAW
    );
    this.webgl.bindBuffer(this.webgl.ELEMENT_ARRAY_BUFFER, this.indexBuffer);
    this.webgl.bufferData(
      this.webgl.ELEMENT_ARRAY_BUFFER,
      lineIndexes,
      this.webgl.STATIC_DRAW
    );
  }

  private getNumberFeatureProperty(feature: Feature, property: string): number {
    let value = feature.get(property) ?? 0.0;
    value = Number.parseFloat(value);
    if (Number.isNaN(value)) {
      value = 0.0;
    }
    return value;
  }

  public render([minX, minY, maxX, maxY]: Extent, resolution: number) {
    this.webgl.useProgram(this.program);
    this.webgl.uniform4f(
      this.uniforms.extent!.location,
      minX,
      minY,
      maxX,
      maxY
    );
    this.webgl.uniform1f(this.uniforms.resolution!.location, resolution);
    this.webgl.bindVertexArray(this.vertexArray);
    this.webgl.drawElements(
      this.webgl.LINES,
      this.lineVertexCount,
      this.webgl.UNSIGNED_INT,
      0
    );
    this.webgl.bindVertexArray(null);
  }
}
