import { useEffect, useState } from "react";
import { useRefreshToken } from "./useRefreshToken";
import { HooksLogger } from "../hooks-logger";
import { CancelToken } from "axios";

export const useServiceData = <D>(
  logName: string,
  serviceCall: () => Promise<D>,
  logger: HooksLogger,
  cancelToken: CancelToken,
  onError?: (error: string) => void,
  interval?: number,
): [D | undefined, boolean, () => void] => {
  const [data, setData] = useState<D>();
  const [loading, setLoading] = useState(false);
  const [token, refresh] = useRefreshToken();
  // const doRefresh = useRef
  useEffect(() => {
    let stop = false;
    let timeout = setTimeout(query, 0);

    return () => {
      stop = true;
      clearTimeout(timeout);
    };

    async function query() {
      logger.request(logName);
      setLoading(true);
      try {
        const data = await serviceCall();

        // component got unmounted or parameters got changed during serviceCall(),
        // so don't call setData with possible outdated data.
        if (stop) return;

        setData(data);
        setLoading(false);
        logger.success(logName, data);
      } catch (e: any) {
        if (cancelToken.reason) return;
        const error = e.message ? e.message : e;
        setLoading(false);
        logger.error(logName, error);
        onError && onError(error)
      }

      if (interval && !stop) {
        timeout = setTimeout(query, interval);
      }
    }
  }, [serviceCall, token, cancelToken, logName, logger, onError, interval]);

  return [data, loading, refresh];
};
