import { Checkbox, makeStyles } from "@material-ui/core";
import { SubjectOutlined as NotesIcon } from "@material-ui/icons";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { BOOKINGS_ENDPOINT } from "../../libs/config";
import { paths } from "../../navigation";
import { bookingStrings as strings } from "../../resources/strings";
import { RouteProps } from "../../types";
import type { BookingData } from "../../types/documents";
import { TableContainer } from "../general";
import BookingFilterBar from "./BookingFilterBar";

interface Props extends RouteProps {
  embedded?: boolean;
}
type Component = (props: Props) => JSX.Element;

const useStyles = makeStyles((theme) => ({
  noteIcon: {
    border: theme.spacing(0.25, "solid", `${theme.accents.primary}`),
    borderRadius: theme.spacing(0.375),
    color: theme.accents.primary,
    fontSize: theme.spacing(2.5),
    height: theme.spacing(2.75),
  },
  checkbox: {
    color: theme.palette.primary.main,
    "&.Mui-checked": {
      color: theme.palette.primary.main,
    },
    "& .MuiSvgIcon-root": {
      fontSize: theme.spacing(4),
    },
  },

  //   Active: {
  //     fontSize: theme.spacing(1.25),
  //     color: theme.status.active,
  //   },
  //   Cancelled: {
  //     fontSize: theme.spacing(1.25),
  //     color: theme.status.cancelled,
  //   },
  //   Completed: {
  //     fontSize: theme.spacing(1.25),
  //     color: theme.status.completed,
  //   },
}));

export const BookingList: Component = ({ embedded, ...routeProps }) => {
  const endpoint = BOOKINGS_ENDPOINT;

  const classes = useStyles();

  const history = useHistory();

  const clickHandler = ({ id }: BookingData) => {
    history.push(paths.bookings.view(id));
  };

  return (
    <div>
      <TableContainer<BookingData>
        {...routeProps}
        endpoint={endpoint}
        rowHighlightStyling={false}
        header={strings.header.bookings}
        tableColumns={[
          {
            id: "firstName",
            Header: strings.labels.firstName,
            accessor: "firstName",
          },
          {
            id: "lastName",
            Header: strings.labels.lastName,
            accessor: "lastName",
          },
          {
            id: "startDate",
            Header: strings.labels.startDate,
            accessor: "startDate",
          },
          {
            id: "route",
            Header: strings.labels.route,
            accessor: "route",
          },
          {
            id: "numberOfBags",
            Header: strings.labels.numberOfBags,
            accessor: "numberOfBags",
          },
          {
            id: "type",
            Header: strings.labels.type,
            accessor: "type",
          },
          {
            id: "referenceNumber",
            Header: strings.labels.referenceNumber,
            accessor: "referenceNumber",
          },
          {
            id: "tourAgency",
            Header: strings.labels.tourAgency,
            accessor: "tourAgency",
          },
          {
            id: "notes",
            Header: strings.labels.notes,
            accessor: ({ notes }) =>
              notes && <NotesIcon className={clsx(classes.noteIcon)} />,
            centered: true,
          },
          {
            id: "tagsCreated",
            Header: strings.labels.tagsCreated,
            accessor: ({ tagsCreated }) => (
              <Checkbox
                className={clsx(classes.checkbox)}
                defaultChecked={tagsCreated}
              />
            ),
            centered: true,
          },
          {
            id: "status",
            Header: strings.labels.status,
            accessor: ({ status }) => {
              return (
                <>
                  {/* <Circle className={clsx(classes[status])} /> */}
                  {"   "}
                  {status}
                </>
              );
            },
          },
        ]}
        clickHandler={clickHandler}
        createButtonConfig={{
          label: strings.labels.newBooking,
          path: paths.bookings.create,
          userAccess: {
            entity: strings.global.entities.bookings,
            permission: strings.global.permissions.create,
          },
        }}
        //filters={filters}
        manualPagination
      >
        <BookingFilterBar />
      </TableContainer>
    </div>
  );
};
