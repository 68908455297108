import { Divider, Tooltip, Typography } from "@material-ui/core";
import {
  CallMadeOutlined as ArrowIcon,
  ChangeHistoryOutlined as PolygonIcon,
  CheckBoxOutlineBlank as SquareIcon,
  ClearOutlined as ClearIcon,
  DeleteOutline as DeleteIcon,
  GestureOutlined as FreehandIcon,
  PlaceOutlined as PointIcon,
  RadioButtonUncheckedOutlined as CircleIcon,
  RemoveOutlined as LineIcon,
  StraightenOutlined as RulerIcon,
  TextFieldsOutlined as TextIcon,
} from "@material-ui/icons";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { mapStrings as strings } from "../../../resources/strings";
import { useStyles } from "./styles";

interface Props {
  selectedLayer: string;
  drawTool: string;
  setDrawTool: (tool: string) => void;
}

type Component = (props: Props) => JSX.Element;

export const DrawToolBar: Component = ({
  selectedLayer,
  drawTool,
  setDrawTool,
}) => {
  const classes = useStyles();

  const handleChange = (_event: React.MouseEvent, newValue: string) => {
    setDrawTool(newValue);
  };

  const tools = {
    [strings.layers.avoidanceZone]: [
      { name: strings.drawTools.freehand, icon: <FreehandIcon /> },
      { name: strings.drawTools.polygon, icon: <PolygonIcon /> },
      { name: strings.drawTools.circle, icon: <CircleIcon /> },
    ],
    [strings.layers.pointOfInterest]: [
      { name: strings.drawTools.point, icon: <PointIcon /> },
    ],
    [strings.layers.markup]: [
      { name: strings.drawTools.ruler, icon: <RulerIcon /> },
      { name: strings.drawTools.pencil, icon: <FreehandIcon /> },
      { name: strings.drawTools.line, icon: <LineIcon /> },
      { name: strings.drawTools.arrow, icon: <ArrowIcon /> },
      { name: strings.drawTools.polygon, icon: <PolygonIcon /> },
      { name: strings.drawTools.circle, icon: <CircleIcon /> },
      { name: strings.drawTools.square, icon: <SquareIcon /> },
      { name: strings.drawTools.text, icon: <TextIcon /> },
      { name: strings.drawTools.divider },
      { name: strings.drawTools.delete, icon: <DeleteIcon /> },
      {
        name: strings.drawTools.clear,
        icon: <ClearIcon style={{ color: "#CC3333" }} />,
      },
    ],
    [strings.layers.gps]: [],
  };

  const getButton = (tool: { name: string; icon?: JSX.Element }) => {
    if (tool.name === strings.drawTools.divider) {
      return (
        <Divider
          orientation="vertical"
          flexItem
          classes={{ root: classes.divider }}
        />
      );
    }

    return (
      <ToggleButton
        value={tool.name}
        key={tool.name}
        classes={{ root: classes.toggleButton }}
      >
        <Tooltip title={<Typography>{tool.name}</Typography>}>
          {tool.icon || <span>{tool.name}</span>}
        </Tooltip>
      </ToggleButton>
    );
  };

  return (
    <ToggleButtonGroup exclusive value={drawTool} onChange={handleChange}>
      {tools[selectedLayer].map((tool) => {
        return getButton(tool);
      })}
    </ToggleButtonGroup>
  );
};
