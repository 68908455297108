import { CancelToken } from "axios";
import { API } from "../API";
import { PROJECTS_ENDPOINT } from "../libs/config";
import { ProjectData } from "../types/documents";

const baseUrl = PROJECTS_ENDPOINT;

export const getProject = async (
  jwt: string,
  cancelToken: CancelToken,
  id: string
) => {
  const api = new API<ProjectData>(cancelToken, jwt);

  return api.get(`${baseUrl}/${id}`);
};
