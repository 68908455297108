import {
  ApiContextProvider,
  EntityListTableContainer,
} from "@pulsion/forms-designer-2";
import { useHistory, useLocation } from "react-router-dom";
import { paths } from "../../navigation";
import { buttonConfigListApi } from "./api";
import { tableColumns } from "./tableColumns";
import { entityListTheme } from "./themes/buttonConfigurationList";
import { ButtonConfigData } from "./types";

export const ButtonConfigurationList = () => {
  const history = useHistory();
  const location = useLocation();

  const appendHistory = (url: string) => {
    history.push(url);
  };

  return (
    <>
      <ApiContextProvider api={buttonConfigListApi}>
        <EntityListTableContainer<ButtonConfigData>
          key={location.key}
          entity="Button Configuration"
          appendHistory={appendHistory}
          createButtonConfig={{
            label: "Create Button Configuration",
            path: paths.formDesigner.create,
          }}
          inputTheme={entityListTheme}
          manualPagination
          deleteButtons
          viewButtons
          getViewEntityPath={(rowObject: ButtonConfigData) =>
            paths.formDesigner.edit(rowObject.id)
          }
          header={"Button Configurations"}
          tableColumns={tableColumns}
        ></EntityListTableContainer>
      </ApiContextProvider>
    </>
  );
};
