import { toStringHDMS, toStringXY } from "ol/coordinate";
import proj4 from "proj4";
import { mapStrings as strings } from "../../../resources/strings";

const ukTiles = [
  ["SV", "SW", "SX", "SY", "SZ", "TV", "TW"],
  ["SQ", "SR", "SS", "ST", "SU", "TQ", "TR"],
  ["SL", "SM", "SN", "SO", "SP", "TL", "TM"],
  ["SF", "SG", "SH", "SJ", "SK", "TF", "TG"],
  ["SA", "SB", "SC", "SD", "SE", "TA", "TB"],
  ["NV", "NW", "NX", "NY", "NZ", "OV", "OW"],
  ["NQ", "NR", "NS", "NT", "NU", "OQ", "OR"],
  ["NL", "NM", "NN", "NO", "NP", "OL", "OM"],
  ["NF", "NG", "NH", "NJ", "NK", "OF", "OG"],
  ["NA", "NB", "NC", "ND", "NE", "OA", "OB"],
  ["HV", "HW", "HX", "HY", "HZ", "JV", "JW"],
  ["HQ", "HR", "HS", "HT", "HU", "JQ", "JR"],
  ["HL", "HM", "HN", "HO", "HP", "JL", "JM"],
];

const ieTiles = [
  ["V", "W", "X", "Y", "Z"],
  ["Q", "R", "S", "T", "U"],
  ["L", "M", "N", "O", "P"],
  ["F", "G", "H", "J", "K"],
  ["A", "B", "C", "D", "E"],
];

export const convertPoint = proj4;

const getBritishGridCoords = (coords: number[], src: string) => {
  const [x, y] = convertPoint(src, strings.projections.EPSG_27700, coords);
  if (x > 0 && x < 700000 && y > 0 && y < 1300000) {
    return [x, y];
  }
};

const getIrishGridCoords = (coords: number[], src: string) => {
  const [x, y] = convertPoint(src, strings.projections.EPSG_29903, coords);
  if (x > 0 && x < 500000 && y > 0 && y < 500000) {
    return [x, y];
  }
};

const getOffset = (tile: string, grid: string[][]) => {
  for (let row = 0; row < grid.length; row++) {
    for (let col = 0; col < grid[0].length; col++) {
      if (grid[row][col] === tile) return [col, row];
    }
  }
};

export const gridRefToCoords = (ref: string) => {
  ref = ref.toUpperCase().replaceAll(" ", "");

  // No. of letters tell us whether grid ref is British or Irish
  const chars = ref.match(/[A-Z]/g)?.length;
  const type = chars === 2 ? "British" : "Irish";

  const tile = ref.slice(0, chars);
  const position = ref.slice(chars);

  const tiles = chars === 2 ? ukTiles : ieTiles;
  const xy = getOffset(tile, tiles);

  const xPos = position.slice(0, position.length / 2).padEnd(5, "0");
  const yPos = position.slice(position.length / 2).padEnd(5, "0");

  if (xy) {
    const x = xy[0] * 100000 + parseInt(xPos);
    const y = xy[1] * 100000 + parseInt(yPos);
    return { type, coords: [x, y] };
  }
  return { type: "", coords: [-1, -1] };
};

const getGridReference = (coords: number[], grid: string[][]) => {
  const [x, y] = coords;

  const xTile = Math.floor(x / 100000);
  const yTile = Math.floor(y / 100000);

  const xPos = Math.floor(x % 100000)
    .toString()
    .padStart(5, "0");
  const yPos = Math.floor(y % 100000)
    .toString()
    .padStart(5, "0");

  const tile = grid[yTile][xTile];

  return `${tile} ${xPos} ${yPos}`;
};

export const getFormattedCoords =
  (srcProjection: string) => (coords: number[] | undefined) => {
    if (!coords) return "";

    const xy = toStringXY(coords, 5);
    const latlong = toStringHDMS(coords, 2);
    const gbCoords = getBritishGridCoords(coords, srcProjection);
    const ieCoords = getIrishGridCoords(coords, srcProjection);

    let output = `XY: ${xy}<br>Lat, Long: ${latlong}<br>`;

    if (gbCoords) {
      const gbTile = getGridReference(gbCoords, ukTiles);
      output += `British Grid: ${gbTile}<br>`;
    }

    if (ieCoords) {
      const ieTile = getGridReference(ieCoords, ieTiles);
      output += `Irish Grid: ${ieTile}<br>`;
    }

    return output;
  };
