import { appStrings } from "./app";

export const flightStrings = {
  global: { ...appStrings },
  header: {
    flights: "Flights",
    flightDetails: "Flight Details",
    createFlight: "Create Flight",
    viewFlight: "View Flight",
    editFlight: "Edit Flight",
    packages: "Packages",
  },
  labels: {
    flightType: "Flight Type",
    techLogNumber: "Tech Log Number",
    observerName: "Observer Name",
    save: "Save",
    reset: "Reset",
    edit: "Edit",
    delete: "Delete",
    dateCreated: "Date Created",
    createdBy: "Created By",
    lastUpdated: "Last Updated",
    lastUpdatedBy: "Updated By",
    description: "Description",
    clear: "Clear",
  },
  messages: {},
  notifications: {},
};
