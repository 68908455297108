import { useCallback, useContext, useEffect, useMemo } from "react";
import {
  Loading,
  usePackagePointsData,
} from "../../../hooks/map/usePointsData";
import { useWebGlPointsLayer } from "../../../hooks/map/useWebGlPointsLayer";
import { mapStrings as strings } from "../../../resources/strings";
import { AssetGeoJson } from "../../../types/documents/Assets";
import { useGeoJSON } from "../helpers/GeoJSON";
import { assetDefectStatusStyle } from "../helpers/Style";
import MapContext from "../MapContext";

interface Props {
  id: string;
  jwt: string;
  name: string;
  setLoading?: (v: boolean) => void;
  flightId: string | null;
}

type Component = (props: Props) => JSX.Element;

export const PackageLayer: Component = ({
  jwt,
  name,
  id,
  flightId,
  setLoading,
}) => {
  const defectLayerProperties = useMemo(() => {
    return {
      title: strings.layers.status(name),
      layerType: strings.layerTypes.webgl,
    };
  }, [name]);

  const defectStyle = useMemo(() => {
    return assetDefectStatusStyle;
  }, []);

  const map = useContext(MapContext);
  const format = useGeoJSON();
  const [, , packageAddDataToSource] = useWebGlPointsLayer({
    map,
    format,
    style: defectStyle,
    properties: defectLayerProperties,
  });

  const addDataToSource = useCallback(
    (data: AssetGeoJson) => {
      packageAddDataToSource(data);
    },
    [packageAddDataToSource]
  );

  const [loadingPointData] = usePackagePointsData({
    id,
    jwt,
    addDataToSource,
  });

  useEffect(() => {
    if (!setLoading) return;
    setLoading(loadingPointData === Loading.Loading);
  }, [loadingPointData, setLoading]);

  return <></>;
};
