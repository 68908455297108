import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { responsesToOptionsMapper } from "../../../../../helpers";
import { useCancelToken } from "../../../../../hooks/general";
import { HooksLogger } from "../../../../../hooks/hooks-logger";
import { listClients } from "../../../../../services/clients.service";
import { getFlightsByClient } from "../../../../../services/flights.service";
import { DropdownMappings, DropdownOptions } from "../../../../../types";

const logger = new HooksLogger("BucketSelect/Get Select Data");

type Props = {
  clientId?: string;
  jwt: string;
};

type Hook = (props: Props) => [DropdownOptions, DropdownOptions, boolean];

export const useBucketSelectData: Hook = ({ clientId, jwt }) => {
  const [clients, setClients] = useState<DropdownOptions>([]);
  const [flights, setFlights] = useState<DropdownOptions>([]);
  const [loading, setLoading] = useState(false);

  const cancelToken = useCancelToken();

  const getDropdownMappings = useCallback(() => {
    const dropdownMappings: DropdownMappings = [
      { name: "clients", index: 0, key: "id", text: "name" },
    ];

    if (clientId) {
      dropdownMappings.push({
        name: "flights",
        index: 1,
        key: "id",
        text: "reference",
      });
    }

    return dropdownMappings;
  }, [clientId]);

  const onLoadData = useCallback(async () => {
    const promises: Array<Promise<any>> = [listClients(jwt, cancelToken)];

    if (clientId) {
      promises.push(getFlightsByClient(cancelToken, clientId));
    }

    const responses = await axios.all(promises);

    return responsesToOptionsMapper(responses, getDropdownMappings());
  }, [jwt, clientId, cancelToken, getDropdownMappings]);

  useEffect(() => {
    const query = async () => {
      try {
        logger.request("Getting data");
        setLoading(true);

        const results = await onLoadData();

        setClients(
          results.clients.sort((a, b) => a.label.localeCompare(b.label))
        );
        setFlights(
          results.flights.sort((a, b) => a.label.localeCompare(b.label))
        );

        logger.success();
      } catch (e: any) {
        if (cancelToken.reason) return;

        const error = e.message ? e.message : e;
        logger.error(error);
      }
      setLoading(false);
    };

    query();
  }, [cancelToken, onLoadData]);

  return [clients, flights, loading];
};
