import { FeatureLike } from "ol/Feature";
import { LineString, Point } from "ol/geom";
import GeometryType from "ol/geom/GeometryType";
import { getLength } from "ol/sphere";
import { Fill, RegularShape, Stroke, Style, Text } from "ol/style";
import CircleStyle from "ol/style/Circle";
import { mapStrings as strings } from "../../../resources/strings";

export const avoidanceZoneLayerStyle = (feature: FeatureLike) => {
  let colour = "#000000";
  switch (feature.get("status")) {
    case strings.avoidanceZone.statusList.permanent:
      colour = "#9400D3"; // Violet
      break;
    case strings.avoidanceZone.statusList.temporary:
      colour = "#FF4500"; // Amber
      break;
    case strings.avoidanceZone.statusList.client:
      colour = "#800517"; // Red
      break;
  }

  return new Style({
    fill: new Fill({ color: colour + "60" }),
    stroke: new Stroke({ color: colour + "ff", width: 3 }),
  });
};

export const pointsOfInterestPointStyle = () => {
  return new Style({
    image: new CircleStyle({
      radius: 5,
      fill: new Fill({ color: "#0080ff" }),
      stroke: new Stroke({ color: "#000000", width: 3 }),
    }),
  });
};

export const pointsOfInterestLabelStyle = (feature: FeatureLike) => {
  let output = feature.get("name");

  return new Style({
    text: new Text({
      fill: new Fill({ color: "#000000" }),
      stroke: new Stroke({ color: "#ffffff", width: 2 }),
      font: "bold 14px Rajdhani, Arial, sans-serif",
      textAlign: "left",
      text: output,
      offsetX: 12,
      offsetY: 0,
    }),
  });
};

export const textStyle = (feature: FeatureLike) => {
  let output = feature.get("name");

  return new Style({
    text: new Text({
      fill: new Fill({ color: "#000000" }),
      stroke: new Stroke({ color: "#ffffff", width: 2 }),
      font: "bold 14px Rajdhani, Arial, sans-serif",
      textAlign: "left",
      textBaseline: "top",
      text: output,
      offsetX: 0,
      offsetY: 0,
    }),
  });
};

export const markupLayerStyle = (feature: FeatureLike) => {
  if (feature.getGeometry()?.getType() === GeometryType.POINT) {
    return new Style({
      image: new CircleStyle({
        radius: 5,
        fill: new Fill({ color: "#000000" }),
      }),
    });
  } else {
    return new Style({
      stroke: new Stroke({ color: "#000000", width: 3 }),
    });
  }
};

export const gpsLayerStyle = (feature: FeatureLike) => {
  if (feature.getGeometry()?.getType() === GeometryType.POINT) {
    return new Style({
      image: new CircleStyle({
        radius: 8,
        fill: new Fill({ color: "#00ff80" }),
        stroke: new Stroke({ color: "#000000", width: 3 }),
      }),
    });
  } else {
    return new Style({
      stroke: new Stroke({ color: "#0000ff", width: 3 }),
    });
  }
};

export const measureStyle = (feature: FeatureLike) => {
  const geom = feature.getGeometry() as LineString;

  // Need to use getLength from ol/sphere, because
  // geom.getLength()) doesn't account for projection
  const length = getLength(geom);

  let output;
  if (length > 1000) {
    output = (length / 1000).toFixed(2) + "km";
  } else {
    output = length.toFixed() + "m";
  }

  return new Style({
    stroke: new Stroke({
      color: "#000000",
      lineDash: [10, 10],
      width: 4,
    }),

    text: new Text({
      fill: new Fill({ color: "#000000" }),
      stroke: new Stroke({ color: "#ffffff", width: 3 }),
      font: "bold 20px Rajdhani, Arial, sans-serif",
      text: output,
      offsetY: -15,
    }),
  });
};

export const arrowStyle = (feature: FeatureLike) => {
  const geom = feature.getGeometry() as LineString;

  const styles: Style[] = [
    // Arrow shaft
    new Style({
      stroke: new Stroke({ color: "#000000", width: 3 }),
    }),
  ];

  geom.forEachSegment((start, end) => {
    const dx = end[0] - start[0];
    const dy = end[1] - start[1];
    const rotation = Math.atan2(dy, dx);

    // Arrow head
    styles.push(
      new Style({
        geometry: new Point(end),
        image: new RegularShape({
          fill: new Fill({ color: "#000000" }),
          points: 3,
          radius: 10,
          rotation: Math.PI / 2 - rotation,
        }),
      })
    );
  });

  return styles;
};

export const googleMapsTerrainStyle = [
  {
    featureType: "poi",
    elementType: "labels",
    stylers: [{ visibility: "off" }],
  },
];

export const overviewBoxStyle = () => {
  return new Style({
    stroke: new Stroke({
      width: 2,
      lineDash: [5, 5],
    }),
  });
};

//Note - A WebGLPointsLayer style is given in JSON format
export const assetVoltageStyle = {
  symbol: {
    symbolType: "circle",
    size: [
      "clamp",
      [
        "interpolate",
        ["linear"],
        ["get", "transmissionVoltage"],
        25,
        2,
        33,
        3,
        132,
        4,
        275,
        6,
        400,
        8,
      ],
      ["/", ["zoom"], 4],
      20,
    ],
    color: [
      "interpolate",
      ["linear"],
      ["get", "transmissionVoltage"],
      20,
      "#1565c0",
      33,
      "#4caf50",
      66,
      "#ffa726",
      132,
      "#ffa726",
      275,
      "#b71c1c",
      400,
      "#b71c1c",
    ],
    rotateWithView: false,
    offset: [0, 0],
    opacity: 1.0,
  },
};

export const assetDefectStatusStyle = {
  symbol: {
    symbolType: "circle",
    size: ["clamp", 16, ["/", ["zoom"], 4], 20],
    color: [
      "match",
      ["get", "clicked"],
      1,
      "#9933ff",
      [
        "match",
        ["get", "hover"],
        1,
        "#ffffff",
        [
          "case",
          ["!=", ["get", "footPatrol"], 0],
          "#0000ff", // Blue if asset marked for foot patrol
          ["!=", ["get", "defective"], 0],
          "#ff0000", // Red if one or more defect is present on the asset
          ["!=", ["get", "complete"], 0],
          "#00c000", // Green if no defects are present on the asset
          "#f5db1b",
        ],
      ], // Yellow if the Asset has not been patrolled yet (not foot patrol, not defective, not complete)
    ],
    rotateWithView: false,
    offset: [0, 0],
    opacity: 1.0,
  },
};
