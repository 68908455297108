import { Grid, makeStyles, Tab, Tabs } from "@material-ui/core";
import { useState } from "react";
import { sortByKey as byKey } from "../../../helpers";
import { AssetContainer, JobData } from "../../../types/documents";
import { BucketGallery } from "../../general/BucketGallery/BucketGallery";
import { TowerPanel } from "./TowerPanel";

interface Props {
  selectedAssetContainer: AssetContainer;
  conditionAssessmentJobs: JobData[];
}

type Component = (props: Props) => JSX.Element;

export const GradingImages: Component = ({
  selectedAssetContainer,
  conditionAssessmentJobs: jobs,
}) => {
  const classes = useStyles();

  const mappedJobs = jobs.reduce(
    (prev, job) => ({ ...prev, [job.asset.towerSide]: job }),
    {}
  ) as Record<string, JobData>;

  const sections = Array.from(
    new Set(
      jobs.flatMap((job) =>
        job.sections.sort(byKey("createdDate", "asc")).map((s) => s.caption)
      )
    )
  );

  const [selectedSection, setSelectedSection] = useState(sections[0]);
  const [selectedZoneParameters, setSelectedZoneParameters] = useState({
    clientId: selectedAssetContainer.clientId,
    flightId: "",
    assetId: "",
    zoneId: "",
  });

  const selectZone = ({ flightId, assetId }: JobData, zoneId: string) => {
    setSelectedZoneParameters((prev) => ({
      ...prev,
      flightId,
      assetId,
      zoneId,
    }));
  };

  return (
    <>
      {jobs.length > 0 && (
        <>
          <Tabs
            value={selectedSection}
            centered
            indicatorColor="primary"
            textColor="primary"
            onChange={(_event, value) => value && setSelectedSection(value)}
          >
            {sections.map((section) => (
              <Tab
                key={section}
                value={section}
                label={section}
                className={classes.sectionToggle}
              />
            ))}
          </Tabs>

          <Grid container direction="row" wrap="nowrap">
            <Grid item xs={6} style={{ borderRight: "2px lightgrey dashed" }}>
              <TowerPanel
                selectedSection={selectedSection}
                selectedZone={selectedZoneParameters.zoneId}
                onSelectZone={selectZone}
                side="left"
                job={mappedJobs.left}
              />
            </Grid>
            <Grid item xs={6}>
              <TowerPanel
                selectedSection={selectedSection}
                selectedZone={selectedZoneParameters.zoneId}
                onSelectZone={selectZone}
                side="right"
                job={mappedJobs.right}
              />
            </Grid>
          </Grid>

          <BucketGallery
            imageParameters={selectedZoneParameters}
            pageSize={8}
          />
        </>
      )}
    </>
  );
};

export const useStyles = makeStyles((theme) => ({
  sectionToggle: {
    fontWeight: 800,
    fontSize: "1.2rem",
    minWidth: theme.spacing(10),
  },
}));
