import { EntityListTheme } from "@pulsion/forms-designer-2";
import { theme } from "../../../styles/theme";

export const entityListTheme: EntityListTheme = {
  colors: {
    primary: theme.palette.primary.main,
    secondary: theme.palette.primary.main,
    modalHeader: theme.palette.primary.main,
  },
  fonts: [theme.typography.fontFamily as string],
  font: {
    primary: {
      family: theme.typography.fontFamily as string,
      color: "#333333",
    },
    secondary: {
      family: theme.typography.fontFamily as string,
      color: theme.palette.text.primary,
      size: "15px",
    },
  },
  button: {
    fontSize: "16px",
  },
  table: {
    marginBottom: "1.5rem",
    boxShadow: "none",
    borderRadius: "0px",
    header: {
      color: theme.palette.primary.main,
      justification: "space-between",
      font: theme.typography.fontFamily as string,
      fontSize: "19px",
      padding: "20px",
      fontWeight: "bold",
      textTransform: "uppercase",
      backgroundColor: theme.backgroundShades.grey,
    },
    headerRow: {
      font: theme.typography.fontFamily as string,
      fontSize: "15px",
      fontColor: "#333333",
      fontWeight: "bold",
      textAlign: "left",
      backgroundColor: theme.backgroundShades.grey,
    },
    cell: {
      font: theme.typography.fontFamily as string,
      fontSize: "15px",
      fontColor: theme.palette.text.primary,
      textAlign: "left",
      backgroundColor: "inherit",
    },
    createButton: {
      paddingRight: "20px",
      boxShadow: "none",
      borderRadius: "0px",
      color: theme.backgroundShades.white,
      backgroundColor: theme.palette.primary.main,
      fontSize: "16px",
      font: theme.typography.fontFamily as string,
    },
  },
};
