import { getToken } from "../../libs/auth";
import type { Axios } from "../../libs/axios";
import axios from "../../libs/axios/axios-instance";
import { SYNC_ENDPOINT } from "../../libs/config";
import { SyncStatus, SyncType } from "../../types/documents/offline/Sync";

const baseUrl = SYNC_ENDPOINT + "/";
const axiosInstance = axios(baseUrl ?? "");

export const syncStatusWebSocketUrl =
  baseUrl.replace(/^http?:\/\//, "ws://") + "status-ws";

export const syncStatus = async (
  cancelToken: Axios.CancelToken,
  syncType: SyncType,
  parameter: string
) => {
  try {
    const config = {
      cancelToken,
    };
    const urlPath = `status/${syncType}/${parameter}`;
    const { data } = await axiosInstance.get<SyncStatus>(urlPath, config);
    return data;
  } catch (e: any) {
    console.log(e);
    throw e.message ? e.message : e;
  }
};

export const syncNow = async (
  cancelToken: Axios.CancelToken,
  syncType: SyncType,
  parameter: string
) => {
  try {
    const jwt = await getToken();
    const body = {};
    const config = {
      cancelToken,
      headers: {
        Authorization: jwt,
      },
    };
    const urlPath = `sync/${syncType}/${parameter}`;
    await axiosInstance.post<void>(urlPath, body, config);
  } catch (e: any) {
    console.log(e);
    throw e.message ? e.message : e;
  }
};
