import { Map } from "ol";
import Feature from "ol/Feature";
import GeoJSON from "ol/format/GeoJSON";
import { Point } from "ol/geom";
import WebGLPointsLayer from "ol/layer/WebGLPoints";
import VectorSource from "ol/source/Vector";
import { LiteralStyle } from "ol/style/literal";
import { useCallback, useEffect, useMemo, useState } from "react";
import { AssetGeoJson } from "../../types/documents/Assets";

type Props = {
  map: Map | undefined;
  properties?:
    | {
        [x: string]: any;
      }
    | undefined;
  format?: GeoJSON | null;
  style: LiteralStyle;
  vectorSource?: VectorSource<Point> | null;
};

type Result = [
  WebGLPointsLayer<VectorSource<Point>> | null,
  VectorSource<Point> | null,
  (data: AssetGeoJson) => void
];

export const useWebGlPointsLayer = ({
  map,
  properties: _properties,
  format,
  style: _style,
  vectorSource: _vectorSource,
}: Props): Result => {
  const [layer, setLayer] = useState<WebGLPointsLayer<
    VectorSource<Point>
  > | null>(null);
  const [vectorSource] = useState<VectorSource<Point>>(
    _vectorSource ? _vectorSource : new VectorSource<Point>()
  );

  const properties = useMemo(() => _properties, [_properties]);
  const style = useMemo(() => _style, [_style]);

  if (map) {
    let selected: Feature | null = null;
    let clicked: Feature | null = null;
    map.on("pointermove", function (e) {
      if (selected != null) {
        selected.set("hover", 0);
        selected = null;
      }
      map.forEachFeatureAtPixel(e.pixel, function (f) {
        let feature = f as Feature;
        feature.set("hover", 1);
        selected = feature;
        return true;
      });
    });

    map.on("click", function (e) {
      if (clicked != null) {
        clicked.set("clicked", 0);
        clicked = null;
      }

      map.forEachFeatureAtPixel(e.pixel, function (f) {
        let feature = f as Feature;
        feature.set("clicked", 1);
        clicked = feature;
        return true;
      });
    });
  }

  useEffect(() => {
    if (!map || !format) return;

    const layer = new WebGLPointsLayer({
      properties: properties,
      source: vectorSource,
      style: style,
      visible: true,
    });

    map.addLayer(layer);
    setLayer(layer);

    return () => {
      vectorSource.clear();
      map.removeLayer(layer);
    };
  }, [map, format, properties, style, vectorSource]);

  const addDataToSource = useCallback(
    (data: AssetGeoJson) => {
      if (!vectorSource || !format || !map) return;

      const geometryVectorSource = vectorSource as VectorSource;
      const features = format.readFeatures(data.data);
      if (features.length === 0) return;
      geometryVectorSource.addFeatures(features);
      map.getView().fit(vectorSource.getExtent(), {
        size: map.getSize(),
        maxZoom: 16,
      });
      console.log(`Loaded  assets`, { count: features.length });
    },
    [vectorSource, format, map]
  );

  return [layer, vectorSource, addDataToSource];
};
