import axios from "axios";
import { BaseLayerOptions } from "ol-layerswitcher";
import TileLayer from "ol/layer/Tile";
import { XYZ } from "ol/source";
import TileGrid from "ol/tilegrid/TileGrid";
import View from "ol/View";
import { useContext, useEffect, useState } from "react";
import { HooksLogger } from "../../../hooks/hooks-logger";
import { mapStrings as strings } from "../../../resources/strings";
import MapContext from "../MapContext";

const logger = new HooksLogger("Map/ClientTileLayer");

interface Props {
  name: string;
  clientId: string;
  zoomToExtent?: boolean;
}

type Component = (props: Props) => JSX.Element;

export const ClientTileLayer: Component = ({
  name,
  clientId,
  zoomToExtent,
}) => {
  const map = useContext(MapContext);
  const [grid, setGrid] = useState<any>();

  const baseUrl = `${process.env.REACT_APP_MAP_TILES_CLOUDFRONT}/${clientId}`;

  useEffect(() => {
    const fetchGrid = async () => {
      logger.request("Fetching client grid", { clientId });
      try {
        const grid = (await axios.get(baseUrl + "/grid.json")).data;
        logger.success("Fetched client grid", { grid });
        if (!grid) return;
        setGrid(grid);
      } catch (e) {
        logger.error(e);
        return;
      }
    };

    fetchGrid();
  }, [baseUrl, clientId]);

  useEffect(() => {
    if (!map || !grid) return;

    const tileLayer: TileLayer<any> = new TileLayer({
      properties: {
        title: `${name} - Map Tiles`,
        layerType: strings.layerTypes.tile,
      },
      source: new XYZ({
        projection: grid.projection,
        url: baseUrl + "/{z}/{x}/{y}.png",
        tileGrid: new TileGrid(grid),
      }),
      zIndex: -1,
    } as BaseLayerOptions);

    map.addLayer(tileLayer);

    if (zoomToExtent) {
      const view = new View({
        projection: grid.projection,
        resolutions: grid.resolutions,
        zoom: 0,
        center: [grid.extent[0], grid.extent[1]],
      });
      map.setView(view);
      view.fit(grid.extent);
    }

    return () => {
      map.removeLayer(tileLayer);
    };
  }, [map, baseUrl, grid, name, zoomToExtent]);
  return <></>;
};
