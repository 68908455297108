import { Grid } from "@material-ui/core";
import log from "loglevel";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { API } from "../../API";
import { Loader, ModifyComponent } from "../../components/general";
import type {
  ChangeHandler,
  Config,
  Mode,
} from "../../components/general/types/Modify";
import { ModifyContextProvider } from "../../context/ModifyContext";
import { removeEmptyAndSpecifiedKeys } from "../../helpers";
import { useCancelToken } from "../../hooks/general";
import { notifications } from "../../libs/notifications";
import { appStrings } from "../../resources/strings";
import { RouteProps } from "../../types";
import { TKeysToRemove } from "../../types/types";
import validate from "../../validation";

export interface Props<D extends object> extends RouteProps {
  children?: JSX.Element;
  childrenWithData?: (formData: D) => JSX.Element;
  putEndpoint: string;
  queryEndpoint: string;
  initialData: D;
  componentConfiguration: Config<D> | ((data: D) => Config<D>);
  mode: Mode;
  constraints: object;
  redirectPath: string | ((id: string) => string);
  manualQueryId?: string;
  keysToRemove?: TKeysToRemove<D>;
  loading?: boolean;
  isDialog?: boolean;
  onLoading?: (loading: boolean) => void;
  onSuccess?: (id: string, data: D) => any;
}

export const ModifyContainer = <D extends { [key: string]: unknown }>({
  children,
  childrenWithData,
  putEndpoint,
  queryEndpoint,
  jwt,
  initialData: _initialData,
  componentConfiguration,
  mode: inputMode,
  permissions,
  constraints,
  redirectPath,
  manualQueryId,
  keysToRemove,
  loading: propsLoading = false,
  isDialog,
  onSuccess,
  onLoading,
}: Props<D>) => {
  const history = useHistory();
  const { id, childId } = useParams<{ id?: string; childId?: string }>();
  const cancelToken = useCancelToken();
  const [mode, setMode] = useState<Mode>(() => inputMode);
  const token = useMemo(() => jwt, [jwt]);
  const url = useMemo(() => queryEndpoint, [queryEndpoint]);
  const [initialData, setInitialData] = useState(() => _initialData);
  const [formData, setFormData] = useState<D>(initialData);
  const [loading, setLoading] = useState(() => false);
  const [, setError] = useState("");
  const [validationResults, setValidationResults] = useState<{
    [key: string]: string[];
  } | null>(null);
  const [success, setSuccess] = useState(() => false);
  const [redirectId, setRedirectId] = useState("");
  const APIFunctions = useMemo(
    () => new API<D>(cancelToken, jwt),
    [cancelToken, jwt]
  );

  useEffect(() => {
    const query = async () => {
      setLoading(true);
      let path = manualQueryId ? `${url}/${manualQueryId}` : `${url}/${id}`;
      if (childId) path = `${path}/${childId}`;
      try {
        const item = await APIFunctions.get(path);
        if (!item) throw Error("No item found");
        setInitialData(item);
        setFormData(item);
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    };
    if ((id || manualQueryId) && inputMode !== "create") query();
    return () => {
      setLoading(false);
    };
  }, [
    id,
    childId,
    manualQueryId,
    inputMode,
    cancelToken,
    token,
    url,
    APIFunctions,
  ]);

  const handleModeSwitch = useCallback(() => {
    switch (mode) {
      case "update":
        setMode("view");
        break;
      case "view":
        setMode("update");
        break;
      case "create":
        let path = "";
        if (typeof redirectPath === "string") {
          path = redirectPath;
        } else {
          path = redirectPath(redirectId);
        }
        if (success && path) history.push(path);
        break;
      default:
        break;
    }
  }, [mode, history, redirectPath, redirectId, success]);

  const handleReset = useCallback(() => {
    setFormData(initialData);
    handleModeSwitch();
    setValidationResults(null);
  }, [initialData, handleModeSwitch]);

  useEffect(() => {
    if (success) {
      handleModeSwitch();
      setSuccess(false);
      onSuccess && onSuccess(redirectId, formData);
    }
  }, [success, redirectId, formData, onSuccess, handleModeSwitch]);

  useEffect(() => {
    onLoading && onLoading(loading);
  }, [loading, onLoading]);

  const handleChange: ChangeHandler = (e) => {
    const { target } = e;
    const { name, value, checked } = target;
    if (!name) return;

    setFormData((prev) => ({
      ...prev,
      [name]: target.hasOwnProperty("checked") ? checked : value,
    }));
  };

  const handleDelete = async () => {
    const target = manualQueryId ?? id;
    if (!target) return;
    setLoading(true);
    try {
      await APIFunctions.delete(url, target);
      setSuccess(true);
      notifications.success(appStrings.notifications.messages.deleted);
    } catch (e) {
      setError(e);
      notifications.error(
        e?.response?.data.error
          ? e.response.data.error
          : appStrings.notifications.messages.deleteError
      );
    }
    setLoading(false);
  };

  const altHandleChange = useCallback(
    ({ name, value }: { name: keyof D; value: unknown }) => {
      if (!name) return;

      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    },
    []
  );

  const submitData = async () => {
    // if the form data has attributes which are undefined/empty arrays
    // or if the keys are passed into prop 'keysToRemove' then delete them

    const formDataForSubmit = removeEmptyAndSpecifiedKeys(
      formData,
      keysToRemove,
      mode
    );
    log.debug("Form Data For Submit:", formDataForSubmit);
    setLoading(true);
    setValidationResults(null);
    try {
      const submittedId = await APIFunctions.submitData(
        formDataForSubmit,
        mode,
        putEndpoint
      );
      setRedirectId(submittedId);
      setSuccess(true);
      setLoading(false);
      notifications.success(appStrings.notifications.messages.submitted);
    } catch (e) {
      setError(e);
      setLoading(false);
      notifications.error(
        e?.response?.data.error
          ? e.response.data.error
          : appStrings.notifications.messages.submitError
      );
    }
  };

  const validateForm = () => {
    const results = validate(formData, constraints) ?? {};
    log.debug("Validation Results", results);
    const isValid = !Object.keys(results).length;
    if (!isValid) {
      setValidationResults(results);
      notifications.error(appStrings.notifications.messages.invalidFields);
      return;
    }
    submitData();
  };

  const renderChildren = () => {
    if (!children) return null;
    return (
      <Grid container justifyContent="center">
        <Grid item xs={12} md={9}>
          {children}
        </Grid>
      </Grid>
    );
  };

  const renderChildrenWithData = (data: D) => {
    if (!childrenWithData) return null;
    return childrenWithData(data);
  };

  const isLoading = loading || propsLoading;

  return (
    <ModifyContextProvider value={{ handleModeSwitch, handleDelete }}>
      <Loader active={isLoading} inline={isDialog}>
        <div>
          <ModifyComponent<D>
            permissions={permissions}
            mode={mode}
            handleModeSwitch={handleModeSwitch}
            validateForm={validateForm}
            data={formData}
            handleChange={handleChange}
            altHandleChange={altHandleChange}
            handleReset={handleReset}
            componentConfiguration={componentConfiguration}
            loading={isLoading}
            setFormData={setFormData}
            validationResults={validationResults}
            jwt={jwt}
          />
          {renderChildren()}
          {renderChildrenWithData(formData)}
        </div>
      </Loader>
    </ModifyContextProvider>
  );
};
