import { Box, List, ListItem, ListItemText } from "@material-ui/core";
import { CancelToken } from "axios";
import { useEffect, useState } from "react";
import { defectStrings as strings } from "../../resources/strings";
import * as defectTypesApi from "../../services/defectTypes.service";
import { Loader } from "../general";
import { useDefectTypeListStyles } from "./styles/defectTypeList";

interface Iprops {
  createNewDefect: (defectTypeId: string) => {};
  loading?: boolean;
  cancelToken: CancelToken;
  flightId: string;
}

export const DefectTypeList = ({
  createNewDefect,
  cancelToken,
  loading = false,
  flightId,
}: Iprops) => {
  const classes = useDefectTypeListStyles();
  const [defectTypes, setDefectTypes] = useState<any[]>([]);
  const [selectedDefectTypeId, setSelectedDefectTypeId] = useState("");

  useEffect(() => {
    const getDefectTypes = async () => {
      const defectTypeData = await defectTypesApi.getDefectTypes(
        cancelToken,
        flightId
      );
      const result = defectTypeData.map((defectType) => ({
        id: defectType.id,
        name: defectType.name,
      }));
      console.log("DEFECT TYPES", result);
      setDefectTypes(result);
    };

    getDefectTypes();
  }, [cancelToken, flightId]);

  const handleDefectTypeClick = (_event: any, index: string) => {
    setSelectedDefectTypeId(index);
    createNewDefect(index);
  };

  return (
    <>
      <Loader inline active={loading}>
        <Box className={classes.root}>
          <div className={classes.headerContainer}>
            <h3 className={classes.header}>
              {strings.header.availableDefectTypes}
            </h3>
          </div>

          <List dense={true} className="">
            {defectTypes.map((dt: any) => (
              <ListItem
                key={dt.id}
                button
                selected={selectedDefectTypeId === dt.id}
                onClick={(event) => handleDefectTypeClick(event, dt.id)}
              >
                <ListItemText style={{ fontSize: "16px" }} primary={dt.name} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Loader>
    </>
  );
};
