import { appStrings } from "./app";

const buttonConfiguration = "Button Configuration";
const buttonConfigurationPlural = `${buttonConfiguration}s`;

export const buttonConfigurationStrings = {
  global: { ...appStrings },
  header: {
    buttonConfigurations: buttonConfigurationPlural,
    createButtonConfiguration: `Create ${buttonConfiguration}`,
    editButtonConfiguration: `Edit ${buttonConfiguration}`,
  },
};
