import { Dialog } from "@material-ui/core";
import { RouteProps } from "../../types";
import { AssetContainer } from "../../types/documents";
import { GradingFormContainer } from "../projects/packages/GradingFormContainer";

interface Props extends RouteProps {
  open: boolean;
  onClose: () => void;
  assetContainer: AssetContainer;
  packageId: string;
}
type Component = (props: Props) => JSX.Element;

export const GradingReportModal: Component = ({
  open,
  onClose,
  assetContainer,
  packageId,
  ...routeProps
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={"xl"}>
      <GradingFormContainer
        {...routeProps}
        assetContainer={assetContainer}
        packageId={packageId}
        readOnly
      />
    </Dialog>
  );
};
