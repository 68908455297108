import { AxiosInstance } from "axios";
import axios from "../../libs/axios/axios-instance";

declare type Config = {
  apiUrl: string | undefined;
  axiosInstance: AxiosInstance;

  buildSectionRoute: (id: string) => string;
  createSection: string;
  deactivateSection: (jobId: string, id: string) => string;

  apiEndPoints: ApiEndpoints;
};

declare type CrudEndpoints = {
  create: string;
  delete: (id: string, props?: any) => string;
  get: (id: string) => string;
  update: (id: string) => string;
};

declare type ReorderEndpoint = {
  reorder: (id: string) => string;
};

declare type FormTemplateSectionEndpoints = CrudEndpoints &
  ReorderEndpoint & {};
declare type SubSectionTemplateEndpoints = CrudEndpoints &
  ReorderEndpoint & {
    createWithId: (id: string) => string;
    update: (id: string, subSectionId: string) => string;
  };
declare type ControlTemplateEndpoints = CrudEndpoints & {
  update: (id: string, subSectionId?: string) => string;
  getNewControlId: () => string;
  getControls: (id: string, subSectionId?: string) => string;
};

declare type FormTemplateEndpoints = CrudEndpoints & {
  getwithSectionNames: (id: string) => string;
  getFormSection: (id: string) => string;
  getFormSubSection: (id: string) => string;
  getFormSectionDetails: (id: string) => string;
  search: () => string;
};

declare type ApiEndpoints = {
  formTemplate: FormTemplateEndpoints;
  sectionTemplate: FormTemplateSectionEndpoints;
  subSectionTemplate: SubSectionTemplateEndpoints;
  controlTemplate: ControlTemplateEndpoints;
};

const config: Config = {
  apiUrl: process.env.REACT_APP_DEFECT_TYPES_ENDPOINT,
  axiosInstance: axios(process.env.REACT_APP_DEFECT_TYPES_ENDPOINT ?? ""),

  buildSectionRoute: (id) => `/sections/${id}`,
  createSection: "/sections/addsection",
  deactivateSection: (jobId, id) => `/sections/${jobId}/${id}`,

  apiEndPoints: {
    formTemplate: {
      create: "defect-types",

      delete: (id: string) => `defect-types/${id}`,
      get: (id: string) => `defect-types/${id}`,
      getwithSectionNames: (id: string) => `defect-types/${id}/section-types`,
      getFormSection: (id: string) => `section-types/${id}`,
      getFormSectionDetails: (id: string) => `section-types/${id}/details`,
      getFormSubSection: (id: string) => `subsection-types/${id}`,
      update: (id: string) => `defect-types/${id}`,
      search: () => `defect-types/search`,
    },
    sectionTemplate: {
      create: `section-types`,
      delete: (id: string) => `section-types/${id}`,
      get: (id: string) => `section-types/${id}`,
      update: (id: string) => `section-types/${id}`,
      reorder: (id: string) => `section-types/${id}/reorder`,
    },
    subSectionTemplate: {
      create: `subsection-types`,
      createWithId: (id: string) => `subsection-types/${id}`,
      delete: (id: string, subsectionId: string) =>
      `subsection-types/${id}/${subsectionId}`,
      get: (id: string, subSectionId?: string) =>
        `subsection-types/${id}/${subSectionId}`,
      update: (id: string, subSectionId?: string) =>
        `subsection-types/${id}/${subSectionId}`,
      reorder: (id: string) => `subsection-types/${id}/reorder`,
    },
    controlTemplate: {
      create: `control-types`,
      delete: (id: string) => `control-types/${id}`,
      get: (id: string) => `control-types/${id}`,
      update: (id: string, subSectionId?: string) => {
        if (subSectionId) return `control-types/${id}/${subSectionId}`;
        return `control-types/${id}`;
      },
      getNewControlId: () => `control-types/get-new-control-id`,
      getControls: (id: string, subSectionId?: string) => {
        if (subSectionId) return `control-types/${id}/${subSectionId}`;
        return `control-types/${id}`;
      },
    },
  },
};

export default config;
