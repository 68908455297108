import BreadcrumbHeader from "../../components/general/BreadcrumbHeader";
import DeleteEditClearSaveButtons
  from "../../components/general/DeleteEditClearSaveButtons";
import { Props } from "../../components/general/ModifyComponent";
import { paths } from "../../navigation";
import { clientStrings as strings } from "../../resources/strings";
import { ClientData, LineLayer } from "../../types/documents";
import { useCancelToken } from "../../hooks/general";
import { useHistory } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import { API } from "../../API";
import { CLIENTS_ENDPOINT, LINES_ENDPOINT } from "../../libs/config";
import { notifications } from "../../libs/notifications";

export default function LineLayerBreadcrumbHeader({
  data,
  jwt,
  mode,
  permissions,
  handleModeSwitch,
}: Props<LineLayer>) {
  const cancelToken = useCancelToken();
  const history = useHistory();
  const [clientName, setClientName] = useState("");
  const [deleteLoading, setDeleteLoading] = useState(false);

  const APIFunctions = useMemo(
    () => new API<ClientData>(cancelToken, jwt),
    [cancelToken, jwt]
  );

  useEffect(() => {
    async function getClientName() {
      if (!data.clientId) return;
      const client = await APIFunctions.get(
        `${CLIENTS_ENDPOINT}/${data.clientId}`
      );
      if (!client) return;
      setClientName(client.name);
    }

    getClientName();
  }, [APIFunctions, data.clientId]);

  const getHeaderFromMode = () => {
    switch (mode) {
      case "view":
        return strings.header.viewLine;
      case "create":
        return strings.header.createLine;
      case "update":
        return strings.header.editLine;
    }
  };

  const crumbs = [
    { name: strings.header.clients, link: paths.clients.list },
    { name: clientName, link: paths.clients.view(data.clientId) },
    {
      name: strings.header.lines,
      link: paths.clients.lines.list(data.clientId)
    },
    {
      name: getHeaderFromMode(),
    },
  ];

  const deleteLine = useCallback(
    async () => {
      setDeleteLoading(true);
      try {
        await APIFunctions.delete(
          `${LINES_ENDPOINT}/${data.clientId}`,
          data.id
        );
        notifications.success(strings.notifications.deleted);
        history.push(paths.clients.lines.list(data.clientId));
      } catch (error) {
        notifications.error(strings.notifications.failedDelete);
      }
      setDeleteLoading(false);
    },
    [APIFunctions, data.clientId, data.id, history]
  );

  return (
    <BreadcrumbHeader crumbs={crumbs}>
      <DeleteEditClearSaveButtons
        entity={strings.global.entities.clients}
        mode={mode}
        permissions={permissions}
        deleteFunction={deleteLine}
        deleteLoading={deleteLoading}
        handleModeSwitch={handleModeSwitch}
      />
    </BreadcrumbHeader>
  );
}
