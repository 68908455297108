import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { imageStrings as strings } from "../../../../../resources/strings";
import { ImageParameters } from "../../../../../types/documents/Images";
import { Dropdown } from "../../../controls";
import { useBucketSelectData } from "./useBucketSelect";

interface Props {
  imageParameters: ImageParameters;
  handleClientId: (client: string) => void;
  handleFlightId: (client: string) => void;
  className?: string;
  jwt: string;
}
type Component = (props: Props) => JSX.Element;
export const BucketSelect: Component = ({
  imageParameters,
  handleClientId,
  handleFlightId,
  className,
  jwt,
}) => {
  const { clientId, flightId } = imageParameters;

  const classes = useStyles();
  const [clients, flights] = useBucketSelectData({ clientId, jwt });

  return (
    <div className={className}>
      <h3 className={clsx(classes.header)}>
        {strings.labels.selectClientFlight}
      </h3>

      <div className={clsx(classes.container)}>
        <div className={clsx(classes.contained)}>
          <Dropdown
            config={{
              controltype: "dropdown",
              name: "clientDropdown",
              label: strings.labels.client,
              options: clients,
              value: clientId,
              fullWidth: true,
            }}
            labelProps={{ className: classes.dropdownLabel }}
            selectProps={{ className: classes.dropdownSelect }}
            handleChange={(e) => handleClientId(e.target.value as string)}
          />
        </div>

        <div className={clsx(classes.contained)}>
          <Dropdown
            config={{
              controltype: "dropdown",
              name: "flightDropdown",
              label: strings.labels.flight,
              fullWidth: true,
              value: flightId,
              options: flights,
            }}
            handleChange={(e) => handleFlightId(e.target.value as string)}
            labelProps={{ className: classes.dropdownLabel }}
            selectProps={{ className: classes.dropdownSelect }}
          />
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
  },
  contained: {
    width: "50%",
    padding: theme.spacing(3),
    paddingTop: 0,
    "& .MuiInputBase-root": {
      background: theme.backgroundShades.grey,
    },
  },
  header: {
    padding: theme.spacing(3),
    margin: 0,
    textAlign: "start",
    color: theme.palette.primary.main,
  },
  dropdownLabel: {
    textAlign: "start",
    fontWeight: "bolder",
  },
  dropdownSelect: {
    border: `${theme.spacing(0.1)}px solid ${theme.border.primary}`,
    marginTop: theme.spacing(1),
  },
}));
