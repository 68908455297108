import { appStrings } from "./app";

export const packageStrings = {
  global: { ...appStrings },
  header: {
    packages: "Packages",
    packageDetails: "Package Details",
    createPackage: "Create Package",
    viewPackage: "View Package",
    editPackage: "Edit Package",
  },
  labels: {
    packageName: "Package Name",
    packageType: "Type",
    dateCreated: "Date Created",
    groupedBy: "Grouped By",
    filter: "Filter",
    flights: "Flights",
    assetsPatrolled: "Assets Patrolled",
    assetsToPatrol: "Assets Not Patrolled",
    assetsDefective: "Defective Assets",
    assetsForFootPatrol: "Assets For Foot Patrol",
    createdBy: "Created By",
    lastUpdated: "Last Updated",
    lastUpdatedBy: "Updated By",
    description: "Description",
    status: "Status",
    save: "Save",
    reset: "Reset",
    edit: "Edit",
    clear: "Clear",
    delete: "Delete",
    createPackage: "Create Package",
  },
  messages: {
    confirmDelete: "Are you sure you want to permanently delete this package?",
  },
  notifications: {
    deleted: "Successfully deleted package.",
    failedDelete: "Failed to delete package.",
  },
};
