import { Button, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { ShowIfAuthorised } from "../../components/authentication";
import { defaultButtonStyle } from "../../components/styles";
import { projectStrings as strings } from "../../resources/strings";
import { Permissions } from "../../types";

const useStyles = makeStyles((theme) => ({
  all: defaultButtonStyle(theme),
  border: { border: theme.spacing(0.3, "solid") },
}));

interface Props {
  entity: string;
  permissions: Permissions | null;
  handleModeSwitch: () => void;
}

export default function EditButton({
  entity,
  permissions,
  handleModeSwitch,
}: Props) {
  const classes = useStyles();

  return (
    <ShowIfAuthorised
      entity={entity}
      permission={strings.global.permissions.update}
      userPermissions={permissions}
    >
      <Button
        className={clsx(classes.all)}
        color="primary"
        variant="contained"
        onClick={handleModeSwitch}
        title="edit"
      >
        {strings.labels.edit}
      </Button>
    </ShowIfAuthorised>
  );
}
