export type LineLayer = {
  id: string;
  clientId: string;
  name: string;
  coordinateSystem: CoordinateSystem,
  minResolution?: number;
  maxResolution?: number;
  createdDate: string;
  updatedDate: string;
};

export type LineLayerLoad = {
  id: string;
  name: string;
  status: LineLayerLoadStatus,
  error?: string;
  availableColumns?: string[];
};

export enum LineLayerLoadStatus {
  CheckingInput = "CheckingInput",
  AwaitingColumnSelection = "awaitingColumnSelection",
  Processing = "processing",
  Succeeded = "succeeded",
  Failed = "failed",
}

type CoordinateSystem = "EPSG:4326" | "EPSG:27700" | "EPSG:29903";

export type LineLayerColumnSelections = {
  id: string;
  displayName: string;
  minResolution?: number;
  maxResolution?: number;
  transmissionVoltage: string;
  coordinateSystem: CoordinateSystem,
};

export type LineGeojson = {
  id: string;
  name: string;
  url: string;
  coordinateSystem: CoordinateSystem,
  minResolution?: number;
  maxResolution?: number;
};
