import { makeStyles } from "@material-ui/core";

export const useClearDefectModalStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    maxWidth: theme.spacing(60),
    border: theme.spacing(0.4, "solid", theme.palette.primary.main),
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  all: {
    fontWeight: theme.spacing(100),
    boxShadow: theme.spacing(0, 0, 0, 0),
    height: theme.spacing(5),
    width: theme.spacing(24.375),
    margin: theme.spacing(1),
    borderRadius: 0,
  },
  border: { border: theme.spacing(0.4, "solid", "!important") },
  danger: {
    color: theme.palette.error.main,
    background: theme.palette.background.default,
  },
}));
