import { Button, FormControl, Grid, makeStyles, MenuItem, Select } from "@material-ui/core";
import { Header } from "../general/controls";
import { Loader } from "../general";
import { SyncItemStatus } from "../../types/documents/offline/Sync";

interface Props {
  name: string;
  loading: boolean;
  status?: SyncItemStatus;
  onSyncNow: () => void;
  parameters: { id: string, name: string }[] | null;
  parametersLoading: boolean;
  selectedParameter: string;
  onSelectParameter: (parameter: string) => void;
}

type Component = (props: Props) => JSX.Element | null;

export const SyncStatusRow: Component = ({
                                           name,
                                           loading,
                                           status,
                                           onSyncNow,

                                           parameters,
                                           parametersLoading,
                                           selectedParameter,
                                           onSelectParameter,
                                         }) => {
  const classes = useStyles();

  return <>
    <Grid item md={12}>
      <FormControl
        fullWidth
        color="primary"
      >
        <Header config={{
          controltype: "header",
          text: name,
          variant: "h6",
          align: "left",
        }} />
      </FormControl>
    </Grid>

    {!loading && status?.lastError && <Grid item md={12}>
        <span>Error: {status.lastError}</span>
    </Grid>}

    <Grid item md={12}>
      <Grid container direction="row">
        {parameters !== null &&
            <Grid item>
                <Loader active={parametersLoading} inline={true}>
                    <FormControl className={classes.dropdown}>
                        <Select
                            disableUnderline
                            label="Client"
                            onChange={event => onSelectParameter(event.target.value as string)}
                            value={selectedParameter || ""}
                        >
                          {Object.values(parameters).map((parameter) => (
                            <MenuItem key={parameter.id} value={parameter.id}>
                              {parameter.name}
                            </MenuItem>
                          ))}
                        </Select>
                    </FormControl>
                </Loader>
            </Grid>
        }
        {(parameters === null || selectedParameter) &&
            <Grid item>
                <Loader active={loading} inline={true}
                        value={status?.progressPercentage ?? 0}>
                    <Button color="primary" variant="contained" onClick={onSyncNow}>Sync
                        Now</Button>
                    <span>Last synced: {formatLastSynced(status?.lastSynced)}</span>
                </Loader>
            </Grid>
        }
      </Grid>
    </Grid>
  </>;
};

function formatLastSynced(lastSynced: string | undefined): string {
  if (!lastSynced) return "n/a";
  const date = new Date(lastSynced);
  console.log(date);
  return date.toLocaleString();
}

const useStyles = makeStyles(theme => ({
  dropdown: {
    width: theme.spacing(22),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
    "& .MuiSelect-root": {
      fontSize: "1rem",
      fontWeight: 500,
      textAlign: "left",
      paddingLeft: theme.spacing(1.5),
      backgroundColor: theme.backgroundShades.grey,
      border: theme.spacing(0.125, "solid", `${theme.border.primary}`),
    },
  },
}));
