import { Feature, Map } from "ol";
import { Extent } from "ol/extent";
import { Geometry, Point } from "ol/geom";
import BaseLayer from "ol/layer/Base";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import WebGLPointsLayer, {
  Options as WebGLPointsLayerOptions,
} from "ol/layer/WebGLPoints";
import { OSM, XYZ } from "ol/source";
import VectorSource from "ol/source/Vector";
import GoogleLayer from "olgm/layer/Google";
import { tryParseSourceAsVectorSource } from "../../../helpers";
import { mapStrings as strings } from "../../../resources/strings";
import { FeatureGroup } from "../../../types/FetureGroup";
import {
  WebGLLinesLayer,
  WebGLLinesLayerOptions
} from "../layers/webgl/WebGLLinesLayer";

export const syncLayerChanges = (srcMap: Map, destMap: Map) => {
  srcMap.getLayers().on("add", (event) => {
    const clones = cloneLayers([event.element]);
    if (clones) {
      clones.forEach((clone) => destMap.addLayer(clone));
    }
  });

  srcMap.getLayers().on("remove", (event) => {
    const targetTitle = event.element.get("title");
    const targetLayer = destMap.getAllLayers().find((layer) => {
      return layer.get("title") === targetTitle;
    });

    targetLayer && destMap.removeLayer(targetLayer);
  });
};

const cloneLayers = (layerGroup: BaseLayer[]) => {
  const clones = new Array<BaseLayer>();

  for (const layer of layerGroup) {
    let clonedLayers: any[] = [];
    if (layer.getLayersArray().length > 1) {
      clonedLayers = cloneLayers(layer.getLayersArray());
    } else {
      const clone = cloneLayer(layer);
      if (clone) {
        clonedLayers = [clone];
      }
    }

    layer.on("change:visible", (event) => {
      const target = event.target as BaseLayer;
      clonedLayers.forEach((layer) => {
        layer.setVisible(target.get(event.key));
      });
    });

    layer.on("change:opacity", (event) => {
      const target = event.target as BaseLayer;
      clonedLayers.forEach((layer) => {
        layer.setOpacity(target.get(event.key));
      });
    });

    clones.push(...clonedLayers);
  }
  return clones;
};

const cloneLayer = (layer: BaseLayer) => {
  const properties = layer.getProperties();
  delete properties.map;

  switch (layer.get("layerType")) {
    case strings.layerTypes.vector:
      const style = (layer as VectorLayer<any>).getStyle();
      const newLayer = new VectorLayer(properties);
      newLayer.setStyle(style);
      return newLayer;

    case strings.layerTypes.tile:
      const source = new XYZ(properties.source);
      return new TileLayer({ ...properties, source });

    case strings.layerTypes.webgl: {
      const props = properties as WebGLPointsLayerOptions<any>;
      return new WebGLPointsLayer(props);
    }

    case strings.layerTypes.webglcustom: {
      const props = properties as WebGLLinesLayerOptions;
      return new WebGLLinesLayer(props);
    }

    case strings.layerTypes.google:
      return new GoogleLayer(properties);

    case strings.layerTypes.osm:
      delete properties.source;
      return new TileLayer({ ...properties, source: new OSM() });
  }
};

export enum AssetStatus {
  Completed,
  Defective,
  FootPatrol,
}
export const updateMapLayersPointProperties = (
  map: Map | undefined,
  pointIds: string[],
  status: AssetStatus,
  defectCount: number = 0
) => {
  if (!map) return [];

  const updated: string[] = [];

  map
    .getAllLayers()
    .filter((l) => l.get("layerType") === strings.layerTypes.webgl)
    .forEach((layer) => {
      const { source, visible } = layer.getProperties();
      if (!source || !visible) return;

      const vectorSource = tryParseSourceAsVectorSource(source);
      if (!vectorSource) return;

      pointIds.forEach((id) => {
        const feature = vectorSource.getFeatureById(id);
        if (!feature) return;

        const { footPatrol, defective, complete } = feature.getProperties();

        switch (status) {
          case AssetStatus.Defective:
            feature.setProperties({
              defective: defectCount,
              complete: 0,
              footPatrol: 0,
            });
            break;

          case AssetStatus.Completed:
            if (defective || (pointIds.length > 1 && footPatrol)) return;
            feature.setProperties({
              complete: 1,
              footPatrol: 0,
            });
            break;

          case AssetStatus.FootPatrol:
            if (defective || (pointIds.length > 1 && complete)) return;
            feature.setProperties({
              complete: 0,
              footPatrol: 1,
            });
            break;
        }
        updated.push(id);
      });
    });

  return Array.from(new Set(updated));
};

export const getIntersectingFeatures = (
  map: Map | undefined,
  feature: Feature<Geometry>,
  layers: string[]
): Feature<Point>[] => {
  const featureGeometry = feature.getGeometry();
  if (!featureGeometry) return [];

  // Get points in extent
  const extent = featureGeometry.getExtent();
  const featuresInExtent = getFeaturesInExtent(map, extent, layers);

  // Filter to points within shape
  const intersectingFeatures = featuresInExtent.filter((point) => {
    const coords = point.getGeometry()?.getCoordinates();
    return coords && featureGeometry?.intersectsCoordinate(coords);
  });

  return intersectingFeatures;
};

export const getFeaturesInExtent = (
  map: Map | undefined,
  extent: Extent,
  layers: string[]
): Feature<Point>[] => {
  if (!map) return [];

  const features: Feature<Point>[] = map
    .getAllLayers()
    .filter((layer) => layers.includes(layer.get("title")))
    .map((layer) => layer.getSource() as VectorSource<Point>)
    .flatMap((source) => source.getFeaturesInExtent(extent));

  return features;
};

export const setClickedStatus = (features: Feature[], status: 0 | 1) => {
  features.forEach((feature) => {
    feature.set("clicked", status);
  });
};

export const getMapFeaturesByLayer = (
  map: Map | undefined,
  extent: Extent,
  layers: string[]
) => {
  if (!map) return [];

  const layerFeatures: FeatureGroup[] = [];
  map
    .getAllLayers()
    .filter((layer) => layers.includes(layer.get("title")))
    .forEach((layer) => {
      const { source, visible, title } = layer.getProperties();
      if (!source || !visible) return;

      const vectorSource = tryParseSourceAsVectorSource(source);
      if (!vectorSource) return;

      const features = vectorSource
        .getFeaturesInExtent(extent)
        .filter(
          (asset) => asset?.getGeometry()?.intersectsExtent(extent) ?? false
        );

      if (features.length > 0) {
        layerFeatures.push({
          title,
          features,
        });
      }
    });

  return layerFeatures;
};
