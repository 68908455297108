import { createTheme } from "@material-ui/core/styles";
import "typeface-barlow";

const primary = "#7D003E";

//booking status colours
const red = "#CC3333";
const orange = "#FF9A00";
const green = "#99CB01";

//notes icon
const burgundy = "#995740";

//text
const grey = "#666666";

//backgrounds
const backgroundGrey = "#FAFAFA";
const backgroundWhite = "#FFFFFF";

//borders
const lightGrey = "#D1D1D1";

const font = "Rajdhani, 'Barlow', sans-serif";

export const theme = createTheme({
  palette: {
    primary: {
      main: primary,
    },
    text: {
      primary: grey,
    },
  },
  typography: {
    fontFamily: font,
    fontSize: 16,
  },
  status: {
    notStarted: red,
    Complete: green,
    inProgress: orange,
  },
  backgroundShades: {
    grey: backgroundGrey,
    white: backgroundWhite,
  },
  accents: {
    primary: burgundy,
  },
  border: {
    primary: lightGrey,
  },
  overrides: {
    MuiGrid: {
      container: {
        width: "100% !important",
        margin: "0 !important",
      },
    },
  },
});
