import axios from "axios";
import interceptors from "./interceptors";

const instance = (baseURL?: string) => {
  const instance = axios.create({ baseURL });

  instance.interceptors.request.use(
    interceptors.onRequest,
    interceptors.onRequestError
  );
  instance.interceptors.response.use(
    interceptors.onResponse,
    interceptors.onResponseError
  );
  return instance;
};

export default instance;
