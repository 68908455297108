import { Button, FormControl, MenuItem, Select } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { gradingsStrings } from "../../../resources/strings/gradings";
import { Control } from "../../../types/gradingForm";

interface Props {
  controls: Control[];

  updateSelectedControls(index: number, value: string): void;

  onSelectPrev?: () => void;
  onSelectNext?: () => void;

  readOnly?: boolean;
}

type Component = (props: Props) => JSX.Element;

export const GradingComponents: Component = ({
  controls,
  updateSelectedControls,
  onSelectPrev,
  onSelectNext,
  readOnly,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.gradingsList}>
      <div className={classes.headerRow}>
        {gradingsStrings.componentsHeaders.component}
      </div>
      <div className={classes.headerRow}>
        {gradingsStrings.componentsHeaders.grading}
      </div>
      {controls.map((control, i) => (
        <>
          <div key={`caption${i}`}>{control.caption}</div>
          <FormControl size="small" key={`select${i}`}>
            <Select
              value={control.value || ""}
              variant="outlined"
              className={classes.gradingSelect}
              disabled={readOnly}
              onChange={(e) =>
                updateSelectedControls(i, e.target.value as string)
              }
            >
              {control.options.map((option) => (
                <MenuItem value={option.caption}>{option.caption}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </>
      ))}
      <div className={classes.buttonRow}>
        <Button
          variant="outlined"
          disabled={!onSelectPrev}
          onClick={() => onSelectPrev && onSelectPrev()}
          className={clsx(classes.gradingButton, classes.previousButton)}
        >
          Previous
        </Button>

        <Button
          variant="contained"
          color="primary"
          disabled={!onSelectNext}
          onClick={() => onSelectNext && onSelectNext()}
          className={classes.gradingButton}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  gradingsList: {
    display: "grid",
    gridAutoRows: "minmax(50px, auto)",
    gridTemplateColumns: "1fr minmax(121px, auto)",
    justifyItems: "stretch",
    alignItems: "stretch",
    "& > *": {
      borderBottom: "1px solid #D8D8D8",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "start",
      minHeight: "50px",
    },
    "& > *:nth-child(odd)": {
      paddingLeft: "20px",
    },
  },
  headerRow: {
    background: "#FAFAFA",
    fontWeight: 600,
    fontSize: "1.2rem",
  },
  gradingSelect: {
    width: "100px",
    "& > div": {
      paddingTop: "8px",
      paddingBottom: "8px",
      textAlign: "start",
    },
  },
  buttonRow: {
    background: "#FAFAFA",
    flexDirection: "row",
    gridColumn: "1 / span 2",
    justifyContent: "space-between",
    paddingLeft: "0 !important",
    flexWrap: "wrap",
  },
  gradingButton: {
    margin: "20px",
    height: "40px",
    maxWidth: "200px",
    flexGrow: 1,
    fontWeight: 900,
    fontSize: "1.3rem",
    borderRadius: 0,
  },
  previousButton: {
    border: theme.spacing(0.25, "solid", `${theme.palette.primary.main}`),
    color: theme.palette.primary.main,
    "&.Mui-disabled": {
      borderWidth: theme.spacing(0.25),
    },
  },
  buttonSpacer: {
    flexGrow: 1,
  },
}));
