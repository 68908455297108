import { RouteProps } from "../../types";
import { useHistory, useParams } from "react-router-dom";
import { TableContainer } from "../general";
import { LineLayer } from "../../types/documents";
import { clientStrings as strings } from "../../resources/strings";
import { formatDateTime } from "../../helpers";
import { paths } from "../../navigation";
import { LINES_ENDPOINT } from "../../libs/config";
import { useCallback, useMemo, useState } from "react";
import { LineLayerUpload } from "./LineLayerUpload";
import {
  LineLayerLoadHistory
} from "../../components/lines/LineLayerLoadHistory";
import { useServiceData } from "../../hooks/general/useServiceData";
import { HooksLogger } from "../../hooks/hooks-logger";
import { useCancelToken } from "../../hooks/general";
import { notifications } from "../../libs/notifications";
import * as linesApi from "../../services/lines.service";

const logger = new HooksLogger("LineLayerList");

export interface Props extends RouteProps {
}

type Component = (props: Props) => JSX.Element;

const listEndpoint = (clientId: string) => `${LINES_ENDPOINT}/${clientId}/`;

export const LineLayerList: Component = (props) => {
  const { jwt } = props;
  const { clientId } = useParams<{ clientId: string }>();
  const history = useHistory();
  const cancelToken = useCancelToken();
  const setError = useCallback(e => notifications.error(e), []);

  const clickHandler = useCallback(
    ({ id }: LineLayer) => {
      history.push(paths.clients.lines.view(clientId, id));
    },
    [clientId, history]
  );

  const fetchLoads = useCallback(() => linesApi.listLoads(
    jwt,
    cancelToken,
    clientId
  ), [jwt, cancelToken, clientId]);
  const [loads] = useServiceData(
    "Line Layer loads",
    fetchLoads,
    logger,
    cancelToken,
    setError,
    1000,
  );

  const [selectedLoadId, setSelectedLoadId] = useState<string>();
  const selectedLoad = loads?.find(l => l.id === selectedLoadId);

  const endpoint = useMemo(() => listEndpoint(clientId), [clientId]);

  return <>
    <TableContainer<LineLayer>
      {...props}
      endpoint={endpoint}
      header={strings.header.lines}
      tableColumns={[
        {
          id: "name",
          Header: strings.labels.name,
          accessor: "name",
        },
        {
          id: "createdDate",
          Header: strings.labels.created,
          accessor: ({ createdDate }) => formatDateTime(createdDate),
        },
        {
          id: "updatedDate",
          Header: strings.labels.updated,
          accessor: ({ updatedDate }) => formatDateTime(updatedDate),
        },
      ]}
      clickHandler={clickHandler}
    />
    <LineLayerUpload clientId={clientId} jwt={props.jwt}/>
    <LineLayerLoadHistory {...props} loads={loads || []}
                          selectedLoad={selectedLoad}
                          onSelectLoad={setSelectedLoadId}
    />
  </>;
};

