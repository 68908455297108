import { useParams } from "react-router-dom";
import { RouteProps } from "../../types";
import { MapComponent } from "../map";

interface Props extends RouteProps {}
type Component = (props: Props) => JSX.Element;

export const MapWithFlight: Component = (props) => {
  const { clientId, packageId } = useParams<{
    clientId: string;
    packageId: string;
  }>();

  return (
    <>
      {clientId && packageId && (
        <MapComponent
          packageId={packageId}
          packageClientId={clientId}
          {...props}
        />
      )}
    </>
  );
};
