import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { CancelToken } from "axios";
import clsx from "clsx";
import { useEffect, useState } from "react";
import {
  ListChildComponentProps,
  VariableSizeList as List,
} from "react-window";
import useDeepCompareEffect from "use-deep-compare-effect";
import { Loader } from "..";
import { getOptionLabelFromValue } from "../../../helpers";
import { intersection, not } from "../../../helpers/array";
import { useCancelToken } from "../../../hooks/general";
import {
  DirectionActionEnum,
  DirectionState,
  useDirection,
} from "../../../hooks/useDirection";
import { DropdownOptionsWithCounts } from "../../../types";
import { defaultButtonStyle } from "../../styles";
import {
  AltChangeHandler,
  TransferList as TTransferList,
} from "../types/Modify";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: "auto",
      gridTemplateColumns: "4fr 1fr 4fr",
    },
    cardHeader: {
      padding: theme.spacing(1, 2),
    },
    list: {
      height: theme.spacing(50),
      backgroundColor: theme.palette.background.paper,
      overflow: "auto",
    },
    button: {
      ...defaultButtonStyle(theme),
      backgroundColor: theme.palette.primary.main,
      color: theme.backgroundShades.white,
      margin: theme.spacing(1, 0),
    },
    middleButtons: {
      width: "100%",
      display: "flex",
      margin: theme.spacing(0.5, 0),
      justifyContent: "space-between",
    },
    checkbox: {
      color: theme.palette.primary.main,
      "& .MuiSvgIcon-root": {
        color: theme.palette.primary.main,
      },
    },
    "@global": {
      "*::-webkit-scrollbar": {
        width: "0.4em",
      },
      "*::-webkit-scrollbar-track": {
        "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
      },
      "*::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(0,0,0,.1)",
      },
    },
  })
);

interface Props<D extends object> {
  config: TTransferList<D>;
  handleChange: AltChangeHandler<D>;
  loading?: boolean;
  options?: Promise<DropdownOptionsWithCounts | undefined>;
  getOptions?: (
    data: Partial<D>,
    cancelToken: CancelToken
  ) => Promise<DropdownOptionsWithCounts | undefined>;
  getOptionsParams?: Partial<D>;
}

const initialState: DirectionState = {
  left: [],
  right: [],
  checked: [],
};

export default function TransferList<D extends object>({
  config: input,
  handleChange,
  loading,
  getOptions,
  getOptionsParams,
  options: inputOptions,
}: Props<D>) {
  const { name, disabled, value, filteredValues } = input;
  const classes = useStyles();
  const [loadingOptions, setLoadingOptions] = useState(false);
  const [state, dispatch] = useDirection(initialState);
  const { left, right, checked } = state;
  const [options, setOptions] = useState<DropdownOptionsWithCounts>([]);
  const cancelToken = useCancelToken();

  useEffect(() => {
    dispatch({ type: DirectionActionEnum.OnChange, options, value });
  }, [options, value, dispatch]);

  useDeepCompareEffect(() => {
    const asyncFunction = async () => {
      setLoadingOptions(true);

      const fetchedOptions =
        getOptionsParams && getOptions
          ? await getOptions(getOptionsParams, cancelToken)
          : [];
      setOptions([...((await inputOptions) ?? []), ...(fetchedOptions ?? [])]);
      setLoadingOptions(false);
    };
    asyncFunction();
  }, [inputOptions, getOptions, getOptionsParams]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value: string) => () => {
    dispatch({ type: DirectionActionEnum.Toggle, value });
  };

  const numberOfChecked = (items: string[]) =>
    intersection(checked, items).length;

  const handleToggleAll = (items: string[]) => () => {
    dispatch({ type: DirectionActionEnum.ToggleAll, items });
  };

  const handleCheckedRight = () => {
    const newRight = right.concat(leftChecked);

    dispatch({
      type: DirectionActionEnum.CheckedRight,
      right: newRight,
      left,
      leftChecked,
    });
    handleChange({ name, value: newRight });
  };

  const handleCheckedLeft = () => {
    const newRight = not(right, rightChecked);

    dispatch({
      type: DirectionActionEnum.CheckedLeft,
      right: newRight,
      left,
      rightChecked,
    });
    handleChange({ name, value: newRight });
  };

  const listItem = ({ data: items, index, style }: ListChildComponentProps) => {
    const item = items[index];

    const labelId = `transfer-list-all-item-${item}-label`;
    const foundValue = getOptionLabelFromValue(item, options);

    // if (foundValue)
    return (
      <ListItem
        key={item}
        role="listitem"
        button
        disabled={disabled}
        onClick={handleToggle(item)}
        style={style}
      >
        <ListItemIcon>
          <Checkbox
            checked={checked.indexOf(item) !== -1}
            tabIndex={-1}
            disableRipple
            inputProps={{ "aria-labelledby": labelId }}
            className={classes.checkbox}
          />
        </ListItemIcon>
        <ListItemText
          id={labelId}
          primary={`${foundValue} (${
            options.find((opt) => opt.value === item)?.totalCount
          })`}
        />
      </ListItem>
    );
    // return <></>;
  };

  const customList = (items: string[]) => (
    <div>
      <Card>
        <Loader inline active={loading || loadingOptions}>
          <List
            className={classes.list}
            itemSize={() => 40}
            itemCount={items.length}
            height={400}
            width={"100%"}
            itemData={items}
          >
            {listItem}
          </List>
        </Loader>
      </Card>
      <Button
        variant="contained"
        size="small"
        className={classes.button}
        onClick={handleToggleAll(items)}
        aria-label="all items selected"
        disabled={disabled}
      >
        {numberOfChecked(items) === items.length && items.length !== 0
          ? "Deselect All"
          : "Select All"}
      </Button>
    </div>
  );

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      alignItems="center"
      className={classes.root}
      title={name}
    >
      <Grid item xs={5}>
        {customList(
          left.filter((i) =>
            filteredValues !== undefined && Array.isArray(filteredValues)
              ? filteredValues.includes(i)
              : true
          )
        )}
      </Grid>
      <Grid item xs={2}>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="contained"
            size="small"
            className={clsx(classes.button, classes.middleButtons)}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0 || disabled}
            aria-label="move selected right"
          >
            <span></span>
            <span>Add</span>
            <span>&gt;</span>
          </Button>
          <Button
            variant="contained"
            size="small"
            className={clsx(classes.button, classes.middleButtons)}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0 || disabled}
            aria-label="move selected left"
          >
            <span>&lt;</span>
            <span>Remove</span>
            <span></span>
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={5}>
        {customList(right)}
      </Grid>
    </Grid>
  );
}
