import { useMemo, useEffect } from "react";
import axios, { CancelToken } from "axios";
import { HooksLogger } from "../hooks-logger";

const logger = new HooksLogger("useCancelToken.ts");

export const useCancelToken = (): CancelToken => {
  const source = useMemo(() => axios.CancelToken.source(), []);
  useEffect(() => {
    logger.info("Initialising");
    return () => {
      logger.info("Cancelling");
      source.cancel("Cancelling request, component unmounted");
    };
  }, [source]);
  return source.token;
};
