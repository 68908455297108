import BaseGPXParser, { Point } from "gpxparser";
import { getNested } from "../../../helpers";
import { notifications } from "../../../libs/notifications";
import { appStrings } from "../../../resources/strings";

export class GPXParser extends BaseGPXParser {
  trackPoints: Point[];

  constructor(data: string) {
    super();
    this.parse(data);

    this.trackPoints = getNested(["tracks", 0, "points"])(this);

    // Basic validation, check for parsererror from XML parser
    // and check that there's at least one point
    const xmlDoc = this.xmlSource as unknown as XMLDocument;
    const point = this.trackPoints[0];

    if (xmlDoc.getElementsByTagName("parsererror").length > 0 || !point) {
      notifications.error(appStrings.notifications.messages.gpxError);
      throw new Error(appStrings.notifications.messages.gpxError);
    }
  }
}
