import LuxonUtils from "@date-io/luxon";
import { makeStyles } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { DateTime } from "luxon";
import { mapStrings as strings } from "../../../resources/strings";
import { AvoidanceZoneData } from "../../../types/documents";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: theme.spacing(2 / 0.75),
    fontWeight: "bolder",
  },
  input: {
    marginTop: theme.spacing(4, "!important"),
  },
}));

interface Props {
  data: AvoidanceZoneData;
  setFormData: React.Dispatch<React.SetStateAction<AvoidanceZoneData>>;
}
type Component = (props: Props) => JSX.Element;

export const DatePicker: Component = ({ data, setFormData }) => {
  const classes = useStyles();
  return (
    <MuiPickersUtilsProvider utils={LuxonUtils}>
      <KeyboardDatePicker
        autoOk
        value={data.date ? DateTime.fromISO(data.date) : ""}
        format="dd/MM/yyyy"
        variant="inline"
        InputLabelProps={{
          shrink: true,
          className: classes.label,
        }}
        InputProps={{
          disableUnderline: true,
          multiline: true,
          className: clsx(classes.input),
        }}
        label={strings.avoidanceZone.date}
        error={!data.date}
        helperText={!data.date ? "Enter a valid date" : ""}
        onChange={(newDate) => {
          setFormData({ ...data, date: newDate?.toISODate() || "" });
        }}
      />
    </MuiPickersUtilsProvider>
  );
};
