import { appStrings } from "./app";

export const clientStrings = {
  global: { ...appStrings },
  header: {
    clients: "Clients",
    clientDetails: "Client Details",
    createClient: "Create Client",
    viewClient: "View Client",
    editClient: "Edit Client",
    mapTiles: "Map Tiles",
    lines: "Lines",
    createLine: "Create Line",
    viewLine: "View Line",
    editLine: "Edit Line",
  },
  labels: {
    save: "Save",
    reset: "Reset",
    edit: "Edit",
    clientId: "Client ID",
    status: "Status",
    created: "Date Created",
    updated: "Last Updated",
    createClient: "Create Client",
    forename: "Forename",
    surname: "Surname",
    name: "Name",
    showLines: "Show Lines",
    createLine: "New Line",

    transmissionVoltage: "Transmission Voltage (kV)",
    minResolution: "Min Resolution",
    maxResolution: "Max Resolution",
    coordinateSystem: "Coordinate System",
  },
  notifications: {
    deleted: "Successfully deleted client.",
    failedDelete: "Failed to delete client.",
  },
};
