import { useMemo } from "react";
import { AssetDetails } from "../../types/documents/Assets";

type CircuitsByTowerSide = {
  left: string[];
  right: string[];
};

export const useSearchCircuitsByTowerSide = (
  circuitIds: string[],
  assets: AssetDetails[]
): CircuitsByTowerSide => {
  return useMemo(() => {
    const circuitAssets = assets.filter((asset) =>
      circuitIds.includes(asset.circuitId)
    );
    const init: CircuitsByTowerSide = {
      left: [],
      right: [],
    };
    const results = circuitAssets.reduce((prev, current) => {
      if (current.towerSide === "left") {
        return {
          left: [...prev.left, current.circuitId],
          right: [...prev.right],
        };
      } else {
        return {
          left: [...prev.left],
          right: [...prev.right, current.circuitId],
        };
      }
    }, init);

    return {
      left: Array.from(new Set(results.left)),
      right: Array.from(new Set(results.right)),
    };
  }, [circuitIds, assets]);
};
