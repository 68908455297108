import { Button, FormHelperText, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { BaseControl } from "../../components/general/controls/BaseControl";
import { Input } from "../../components/general/controls/Input";
import { Link } from "../../components/general/controls/Link";
import { Loader } from "../../components/general/Loader";
import { useForm } from "../../hooks/general/useForm";
import { completeNewPassword, signInWithDetails } from "../../libs/auth";
import { paths } from "../../navigation/paths";
import { authStrings as strings } from "../../resources/strings/auth";
import type { CognitoUser } from "../../types";
import { AuthWrapper } from "./AuthWrapper";

interface Props {
  user: CognitoUser | null;
}
type Component = (props: Props) => JSX.Element;

type Form = {
  username: string;
  password: string;
  newPassword: string;
  confirmNewPassword: string;
};

const useStyles = makeStyles((theme) => ({
  spacer: {
    margin: theme.spacing(2, "0"),
  },
  button: { borderRadius: 0, fontWeight: "bolder" },
  header: {
    fontWeight: "bolder",
    margin: "2rem 0",
    fontSize: "1.2rem",
  },
  link: {
    textDecoration: "underline",
  },
}));

export const CustomLogin: Component = ({ user }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [result, setResult] = useState({ challengeName: "" });
  const [success, setSuccess] = useState(false);

  const [formData, handleChange] = useForm<Form>({
    username: "",
    password: "",
    newPassword: "",
    confirmNewPassword: "",
  });
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    if (user || success) history.push(paths.root);
  }, [user, success, history]);

  const onLogin: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const result = await signInWithDetails(
        formData.username,
        formData.password
      );

      setLoading(false);
      setError("");

      if (result.challengeName) {
        setResult(result);
      } else {
        setSuccess(true);
      }
    } catch (e: any) {
      setLoading(false);
      setSuccess(false);
      setError(e ? e : strings.errors.signIn);
    }
  };

  const onChangePassword: React.FormEventHandler<HTMLFormElement> = async (
    e
  ) => {
    e.preventDefault();

    try {
      setLoading(true);
      await completeNewPassword(result, formData.newPassword);

      setLoading(false);
      setError("");
      setResult({ challengeName: "" });

      setSuccess(true);
    } catch (e: any) {
      setLoading(false);
      setSuccess(false);
      setError(e ? e : strings.errors.changePassword);
    }
  };

  const renderControl = (control: JSX.Element) => {
    return (
      <BaseControl control={true} padded={true}>
        {control}
      </BaseControl>
    );
  };

  const username: string = "username";

  const renderLoginForm = () => {
    const formControls = [
      <Input
        config={{
          value: formData.username,
          name: username,
          label: strings.labels.username,
          fullWidth: true,
          autoComplete: "username",
          controltype: "input",
        }}
        handleChange={handleChange}
      />,
      <Input
        config={{
          value: formData.password,
          name: "password",
          label: strings.labels.password,
          type: "password",
          fullWidth: true,
          autoComplete: "password",
          controltype: "input",
        }}
        handleChange={handleChange}
      />,
      <Button
        type="submit"
        children={strings.labels.login}
        color="primary"
        variant="contained"
        fullWidth
        disabled={!formData.username || !formData.password}
        className={clsx(classes.spacer, classes.button)}
      />,
    ];
    return (
      <>
        <Typography
          variant="button"
          color="primary"
          className={clsx(classes.header)}
        >
          {strings.headers.login}
        </Typography>
        <form noValidate onSubmit={onLogin} autoComplete="off">
          {formControls.map((control) => renderControl(control))}
          {error ? <FormHelperText error>{error}</FormHelperText> : null}
        </form>
        <Link
          key="passwordReset"
          config={{
            text: strings.labels.resetPassword,
            to: paths.auth.passwordReset,
            className: clsx(classes.spacer, classes.link),
          }}
        />
      </>
    );
  };

  const renderNewPasswordForm = () => {
    const formControls = [
      <Input
        config={{
          value: formData.newPassword,
          name: "newPassword",
          label: strings.labels.newPassword,
          type: "password",
          fullWidth: true,
          autoComplete: "new-password",
          controltype: "input",
        }}
        handleChange={handleChange}
      />,
      <Input
        config={{
          value: formData.confirmNewPassword,
          name: "confirmNewPassword",
          label: strings.labels.confirmNewPassword,
          type: "password",
          fullWidth: true,
          autoComplete: "confirm-password",
          controltype: "input",
        }}
        handleChange={handleChange}
      />,
      <Button
        type="submit"
        children={strings.labels.confirm}
        color="primary"
        variant="contained"
        fullWidth
        disabled={formData.newPassword !== formData.confirmNewPassword}
        className={clsx(classes.spacer, classes.button)}
      />,
    ];
    return (
      <>
        <Typography
          variant="button"
          color="primary"
          className={clsx(classes.header)}
        >
          {strings.headers.changePassword}
        </Typography>
        <form noValidate onSubmit={onChangePassword} autoComplete="off">
          {formControls.map((control) => renderControl(control))}
          {error ? <FormHelperText error>{error}</FormHelperText> : null}
        </form>
      </>
    );
  };

  const renderContent = () => {
    if (loading) {
      return <Loader active inline text={strings.labels.signingIn} />;
    }

    switch (result.challengeName) {
      case strings.challenges.newPassword:
        return renderNewPasswordForm();
      default:
        return renderLoginForm();
    }
  };

  return <AuthWrapper>{renderContent()}</AuthWrapper>;
};
