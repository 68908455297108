import { useReducer } from "react";
import { intersection, not, union } from "../helpers/array";
import { DropdownOptionsWithCounts } from "../types";

type OnChangeDirectionAction = {
  type: DirectionActionEnum.OnChange;
  options: DropdownOptionsWithCounts;
  value: any;
};

type CheckedRightDirectionAction = {
  type: DirectionActionEnum.CheckedRight;
  right: any;
  left: string[];
  leftChecked: string[];
};
type CheckedLeftDirectionAction = {
  type: DirectionActionEnum.CheckedLeft;
  right: any;
  left: string[];
  rightChecked: string[];
};
type ToggleDirectionAction = {
  type: DirectionActionEnum.Toggle;
  value: string;
};
type ToggleAllDirectionAction = {
  type: DirectionActionEnum.ToggleAll;
  items: string[];
};

type DirectionAction =
  | OnChangeDirectionAction
  | CheckedRightDirectionAction
  | CheckedLeftDirectionAction
  | ToggleDirectionAction
  | ToggleAllDirectionAction;

export type DirectionState = {
  left: string[];
  right: string[];
  checked: string[];
};

export enum DirectionActionEnum {
  OnChange = "onChange",
  CheckedLeft = "checkLeft",
  CheckedRight = "checkRight",
  Toggle = "toggle",
  ToggleAll = "toggleAll",
}

export const useDirection = (initialState: DirectionState) => {
  const directionOnChange = (
    state: DirectionState,
    action: OnChangeDirectionAction
  ): DirectionState => {
    const { options, value } = action;
    return {
      left: options
        .map((option) => option.value)
        .filter((opVal) => !value?.includes(opVal.toString())),
      right: options
        .map((option) => option.value)
        .filter((opVal) => value?.includes(opVal.toString())),
      checked: state.checked,
    };
  };

  const directionCheckedRight = (
    state: DirectionState,
    action: CheckedRightDirectionAction
  ): DirectionState => {
    const { right, left, leftChecked } = action;

    return {
      right: right,
      left: not(left, leftChecked),
      checked: not(state.checked, leftChecked),
    };
  };

  const directionCheckedLeft = (
    state: DirectionState,
    action: CheckedLeftDirectionAction
  ): DirectionState => {
    const { left, right, rightChecked } = action;

    return {
      left: left.concat(rightChecked),
      right: right,
      checked: not(state.checked, rightChecked),
    };
  };

  const directionToggle = (
    state: DirectionState,
    action: ToggleDirectionAction
  ): DirectionState => {
    const currentIndex = state.checked.indexOf(action.value);
    const newChecked = [...state.checked];

    if (currentIndex === -1) {
      newChecked.push(action.value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    return {
      ...state,
      checked: newChecked,
    };
  };

  const directionToggleAll = (
    state: DirectionState,
    action: ToggleAllDirectionAction
  ): DirectionState => {
    const allChecked =
      intersection(state.checked, action.items).length === action.items.length;
    let checkHandler = not;
    if (!allChecked) {
      checkHandler = union;
    }

    return {
      ...state,
      checked: checkHandler(state.checked, action.items),
    };
  };

  const directionReducer = (
    state: DirectionState,
    action: DirectionAction
  ): DirectionState => {
    switch (action.type) {
      case "onChange":
        return directionOnChange(state, action);
      case "checkRight":
        return directionCheckedRight(state, action);
      case "checkLeft":
        return directionCheckedLeft(state, action);
      case "toggle":
        return directionToggle(state, action);
      case "toggleAll":
        return directionToggleAll(state, action);
      default:
        return state;
    }
  };

  return useReducer(directionReducer, initialState);
};
