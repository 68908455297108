import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  header: {
    background: theme.palette.background.paper,
    fontSize: "1.2rem",
    textAlign: "start",
    padding: theme.spacing(1.5),
  },
  headerField: {
    fontWeight: 600,
  },
  tableRow: {
    display: "table-row",
    "& td:first-child": {
      minWidth: "150px",
      maxWidth: "150px",
    },
  },
  tableHead: {
    "& tr": {
      "&:not(:last-child)": {
        "& th": {
          fontWeight: 600,
          fontSize: "1.2rem",
        },
      },
      "&:last-child": {
        verticalAlign: "bottom",
        paddingBottom: theme.spacing(0.875),

        "& th:not(:first-child)": {
          "& div": {
            writingMode: "vertical-lr",
            transform: "rotate(180deg)",
          },
        },
      },
    },
  },
  tableCell: {
    padding: theme.spacing(0.625),
    fontSize: "1.14rem",
    textAlign: "center",
    border: theme.spacing(0.125, "solid", `${theme.border.primary}`),
    minWidth: "30px",
    maxWidth: "30px",
  },
  table: {
    boxShadow: "0 0 0 0",
  },

  dropdown: {
    width: "100%",
    padding: theme.spacing(0.5),

    "& .MuiSelect-root": {
      fontSize: "1.1rem",
      fontWeight: 500,
      textAlign: "left",
      paddingLeft: theme.spacing(2),
      backgroundColor: theme.backgroundShades.grey,
      border: theme.spacing(0.125, "solid", `${theme.border.primary}`),
    },
  },

  filterTitle: {
    fontSize: "1.3rem",
    fontWeight: 700,
    textTransform: "uppercase",
    padding: theme.spacing(0.5),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  filterLabel: {
    fontWeight: 600,
    textAlign: "left",
    padding: theme.spacing(0.5),
    marginTop: theme.spacing(2),
  },

  button: {
    fontWeight: 700,
    fontSize: "1.3rem",
    borderRadius: 0,
    ":hover": {
      fontWeight: 200,
    },
  },

  applyButton: {
    width: "calc(100% - 8px)",
    marginTop: theme.spacing(2),
  },
  resetButton: {
    width: "calc(100% - 8px)",
    marginTop: theme.spacing(2),
    border: `${theme.spacing(0.3, "solid")} !important`,
  },

  headerButton: {
    width: theme.spacing(12),
    border: `${theme.spacing(0.3, "solid")} !important`,
  },

  gradingForm: {
    display: "grid",
    gridTemplateAreas: `
      'filterPanel reportDetails'
      'filterPanel table'
    `,
    gridTemplateRows: "auto 1fr",
    gridTemplateColumns: "250px calc(100% - 250px)",
    height: "calc(100% - 38px)",
    background: theme.backgroundShades.white,
  },

  hideFilterPanel: {
    gridTemplateAreas: `
      'reportDetails'
      'table'
    `,
    gridTemplateColumns: "100%",
  },

  filterPanel: {
    gridArea: "filterPanel",
    borderRight: "1px solid #D8D8D8",
    padding: theme.spacing(1),
  },
  hide: {
    display: "none",
  },
  reportDetails: {
    gridArea: "reportDetails",
    alignItems: "center",
    display: "flex",
    gap: theme.spacing(1.5),
  },
  gradingControls: {
    gridArea: "table",
    borderRight: "1px solid #D8D8D8",
  },
  dropdownGroup: {
    fontStyle: "italic",
    fontWeight: 700,
    opacity: "1 !important",
  },
}));
