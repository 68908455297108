export const assetLoadStrings = {
  labels: {
    general: "General",
    name: "Unique Asset ID",
    displayName: "Asset Display Name",
    transmissionVoltage: "Transmission Voltage",
    containerName: "Asset Container Name",
    containerKeys: "Asset Key",
    assetType: "Asset Type",
    circuitId: "Circuit ID",
    circuitSide: "Side of Tower",

    coordinates: "Coordinates",
    xCoordinate: "X Coordinate (Longitutde/Easting)",
    yCoordinate: "Y Coordinate (Latitude/Northing)",
    coordinateSystem: "Coordinate System",
    EPSG_4326: "World Geodetic System / GPS (EPSG:4326)",
    EPSG_27700: "British National Grid (EPSG:27700)",
    EPSG_29903: "Irish Grid TM75 (EPSG:29903)",

    defaultType: "Default Asset Type",
    typeMap: "Asset Type Mapping",

    save: "Save",
    reset: "Reset",
  },
  assetFilter: {
    name: "Name",
    groupedBy: "Grouped By",
    create: "New Asset Filter",
    edit: "Edit Asset Filter",
    save: "Save",
    delete: "Delete",
  },
};

export const assetDetailsStrings = {
  labels: {
    name: "Asset Name",
    voltage: "Transmission Voltage",
    towerName: "Tower Name",
    towerType: "Tower Type",
    towerSide: "Tower Side",
    circuitId: "Circuit ID",
  },
  propertyNames: {
    displayName: "displayName",
  },
};
