import { CancelToken } from "axios";
import { useEffect, useState } from "react";
import * as clientsApi from "../../services/clients.service";
import { ClientData } from "../../types/documents";
import { HooksLogger } from "../hooks-logger";

const logger = new HooksLogger("Use Clients.ts");

type Props = {
  jwt: any;
  cancelToken: CancelToken;
  userClientId: any;
};

type Result = [Record<string, ClientData>, boolean];

export const useClients = ({
  userClientId,
  jwt,
  cancelToken,
}: Props): Result => {
  const [clients, setClients] = useState<Record<string, ClientData>>({});
  const [clientsLoading, setClientsLoading] = useState(false);

  useEffect(() => {
    if (userClientId) {
      setClientsLoading(false);
      // No need to call setClients.
      return;
    }
    const query = async () => {
      logger.request("Getting client data");
      setClientsLoading(true);
      try {
        const items = await clientsApi.listClients(jwt, cancelToken);
        const clients: Record<string, ClientData> = {};
        for (const client of items) {
          clients[client.id] = client;
        }
        setClients(clients);
        setClientsLoading(false);
        logger.success(clients);
      } catch (e: any) {
        if (cancelToken.reason) return;
        const error = e.message ? e.message : e;
        setClientsLoading(false);
        logger.error(error);
      }
    };

    query();
  }, [userClientId, jwt, cancelToken]);

  return [clients, clientsLoading];
};
