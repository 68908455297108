export type ParseFqnResult = {
  sectionType: string;
  sectionId: string;
  subsectionType: string;
  controlType: string;
};

export const parseFqn = (fqn: string): ParseFqnResult => {
  //Validate the FQN
  const regexes = getFqnRegex();
  const fqnMatch = fqn.match(regexes.fqnPattern);
  if (!fqnMatch) {
    throw new Error("Cannot parse invalid FQN");
  }

  const idTypes = {
    sectionType: "st",
    sectionId: "s",
    subsectionType: "sst",
    controlType: "ctrlt",
  };
  let fqnProperties: any = {};
  Object.entries(idTypes).forEach(([type, identifier]) => {
    fqnProperties[type] = getIdFromFqn(identifier, fqn);
  });

  return fqnProperties as ParseFqnResult;
};

export const buildFqn = ({
  sectionId = "*",   // The selected section ID, which defaults to * for a Form section, but would be populated with an ID for a job section
  sectionTypeId,     // The ID of the section within the selected section
  subsectionTypeId,  // The ID of the subsection within the current section
  controlTypeId     // The ID of the control within the section or subsection
}: {
  sectionId: string;
  sectionTypeId: string;
  subsectionTypeId?: string;
  controlTypeId?: string;
}) => {
  const fqns: string[] = [];
  if (sectionTypeId) {
    fqns.push(`.st[${sectionTypeId}]`);
  }
  if (sectionId) {
    fqns.push(`.s[${sectionId}]`);
  }
  if (subsectionTypeId) {
    fqns.push(`.sst[${subsectionTypeId}]`);
  }
  if (controlTypeId) {
    fqns.push(`.ctrlt[${controlTypeId}]`);
  }

  const newFqn = `^${fqns.join("")}$`;
  // confirm the new FQN is valid and can be parsed correctly
  parseFqn(newFqn);
  return newFqn;
};

function getIdFromFqn(idIdentifier: string, fqn: string): string | undefined {
  const idTypeLength = idIdentifier.length;
  const idLength = 36;
  const indexOfIdentifier = fqn.indexOf(idIdentifier + "[");
  if (indexOfIdentifier > 0) {
    // if sectionId = * (catch all) then return all section IDs with the same sectionType
    if (fqn.substring(indexOfIdentifier + 2, indexOfIdentifier + 3) === "*") {
      const sectionTypeIndex = fqn.indexOf("st[");
      const indexOfId = sectionTypeIndex + idTypeLength + 1;
      return "st" + fqn.substring(indexOfId + 1, indexOfId + idLength + 1);
    } else {
      const indexOfId = indexOfIdentifier + idTypeLength + 1;
      return fqn.substring(indexOfId, indexOfId + idLength);
    }
  } else return undefined;
}

export function replaceSectionInFqn(fqn: string, newSectionId: string): string {
   const regex = getFqnRegex();
   const newSection = `.s[${newSectionId}]`;
   const newFqn = fqn.replace(regex.sectionPattern, newSection);
   return newFqn;
}

function getFqnRegex() {
  const guidPattern =
    "[a-fA-f0-9]{8}-[a-fA-f0-9]{4}-[a-fA-f0-9]{4}-[a-fA-f0-9]{4}-[a-fA-f0-9]{12}";
  const sectionOptionsForForms = `(\\*|me)`;  //This is not needed in the job renderer but can be activated when we add a form renderer to this app.
  const sectionOptionsForJobs = `(\\*|me|${guidPattern})`;
  const sectionPatternForForms = `(\\.s\\[${sectionOptionsForForms}\\])`;
  const sectionPattern = `(\\.s\\[${sectionOptionsForJobs}\\])`;
  const sectionTypePattern = `(\\.st\\[${guidPattern}\\])`;
  const subSectionPattern = `(\\.sst\\[${guidPattern}\\])`;
  const controlPattern = `(\\.ctrlt\\[${guidPattern}\\])`;
  const fqnPattern = `^\\^${sectionTypePattern}${sectionPattern}(${subSectionPattern})*(${controlPattern})*\\$$`;

  return {
    guidPattern: RegExp(guidPattern, "g"),
    sectionPatternForForms: RegExp(sectionPatternForForms, "g"),
    sectionPattern: RegExp(sectionPattern, "g"),
    sectionTypePattern: RegExp(sectionTypePattern, "g"),
    subSectionPattern: RegExp(subSectionPattern, "g"),
    controlPattern: RegExp(controlPattern, "g"),
    fqnPattern: RegExp(fqnPattern, "g"),
  };
}

