import { appStrings } from "./app";

export const userStrings = {
  global: { ...appStrings },
  header: {
    users: "Users",
    userDetails: "User Details",
    createUser: "Create User",
    viewUser: "View User",
    editUser: "Edit User",
    signInInfo: "Sign-In Information",
    userInfo: "User Information",
  },
  labels: {
    username: "Username",
    email: "Email address",
    roleId: "User Role",
    forename: "Forename",
    surname: "Surname",
    created: "Date Created",
    updated: "Last Updated",
    role: "Role",
    client: "Client",
    createUser: "Create User",
    save: "Save",
    reset: "Reset",
    edit: "Edit",
  },
  notifications: {
    deleted: "Successfully deleted user.",
    failedDelete: "Failed to delete user.",
  },
};
