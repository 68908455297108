import { makeStyles } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles((theme) => ({
  searchBar: {
    display: "flex",
    alignItems: "center",
    width: 350,
    borderRadius: "0",
    border: theme.spacing(0.125, "solid", `${theme.border.primary}`),
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.spacing(0, 0, 0, 0),
  },
  input: {
    ml: 1,
    flex: 1,
    padding: theme.spacing(0.625),
    paddingLeft: theme.spacing(1.875),
  },
  icon: {
    p: theme.spacing(1.25),
  },
}));

type Component = () => JSX.Element;

export const BookingSearchBar: Component = () => {
  const classes = useStyles();

  const component = () => {
    return <></>;
  };

  return (
    <Paper component={component} className={clsx(classes.searchBar)}>
      <InputBase
        className={clsx(classes.input)}
        placeholder="Search customer name or ref no."
        inputProps={{ "aria-label": "search" }}
      />
      <IconButton
        disabled
        className={clsx(classes.icon)}
        aria-label="search-icon"
      >
        <SearchIcon />
      </IconButton>
    </Paper>
  );
};
