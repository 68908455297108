import { defectStrings as strings } from "../../resources/strings";
import { useDefectsToolbarStyles } from "./styles/defectsToolbar";

export const DefectsToolbarHeader = () => {
  const classes = useDefectsToolbarStyles();

  return (
    <div className={classes.root}>
      <h3 className={classes.header}>{strings.header.inspectionTools}</h3>
    </div>
  );
};
