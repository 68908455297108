import { Dialog, DialogContent } from "@material-ui/core";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { API } from "../../../API";
import { Loader } from "../../../components/general";
import type { Mode } from "../../../components/general/types/Modify";
import { isOfflineApp } from "../../../helpers";
import { useCancelToken } from "../../../hooks/general";
import { PACKAGES_ENDPOINT } from "../../../libs/config";
import { notifications } from "../../../libs/notifications";
import { paths } from "../../../navigation";
import { appStrings as strings } from "../../../resources/strings";
import { getAssetFilters } from "../../../services/assetFilters.service";
import { getProject } from "../../../services/projects.service";
import { theme } from "../../../styles/theme";
import { RouteProps } from "../../../types";
import type { PackageData, ProjectData } from "../../../types/documents";
import { AssetFilter } from "../../../types/documents/Assets";
import { AssetFilterModify } from "../../assetFilter/AssetFilterModify";
import { ModifyContainer } from "../../general";
import { componentConfigurations, constraints } from "./resources";

const putEndpoint = `${PACKAGES_ENDPOINT}/submit`;
const queryEndpoint = `${PACKAGES_ENDPOINT}`;

export interface Props extends RouteProps {
  mode: Mode;
}
type Component = (props: Props) => JSX.Element;

export const PackageModify: Component = ({ mode, ...routeProps }) => {
  const { projId } = useParams<{ projId: string }>();

  const [projectId, setProjectId] = useState("");
  const [project, setProject] = useState<ProjectData>();

  const [filterOptions, setFilterOptions] = useState<any[]>();
  const [filterId, setFilterId] = useState("");
  const [groupedBy, setGroupedBy] = useState("");

  const [groupedByOptions, setGroupedByOptions] = useState<any>();

  const [open, setOpen] = useState(false);

  const { jwt } = routeProps;
  const cancelToken = useCancelToken();

  const APIFunctions = useMemo(
    () => new API(cancelToken, jwt),
    [jwt, cancelToken]
  );

  const clientId = useMemo(() => (project ? project.clientId : ""), [project]);

  useEffect(() => setProjectId(projId), [projId]);

  useEffect(() => {
    const getProjectData = async () => {
      if (!projectId) return;

      try {
        const project = await getProject(jwt, cancelToken, projectId);
        if (!project) throw new Error();

        setProject(project);
        setGroupedBy(project.groupedBy);
      } catch (e: any) {
        notifications.error(strings.notifications.messages.retrieveError);
      }
    };

    getProjectData();
  }, [jwt, cancelToken, projectId]);

  useEffect(() => {
    if (!clientId) return;
    const getGroupedByOptions = async () => {
      const groups = await APIFunctions.getAssetGroups(clientId);
      if (!groups) return;
      const groupOptions = groups.map((group) => ({
        label: group,
        value: group,
      }));
      setGroupedByOptions(groupOptions);
    };
    getGroupedByOptions();
  }, [APIFunctions, clientId]);

  const getGroupedAssets = useCallback(async () => {
    if (!project) return;

    try {
      const groupedAssets = await APIFunctions.getGroupedAssets(
        project.groupedBy,
        clientId
      );
      if (!groupedAssets) throw new Error();

      return groupedAssets
        .filter(({ value }) => project.selectedGroups.includes(value))
        .map(({ value, totalCount }) => ({
          value,
          label: value,
          totalCount,
        }));
    } catch (e: any) {
      notifications.error(strings.notifications.messages.retrieveError);
    }
  }, [APIFunctions, project, clientId]);

  const getFilters = useCallback(async () => {
    if (!project || isOfflineApp()) return;

    try {
      const filters: AssetFilter[] = await getAssetFilters(
        jwt,
        cancelToken,
        project.clientId,
        project.groupedBy
      );
      if (!filters) throw new Error();

      const groupOptions = filters
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((filter) => ({
          label: filter.name,
          value: filter.id,
          options: filter.selectedGroups,
        }));

      setFilterOptions([{ label: "< None >", value: "" }, ...groupOptions]);
    } catch (e: any) {
      notifications.error(strings.notifications.messages.retrieveError);
    }
  }, [jwt, cancelToken, project]);

  useEffect(() => {
    getFilters();
  }, [getFilters]);

  const closeDialog = async () => {
    setOpen(false);
    getFilters();
  };

  return (
    <>
      <Loader active={mode === "create" && !groupedBy}>
        <ModifyContainer<PackageData>
          {...routeProps}
          putEndpoint={putEndpoint}
          queryEndpoint={queryEndpoint}
          mode={mode}
          initialData={{
            id: "",
            projectId: projId,
            name: "",
            createdDate: "0000-00-00T00:00:00.000A",
            createdById: "",
            groupedBy: groupedBy,
            filter: "",
            selectedGroups: [],
            assetsPatrolled: 0,
            assetsDefective: 0,
            assetsToPatrol: 0,
            assetsFootPatrolled: 0,
          }}
          componentConfiguration={({ filter, projectId }) => {
            setFilterId(filter);
            setProjectId(projectId);
            return [
              componentConfigurations.details(mode, jwt, cancelToken, {
                filter,
                filterOptions,
                groupedBy,
                groupedByOptions,
                filteredValues:
                  filterOptions?.find((i) => i.value === filter)?.options ?? "",
                getGroupedAssets,
                setOpen,
              }),
            ];
          }}
          keysToRemove={[
            "createdById",
            "updatedById",
            "createdDate",
            "updatedDate",
            "filter",
            { mode: "update", attribute: "projectId" },
            "assetsPatrolled",
            "assetsDefective",
            "assetsToPatrol",
            "assetsFootPatrolled",
            "project",
            { mode: "create", attribute: "id" },
            "assets",
          ]}
          constraints={constraints}
          redirectPath={paths.projects.view(projectId)}
        />
      </Loader>
      {!isOfflineApp() ? (
        <Dialog open={open} onClose={closeDialog}>
          <DialogContent style={{ padding: theme.spacing(2) }}>
            <AssetFilterModify
              clientId={project?.clientId ?? ""}
              groupedBy={project?.groupedBy ?? ""}
              id={filterId}
              onSuccess={closeDialog}
              {...routeProps}
            />
          </DialogContent>
        </Dialog>
      ) : (
        <></>
      )}
    </>
  );
};
