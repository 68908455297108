import { CancelToken } from "axios";
import axios from "../libs/axios/axios-instance";
import { ASSET_FILTERS_ENDPOINT } from "../libs/config";

const baseUrl = ASSET_FILTERS_ENDPOINT + "/";
const axiosInstance = axios(baseUrl ?? "");

export const getAssetFilters = async (
  jwt: string,
  cancelToken: CancelToken,
  clientId: string,
  groupedBy: string
) => {
  try {
    const config = { cancelToken };

    const body = {
      clientId,
      groupedBy,
    };

    const { data } = await axiosInstance.post("", body, config);
    return data;
  } catch (e: any) {
    if (cancelToken.reason) return;

    console.log(e);
    throw e.message ? e.message : e;
  }
};
