function not(a: string[], b: string[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: string[], b: string[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a: string[], b: string[]) {
  return [...a, ...not(b, a)];
}

function withIndexReplaced<T>(arr: T[], index: number, val: T): T[] {
  const cloned = arr.slice(0);
  cloned[index] = val;
  return cloned;
}

export { not, intersection, union, withIndexReplaced };
