import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useCancelToken } from "../../../hooks/general";
import { notifications } from "../../../libs/notifications";
import { appStrings as strings } from "../../../resources/strings";
import { getProjectGradings } from "../../../services/gradings.service";
import { getProject } from "../../../services/projects.service";
import { ProjectData } from "../../../types/documents";

type CircuitIds = {
  left: string[];
  right: string[];
};

const initCircuitIds: CircuitIds = {
  left: [],
  right: [],
};
export const useProjectTableData = (jwt: string) => {
  const [project, setProject] = useState<ProjectData | undefined>();
  const [tableData, setTableData] = useState<any[]>([]);
  const [reportDate, setReportDate] = useState("");
  const [circuitIds, setCircuitIds] = useState<CircuitIds>(initCircuitIds);
  const [loading, setLoading] = useState(true);

  const cancelToken = useCancelToken();
  const { projectId } = useParams<{ projectId: string }>();

  useEffect(() => {
    const getProjectData = async () => {
      try {
        const project = await getProject(jwt, cancelToken, projectId);
        if (!project) throw new Error();

        setProject(project);
      } catch (e: any) {
        notifications.error(strings.notifications.messages.retrieveError);
      }
    };

    const getTableData = async () => {
      try {
        const tableData = await getProjectGradings(jwt, cancelToken, projectId);
        if (!tableData) throw new Error();

        setTableData(tableData.items);

        const lastUpdatedGrading = tableData.items.reduce(
          (accumulator, value) => {
            return accumulator.updatedDate > value.updatedDate
              ? accumulator
              : value;
          }
        );

        const date = DateTime.fromISO(lastUpdatedGrading.updatedDate);
        setReportDate(date.toLocaleString(DateTime.DATE_SHORT));

        const leftCircuits = new Set<string>();
        const rightCircuits = new Set<string>();

        for (const row of tableData.items) {
          row.assetContainer.leftCircuit &&
            leftCircuits.add(row.assetContainer.leftCircuit);
          row.assetContainer.rightCircuit &&
            rightCircuits.add(row.assetContainer.rightCircuit);
        }

        setCircuitIds({
          left: Array.from(leftCircuits),
          right: Array.from(rightCircuits),
        });
      } catch (e: any) {
        notifications.error(strings.notifications.messages.retrieveError);
      }
      setLoading(false);
    };

    getProjectData();
    getTableData();
  }, [jwt, cancelToken, projectId]);

  return { project, reportDate, tableData, circuitIds, loading };
};
