import { useState } from "react";
import { HooksLogger } from "../hooks-logger";
import { CancelToken } from "axios";

export const useServiceAction = <Arg extends any[], Result>(
  logName: string,
  serviceCall: (...arg: Arg) => Promise<Result>,
  logger: HooksLogger,
  cancelToken: CancelToken,
  onSuccess?: (result: Result) => void,
  onError?: (error: string) => void,
): [(...arg: Arg) => void, boolean] => {
  const [inProgress, setInProgress] = useState(false);

  const handler = (...args: Arg) => {
    if (inProgress) return;

    const query = async () => {
      logger.request(logName, args);
      setInProgress(true);

      try {
        const result = await serviceCall(...args);
        setInProgress(false);
        logger.success(logName, result);
        onSuccess && onSuccess(result);
      } catch (e: any) {
        if (cancelToken.reason) return;
        const error = e.message ? e.message : e;
        setInProgress(false);
        logger.error(error);
        onError && onError(error);
      }
    };

    query();
  };

  return [handler, inProgress];
};
