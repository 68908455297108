import { useCallback, useEffect, useMemo, useState } from "react";
import { CognitoUser, Permissions } from "../../types";

export const useIsAuthorised = (
  // permissions may end up coming from store rather than
  // passing in each time
  permissions: Permissions | null,
  entity: string,
  permission: string,
  user?: CognitoUser | null,
  override?: (user: CognitoUser) => boolean
) => {
  const [isAuthorised, setIsAuthorised] = useState(false);

  const memoUser = useMemo(() => user, [user]);

  const handleIsAuthorised = useCallback(
    (allowedPermissions: string[]) => {
      if (override && memoUser) {
        setIsAuthorised(override(memoUser));
      } else if (allowedPermissions.includes(permission)) {
        setIsAuthorised(true);
      }
    },
    [override, memoUser, permission]
  );

  useEffect(() => {
    if (permissions) {
      let allowedPermissions: string[] = [];
      if (permissions[entity]) {
        allowedPermissions = permissions[entity];
      }

      handleIsAuthorised(allowedPermissions);
    }
  }, [permissions, entity, handleIsAuthorised]);

  return isAuthorised;
};
