import { makeStyles, Slider, Typography } from "@material-ui/core";
import { Dispatch, SetStateAction } from "react";
import { GPXParser } from "../helpers/GPXParser";

interface Props {
  position: number;
  setPosition: Dispatch<SetStateAction<number>>;
  gpx: GPXParser;
}

type Component = (props: Props) => JSX.Element;

export const GPSToolBar: Component = ({ position, setPosition, gpx }) => {
  const classes = useStyles();
  const trackPoints = gpx.trackPoints;

  const display = () => {
    const point = trackPoints[position];
    const time = point.time.toLocaleTimeString();
    const altitude = point.ele ? point.ele + "m" : "Unknown";
    return `Time: ${time} - Altitude: ${altitude}`;
  };

  return (
    <>
      <Typography id="slider">{display()}</Typography>
      <Slider
        aria-labelledby="slider"
        value={position}
        onChange={(_, value) => setPosition(value as number)}
        min={0}
        max={trackPoints.length - 1}
        className={classes.slider}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  slider: {
    width: "40vw",
    marginLeft: theme.spacing(4),
  },
}));
