import { Loader } from "../components/general";
import { Layout, OfflineLayout } from "../components/layout";
import { useAuth, useJWT } from "../hooks/authentication";
import { useUserPermissions } from "../hooks/permissions";
import { Routes } from "../navigation";
import { OfflineRoutes } from "../navigation/routes";
import InterceptFetch from "./InterceptFetch";

const Wrapper: React.FC = ({ children }) => (
  <div className="App">{children}</div>
);

export const App = () => {
  const { user, checkedAuth } = useAuth();
  const { permissions, loading: loadingPermissions } = useUserPermissions(user);
  const jwt = useJWT(user);

  const isLoading = [!checkedAuth, loadingPermissions].some(
    (condition) => condition
  );
  if (isLoading) {
    return (
      <Wrapper>
        <Loader active />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Layout permissions={permissions} user={user}>
        <Routes user={user} permissions={permissions} jwt={jwt} />
      </Layout>
    </Wrapper>
  );
};

export const OfflineApp = () => {
  const { user } = useAuth();
  const { permissions } = useUserPermissions(user);
  const jwt = useJWT(user);

  return (
    <Wrapper>
      {process.env.NODE_ENV === 'development' && <InterceptFetch />}
      <OfflineLayout permissions={permissions} user={user}>
        <OfflineRoutes user={user} permissions={permissions} jwt={jwt} />
      </OfflineLayout>
    </Wrapper>
  );
};
