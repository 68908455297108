import { useCallback, useEffect, useState } from "react";
import { useCancelToken } from "../../hooks/general";
import { HooksLogger } from "../../hooks/hooks-logger";
import { CognitoUser } from "../../types";
import { ClientData, Role, RoleType } from "../../types/documents";
import { onloadData } from "./functions";

const logger = new HooksLogger("UserModify/getRoles");

type Props = {
  jwt: string;
  user: CognitoUser | null;
  roleType: RoleType;
};
type Result = [boolean, Role[], ClientData[]];
export const useUserData = ({ jwt, user, roleType }: Props): Result => {
  const [roles, setRoles] = useState<Role[]>([]);
  const [clients, setClients] = useState<ClientData[]>([]);
  const cancelToken = useCancelToken();
  const [loading, setLoading] = useState(false);

  const getData = useCallback(() => {
    return onloadData(user, roleType, cancelToken);
  }, [user, roleType, cancelToken]);

  useEffect(() => {
    const query = async () => {
      try {
        logger.request("Getting data");
        setLoading(true);
        const results = await getData();
        setRoles(results.roles);
        setClients(results.clients);
        setLoading(false);
        logger.success();
      } catch (e: any) {
        if (cancelToken.reason) return;

        const error = e.message ? e.message : e;
        setLoading(false);
        logger.error(error);
      }
    };

    query();
  }, [jwt, cancelToken, getData]);

  return [loading, roles, clients];
};
