import { CancelToken } from "axios";
import axios from "../libs/axios/axios-instance";
import { USERS_ENDPOINT } from "../libs/config";

const baseUrl = USERS_ENDPOINT;
const axiosInstance = axios(baseUrl ?? "");

export const deleteUser = async (cancelToken: CancelToken, id: string) => {
  const config = { cancelToken };
  const urlPath = `/${id}`;

  try {
    return await axiosInstance.delete(urlPath, config);
  } catch (e: any) {
    if (cancelToken.reason) return;

    console.log(e);
    throw e.message ? e.message : e;
  }
};
