import { Options as OverlayOptions } from "ol/Overlay";
import Magnify from "ol-ext/overlay/Magnify";
import OLGoogleMaps from "olgm/OLGoogleMaps";

import "./Magnifier.css";

export default class Magnifier extends Magnify {
  constructor(projection: string) {
    super({
      zoomOffset: 2,
      projection: projection,
    } as Options);

    const olgm = new OLGoogleMaps({ map: this.getMagMap() });
    olgm.activate();
  }

  setActive(active: boolean): void {
    this.set("active", active);
    this.getMagMap()
      .getView()
      .setZoom(this.getMap()!.getView().getZoom()! + this.get("zoomOffset"));
  }
}

interface Options extends OverlayOptions {
  projection: string;
  zoomOffset: number;
}
