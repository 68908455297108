import { Mode, Section } from "../../components/general/types/Modify";
import { assetLoadStrings as strings } from "../../resources/strings/assets";
import { AssetFilter } from "../../types/documents/Assets";

export const initialData = {
  name: "",
  clientId: "",
  groupedBy: "",
  selectedGroups: [],
};

export const constraints = {
  name: {
    presence: {
      allowEmpty: false,
      message: `^${strings.assetFilter.name} is required`,
    },
  },
};

export const configInputs = (mode: Mode, getGroupedAssets: any) => {
  const config: Section<AssetFilter> = {
    key: "details",
    content: [
      {
        controltype: "header",
        text:
          mode === "create"
            ? strings.assetFilter.create
            : strings.assetFilter.edit,
        align: "left",
        variant: "h5",
      },
      {
        controltype: "input",
        name: "name",
        label: strings.assetFilter.name,
        required: true,
      },
      {
        controltype: "input",
        name: "groupedBy",
        label: strings.assetFilter.groupedBy,
        disabled: true,
      },
      {
        controltype: "transferlist",
        name: "selectedGroups",
        md: 12,
        getOptions: [getGroupedAssets, []],
      },
    ],
  };

  return config;
};

export const configSubmit: Section<AssetFilter> = {
  key: "submitButtons",
  content: [
    {
      controltype: "button",
      modes: ["create", "update"],
      label: strings.assetFilter.save,
      color: "primary",
      variant: "contained",
    },
    {
      controltype: "button",
      modes: ["update"],
      label: strings.assetFilter.delete,
      color: "primary",
      variant: "outlined",
      type: "button",
      buttonaction: "delete",
    },
  ],
};
