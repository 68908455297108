import { IconButton, makeStyles } from "@material-ui/core";
import { TablePaginationActionsProps } from "@material-ui/core/TablePagination/TablePaginationActions";
import React from "react";
import {
  FirstPageIcon,
  LastPageIcon,
  NextPageIcon,
  PreviousPageIcon,
} from "../../../../../assets";

export const PaginationActions = ({
  count,
  page,
  rowsPerPage,
  onPageChange,
}: TablePaginationActionsProps) => {
  const classes = useStyles();

  const lastPage = Math.max(0, Math.ceil(count / rowsPerPage) - 1);

  const handleFirstPageClicked = (event: React.MouseEvent<HTMLButtonElement>) =>
    onPageChange(event, 0);

  const handleBackClicked = (event: React.MouseEvent<HTMLButtonElement>) =>
    onPageChange(event, page - 1);

  const handleNextClicked = (event: React.MouseEvent<HTMLButtonElement>) =>
    onPageChange(event, page + 1);

  const handleLastPageClicked = (event: React.MouseEvent<HTMLButtonElement>) =>
    onPageChange(event, lastPage);

  return (
    <div className={classes.container}>
      <IconButton
        onClick={handleFirstPageClicked}
        disabled={page <= 0}
        aria-label="first page"
        size="small"
        title="first page"
        className={classes.button}
      >
        <FirstPageIcon />
      </IconButton>

      <IconButton
        onClick={handleBackClicked}
        disabled={page <= 0}
        aria-label="previous page"
        size="small"
        title="previous page"
        className={classes.button}
      >
        <PreviousPageIcon />
      </IconButton>

      <IconButton
        onClick={handleNextClicked}
        disabled={page >= lastPage}
        aria-label="next page"
        size="small"
        title="next page"
        className={classes.button}
      >
        <NextPageIcon />
      </IconButton>

      <IconButton
        onClick={handleLastPageClicked}
        disabled={page >= lastPage}
        aria-label="last page"
        size="small"
        title="last page"
        className={classes.button}
      >
        <LastPageIcon />
      </IconButton>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    flexShrink: 0,
    margin: theme.spacing(0, 2.5),
  },

  button: {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    margin: theme.spacing(0, 0.25),

    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },

    "&:disabled": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
      opacity: "50%",
    },
  },
}));
