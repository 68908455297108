import { Dialog, DialogContent } from "@material-ui/core";
import { useEffect, useState } from "react";
import { AssetPropertiesList } from "./AssetPropertiesList";

type Props = {
  jwt: string;
  assetId: string;
  container: HTMLElement | null;
  onClose?: () => void;
};

export const AssetDetailsModal = ({
  jwt,
  assetId,
  container,
  onClose,
}: Props) => {
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const shouldOpenModal = assetId ? true : false;
    setModalOpen(shouldOpenModal);
  }, [assetId]);

  const closeDialog = () => {
    onClose?.();
    setModalOpen(false);
  };

  return (
    <Dialog
      open={modalOpen}
      onClose={closeDialog}
      container={container}
      style={{ position: "absolute" }}
      PaperProps={{
        style: {
          position: "absolute",
          top: "64px",
          left: 0,
          margin: "16px",
          width: "30%",
          height: "max-content",
          maxHeight: "calc(100% - 32px)",
          borderRadius: 0,
        },
      }}
      BackdropProps={{ style: { backgroundColor: "transparent" } }}
    >
      <DialogContent style={{ padding: "8px 16px" }}>
        <AssetPropertiesList id={assetId} jwt={jwt} />
      </DialogContent>
    </Dialog>
  );
};
