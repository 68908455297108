import { MenuItem, Select } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircle from "@material-ui/icons/CheckCircle";
import SelectedCircle from "@material-ui/icons/RadioButtonChecked";
import clsx from "clsx";
import { AssetContainer } from "../../../types/documents";
import {
  Control,
  GradingForm,
  Section,
  SubSection,
} from "../../../types/gradingForm";

interface Props {
  assetContainers?: AssetContainer[];
  selectedAssetContainer?: AssetContainer;

  onSelectTower(tower: string): void;

  job?: GradingForm;
  selectedSectionId?: string;

  onSelectControlSection(id: string): void;

  readOnly?: boolean;
}

type Component = (props: Props) => JSX.Element;

export const GradingSectionList: Component = ({
  assetContainers,
  selectedAssetContainer,
  onSelectTower,

  job,
  selectedSectionId,
  onSelectControlSection,

  readOnly,
}) => {
  const classes = useStyles();

  const allControlsHaveValue = (controls: Control[]) =>
    controls.every((c) => !!c.value);

  const renderCheckedIcon = (controls: Control[], className: string) =>
    allControlsHaveValue(controls) ? (
      <CheckCircle color="primary" className={className} viewBox="2 2 20 20" />
    ) : (
      <div className={clsx(className, classes.incompleteIcon)} />
    );

  const renderSelectedIcon = (selected: boolean, className: string) =>
    selected ? (
      <SelectedCircle
        color="primary"
        className={className}
        viewBox="2 2 20 20"
      />
    ) : (
      <div className={clsx(className, classes.incompleteIcon)} />
    );

  const renderSelectableSection = (caption: string, id: string) => (
    <div
      className={clsx(
        classes.sectionLink,
        id === selectedSectionId && classes.activeSection
      )}
      onClick={() => onSelectControlSection(id)}
    >
      {caption}
    </div>
  );

  const renderSubSection = (subsection: SubSection) => {
    const icon = readOnly
      ? renderSelectedIcon(
          subsection.id === selectedSectionId,
          classes.subSectionIcon
        )
      : renderCheckedIcon(subsection.controls, classes.subSectionIcon);

    return (
      <div className={classes.subSection} key={subsection.id}>
        {icon}
        {renderSelectableSection(subsection.caption, subsection.id)}
      </div>
    );
  };

  const renderSubSections = (section: Section, subsections: SubSection[]) => (
    <div className={classes.sectionList}>
      {subsections.map(renderSubSection)}
    </div>
  );

  const renderSection = (section: Section) => {
    const allControls =
      "controls" in section.sectionJson
        ? section.sectionJson.controls
        : "subsections" in section.sectionJson
        ? section.sectionJson.subsections.flatMap((s) => s.controls)
        : [];

    const icon = readOnly
      ? renderSelectedIcon(
          section.id === selectedSectionId,
          classes.sectionIcon
        )
      : renderCheckedIcon(allControls, classes.sectionIcon);

    const caption =
      "controls" in section.sectionJson ? (
        renderSelectableSection(section.caption, section.id)
      ) : (
        <>{section.caption}</>
      );

    const subsections =
      "subsections" in section.sectionJson ? (
        renderSubSections(section, section.sectionJson.subsections)
      ) : (
        <></>
      );

    return (
      <div className={classes.section} key={section.id}>
        {icon}
        {caption}
        {subsections}
      </div>
    );
  };

  return (
    <>
      {assetContainers && assetContainers.length > 1 && (
        <Select
          label="Tower"
          className={classes.selectTower}
          value={selectedAssetContainer?.id || ""}
          variant="outlined"
          placeholder="Please Select Tower"
          onChange={(e) => onSelectTower(e.target.value as string)}
          fullWidth={true}
        >
          {assetContainers
            ?.sort((a, b) => a.name.localeCompare(b.name))
            .map((assetContainer) => (
              <MenuItem key={assetContainer.id} value={assetContainer.id}>
                {assetContainer.name}
              </MenuItem>
            ))}
        </Select>
      )}
      {job && (
        <div className={classes.sectionList}>
          {job.sections.map(renderSection)}
        </div>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  selectTower: {
    marginTop: "20px",
    width: "calc(100% - 40px)",

    "& > div": {
      paddingTop: "8px",
      paddingBottom: "8px",
      textAlign: "start",
    },
  },

  sectionList: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    textAlign: "left",
    marginTop: "20px",

    fontWeight: 600,
  },
  section: {
    position: "relative",
    fontSize: "18px",
    paddingLeft: "31px",
    marginLeft: "33px",
    paddingBottom: "30px",
    borderLeft: "1px solid #CCCCCC",
    "&:last-child": {
      borderLeft: "none",
      paddingLeft: "32px",
    },
  },
  activeSection: {
    color: theme.palette.primary.main,
  },
  sectionIcon: {
    position: "absolute",
    left: 0,
    top: 0,
    transform: "translateX(-50%)",
    width: "27px",
    height: "27px",
    background: "white",
  },
  subSection: {
    position: "relative",
    fontSize: "16px",
    paddingLeft: "33px",
    marginLeft: "10px",
    borderLeft: "1px solid #CCCCCC",
    display: "flex",
    alignItems: "center",
    minHeight: "40px",
    paddingBottom: "20px",
    "&:last-child": {
      paddingBottom: 0,
      minHeight: "20px",
    },
  },
  subSectionIcon: {
    position: "absolute",
    left: 0,
    top: 0,
    transform: "translateX(-50%)",
    width: "20px",
    height: "20px",
    background: "white",
  },
  sectionLink: {
    cursor: "pointer",
  },
  incompleteIcon: {
    background: "#FAFAFA",
    border: "1px solid #CCC",
    borderRadius: "50%",
  },
}));
