import { useEffect, useState } from "react";
import { notifications } from "../../libs/notifications";
import { appStrings as strings } from "../../resources/strings";
import { getImageUrls } from "../../services/images.service";
import { ImageMetadata } from "../../types/documents";
import { useCancelToken } from "../general";

interface Props {
  type: "main" | "thumb";
  imageMetadata: ImageMetadata | ImageMetadata[];
}

export const useImageUrls = ({ type, imageMetadata }: Props) => {
  const cancelToken = useCancelToken();
  const [urls, setUrls] = useState<Record<string, string>>({});

  useEffect(() => {
    const getUrls = async () => {
      try {
        const metadata = Array.isArray(imageMetadata)
          ? imageMetadata
          : [imageMetadata];
        const urls = await getImageUrls(type, metadata, cancelToken);
        setUrls(urls ?? {});
      } catch (e) {
        notifications.error(strings.notifications.messages.retrieveError);
      }
    };

    getUrls();
  }, [type, imageMetadata, cancelToken]);

  return urls;
};
