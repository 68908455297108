import { SvgIconTypeMap } from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import CameraAlt from "@material-ui/icons/CameraAlt";
import FormatListBulletedOutlinedIcon from "@material-ui/icons/FormatListBulletedOutlined";
import GroupIcon from "@material-ui/icons/Group";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import MapOutlinedIcon from "@material-ui/icons/MapOutlined";
import People from "@material-ui/icons/People";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import SettingsIcon from "@material-ui/icons/Settings";
import SyncIcon from "@material-ui/icons/Sync";
import VerticalAlignTopIcon from "@material-ui/icons/VerticalAlignTop";
import { paths } from "../../navigation";
import { appStrings as strings } from "../../resources/strings";
import { CognitoUser, UserAccess } from "../../types";

type IconType =
  | OverridableComponent<SvgIconTypeMap<{}, "svg">>
  | (() => JSX.Element);

const { entities, permissions, menuLabels } = strings;

export interface MenuItem {
  label: string;
  Icon: IconType;
  itemPermission?: UserAccess;
  link: string;
}

export const menuItems: MenuItem[] = [
  {
    label: menuLabels.map,
    Icon: MapOutlinedIcon,
    link: paths.map,
  },
  {
    label: menuLabels.assetsUpload,
    Icon: VerticalAlignTopIcon,
    itemPermission: {
      entity: entities.assets,
      permission: permissions.create,
    },
    link: paths.assets.upload(""),
  },
  {
    label: menuLabels.clients,
    Icon: GroupIcon,
    itemPermission: {
      entity: entities.clients,
      permission: permissions.read,
      override: (user: CognitoUser) => user.roleType !== "Client",
    },
    link: paths.clients.list,
  },
  {
    label: menuLabels.users,
    Icon: PersonOutlineOutlinedIcon,
    itemPermission: {
      entity: entities.users,
      permission: permissions.read,
      override: (user: CognitoUser) =>
        user.roleId === "10d742ed-0c45-45d9-97c3-8b9afdc04bf8" ||
        user.roleId === "6a371faa-483d-47ee-ba61-c99c2adaa922",
    },
    link: paths.users.list,
  },
  {
    label: menuLabels.projects,
    Icon: FormatListBulletedOutlinedIcon,
    itemPermission: {
      entity: entities.projects,
      permission: permissions.read,
    },
    link: paths.projects.list,
  },
  {
    label: menuLabels.infoLog,
    Icon: InfoOutlinedIcon,
    itemPermission: {
      entity: entities.flights,
      permission: permissions.read,
      override: (user: CognitoUser) => user.roleType !== "Client",
    },
    link: paths.dailyInfoLog,
  },
  {
    label: menuLabels.formDesigner,
    Icon: People,
    itemPermission: {
      entity: entities.defectTypes,
      permission: permissions.read,
    },
    link: paths.formDesigner.list,
  },
  {
    label: menuLabels.imageUploader,
    Icon: CameraAlt,
    itemPermission: {
      entity: entities.images,
      permission: permissions.create,
    },
    link: paths.images.view,
  },
];

export const offlineMenuItems: MenuItem[] = [
  {
    label: menuLabels.map,
    Icon: MapOutlinedIcon,
    link: paths.map,
  },
  {
    label: menuLabels.projects,
    Icon: FormatListBulletedOutlinedIcon,
    link: paths.projects.list,
  },
  {
    label: menuLabels.sync,
    Icon: SyncIcon,
    link: paths.offline.sync,
  },
  {
    label: menuLabels.settings,
    Icon: SettingsIcon,
    link: paths.offline.settings("1"),
  },
];
