import { v4 as uuid } from "uuid";
import { processExpressions, regenerateExpressions } from "./expressionHelper";
import { buildFqn, replaceSectionInFqn } from "./fqnHelper";
import { SectionFormData, SectionRecord } from "./interfaces";
var he = require('he');

export function UpdatePropertiesOnSectionRecord(sectionRecord: SectionRecord, sameTypedSectionsCount: number, newSectionOrder: number): SectionRecord {
    const today = new Date().toISOString();
    const newId = uuid();
    const newFqn = buildFqn({sectionId: newId, sectionTypeId: sectionRecord.sectionTypeId});  
    const sectionCaptionOverride = ""; 
    const newCaption = GetNewSectionCaption(sectionRecord, sectionCaptionOverride, sameTypedSectionsCount);
    const newSectionJson = transformSectionJson(sectionRecord.sectionJson, newId);
    
    //Change to newFqn for repeatable/template sections only - not grid sections
    if (sectionRecord.repeatable || sectionRecord.template){
        sectionRecord.fqn = newFqn;
        console.log("Updated FQN for sectionRecord", sectionRecord);
    }

    sectionRecord.sectionId = newId;
    sectionRecord.createdDate = today;
    sectionRecord.updatedDate = today;
    sectionRecord.caption = newCaption;
    sectionRecord.order = newSectionOrder;
    sectionRecord.template = false;
    sectionRecord.sectionJson = newSectionJson;

    return sectionRecord;
}

export function GenerateNewSectionFromSectionRecord(sectionRecord: SectionRecord): SectionFormData {
    const section = {
      sectionId: sectionRecord.sectionId,
      sectionTypeId: sectionRecord.sectionTypeId,
      repeatable: sectionRecord.repeatable,
      template: sectionRecord.template,
      gridInput: sectionRecord.gridInput,
      siteVisit: sectionRecord.siteVisit,
      createdDate: sectionRecord.createdDate,
      fqn: sectionRecord.fqn,
      sectionCaption: sectionRecord.caption,
      sectionFormData: sectionRecord.sectionJson,
      sectionOrder: sectionRecord.order,
      deletable: true,
      isNew: true,
      visible: sectionRecord.visible
    }
    return section;
}

export function GenerateSectionRecordFromNewSection(sectionData: SectionFormData, tenantId: string, jobId: string, userId: string): SectionRecord {
  const section = {
    sectionId: sectionData.sectionId,
    tenantId: tenantId,
    jobId: jobId,
    sectionTypeId: sectionData.sectionTypeId,
    sectionJson: sectionData.sectionFormData,
    repeatable: sectionData.repeatable,
    template: sectionData.template,
    active: true,
    createdById: userId,
    createdDate: sectionData.createdDate,
    updatedById: userId,
    updatedDate: sectionData.createdDate,
    caption: sectionData.sectionCaption,
    order: sectionData.sectionOrder,
    fqn: sectionData.fqn,
    visible: sectionData.visible,
    gridInput: sectionData.gridInput,
    internalSectionTypeId: sectionData.sectionTypeId,
    siteVisit: sectionData.siteVisit,
    name: sectionData.sectionCaption,
    state: ""
  }
  return section;
}

export function GetNewSectionOrder(sectionList: SectionRecord[], sectionTypeId: string)
{
    var sameTypedSections = sectionList.filter(section => section.sectionTypeId === sectionTypeId);
    var maxOrder = Math.max.apply(Math, sameTypedSections.map(s => s.order));
    maxOrder += 0.0001;
    return maxOrder; //decimal
}

export function GetSameTypeCount(sectionList: SectionRecord[], sectionTypeId: string)
{
    var sameTypedSections = sectionList.filter(section => section.sectionTypeId === sectionTypeId);
    var sameTypeCount = sameTypedSections.filter(s => !s.template).length;
    return sameTypeCount; 
}

export function GetNewSectionCaption(section: SectionRecord, sectionCaptionOverride: string, sameTypedSectionsCount: number = 0)
{
    let caption;
    if (sectionCaptionOverride)
    {
        caption = GetSafeString(sectionCaptionOverride);
    }
    else
    {
        caption = GetStringWithIncrementedSuffix(section.caption, sameTypedSectionsCount);
    }
    return caption;
}

function GetSafeString(input: string)
{
    return he.encode(input);
}

function GetStringWithIncrementedSuffix(input: string, currentSuffix: number)
{
    if (currentSuffix <= 0){
        return input;
    } else {
        return `${input} ${currentSuffix + 1}`;
    }
}

function SetControlValueInNewSection(control: any){
  control.value = (!(control.value === undefined)) ? null : undefined;
  return control;
}

export function transformSectionJson(sectionJson: string, newSectionId: string){
    let original_section;
    if (typeof sectionJson === "string"){
      original_section = JSON.parse(sectionJson);
    } else {
      original_section = sectionJson;
    }
    console.log("original section object", original_section);
    if (isEmptyObject(original_section)){
      return sectionJson;
    }
  
    //Remove an empty "expressionTypes" from the section JSON
    if (Array.isArray(original_section.expressionTypes) && original_section.expressionTypes.length === 0){
      delete original_section.expressionTypes;
      console.log("removed empty expression types");
    }
  
    //The section json will either have a "controls" property or a "subsections" property
    let sectionHasControls = false;
    let sectionHasSubsections = false;
    if (Array.isArray(original_section.controls) && original_section.controls.length > 0){
      sectionHasControls = true;
      console.log("section has controls");
    }
    if (Array.isArray(original_section.subsections) && original_section.subsections.length > 0){
      sectionHasSubsections = true;
      console.log("section has subsections");
    }
  
    //Update the control fqns
    if (sectionHasControls){
      const controlUpdate: (control: object & {value: string, fqn: string, visible: boolean}) => void = control => {
        const {value, visible, fqn, ...properties} = control;
        SetControlValueInNewSection(control);
        const newFqn = replaceSectionInFqn(fqn, newSectionId)
        return {...properties, fqn: newFqn, visible} //Keeping properties in the same order as Velappity
      }
      
      const updatedSectionControls = original_section.controls.map(controlUpdate);
      original_section.controls = updatedSectionControls;
    }
  
    try {
      if (sectionHasSubsections){
        //Iterate through the subsections and update the fqns in subsections and their controls
        const subsectionUpdate: (subsection: object & {fqn: string, visible: boolean, controls: any[]}) => void = subsection => {
          var {visible, fqn, controls, ...properties} = subsection;
          controls = controls.map(SetControlValueInNewSection)
          const newFqn = replaceSectionInFqn(fqn, newSectionId);
          for (var control of controls) {
            control.fqn = replaceSectionInFqn(control.fqn, newSectionId);
          }
          return {controls, ...properties, fqn: newFqn, visible}
        }
        const updatedSubsections = original_section.subsections.map(subsectionUpdate);
        original_section.subsections= updatedSubsections;
      }
    } catch(e) {
      console.log(e);
    }
    
  
    var newJsonData = JSON.stringify(original_section);
    newJsonData = regenerateExpressions(newJsonData, newSectionId);
    newJsonData = processExpressions(newJsonData);
    return newJsonData;
  }

  function isEmptyObject(obj: object){
    return obj // null and undefined check
    && Object.keys(obj).length === 0
    && Object.getPrototypeOf(obj) === Object.prototype;
  }