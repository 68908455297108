import { useEffect, useMemo, useState } from "react";
import { API } from "../../API";
import { Content, Section } from "../../components/general/types/Modify";
import { isOfflineApp } from "../../helpers";
import { useAssetGroups } from "../../hooks/assets/useAssetGroups";
import { useIsAuthorised } from "../../hooks/authentication";
import { useCancelToken } from "../../hooks/general";
import { TAGS_ENDPOINT } from "../../libs/config";
import { projectStrings as strings } from "../../resources/strings";
import * as clientsApi from "../../services/clients.service";
import {
  BasicComponentConfigurations,
  DropdownOptions,
  RouteProps,
} from "../../types";
import { ProjectData } from "../../types/documents";
import ProjectsBreadcrumbHeader from "./ProjectBreadcrumbHeader";

const constraints = {
  jobCode: {
    presence: {
      allowEmpty: false,
      message: `^${strings.labels.jobCode} is required`,
    },
  },
  clientId: {
    presence: {
      allowEmpty: false,
      message: `^${strings.labels.client} is required`,
    },
  },
  type: {
    presence: {
      allowEmpty: false,
      message: `^${strings.labels.projectType} is required`,
    },
  },
};

type Result = [
  object,
  BasicComponentConfigurations<ProjectData>,
  React.Dispatch<React.SetStateAction<string>>
];

export const useProjectConfigurations = ({
  jwt,
  permissions,
}: RouteProps): Result => {
  const cancelToken = useCancelToken();

  const showClients =
    useIsAuthorised(
      permissions,
      strings.global.entities.clients,
      strings.global.permissions.read
    ) || isOfflineApp();

  const [clientId, setClientId] = useState<string>("");
  const [clientOptions, setClientOptions] = useState<DropdownOptions>();

  const [, assetGroups] = useAssetGroups({ jwt, clientId });
  const [assetGroupOptions, setAssetGroupOptions] = useState<DropdownOptions>();

  const getGroupedAssets = async ({
    groupedBy,
    clientId,
  }: Partial<ProjectData>) => {
    if (!groupedBy || !clientId) return;
    try {
      const APIFunctions = new API(cancelToken, jwt);
      const groupedAssets = await APIFunctions.getGroupedAssets(
        groupedBy,
        clientId
      );
      return groupedAssets?.map(({ value, totalCount }) => ({
        value,
        label: value,
        totalCount,
      }));
    } catch (error) {
      console.log(error);
      return;
    }
  };

  useEffect(() => {
    const groupOptions: DropdownOptions = assetGroups.map((group) => ({
      label: group,
      value: group,
    }));
    setAssetGroupOptions(() => [...groupOptions]);
  }, [assetGroups]);

  const getClients = useMemo(
    () => () => clientsApi.listClients(jwt, cancelToken),
    [jwt, cancelToken]
  );

  useEffect(() => {
    const query = async () => {
      const clients = await getClients();
      if (!clients) return;
      const clientOptions = clients?.map(({ id, name }) => ({
        value: id,
        label: name,
      }));
      setClientOptions(clientOptions);
    };
    showClients && query();
  }, [getClients, showClients]);

  const componentConfiguration: BasicComponentConfigurations<ProjectData> = {
    details: (_mode): Section<ProjectData> => {
      return {
        key: "details",
        content: [
          {
            controltype: "custom",
            Component: ProjectsBreadcrumbHeader,
            md: 12,
            fullwidth: true,
          },
          {
            controltype: "header",
            text: strings.header.projectDetails,
            align: "left",
            variant: "subtitle1",
          },
          {
            controltype: "input",
            name: "jobCode",
            label: strings.labels.jobCode,
            required: true,
          },
          ...(showClients
            ? [
                {
                  controltype: "dropdown",
                  name: "clientId",
                  label: strings.labels.client,
                  required: true,
                  options: clientOptions,
                } as Content<ProjectData>,
              ]
            : []),
          {
            controltype: "input",
            name: "description",
            label: strings.labels.description,
          },
          {
            controltype: "dropdown",
            name: "groupedBy",
            label: strings.labels.groupedBy,
            required: true,
            options: assetGroupOptions,
          },
          {
            controltype: "checkbox",
            required: true,
            name: "type",
            label: strings.labels.projectType,
            tagConfig: {
              endpoint: TAGS_ENDPOINT,
              jwt,
              tagGroup: "type",
            },
          },
          {
            controltype: "transferlist",
            name: "selectedGroups",
            md: 12,
            getOptions: [getGroupedAssets, ["groupedBy", "clientId"]],
          },
        ],
      };
    },
  };

  return [constraints, componentConfiguration, setClientId];
};
