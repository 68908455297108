import React, { useCallback, useEffect, useRef, useState } from "react";
import { useCancelToken } from "../../hooks/general";
import * as mapTilesApi from "../../services/mapTiles.service";
import { SourceTile } from "../../services/mapTiles.service";
import { HooksLogger } from "../../hooks/hooks-logger";
import { Table } from "../../components/general";
import { Permissions } from "../../types";
import { useServiceData } from "../../hooks/general/useServiceData";
import { useServiceAction } from "../../hooks/general/useServiceAction";
import { useS3Upload } from "../../hooks/general/useS3Upload";
import { CircularProgress } from "@material-ui/core";
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';

const logger = new HooksLogger("Clients/UploadClientTiles");

export interface Props {
  data: {
    id: string,
  },
  jwt: string,
  permissions: Permissions | null,
}

type Component = (props: Props) => JSX.Element;

export const UploadClientTiles: Component = ({ data, jwt, permissions }) => {
  const { id: clientId } = data;

  const cancelToken = useCancelToken();

  const [, setError] = useState("");

  const listTiles = useCallback(() => mapTilesApi.listTiles(jwt, cancelToken, clientId), [jwt, cancelToken, clientId]);
  const [tiles, tilesLoading, refreshTiles] = useServiceData(
    "Fetching client source tiles",
    listTiles,
    logger,
    cancelToken,
    setError
  );

  const apiDeleteTile = useCallback((tile: SourceTile) => mapTilesApi.deleteTile(jwt, cancelToken, tile), [jwt, cancelToken]);
  const [deleteTile, deleting] = useServiceAction(
    "Deleting source tile",
    apiDeleteTile,
    logger,
    cancelToken,
    refreshTiles,
    setError
  );

  const uploadTiles = useCallback(
    (fileNames: string[]) => mapTilesApi.uploadTiles(jwt, cancelToken, clientId, fileNames),
    [jwt, cancelToken, clientId]
  );
  const [uploadHandler, uploading, uploadProgress] = useS3Upload(
    "Uploading Tiles",
    uploadTiles,
    logger,
    cancelToken,
    refreshTiles,
    error => {
      setError(error);
      refreshTiles();
    }
  );
  const uploadFile = useRef<HTMLInputElement | null>(null);

  const processTiles = useCallback(
    () => mapTilesApi.processTiles(jwt, cancelToken, clientId),
    [jwt, cancelToken, clientId]
  );
  const [processHandler] = useServiceAction(
    "Processing Tiles",
    processTiles,
    logger,
    cancelToken
  );

  const getProcessingStatus = useCallback(
    () => mapTilesApi.getProcessingStatus(jwt, cancelToken, clientId),
    [jwt, cancelToken, clientId]
  );
  let [processingStatus, processingStatusLoading, refreshProcessingStatus] = useServiceData(
    "Fetching processing status",
    getProcessingStatus,
    logger,
    cancelToken,
    setError
  );
  useEffect(() => {
    const interval = setInterval(refreshProcessingStatus, 5000);
    return () => clearInterval(interval);
  }, [refreshProcessingStatus]);

  if (!processingStatus && processingStatusLoading) {
    processingStatus = "RUNNING";
  }

  let processing;
  let canTriggerProcessing;
  switch (processingStatus) {
    case "FAILED":
      processing = <ClearIcon style={{ color: "red" }}/>;
      canTriggerProcessing = true;
      break;
    case "SUCCEEDED":
      processing = <CheckIcon style={{ color: "green" }}/>;
      canTriggerProcessing = true;
      break;
    case "RUNNING":
      processing = <CircularProgress size="1rem"/>;
      canTriggerProcessing = false;
      break;
    default:
      processing = <></>;
      canTriggerProcessing = true;
  }

  const loading = tilesLoading || deleting || uploading;

  return <>
    <input type="file" ref={uploadFile} style={{ display: "none" }} onChange={uploadHandler} multiple={true}/>
    <Table<SourceTile> header={"Input Tiles"} altHeaderStyle permissions={permissions} data={tiles || []}
                       columns={[
                         {
                           accessor: "name",
                           Header: "Name"
                         }
                       ]}
                       clickHandler={deleteTile} viewButtonText="Delete"
                       size={10} loading={loading}
                       loadProgress={uploadProgress}
                       createButtonConfig={[
                         {
                           label: <>
                             {processing}
                             Process
                           </>,
                           userAccess: {
                             entity: "clients",
                             permission: "update"
                           },
                           handler: processHandler,
                           disabled: !canTriggerProcessing
                         },
                         {
                           label: "Upload",
                           userAccess: {
                             entity: "clients",
                             permission: "update"
                           },
                           handler: () => uploadFile.current?.click(),
                         }
                       ]}
                       onPageChange={() => {
                       }}
                       onSizeChange={() => {
                       }}
    />
  </>
};

