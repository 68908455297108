import { Button, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { FlightButton } from "../../hooks/flights/useFlightButtons";
const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "10px 0",
  },
  button: {
    borderRadius: 0,
    boxShadow: "none",
    textTransform: "uppercase",
    fontWeight: 700,
    marginBottom: "15px",
    "&:last-child": {
      marginBottom: 0,
    },
  },
}));

type Props = {
  flightButtons: FlightButton[];
};

export const FlightButtonState = ({ flightButtons }: Props) => {
  const classes = useStyles();

  const results = flightButtons
    .filter((button) => button.render())
    .map((button) => {
      const isAbortButton = button.name === "Abort";
      return (
        <Button
          color="primary"
          variant={isAbortButton ? "outlined" : "contained"}
          type="button"
          title={button.name}
          onClick={button.onClick}
          className={clsx(classes.button)}
        >
          {button.name}
        </Button>
      );
    });

  return <div className={clsx(classes.buttonContainer)}>{results}</div>;
};
