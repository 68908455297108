import { Mode, Section } from "../../../components/general/types/Modify";
import { mapStrings as strings } from "../../../resources/strings";
import { PointOfInterestData } from "../../../types/documents";

export const initialData = (geometry: object) => {
  return { name: "", geometry };
};

export const constraints = {
  name: {
    presence: {
      allowEmpty: false,
      message: `^${strings.pointOfInterest.name} is required`,
    },
  },
};

export const configInputs = (mode: Mode) => {
  const content: Section<PointOfInterestData> = {
    key: "details",
    content: [
      {
        controltype: "header",
        text:
          mode === "create"
            ? strings.pointOfInterest.create
            : strings.pointOfInterest.edit,
        align: "left",
        variant: "h5",
      },
      {
        controltype: "input",
        name: "name",
        label: strings.pointOfInterest.name,
      },
    ],
  };

  return content;
};

export const configSubmit: Section<PointOfInterestData> = {
  key: "submitButtons",
  content: [
    {
      controltype: "button",
      modes: ["create", "update"],
      label: strings.buttons.save,
      color: "primary",
      variant: "contained",
    },
    {
      controltype: "button",
      modes: ["update"],
      label: strings.buttons.delete,
      color: "primary",
      variant: "outlined",
      type: "button",
      buttonaction: "delete",
    },
  ],
};
