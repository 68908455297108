import BreadcrumbHeader from "../../components/general/BreadcrumbHeader";
import { Mode } from "../../components/general/types/Modify";
import { paths } from "../../navigation";
import { buttonConfigurationStrings as strings } from "../../resources/strings";

type Props = {
  mode: Mode;
};

export default function ButtonConfigurationBreadcrumbHeader({ mode }: Props) {
  const getHeaderFromMode = () => {
    switch (mode) {
      case "view":
        return strings.header.editButtonConfiguration;
      case "create":
        return strings.header.createButtonConfiguration;
      case "update":
        return strings.header.editButtonConfiguration;
    }
  };

  const crumbs = [
    {
      name: strings.header.buttonConfigurations,
      link: paths.formDesigner.list,
    },
    {
      name: getHeaderFromMode(),
    },
  ];

  return <BreadcrumbHeader crumbs={crumbs}></BreadcrumbHeader>;
}
