import { useMemo } from "react";
import type { Mode } from "../../components/general/types/Modify";
import { LINES_ENDPOINT } from "../../libs/config";
import { paths } from "../../navigation";
import { RouteProps } from "../../types";
import type { LineLayer } from "../../types/documents";
import { ModifyContainer } from "../general";
import {
  lineComponentConfigurations,
  lineConstraints,
  lineInitialFormData,
} from "./resources";
import { useParams } from "react-router-dom";

const linePutEndpoint = (clientId: string) => `${LINES_ENDPOINT}/${clientId}/submit`;
const lineQueryEndpoint = (clientId: string) => `${LINES_ENDPOINT}/${clientId}`;

export interface Props extends RouteProps {
  mode: Mode;
}

type Component = (props: Props) => JSX.Element;

export const LineLayerModify: Component = ({ mode, ...routeProps }) => {
  const { clientId } = useParams<{ clientId: string }>();

  const putEndpoint = useMemo(
    () => linePutEndpoint(clientId),
    [clientId]
  );
  const queryEndpoint = useMemo(
    () => lineQueryEndpoint(clientId),
    [clientId]
  );

  return (
    <ModifyContainer<LineLayer>
      {...routeProps}
      putEndpoint={putEndpoint}
      queryEndpoint={queryEndpoint}
      mode={mode}
      initialData={lineInitialFormData(clientId)}
      componentConfiguration={lineComponentConfigurations}
      constraints={lineConstraints}
      redirectPath={paths.clients.view}
      keysToRemove={["clientId", "createdDate", "updatedDate"]}
    />
  );
};

