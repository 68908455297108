import { CancelToken } from "axios";
import { createDropdownOptions } from "../../../helpers";
import { listClients } from "../../../services/clients.service";
import { getRoles } from "../../../services/permissions.service";
import { CognitoUser, DropdownMappings, DropdownOptions } from "../../../types";
import type { ClientData, Role } from "../../../types/documents";
import { RoleType } from "../../../types/documents";

type Result = {
  roles: Array<Role>;
  clients: Array<ClientData>;
};

export const onloadData = async (
  user: CognitoUser | null,
  roleType: RoleType,
  cancelToken: CancelToken
): Promise<Result> => {
  const promises: Array<Promise<any[]>> = [getRoles(cancelToken, roleType)];

  if (user?.isSuperTenant()) {
    promises.push(listClients("", cancelToken));
  }

  const [roles, clients] = await Promise.all(promises);
  return { roles, clients };
};

export const mapToDropdownOptions = (
  responses: Array<any>,
  dropdownMappings: DropdownMappings
) => {
  const results = responses.map<[string, DropdownOptions]>((items, index) => {
    const map = dropdownMappings[index];
    const options = createDropdownOptions(items, map.key, map.text);

    return [map.name, options];
  });

  return Object.fromEntries(new Map(results));
};
