import log from "loglevel";
import axios from "../../libs/axios/axios-instance";

const apiUrl = process.env.REACT_APP_JOBS_ENDPOINT;
const axiosInstance = axios(apiUrl ?? "")
const setupSectionData = (jobId: string, sectionData: any) => {
  const sectionJSONData = JSON.parse(sectionData.sectionFormData);
  // subsection state is stripped because it is not used by the API
  sectionJSONData.subsections && sectionJSONData.subsections.forEach((x: any) => delete x.state);
  const {
    createdDate,
    fqn,
    gridInput,
    repeatable,
    sectionTypeId,
    template,
    sectionCaption,
    sectionId,
  } = sectionData;

  return {
    createdDate,
    fqn,
    gridInput,
    repeatable,
    sectionTypeId,
    template,
    sectionCaption: sectionCaption,
    id: sectionId,
    jobId: jobId,
    visible: true,
    active: true,
    state: sectionData.state,
    sectionFormData: JSON.stringify(sectionJSONData),
  };
};

//Gets a list of properties taken from the section data, including the JSON data, for displaying a job in the job renderer
const getJobFormData = async (jobId: string) => {
  const urlPath = `/jobs/${jobId}/jobTypeData`;
  return await axiosInstance({ url: urlPath })
    .then((res) => {
      const data = res.data;
      if (!data || !data.length) {
        // When getting an invalid id in the right format, an empty array is returned
        throw new Error("Job not found");
      }
      return data;
    })
    .catch((err) => {
      throw err;
    });
};

const getAllJobStates = () => {
  return axiosInstance({
    url: "/jobstates/",
    method: "get",
  })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const getJobData = (id: string) => {
  return axiosInstance({
    url: `/jobs/${id}`,
    method: "get",
  })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const getFormListData = async () => {
  return await axiosInstance({
    url: `/jobtypes`,
    method: "get",
  })
    .then((res) => {
      const data = res.data;
      if (!data || !data.length) {
        // When getting an invalid id in the right format, an empty array is returned
        throw new Error("Job not found");
      }
      return data;
    })
    .catch((err) => {
      throw err;
    });
};

//Updates a section after a change has been made to the section JSON
const updateSectionData = async (
  formData: any,
  selectedSectionId: string,
  jobId: string
) => {
  if (selectedSectionId) {
    const sectionData = getSectionData(formData, selectedSectionId);
    try {
      JSON.parse(sectionData.sectionFormData);
    } catch (error) {
      throw new Error("Invalid Section Data submitted");
    }

    const preparedSectionData = setupSectionData(jobId, sectionData);
    await axiosInstance({
      url: `/jobs/sections/${selectedSectionId}`,
      method: "put",
      data: preparedSectionData,
    })
      .then((res) => log.debug(res))
      .catch((err) => {
        throw err;
      });
  }
};

//Save row for repeatable section
const saveRow = async (
  formData: any,
  selectedSectionId: string,
  jobId: string
) => {
  const sectionData = getSectionData(formData, selectedSectionId);
  try {
    JSON.parse(sectionData.sectionFormData);
  } catch (error) {
    throw new Error("Invalid Section Data submitted");
  }

  const preparedSectionData = setupSectionData(jobId, sectionData);
  await axiosInstance({
    url: "/sections/savesection",
    method: "post",
    data: preparedSectionData,
  })
    .then((res) => log.debug(res))
    .catch((err) => {
      throw err;
    });
};

const getTemplateOnlySections = async (jobId: string) => {
  return await axiosInstance({
    url: `/sectionTypes/repeatablesectionsforjob/${jobId}`,
    method: "get",
  })
    .catch((err) => {
      throw new Error(err);
    });
};

const updateAttachmentMetadata = async (
  jobId: string,
  attachmentId: string,
  fileExtension: string
) => {
  const jobAttachmentMetaDataDto = { id: attachmentId, jobId, fileExtension };

  return await axiosInstance({
    url: `/jobs/uploadattachment`,
    method: "post",
    data: jobAttachmentMetaDataDto,
  })
    .then((res) => {
      log.debug(res);
      return res.data.id;
    })
    .catch((err) => {
      throw err;
    });
};

const uploadAttachment = async (attachmentId: string, file: any) => {
  return await axiosInstance({
    url: `/jobs/uploadattachment/${attachmentId}/base64`,
    method: "put",
    data: file,
  })
    .catch((err) => {
      throw err;
    });
};

const getControlAttachment = async (attachmentId: string) => {
  return await axiosInstance({
    url: `/controlattachments/${attachmentId}/base64`,
    method: "get",
  })
    .then((res) => {
      log.debug(res);
      return "data:image/png;base64," + res.data;
    })
    .catch((err) => {
      throw err;
    });
};

function getSectionData(formData: Array<any>, sectionId: string) {
  return formData.find((section) => section?.sectionId === sectionId);
}

export {
  getJobFormData,
  updateSectionData,
  setupSectionData,
  getTemplateOnlySections,
  updateAttachmentMetadata,
  getControlAttachment,
  uploadAttachment,
  getAllJobStates,
  getJobData,
  getFormListData,
  saveRow,
};
