import { Dialog, DialogContent } from "@material-ui/core";
import { Feature } from "ol";
import { useEffect, useMemo, useState } from "react";
import { PointOfInterestModify } from "../../../containers/mapFeatures";
import { theme } from "../../../styles/theme";
import { RouteProps } from "../../../types";
import { PointOfInterestData } from "../../../types/documents";
import { Mode } from "../../general/types/Modify";
import { useGeoJSON } from "./GeoJSON";

interface Props extends RouteProps {
  mode: Mode;
  pointOfInterest?: Feature;
  onSuccess?: (id: string, data: PointOfInterestData) => void;
  onClose?: () => void;
}

type Component = (props: Props) => JSX.Element;
export const PointOfInterestModal: Component = ({
  pointOfInterest,
  mode,
  onSuccess: _onSuccess,
  onClose,
  ...routeProps
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const format = useGeoJSON();

  const onSuccess = (id: string, data: PointOfInterestData) => {
    if (!pointOfInterest) return;

    if (id) {
      pointOfInterest.setProperties({ id, name: data.name });
    } else {
      pointOfInterest.setGeometry(undefined);
    }

    _onSuccess?.(id, data);
    closeDialog();
  };

  const geometry = useMemo(() => {
    const geom = pointOfInterest?.getGeometry();
    if (!format || !geom) return;

    return format.writeGeometryObject(geom);
  }, [format, pointOfInterest]);

  useEffect(() => {
    const shouldOpenModal = pointOfInterest ? true : false;
    setModalOpen(shouldOpenModal);
  }, [pointOfInterest]);

  const closeDialog = () => {
    onClose?.();
    setModalOpen(false);
  };

  return (
    <Dialog
      open={modalOpen}
      onClose={closeDialog}
      BackdropProps={{ style: { backgroundColor: "transparent" } }}
    >
      <DialogContent style={{ padding: theme.spacing(2) }}>
        {pointOfInterest && geometry && (
          <PointOfInterestModify
            mode={mode}
            geometry={geometry}
            id={pointOfInterest.getProperties().id}
            onSuccess={onSuccess}
            {...routeProps}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};
