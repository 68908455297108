import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import React, { ChangeEvent } from "react";

interface Props {
  handleUploads: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
}

type Component = (props: Props) => JSX.Element;
export const UploadButton: Component = ({ handleUploads }) => {
  const classes = useStyles();

  const uploadFiles = async (e: ChangeEvent<HTMLInputElement>) => {
    await handleUploads(e);
  };

  return (
    <>
      <input
        className={classes.input}
        id="contained-button-file"
        multiple
        accept=".jpg, .png"
        type="file"
        onChange={uploadFiles}
      />

      <label htmlFor="contained-button-file">
        <Button
          variant="contained"
          color="primary"
          component="span"
          className={classes.button}
          endIcon={<PhotoCamera />}
        >
          Upload
        </Button>
      </label>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  input: {
    display: "none",
  },
  button: {
    margin: theme.spacing(2),
    borderRadius: 0,
  },
}));
