import { Button } from "@material-ui/core";
import { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { API } from "../../../API";
import BreadcrumbHeader from "../../../components/general/BreadcrumbHeader";
import { isOfflineApp } from "../../../helpers";
import { useCancelToken } from "../../../hooks/general";
import { PACKAGES_ENDPOINT } from "../../../libs/config";
import { paths } from "../../../navigation";
import { RouteProps } from "../../../types";
import { PackageData } from "../../../types/documents";
import { FlightList } from "../../flights/FlightList";

interface Props extends RouteProps {}

type Component = (props: Props) => JSX.Element;

export const ViewFlights: Component = ({ ...routeProps }) => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const cancelToken = useCancelToken();
  const [pkgName, setPkgName] = useState("");
  const [clientId, setClientId] = useState("");
  const APIFunctions = useMemo(
    () => new API(cancelToken, routeProps.jwt),
    [cancelToken, routeProps.jwt]
  );

  const crumbs = [
    {
      name: pkgName,
      link: paths.packages.view(id),
    },
    {
      name: "Flights",
    },
  ];

  useEffect(() => {
    async function getJobCode() {
      const pkg = (await APIFunctions.get(
        `${PACKAGES_ENDPOINT}/${id}`
      )) as PackageData;
      if (!pkg) return;
      setPkgName(pkg.name);
      setClientId(pkg.project?.clientId || "");
    }
    getJobCode();
  }, [APIFunctions, id]);

  const createFlight = () => {
    history.push(paths.flights.createFlight(clientId, id));
  };
  return (
    <div>
      <BreadcrumbHeader crumbs={crumbs}>
        {isOfflineApp() ? (
          <Button color="primary" variant="contained" onClick={createFlight}>
            Create Flight
          </Button>
        ) : (
          <></>
        )}
      </BreadcrumbHeader>
      <FlightList {...routeProps} header={null} filters={{ packageId: id }} />
    </div>
  );
};
