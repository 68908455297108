import { Button, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { useCallback, useState } from "react";
import { ShowIfAuthorised } from "../../components/authentication";
import { ConfirmModal } from "../../components/general/ConfirmModal";
import { defaultButtonStyle, deleteButtonStyle } from "../../components/styles";
import { projectStrings as strings } from "../../resources/strings";
import { Permissions } from "../../types";

const useStyles = makeStyles((theme) => ({
  all: defaultButtonStyle(theme),
  border: { border: theme.spacing(0.3, "solid") },
  delete: deleteButtonStyle(theme),
}));

interface Props {
  entity: string;
  permissions: Permissions | null;
  deleteFunction: (...args: any[]) => any;
  deleteLoading: boolean;
}

export default function DeleteButton({
  entity,
  permissions,
  deleteFunction,
  deleteLoading,
}: Props) {
  const classes = useStyles();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const onConfirm = useCallback(
    (...args) => {
      deleteFunction(args);
      setShowDeleteConfirmation(false);
    },
    [deleteFunction]
  );

  return (
    <>
      <ShowIfAuthorised
        entity={entity}
        permission={strings.global.permissions.delete}
        userPermissions={permissions}
      >
        <ConfirmModal
          confirmButtonLabel={strings.labels.delete}
          onConfirm={onConfirm}
          message={strings.messages.confirmDelete}
          open={showDeleteConfirmation}
          handleClose={() => setShowDeleteConfirmation(false)}
          loading={deleteLoading}
        />
        <Button
          className={clsx(classes.all, classes.border, classes.delete)}
          color="primary"
          variant="outlined"
          onClick={() => setShowDeleteConfirmation(true)}
          title="delete"
        >
          {strings.labels.delete}
        </Button>
      </ShowIfAuthorised>
    </>
  );
}
