import type { DateTimeFormatOptions, LocaleOptions } from "luxon";
import { DateTime } from "luxon";
import VectorSource from "ol/source/Vector";
import { Mode } from "../components/general/types/Modify";
import { PDG_ROLE_TYPE } from "../libs/config";
import { DropdownMappings } from "../types";
import { Role } from "../types/documents";
import { TKeysToRemove } from "../types/types";

type DropdownOptions = Array<{ label: string; value: string }>;

const dateOptions: LocaleOptions & DateTimeFormatOptions = {
  locale: "en-gb",
  dateStyle: "short",
};

export const tryParseSourceAsVectorSource = (source: any) => {
  if (!(source as VectorSource).getFeaturesInExtent) return;

  return source as VectorSource;
};

export const getOptionLabelFromValue = <V extends string | number>(
  value: V | V[],
  options: { label: string; value: V }[]
) => {
  if (Array.isArray(value)) {
    const selected = options
      .filter((op) => value.includes(op.value))
      .map((op) => op.label);
    return selected.join(", ");
  }
  const option = options.find((op) => op.value === value);

  if (option) return option.label;
  return "";
};

export const formatDateTime = (dateString: string) => {
  const formattedString = DateTime.fromISO(dateString).toLocaleString({
    ...dateOptions,
    timeStyle: "short",
  });
  return formattedString;
};

export const formatDate = (dateString: string) => {
  const formattedString = DateTime.fromISO(dateString).toLocaleString({
    ...dateOptions,
  });
  return formattedString;
};

export const getArrayAvg = (arr: number[]) => {
  const sum = arr.reduce((a, b) => a + b, 0);
  return sum / arr.length || 0;
};

export const createDropdownOptions = <
  D extends { [key: string]: any; order?: number }
>(
  options: Array<D>,
  idKey: keyof D,
  textKey: keyof D
): DropdownOptions => {
  let dropdownOptions: DropdownOptions = [];
  const mapDropdownOptions = (option: D) => ({
    label: option[textKey],
    value: option[idKey],
  });

  if (options.some((option) => option.order)) {
    dropdownOptions = options.sort(sortTags).map(mapDropdownOptions);
  } else {
    dropdownOptions = options.map(mapDropdownOptions);
  }

  return dropdownOptions;
};

export const sortTags = <D extends { order?: number }>(a: D, b: D): number => {
  if (a.order === undefined || b.order === undefined) return 0;

  if (a.order < b.order) return -1;
  if (a.order > b.order) return 1;
  return 0;
};

export const deepClone = <T>(input: T) =>
  JSON.parse(JSON.stringify(input)) as T;

export const removeEmptyAndSpecifiedKeys = <Data>(
  formData: Data,
  keysToRemove?: TKeysToRemove<Data>,
  mode?: Mode
) => {
  const formDataForSubmit = { ...formData };
  for (const key in formDataForSubmit) {
    if (Object.prototype.hasOwnProperty.call(formDataForSubmit, key)) {
      if (keysToRemove?.includes(key)) {
        delete formDataForSubmit[key];
      } else {
        const modeSpecificDontSubmit = keysToRemove?.find(
          (obj) => typeof obj === "object" && obj.attribute === key
        ) as { mode: Mode; attribute: keyof Data };
        if (modeSpecificDontSubmit?.mode === mode)
          delete formDataForSubmit[key];
      }
    }
  }
  return formDataForSubmit;
};

export const getNested = (path: any[]) => (object: any) =>
  path.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), object);

export const isSuperTenantRole = (roles: Role[], roleId: string): boolean => {
  const tenantRoles = roles.filter((role) => role.type === PDG_ROLE_TYPE);
  return tenantRoles.some((role) => role.roleId === roleId);
};

export const responsesToOptionsMapper = (
  responses: any[],
  mappings: DropdownMappings
) => {
  const results = responses.map<[string, DropdownOptions]>((items, index) => {
    const map = mappings[index];
    const options = createDropdownOptions(items, map.key, map.text);

    return [map.name, options];
  });

  return Object.fromEntries(new Map(results));
};

export const isOfflineApp = () => {
  return !!process.env.REACT_APP_OFFLINE;
};

export const sortByKey =
  <T>(key: keyof T, direction: "asc" | "desc") =>
  (a: T, b: T) => {
    const result = a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0;
    return direction === "asc" ? result : -result;
  };
