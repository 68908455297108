import { useCancelToken } from "../../../hooks/general";
import { searchAssets } from "../../../services/assets.service";
import { AssetGeoJson } from "../../../types/documents/Assets";
import { ConditionSearch } from "./SearchPanel";

type Result = [
  (searchData: ConditionSearch) => Promise<AssetGeoJson | undefined>
];

export const useAssetConditionSearch = (
  clientId: string,
  projectType: string
): Result => {
  const cancelToken = useCancelToken();
  const handleSearch = async (searchData: ConditionSearch) => {
    const results = await searchAssets(
      cancelToken,
      clientId,
      projectType,
      searchData
    );

    return results;
  };

  return [handleSearch];
};
