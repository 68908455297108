import { FormControl, MenuItem, Select, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { ChangeEvent, useCallback, useState } from "react";
import { ClientFileList } from "../../components/assets/ClientFileList";
import { Loader } from "../../components/general";
import BreadcrumbHeader from "../../components/general/BreadcrumbHeader";
import { Crumb } from "../../components/general/types/Modify";
import { useCancelToken } from "../../hooks/general";
import { useServiceAction } from "../../hooks/general/useServiceAction";
import { useServiceData } from "../../hooks/general/useServiceData";
import { HooksLogger } from "../../hooks/hooks-logger";
import { notifications } from "../../libs/notifications";
import * as assetsApi from "../../services/assets.service";
import * as clientsApi from "../../services/clients.service";
import { RouteProps } from "../../types";
import { AssetsFile } from "../../types/documents";
import { AssetExecutions } from "./AssetExecutions";

const logger = new HooksLogger("Assets/upload");

export interface Props extends RouteProps {}

type Component = (props: Props) => JSX.Element;

export const AssetUpload: Component = ({ ...routeProps }) => {
  const { jwt } = routeProps;
  const classes = useStyles();
  const cancelToken = useCancelToken();
  const setError = useCallback((e) => notifications.error(e), []);

  const listClients = useCallback(
    () => clientsApi.listClients(jwt, cancelToken),
    [jwt, cancelToken]
  );
  const [clients] = useServiceData(
    "Getting client data",
    listClients,
    logger,
    cancelToken,
    setError
  );

  const [selectedClient, setSelectedClient] = useState<string | null>(null);
  const handleSelectClient = (event: any) => {
    setSelectedClient(event.target.value);
  };

  const listClientFiles = useCallback(async () => {
    if (!selectedClient) return [];
    return await assetsApi.listClientFiles(jwt, cancelToken, selectedClient);
  }, [jwt, cancelToken, selectedClient]);
  const [clientFiles, clientFilesLoading, refreshClientFiles] = useServiceData(
    "Getting client files",
    listClientFiles,
    logger,
    cancelToken,
    setError
  );

  const deleteFileAction = useCallback(
    async (objectName: string) => {
      if (!selectedClient) return;
      await assetsApi.deleteClientFile(
        jwt,
        cancelToken,
        selectedClient,
        objectName
      );
    },
    [jwt, cancelToken, selectedClient]
  );
  const [deleteFile, fileDeleting] = useServiceAction(
    "Deleting file",
    deleteFileAction,
    logger,
    cancelToken,
    () => refreshClientFiles(),
    setError
  );

  const loadingFiles = clientFilesLoading || fileDeleting;

  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const uploadHandler = (event: ChangeEvent) => {
    if (selectedClient === null) return;

    const input = event.target as HTMLInputElement;
    if (input === null || input.files === null) return;

    const selectedFiles = Array.from(input.files);
    if (selectedFiles.length === 0) return;

    setUploading(true);

    const fileNames: string[] = [];
    for (const selectedFile of selectedFiles) {
      fileNames.push(selectedFile.name);
    }

    const query = async () => {
      logger.request("Getting presigned post data");
      try {
        const postData = await assetsApi.uploadClientFiles(
          jwt,
          cancelToken,
          selectedClient,
          fileNames
        );
        logger.success(postData);

        let i = 0;

        //See README for why this was changed # ESLint build errors
        const incrementCounter = () => {
          i += 1;
        };

        for (const selectedFile of selectedFiles) {
          incrementCounter();

          if (!(selectedFile.name in postData)) {
            logger.error("post data for " + selectedFile.name + " is missing");
            continue;
          }

          const url = postData[selectedFile.name];
          logger.request("Putting " + selectedFile.name);
          let baseProgress = ((i - 1) * 100) / selectedFiles.length;
          await new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("PUT", url);
            xhr.onreadystatechange = () => {
              if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                  resolve(null);
                } else {
                  reject(xhr.statusText);
                }
              }
            };

            setUploadProgress(baseProgress);
            xhr.upload.onprogress = (event) => {
              logger.info("progress", event);
              if (event.lengthComputable) {
                const fileCompletion = (event.loaded * 100) / event.total;
                setUploadProgress(
                  baseProgress + fileCompletion / selectedFiles.length
                );
              }
            };

            xhr.send(selectedFile);
          });

          logger.success("Putted " + selectedFile.name);
        }

        setUploading(false);
        refreshClientFiles();
      } catch (e: any) {
        if (cancelToken.reason) return;
        const error = e.message ? e.message : e;

        setUploading(false);
        refreshClientFiles();
        setError(error);
        logger.error(error);
      }
    };

    query();
  };

  const [processing, setProcessing] = useState(false);
  const processFile = (file: AssetsFile) => () => {
    if (selectedClient === null) return;

    const query = async () => {
      logger.request("Processing files", { selectedClient });
      setProcessing(true);
      try {
        await assetsApi.processClientFile(
          jwt,
          cancelToken,
          selectedClient,
          file
        );
        logger.success();
        setProcessing(false);
      } catch (e: any) {
        if (cancelToken.reason) return;
        const error = e.message ? e.message : e;
        setProcessing(false);
        setError(error);
        logger.error(error);
      }
    };

    query();
  };

  const disableButtons = uploading || processing;

  const crumbs: Crumb[] = [
    {
      name: "Upload Assets",
    },
  ];

  return (
    <>
      <BreadcrumbHeader crumbs={crumbs}>
        <Loader active={loadingFiles} inline={true}>
          <span className={classes.clientSelect}>
            <Typography className={classes.font}>Client:</Typography>
            <FormControl className={clsx(classes.dropdown)}>
              <Select
                disableUnderline
                label="Client"
                onChange={handleSelectClient}
                value={selectedClient ?? ""}
              >
                {clients
                  ?.sort((a, b) => a.name.localeCompare(b.name))
                  .map((client) => (
                    <MenuItem key={client.id} value={client.id}>
                      {client.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </span>
        </Loader>
      </BreadcrumbHeader>
      {selectedClient !== null && (
        <ClientFileList
          disableButtons={disableButtons}
          processing={processing}
          uploading={uploading}
          uploadProgress={uploadProgress}
          loading={clientFilesLoading}
          files={clientFiles || []}
          onProcessFile={processFile}
          uploadFiles={uploadHandler}
          onDeleteFile={deleteFile}
        />
      )}
      <AssetExecutions {...routeProps} />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  header: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    backgroundColor: theme.palette.background.default,
    borderBottom: theme.spacing(0.125, "solid", `${theme.border.primary}`),
  },
  clientSelect: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "10px",
  },
  dropdown: {
    width: theme.spacing(22),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(3),
    "& .MuiSelect-root": {
      fontSize: "1rem",
      fontWeight: 500,
      textAlign: "left",
      paddingLeft: theme.spacing(1.5),
      backgroundColor: theme.backgroundShades.grey,
      border: theme.spacing(0.125, "solid", `${theme.border.primary}`),
    },
  },
  font: {
    fontWeight: 600,
  },
}));
