import { OFFLINE_SETTINGS_ENDPOINT } from "../../libs/config";
import { paths } from "../../navigation";
import { RouteProps } from "../../types";
import { SettingsData } from "../../types/documents/offline/Settings";
import { ModifyContainer } from "../general";
import { settingsComponents, settingsConstraints } from "./resources";
import { useServiceData } from "../../hooks/general/useServiceData";
import { useCallback, useMemo } from "react";
import { HooksLogger } from "../../hooks/hooks-logger";
import * as gpsApi from "../../services/offline/gps.service";
import { useCancelToken } from "../../hooks/general";
import { notifications } from "../../libs/notifications";

const logger = new HooksLogger("Settings");

const putEndpoint = `${OFFLINE_SETTINGS_ENDPOINT}`;
const queryEndpoint = `${OFFLINE_SETTINGS_ENDPOINT}`;

interface Props extends RouteProps {
}

type Component = (props: Props) => JSX.Element;

export const Settings: Component = ({ ...routeProps }) => {
  const cancelToken = useCancelToken();
  const setError = useCallback(e => notifications.error(e), []);

  const listDevices = useCallback(() => gpsApi.listDevices(cancelToken), [cancelToken]);
  const [devices, devicesLoading, refreshDevices] = useServiceData(
    "Fetching GPS device list",
    listDevices,
    logger,
    cancelToken,
    setError
  );

  const deviceOptions = useMemo(() => devices?.map(d => ({
    value: d.id,
    label: d.name,
  })), [devices])

  return (
    <ModifyContainer<SettingsData>
      {...routeProps}
      putEndpoint={putEndpoint}
      queryEndpoint={queryEndpoint}
      mode="update"
      initialData={{
        dataDirectory: "",
        gpsDevice: "",
      }}
      componentConfiguration={[
        settingsComponents.details("update", routeProps.jwt, cancelToken, {
          deviceOptions,
          devicesLoading,
          refreshDevices,
        })
      ]}
      constraints={settingsConstraints}
      redirectPath={paths.clients.view}
      loading={false}
    />
  );
};
