import {
  Box,
  Grid,
  IconButton,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { DescriptionOutlined } from "@material-ui/icons";
import clsx from "clsx";
import { useCallback, useState } from "react";
import { GradingReportModal } from "../../containers/gradingReport/GradingReportModal";
import { formatDate } from "../../helpers";
import { useCancelToken } from "../../hooks/general";
import { useServiceData } from "../../hooks/general/useServiceData";
import { HooksLogger } from "../../hooks/hooks-logger";
import { defectStrings as strings } from "../../resources/strings";
import { getLatestAssetGradingData } from "../../services/gradings.service";
import { AssetContainer } from "../../types/documents";
import { Loader } from "../general";
import { useDefectsListStyles } from "./styles/defectsList";

interface Props {
  jwt: string;
  assetId: string | null;
}

type Component = (props: Props) => JSX.Element;

type LatestAssetGradingData = {
  assetContainer: AssetContainer;
  packageId: string;
  createdDate: string;
};

const logger = new HooksLogger("LatestConditionAssessment");

export const LatestConditionAssessment: Component = ({ jwt, assetId }) => {
  const [open, setOpen] = useState(false);
  const classes = useDefectsListStyles();

  const cancelToken = useCancelToken();

  const fetchLatestAssetGradingData = useCallback(
    () =>
      assetId
        ? getLatestAssetGradingData(jwt, cancelToken, assetId)
        : Promise.resolve(undefined),
    [jwt, cancelToken, assetId]
  );
  const [latestGradingData, loading] = useServiceData<
    LatestAssetGradingData | undefined
  >(
    "Fetching details of latest condition assessment",
    fetchLatestAssetGradingData,
    logger,
    cancelToken
  );

  return (
    <Box className={clsx(classes.root)}>
      <div className={classes.headerContainer}>
        <h3 className={classes.header}>
          {strings.header.latestConditionAssessment}
        </h3>
      </div>
      <Loader active={loading} inline>
        {latestGradingData && (
          <>
            <ListItem>
              <Grid container wrap="nowrap" spacing={0}>
                <Grid item xs={10}>
                  <ListItemText
                    style={{ fontSize: "16px" }}
                    primary={formatDate(latestGradingData.createdDate)}
                  />
                </Grid>
                <Grid item xs zeroMinWidth>
                  <IconButton
                    size={"small"}
                    aria-label="info"
                    className={classes.button}
                    onClick={() => setOpen(true)}
                  >
                    <DescriptionOutlined />
                  </IconButton>
                </Grid>
              </Grid>
            </ListItem>
            <GradingReportModal
              open={open}
              onClose={() => setOpen(false)}
              assetContainer={latestGradingData.assetContainer}
              packageId={latestGradingData.packageId}
              jwt={jwt}
              permissions={null}
              user={null}
            />
          </>
        )}
      </Loader>
    </Box>
  );
};
