import { Button, makeStyles, Theme } from "@material-ui/core";
import clsx from "clsx";
import { defaultButtonStyle } from "../../components/styles";
import { projectStrings as strings } from "../../resources/strings";

const useStyles = makeStyles((theme: Theme) => ({
  all: defaultButtonStyle(theme),
  border: { border: theme.spacing(0.3, "solid") },
}));

export default function ClearSaveButtons() {
  const classes = useStyles();

  return (
      <>
        <Button
          type="reset"
          className={clsx(classes.all, classes.border)}
          color="primary"
          variant="outlined"
          title="clear"
        >
          {strings.labels.clear}
        </Button>
        <Button
          color="primary"
          className={clsx(classes.all)}
          variant="contained"
          type="submit"
          title="save"
        >
          {strings.labels.save}
        </Button>
      </>
    );
}
