import { useEffect, useState } from "react";
import { getAssetsByGroup } from "../../services/assets.service";
import { AssetDetails } from "../../types/documents/Assets";
import { useCancelToken } from "../general";
import { AssetGroupValuesProps } from "./useAssetGroupValues";

type AssetsByGroupandValueProps = AssetGroupValuesProps & {
  values?: string[];
  sort: (a: AssetDetails, b: AssetDetails) => number;
};
type AssetsByGroupandValuePropsResult = [boolean, AssetDetails[]];

export const useAssetsByGroupAndValues = ({
  clientId,
  groupedBy,
  values,
  sort,
}: AssetsByGroupandValueProps): AssetsByGroupandValuePropsResult => {
  const [loading, setLoading] = useState(false);
  const [assets, setAssets] = useState<AssetDetails[]>([]);

  const cancelToken = useCancelToken();
  useEffect(() => {
    const getAssetGroups = async () => {
      if (!groupedBy || !clientId || !values || values.length < 1) {
        setAssets(() => []);
        setLoading(false);

        return;
      }
      try {
        const assetDetails = await getAssetsByGroup(
          cancelToken,
          clientId,
          groupedBy,
          values
        );

        if (!assetDetails) setAssets([]);
        else {
          const sortedAssets = assetDetails.sort((a, b) => sort(a, b));
          setAssets([...sortedAssets]);
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
        return;
      }
    };

    setLoading(true);
    getAssetGroups();
  }, [clientId, groupedBy, values, cancelToken, sort]);

  return [loading, assets];
};
