import { Feature } from "ol";
import { Coordinate } from "ol/coordinate";
import { Circle, Geometry, LineString, Point, SimpleGeometry } from "ol/geom";
import { Draw } from "ol/interaction";
import { createBox } from "ol/interaction/Draw";
import VectorSource from "ol/source/Vector";
import { mapStrings as strings } from "../../../resources/strings";
import { arrowStyle, measureStyle, textStyle } from "./Style";

export const pointToCircle = (point: Feature<Point>) => {
  const geom = point.getGeometry();

  const centre = geom?.getCoordinates();
  const radius = point.get("radius");

  if (centre && radius) {
    return new Circle(centre, radius);
  }
};

export const circleToPoint = (circle: Feature<Circle>) => {
  const geom = circle.getGeometry();
  if (!geom) return;

  const point = new Feature(new Point(geom.getCenter()));
  point.set("radius", (geom as Circle).getRadius());

  return point;
};

export const getDrawTool = (
  type: string,
  vectorSource: VectorSource<Geometry>,
  onDrawEnd?: (feature: Feature<Geometry>) => void
) => {
  let draw;

  switch (type) {
    case strings.drawTools.pencil:
      draw = new Draw({ type: "LineString", freehand: true });
      break;

    case strings.drawTools.freehand:
      draw = new Draw({ type: "Polygon", freehand: true });
      break;

    case strings.drawTools.text:
      draw = new Draw({ type: "Point" });

      draw.on("drawstart", (event) => {
        event.feature.setStyle(textStyle);
      });
      break;

    case strings.drawTools.ruler:
      draw = new Draw({
        type: "LineString",
        maxPoints: 2,
        freehand: true,
      });

      draw.on("drawstart", (event) => {
        event.feature.setStyle(measureStyle);
      });
      break;

    case strings.drawTools.line:
      draw = new Draw({
        type: "LineString",
        maxPoints: 2,
        freehand: true,
        geometryFunction: createLine(),
      });
      break;

    case strings.drawTools.square:
      draw = new Draw({
        type: "Circle",
        geometryFunction: createBox(),
        freehand: true,
      });
      break;

    case strings.drawTools.arrow:
      draw = new Draw({
        type: "LineString",
        maxPoints: 2,
        freehand: true,
        geometryFunction: createLine(),
      });

      draw.on("drawend", (event) => {
        event.feature.setStyle(arrowStyle);
      });
      break;

    case strings.drawTools.circle:
      draw = new Draw({ type: "Circle", freehand: true });
      let radiusMeasure: Feature<LineString>;

      draw.on("drawstart", (event) => {
        const circle = event.feature.getGeometry() as Circle;
        const centre = circle.getCenter();

        const line = new LineString([centre, centre]);
        radiusMeasure = new Feature(line);
        radiusMeasure.setStyle(measureStyle);

        circle.on("change", () => {
          line.setCoordinates([
            centre,
            [centre[0] + circle.getRadius(), centre[1]],
          ]);
        });

        vectorSource.addFeature(radiusMeasure);
      });

      draw.on("drawend", () => vectorSource.removeFeature(radiusMeasure));
      draw.on("drawabort", () => vectorSource.removeFeature(radiusMeasure));
      break;

    default:
      draw = new Draw({ type });
  }

  if (onDrawEnd) {
    draw.on("drawend", (event) => {
      onDrawEnd(event.feature);
    });
  }

  return draw;
};

const createLine = () => {
  // For some reason, on mouse release, the last element of the coords array
  // is removed, so we need to store it here and only update when present
  let end: Coordinate;

  return (coordinates: any, opt_geometry: SimpleGeometry) => {
    const start: Coordinate = coordinates[0];
    if (coordinates.length > 1) {
      end = coordinates[coordinates.length - 1];
    }

    let geometry = opt_geometry;
    if (geometry) {
      geometry.setCoordinates([start, end]);
    } else {
      geometry = new LineString([start, end]);
    }

    return geometry;
  };
};
