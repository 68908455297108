import { appStrings } from "./app";

export const defectStrings = {
  global: { ...appStrings },
  header: {
    inspectionTools: "Inspection Tools",
    createFlightToAddDefect: "Please Create a Flight to Add a Defect",
    packageDefects: (pkg: string) => `Defects in Package ${pkg}`,
    allDefects: "All Defects",
    pastDefects: "Past Defects",
    availableDefectTypes: "Available Defect Types",
    latestConditionAssessment: "Latest Condition Assessment",
  },
  labels: {
    clearDefect: "Clear Defect",
    deleteDefect: "Delete Defect",
    areYouSure: "Are you sure you want to delete this defect?",
    areYouSureMulti:
      "Are you sure you want to delete all current and past defects?",
    workOrder: "Work order (optional)",
  },
  tooltips: {
    drawingTool: "Drawing Tool",
    complete: "Complete",
    footPatrol: "Mark as Foot Patrol",
    clearAllDefects: "Clear all Defects",
  },
};
