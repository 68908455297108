import { Link, Tooltip, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Column, Row } from "react-table";
import {
  ColumnMap,
  ColumnMapControls,
  ColumnMapSubsections,
  columnNames,
  getGradingReportColumns,
} from "../../resources/GradingReportTemplate";
import { towerTypeOptions } from "../../resources/testGradingForm";

const initColumns: any[] = [];

export const useGradingColumns = (
  handleSelectedRow: (row: any) => void,
  leftCircuitIds: string[],
  rightCircuitIds: string[],
  hideCircuitIfNoIds = false,
  displayTooltip = true
) => {
  const [columns, setColumns] = useState<any[]>(initColumns);

  useEffect(() => {
    if (leftCircuitIds.length < 1 && rightCircuitIds.length < 1) {
      setColumns(() => []);
      return;
    }
    const stringRangeFilter = (
      rows: Row[],
      [column]: string[],
      [start, end]: string[]
    ) => {
      return rows.filter((row) => {
        const value = row.values[column];

        if (start && end && start.localeCompare(end) > 0) {
          [start, end] = [end, start];
        }

        const afterStart = start ? value.localeCompare(start) >= 0 : true;
        const beforeEnd = end ? value.localeCompare(end) <= 0 : true;

        return afterStart && beforeEnd;
      });
    };

    const getSubsectionColumns = (
      heading: string,
      section: ColumnMapSubsections,
      displayTooltip = true
    ) => {
      return {
        Header:
          section.tooltip && displayTooltip ? (
            <Tooltip title={<Typography>{section.tooltip}</Typography>}>
              <div>{heading}</div>
            </Tooltip>
          ) : (
            heading
          ),
        id: heading,
        columns: getSectionColumns(section.subsections, displayTooltip),
      } as Column;
    };

    const getControlColumns = (heading: string, section: ColumnMapControls) => {
      const control = section.controls;

      return {
        Header: heading,
        columns: Object.entries(control).map(([control, column]) => {
          return {
            Header: control,
            accessor: column.dbColumnName,
            options: column.options,
            exportStyle: column.exportStyle,
            filter: "includesSome",
          } as Column & { options: any };
        }),
      };
    };

    const getSectionColumns = (
      section: ColumnMap,
      displayTooltip = true
    ): any => {
      return Object.entries(section).map(([key, value]) => {
        return value.type === "controls"
          ? getControlColumns(key, value)
          : getSubsectionColumns(key, value, displayTooltip);
      });
    };

    const columns = [
      {
        Header: "Tower Details",
        columns: [
          {
            Header: "Tower No.",
            accessor: columnNames.name,
            filter: stringRangeFilter,
            Cell: ({ row, value }: { row: any; value: string }) => {
              return (
                <Link
                  href="#"
                  underline="always"
                  variant="body1"
                  onClick={() => handleSelectedRow(row.original)}
                  style={{ fontWeight: 500 }}
                >
                  {value}
                </Link>
              );
            },
          },
          {
            Header: "Grouping",
            accessor: columnNames.groupings,
            filter: "includesSome",
          },
          {
            Header: "Tension or Suspension (T/S)",
            accessor: columnNames.towerType,
            options: towerTypeOptions,
          },
        ],
      },
      ...getSectionColumns(
        getGradingReportColumns(
          leftCircuitIds,
          rightCircuitIds,
          hideCircuitIfNoIds
        ),
        displayTooltip
      ),
    ];

    setColumns(() => [...columns]);

    return () => {
      console.log("Clean up use grading columns");
    };
  }, [
    handleSelectedRow,
    leftCircuitIds,
    rightCircuitIds,
    hideCircuitIfNoIds,
    displayTooltip,
  ]);

  return columns;
};
