import { Dialog, DialogContent } from "@material-ui/core";
import { Feature } from "ol";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useDrawingTool } from "../../../hooks/defects/useDrawingTool";
import { useCancelToken } from "../../../hooks/general";
import { mapStrings } from "../../../resources/strings";
import { DefectsToolBar } from "../../defects/DefectsToolBar";
import MapContext from "../MapContext";
import { AssetStatus } from "./LayerFunctions";

type Props = {
  jwt: string;
  flightId: string | null;
  packageId?: string;
  singleAsset?: Feature | null;
  closeSingleAsset?: () => void;
  updatePointProperties: (
    pointIds: string[],
    status: AssetStatus,
    defectCount?: number
  ) => string[];
  assetSelectType: string;
  clientName: string;
  container: HTMLElement | null;
};

export const DefectsToolbarModal = ({
  jwt,
  flightId,
  packageId,
  singleAsset,
  closeSingleAsset: _closeSingleAsset,
  updatePointProperties,
  assetSelectType,
  clientName,
  container,
}: Props) => {
  const map = useContext(MapContext);
  const cancelToken = useCancelToken();

  const [modalOpen, setModalOpen] = useState(false);
  const [assetIds, setAssetIds] = useState<string[]>([]);
  const [defectCount, setDefectCount] = useState(0);

  const layers = useMemo(
    () => [mapStrings.layers.search, mapStrings.layers.status(clientName)],
    [clientName]
  );

  const [clearSelected, initialiseDrawTool] = useDrawingTool({
    map,
    layers,
    enabled: assetSelectType === mapStrings.viewTools.selectMultipleAssets,
  });

  const [id, defects] = useMemo(() => {
    const id = singleAsset ? singleAsset.getProperties().id : "";
    const defects = singleAsset ? singleAsset.getProperties().defects : 0;
    return [id, defects];
  }, [singleAsset]);

  const closeSingleAsset = useCallback(() => {
    if (_closeSingleAsset) {
      _closeSingleAsset();
    }
  }, [_closeSingleAsset]);

  useEffect(() => {
    if (id) {
      setAssetIds([id]);
      setDefectCount(defects);
      setModalOpen(true);
    } else {
      setModalOpen(false);
      setAssetIds([]);
      setDefectCount(0);
      closeSingleAsset();
    }
  }, [id, defects, closeSingleAsset]);

  const handleDefectCount = useCallback(
    (assetIds: string[], status: AssetStatus, defectCount?: number) => {
      return updatePointProperties(assetIds, status, defectCount);
    },
    [updatePointProperties]
  );

  useEffect(() => {
    initialiseDrawTool((features) => {
      if (features.length === 0) return;

      const init: { ids: string[]; total: number } = {
        ids: [],
        total: 0,
      };

      const featuresReduced = features.reduce((previous, current) => {
        const id = current.getProperties().id;
        const count: number = current.getProperties().defects;

        const { ids, total } = previous;
        return {
          ids: [...ids, id],
          total: total + count,
        };
      }, init);

      setAssetIds(() => featuresReduced.ids);
      setDefectCount(() => featuresReduced.total);
      setModalOpen(true);
    });
  }, [initialiseDrawTool]);

  const closeDialog = () => {
    setModalOpen(false);
    setAssetIds([]);
    setDefectCount(0);
    closeSingleAsset();
    clearSelected();
  };

  return (
    <Dialog
      open={modalOpen}
      onClose={closeDialog}
      container={container}
      style={{ position: "absolute" }}
      PaperProps={{
        style: {
          position: "absolute",
          top: "64px",
          left: 0,
          margin: "16px",
          width: "30%",
          height: "max-content",
          maxHeight: "calc(100% - 32px)",
          borderRadius: 0,
        },
      }}
      BackdropProps={{ style: { backgroundColor: "transparent" } }}
    >
      <DialogContent style={{ padding: "8px 16px" }}>
        <DefectsToolBar
          assetIds={assetIds}
          flightId={flightId}
          packageId={packageId}
          defectCount={defectCount}
          updateFeatureStatus={handleDefectCount}
          jwt={jwt}
          cancelToken={cancelToken}
        />
      </DialogContent>
    </Dialog>
  );
};
