import { Lens, Optional } from "monocle-ts";
import { indexArray } from "monocle-ts/Index/Array";
import { useCallback, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { GradingFormComponent } from "../../../components/projects/packages/GradingForm";
import { useCancelToken } from "../../../hooks/general";
import { useServiceAction } from "../../../hooks/general/useServiceAction";
import { useServiceData } from "../../../hooks/general/useServiceData";
import { HooksLogger } from "../../../hooks/hooks-logger";
import { notifications } from "../../../libs/notifications";
import { createGradingForm } from "../../../resources/testGradingForm";
import { getExtractedJobControls } from "../../../services/defects.service";
import {
  getGradingForm,
  submitGradingForm,
} from "../../../services/gradings.service";
import { getPackageAssetContainers } from "../../../services/packages.service";
import { RouteProps } from "../../../types";
import { AssetContainer, JobData } from "../../../types/documents";
import {
  Control,
  GradingForm,
  Section,
  SectionJsonWithControls,
  SectionJsonWithSubsections,
  SubSection,
} from "../../../types/gradingForm";

const logger = new HooksLogger("GradingsForm");

interface Props extends RouteProps {
  assetContainer?: AssetContainer;
  packageId?: string;
  readOnly?: boolean;
}

type Component = (props: Props) => JSX.Element;

export const GradingFormContainer: Component = ({
  assetContainer,
  packageId: propPkgId,
  readOnly,
  ...props
}) => {
  const { jwt } = props;
  const { packageId: paramPkgId } = useParams<{ packageId: string }>();
  const packageId = propPkgId ?? paramPkgId;

  const cancelToken = useCancelToken();
  const onError = useCallback((e: string) => notifications.error(e), []);

  const fetchAssetContainers = useCallback(
    () =>
      assetContainer
        ? Promise.resolve([assetContainer])
        : getPackageAssetContainers(jwt, cancelToken, packageId),
    [jwt, cancelToken, packageId, assetContainer]
  );
  const [assetContainers] = useServiceData<AssetContainer[]>(
    "Fetching asset containers",
    fetchAssetContainers,
    logger,
    cancelToken,
    onError
  );

  const [selectedAssetContainerId, setSelectedAssetContainerId] =
    useState<string>(assetContainer?.id ?? "");
  const selectedAssetContainer = assetContainers?.find(
    (t) => t.id === selectedAssetContainerId
  );

  const gradingFormCache = useRef<Record<string, GradingForm>>({});
  const conditionAssessmentJobCache = useRef<Record<string, JobData[]>>({});

  const fetchGradingFormJob = useCallback(async () => {
    if (!selectedAssetContainerId) {
      return undefined;
    }

    if (!(selectedAssetContainerId in gradingFormCache.current)) {
      const job = await getGradingForm(
        jwt,
        cancelToken,
        packageId,
        selectedAssetContainerId
      );

      gradingFormCache.current[selectedAssetContainerId] =
        job || createGradingForm(packageId, selectedAssetContainerId);
    }

    return gradingFormCache.current[selectedAssetContainerId];
  }, [selectedAssetContainerId, gradingFormCache, packageId, jwt, cancelToken]);

  const [gradingFormJob, , refreshGradingFormJob] = useServiceData(
    "Fetching job",
    fetchGradingFormJob,
    logger,
    cancelToken,
    onError
  );

  const fetchConditionAssessmentJob = useCallback(async () => {
    if (!selectedAssetContainerId) return undefined;

    if (!(selectedAssetContainerId in conditionAssessmentJobCache.current)) {
      const jobs = await getExtractedJobControls(
        packageId,
        selectedAssetContainerId,
        cancelToken
      );

      conditionAssessmentJobCache.current[selectedAssetContainerId] = jobs;
    }

    return conditionAssessmentJobCache.current[selectedAssetContainerId];
  }, [selectedAssetContainerId, packageId, cancelToken]);

  const [conditionAssessmentJobs] = useServiceData(
    "Fetching condition assessment with extracted controls",
    fetchConditionAssessmentJob,
    logger,
    cancelToken,
    onError
  );

  const [selectedSectionId, setSelectedSectionId] = useState<string>();
  const [selectedControls, selectedSection, selectedControlsLens] = useMemo(
    () =>
      gradingFormJob && selectedSectionId
        ? findControlsForId(gradingFormJob, selectedSectionId)
        : [undefined, undefined, undefined],
    [gradingFormJob, selectedSectionId]
  );

  const doUpdateSelectedControls = useCallback(
    async (index: number, value: string) => {
      if (!selectedControlsLens || !selectedAssetContainerId) return;

      const updateFn = selectedControlsLens
        .compose(indexArray<Control>().index(index))
        .composeLens(Lens.fromProp<Control>()("value"))
        .set(value);

      gradingFormCache.current[selectedAssetContainerId] = updateFn(
        gradingFormCache.current[selectedAssetContainerId]
      );
      await submitGradingForm(
        jwt,
        cancelToken,
        gradingFormCache.current[selectedAssetContainerId]
      );
    },
    [
      selectedAssetContainerId,
      selectedControlsLens,
      jwt,
      cancelToken,
      gradingFormCache,
    ]
  );
  const [updateSelectedControls] = useServiceAction(
    "Saving form",
    doUpdateSelectedControls,
    logger,
    cancelToken,
    refreshGradingFormJob,
    onError
  );

  const sectionIds = useMemo(
    () => (gradingFormJob ? extractSectionIdList(gradingFormJob) : []),
    [gradingFormJob]
  );

  const [prevSectionId, nextSectionId] = useMemo(() => {
    if (!selectedSectionId) return [undefined, undefined];
    const sectionIdIndex = sectionIds.indexOf(selectedSectionId);
    const prev =
      sectionIdIndex > 0 ? sectionIds[sectionIdIndex - 1] : undefined;
    const next =
      sectionIdIndex < sectionIds.length - 1
        ? sectionIds[sectionIdIndex + 1]
        : undefined;
    return [prev, next];
  }, [sectionIds, selectedSectionId]);
  const selectPrev = useMemo(
    () =>
      prevSectionId ? () => setSelectedSectionId(prevSectionId) : undefined,
    [setSelectedSectionId, prevSectionId]
  );
  const selectNext = useMemo(
    () =>
      nextSectionId ? () => setSelectedSectionId(nextSectionId) : undefined,
    [setSelectedSectionId, nextSectionId]
  );

  return (
    <GradingFormComponent
      assetContainers={assetContainers}
      selectedAssetContainer={selectedAssetContainer}
      onSelectTower={setSelectedAssetContainerId}
      onSelectControlSection={setSelectedSectionId}
      selectedSectionId={selectedSectionId}
      selectedControls={selectedControls}
      selectedSection={selectedSection}
      onSelectPrev={selectPrev}
      onSelectNext={selectNext}
      job={gradingFormJob}
      conditionAssessmentJobs={conditionAssessmentJobs}
      updateSelectedControls={updateSelectedControls}
      readOnly={readOnly}
      {...props}
    />
  );
};

const extractSectionIdList = (job: GradingForm): string[] => {
  const sections = [];

  for (let sectionIdx = 0; sectionIdx < job.sections.length; sectionIdx += 1) {
    const section = job.sections[sectionIdx];
    if ("controls" in section.sectionJson) {
      sections.push(section.id);
    }
    if ("subsections" in section.sectionJson) {
      const subsections = section.sectionJson.subsections;
      for (
        let subsectionIdx = 0;
        subsectionIdx < subsections.length;
        subsectionIdx += 1
      ) {
        const subsection = subsections[subsectionIdx];
        sections.push(subsection.id);
      }
    }
  }

  return sections;
};

const findControlsForId = (
  job: GradingForm,
  id: string
):
  | [Control[], Section | SubSection, Optional<GradingForm, Control[]>]
  | [undefined, undefined, undefined] => {
  for (let sectionIdx = 0; sectionIdx < job.sections.length; sectionIdx += 1) {
    const section = job.sections[sectionIdx];
    if (section.id === id && "controls" in section.sectionJson) {
      const sectionJsonLens = Lens.fromProp<GradingForm>()("sections")
        .composeOptional(indexArray<Section>().index(sectionIdx))
        .composeLens(Lens.fromProp<Section>()("sectionJson")) as Optional<
        GradingForm,
        SectionJsonWithControls
      >;
      return [
        section.sectionJson.controls,
        section,
        sectionJsonLens.composeLens(
          Lens.fromProp<SectionJsonWithControls>()("controls")
        ),
      ];
    }
    if ("subsections" in section.sectionJson) {
      const subsections = section.sectionJson.subsections;
      for (
        let subsectionIdx = 0;
        subsectionIdx < subsections.length;
        subsectionIdx += 1
      ) {
        const subsection = subsections[subsectionIdx];
        if (subsection.id === id) {
          const sectionJsonLens = Lens.fromProp<GradingForm>()("sections")
            .composeOptional(indexArray<Section>().index(sectionIdx))
            .composeLens(Lens.fromProp<Section>()("sectionJson")) as Optional<
            GradingForm,
            SectionJsonWithSubsections
          >;
          return [
            subsection.controls,
            subsection,
            sectionJsonLens
              .composeLens(
                Lens.fromProp<SectionJsonWithSubsections>()("subsections")
              )
              .compose(indexArray<SubSection>().index(subsectionIdx))
              .composeLens(Lens.fromProp<SubSection>()("controls")),
          ];
        }
      }
    }
  }
  return [undefined, undefined, undefined];
};
