import { useEffect, useMemo, useState } from "react";
import { API } from "../../API";
import { Axios } from "../../libs/axios";
import * as defectsApi from "../../services/defects.service";
import { AssetIdDefectsResult } from "../../services/defects.service";
import { DefectData } from "../../types/documents";

const hasOwnProperty = <X extends {}, Y extends PropertyKey>(
  obj: X,
  prop: Y
): obj is X & Record<Y, unknown> => {
  return obj.hasOwnProperty(prop);
};

type Props = {
  defectCount: number;
  assetIds: string[];
  jwt: string;
  cancelToken: Axios.CancelToken;
};

type Result = [
  DefectData[],
  number,
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>
];

export const useAssetDefects = ({
  defectCount,
  assetIds,
  jwt,
  cancelToken,
}: Props): Result => {
  const [defects, setDefects] = useState<DefectData[]>([]);
  const [numberOfDefects, setNumberOfDefects] = useState(defectCount);
  const [defectListChangesPending, setDefectListChangesPending] =
    useState(true);

  const api = useMemo(
    () => new API<DefectData>(cancelToken, jwt),
    [cancelToken, jwt]
  );
  useEffect(() => {
    if (!defectListChangesPending) {
      return;
    }

    const filterData = (data: AssetIdDefectsResult): boolean => {
      return (
        data !== undefined &&
        hasOwnProperty(data, "results") &&
        Array.isArray(data.results)
      );
    };

    const loadDefectData = async () => {
      const results = await defectsApi.getDefectsByAssetIds(
        assetIds,
        jwt,
        cancelToken
      );
      const hasResults = filterData(results);
      setDefects(hasResults ? results.results : []);
      setNumberOfDefects(hasResults ? results.results.length : 0);
      setDefectListChangesPending(false);
    };

    loadDefectData();
  }, [api, assetIds, cancelToken, defectListChangesPending, jwt]);

  return [
    defects,
    numberOfDefects,
    defectListChangesPending,
    setDefectListChangesPending,
  ];
};
