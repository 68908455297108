import { appStrings } from "./app";

export const bookingStrings = {
  global: { ...appStrings },
  header: { bookings: "Bookings" },
  labels: {
    firstName: "First Name",
    lastName: "Last Name",
    startDate: "Start Date",
    route: "Route",
    numberOfBags: "No. of Bags",
    type: "Type",
    referenceNumber: "Reference No.",
    tourAgency: "Tour Agency",
    notes: "Notes",
    tagsCreated: "Tags Created",
    status: "Status",
    newBooking: "Create Booking",
  },
};
