import { useContext } from "react";
import { useWebGlPointsLayer } from "../../../hooks/map/useWebGlPointsLayer";
import { mapStrings as strings } from "../../../resources/strings";
import { RouteProps } from "../../../types";
import { AssetGeoJson } from "../../../types/documents/Assets";
import { useGeoJSON } from "../helpers/GeoJSON";
import { assetDefectStatusStyle } from "../helpers/Style";
import MapContext from "../MapContext";
import { SearchContainer } from "../search/SearchContainer";

interface SearchLayerProps extends RouteProps {
  clientId: string | null;
}

const searchProperties = {
  title: `Search Results`,
  displayTitle: `Search Results`,
  layerType: strings.layerTypes.webgl,
};

export const SearchLayer = ({ clientId, ...routeProps }: SearchLayerProps) => {
  const map = useContext(MapContext);
  const format = useGeoJSON();
  const [, vectorSource, addDataToSource] = useWebGlPointsLayer({
    map,
    format,
    style: assetDefectStatusStyle,
    properties: searchProperties,
  });

  const handleSearchResults = (searchResults: AssetGeoJson) => {
    if (!vectorSource || !map) return;
    vectorSource.clear();
    addDataToSource(searchResults);
  };

  const handleClearSearch = () => {
    if (!vectorSource || !map) return;
    vectorSource.clear();
    map.getView().setZoom(8);
  };

  return (
    <>
      <SearchContainer
        clientId={clientId}
        {...routeProps}
        handleSearchResults={handleSearchResults}
        handleClearSearch={handleClearSearch}
      ></SearchContainer>
    </>
  );
};
