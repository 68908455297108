import type { HubCallback } from "@aws-amplify/core";
import Amplify, { Auth, Hub } from "aws-amplify";
import {
  APP_DOMAIN,
  COGNITO_APP_CLIENT_ID,
  COGNITO_DOMAIN,
  COGNITO_IDENTITY_POOL_ID,
  COGNITO_REGION,
  COGNITO_SIGNIN_URL,
  COGNITO_SIGNOUT_URL,
  COGNITO_USER_POOL_ID,
  OFFLINE,
} from "./config";

const isProd = process.env.NODE_ENV === "production";
const DOMAIN = isProd ? `.${APP_DOMAIN}` : "localhost";

let amplifyConfig = {
  aws_project_region: COGNITO_REGION,
  aws_cognito_identity_pool_id: COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: COGNITO_REGION,
  aws_user_pools_id: COGNITO_USER_POOL_ID,
  aws_user_pools_web_client_id: COGNITO_APP_CLIENT_ID,
  oauth: {
    domain: COGNITO_DOMAIN,
    scope: [
      "phone",
      "email",
      "profile",
      "openid",
      "aws.cognito.signin.user.admin",
    ],
    redirectSignIn: COGNITO_SIGNIN_URL,
    redirectSignOut: COGNITO_SIGNOUT_URL,
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_POOLS",
  ...(OFFLINE === "0"
    ? {
        Auth: {
          cookieStorage: {
            domain: DOMAIN,
            secure: isProd,
            path: "/",
          },
        },
      }
    : {}),
};

console.log("Amplify Config", amplifyConfig);

Amplify.configure(amplifyConfig);

type TAuth = typeof Auth;
type TAmplify = typeof Amplify;

export { Auth, Hub };
export type { TAmplify, TAuth, HubCallback };
