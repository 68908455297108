import { Grid } from "@material-ui/core";
import { searchStrings as strings } from "../../../resources/strings";
import { DropdownOptions } from "../../../types";
import { Dropdown, Header, Input } from "../../general/controls";
import { BaseControl } from "../../general/controls/BaseControl";
import { ChangeHandler } from "../../general/types/Modify";
import { StandardSearch } from "./SearchPanel";
import { useStyles } from "./styles";

type StandardSearchFormProps = {
  searchData: StandardSearch;
  assetOptions: DropdownOptions;
  assetGroupOptions: DropdownOptions;
  assetGroupValueOptions: DropdownOptions;
  handleSearchChange: ChangeHandler;
};

export const StandardSearchForm = ({
  searchData,
  assetOptions,
  assetGroupOptions,
  assetGroupValueOptions,
  handleSearchChange,
}: StandardSearchFormProps) => {
  const classes = useStyles();

  return (
    <>
      <Grid item>
        <BaseControl>
          <Header
            config={{
              controltype: "header",
              text: strings.labels.dateRange,
              align: "left",
            }}
          ></Header>
        </BaseControl>
        <Grid container direction="row">
          <Grid item>
            <BaseControl>
              <Input<StandardSearch>
                config={{
                  controltype: "input",
                  name: "dateFrom",
                  type: "date",
                  label: strings.labels.from,
                  inline: true,
                  value: searchData.dateFrom,
                  required: true,
                }}
                handleChange={handleSearchChange}
              />
            </BaseControl>
          </Grid>
          <Grid item>
            <BaseControl>
              <Input<StandardSearch>
                config={{
                  controltype: "input",
                  name: "dateTo",
                  type: "date",
                  label: strings.labels.to,
                  inline: true,
                  value: searchData.dateTo,
                  required: true,
                }}
                handleChange={handleSearchChange}
              />
            </BaseControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <BaseControl>
          <Dropdown<StandardSearch>
            config={{
              controltype: "dropdown",
              name: "groupByKey",
              options: assetGroupOptions,
              label: strings.labels.groupByKey,
              value: searchData.groupByKey,
              required: true,
            }}
            labelProps={{ className: classes.label }}
            selectProps={{ className: classes.dropdown }}
            handleChange={handleSearchChange}
          ></Dropdown>
        </BaseControl>
      </Grid>

      <Grid item>
        <BaseControl>
          <Dropdown<StandardSearch>
            config={{
              controltype: "dropdown",
              name: "groupByValue",
              options: assetGroupValueOptions,
              label: strings.labels.groupByValues,
              value: searchData.groupByValue,
              required: true,
              multiple: true,
            }}
            labelProps={{ className: classes.label }}
            selectProps={{ className: classes.dropdown }}
            handleChange={handleSearchChange}
          ></Dropdown>
        </BaseControl>
      </Grid>
      <Grid item>
        <BaseControl>
          <Header
            config={{
              controltype: "header",
              text: strings.labels.assetRange,
              align: "left",
            }}
          ></Header>
        </BaseControl>
        <Grid container direction="row" justifyContent="flex-start">
          <Grid item>
            <BaseControl direction="row">
              <Dropdown<StandardSearch>
                config={{
                  controltype: "dropdown",
                  name: "groupByValueFrom",
                  label: strings.labels.from,
                  options: assetOptions,
                  value: searchData.groupByValueFrom,
                }}
                labelProps={{ className: classes.inlineLabel }}
                selectProps={{ className: classes.dropdownInline }}
                handleChange={handleSearchChange}
              />
            </BaseControl>
          </Grid>
          <Grid item>
            <BaseControl direction="row">
              <Dropdown<StandardSearch>
                config={{
                  controltype: "dropdown",
                  name: "groupByValueTo",
                  label: strings.labels.to,
                  options: assetOptions,
                  value: searchData.groupByValueTo,
                }}
                labelProps={{ className: classes.inlineLabel }}
                selectProps={{ className: classes.dropdownInline }}
                handleChange={handleSearchChange}
              />
            </BaseControl>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
