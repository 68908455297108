import { makeStyles } from "@material-ui/core";

export const useDefectsListStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100px",
    padding: "8px 0px",
  },
  headerContainer: {
    borderBottom: `1px solid ${theme.border.primary}`,
    paddingBottom: "10px",
  },
  header: {
    color: theme.palette.primary.main,
    fontFamily: theme.typography.fontFamily,
    textTransform: "uppercase",
    fontWeight: 700,
  },
  button: {
    "&:hover & svg": {
      color: theme.palette.primary.main,
    },
  },
  list: {
    height: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    overflow: "auto",
  },
}));
