import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  button: {
    fontWeight: 700,
    borderRadius: 0,
    minWidth: theme.spacing(10),
    margin: theme.spacing(0.5),
    padding: theme.spacing(0.5),
    border: theme.spacing(0.25, "solid", theme.palette.primary.main),
    color: theme.palette.primary.main,
    "&.Mui-disabled": {
      color: `${theme.border.primary}`,
      border: theme.spacing(0.25, "solid", `${theme.border.primary}`),
    },
  },

  toggleButton: {
    color: "inherit",
    border: theme.spacing(0.125, "solid", `${theme.border.primary}`),
    borderRadius: 0,
    padding: theme.spacing(0.375),
    margin: theme.spacing(0.5),
    "&.Mui-selected": {
      color: theme.palette.primary.main,
    },
    "&.MuiToggleButtonGroup-groupedHorizontal": {
      borderLeft: theme.spacing(0.125, "solid", `${theme.border.primary}`),
      marginLeft: theme.spacing(0.5),
    },
    "&.MuiToggleButton-root.Mui-selected + .MuiToggleButton-root.Mui-selected":
      {
        borderLeft: theme.spacing(0.125, "solid", `${theme.border.primary}`),
        marginLeft: theme.spacing(0.5),
      },
  },

  divider: {
    margin: theme.spacing(1),
    backgroundColor: `${theme.border.primary}`,
    "&.MuiToggleButtonGroup-groupedHorizontal": {
      marginLeft: theme.spacing(1),
    },
  },
}));
