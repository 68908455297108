export enum SyncType {
  Clients = 1,
  MapTiles = 2,
  Assets = 3,
  MapFeatures = 4,
  Jobs = 5,
  Flights = 6,
  Projects = 7,
  Tags = 8,
  Lines = 9,
}

export enum SyncState {
  UpToDate = 0,
  Checking = 1,
  Syncing = 2,
  Errored = 3,
}

export interface SyncItemStatus {
  syncState?: SyncState;
  lastSynced?: string;
  progressPercentage?: number;
  lastError?: string;
}

export type SyncItemParameter = null | "clientId";

export type SyncStatus = {
  [s in string]?: SyncItemStatus;
};
