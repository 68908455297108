import { useMemo } from "react";
import type {
  Content,
  LayoutProps,
  Mode,
} from "../../components/general/types/Modify";
import { isSuperTenantRole } from "../../helpers";
import { USERS_ENDPOINT } from "../../libs/config";
import { paths } from "../../navigation";
import { userStrings as strings } from "../../resources/strings";
import { RouteProps } from "../../types";
import type { UserData } from "../../types/documents";
import { ModifyContainer } from "../general";
import { constraints } from "./";
import { mapToDropdownOptions } from "./functions/onLoadModify";
import { componentConfigurations, getDropdownMappings } from "./resources";
import { useUserData } from "./useUserData";

const putEndpoint = `${USERS_ENDPOINT}/submit`;
const queryEndpoint = `${USERS_ENDPOINT}`;

export interface Props extends RouteProps {
  mode: Mode;
}
type Component = (props: Props) => JSX.Element;

export const UserModify: Component = ({ mode, ...routeProps }) => {
  const { jwt, user: routeUser } = routeProps;
  const user = useMemo(() => routeUser, [routeUser]);
  const [loading, roles, clients] = useUserData({
    jwt,
    user,
    roleType: user?.isSuperTenant() ? "All" : "Client",
  });

  const getClientId = () => {
    if (!user) return "";
    if (user.isSuperTenant()) return "";

    return user.tenantId;
  };

  const initialData = {
    userId: "",
    username: "",
    email: "",
    roleId: "",
    forename: "",
    surname: "",
    clientId: getClientId(),
    created: "",
    updated: "",
  };

  return (
    <ModifyContainer<UserData>
      {...routeProps}
      putEndpoint={putEndpoint}
      queryEndpoint={queryEndpoint}
      mode={mode}
      initialData={initialData}
      componentConfiguration={({ roleId }) => {
        const config = [];

        const isPdgUser = user?.isSuperTenant();
        const isAdminRole = isSuperTenantRole(roles, roleId);

        const showClientDropdown = isPdgUser && !isAdminRole;

        const dropdownOptions: Array<any[]> = [roles];
        if (showClientDropdown) {
          dropdownOptions.push(clients);
        }
        const dropdownMappings = mapToDropdownOptions(
          dropdownOptions,
          getDropdownMappings(user)
        );

        var clientContent: (Content<UserData> & LayoutProps) | undefined =
          showClientDropdown
            ? {
                controltype: "dropdown",
                name: "clientId",
                label: strings.labels.client,
                options: dropdownMappings.clients,
                required: true,
              }
            : undefined;

        config.push(
          componentConfigurations.details(
            mode,
            dropdownMappings.roles,
            jwt,
            clientContent
          )
        );

        return config;
      }}
      constraints={constraints}
      redirectPath={paths.users.view}
      loading={loading}
    />
  );
};
