/// <reference path='./olgm.d.ts'>
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import "ol-layerswitcher/dist/ol-layerswitcher.css";
import "ol/ol.css";
import "olgm/olgm.css";
import "../../../types/google";
import { useStyles } from "../styles";

type LayerNavItemProps = {
  layerDropdown: any;
  handleSelectLayer: any;
  selectedLayer: any;
};

export const LayerNavItem = ({
  layerDropdown,
  handleSelectLayer,
  selectedLayer,
}: LayerNavItemProps): JSX.Element => {
  const classes = useStyles();

  return layerDropdown.length > 1 ? (
    <>
      <Grid item>
        <Typography className={classes.font}>Layer:</Typography>
      </Grid>
      <Grid item>
        <FormControl className={classes.dropdown}>
          <Select
            disableUnderline
            label="Layer"
            onChange={handleSelectLayer}
            value={selectedLayer}
          >
            {layerDropdown.map((layer: any) => {
              return (
                <MenuItem key={layer} value={layer}>
                  {layer}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
    </>
  ) : (
    <></>
  );
};
