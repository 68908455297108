import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@material-ui/core";
import { useState } from "react";

interface Props {
  gridRef?: boolean;
  open: boolean;
  onClose: () => void;
  onSubmit: (coords: number[]) => void;
}

type Component = (props: Props) => JSX.Element;

export const CoordsInputDialog: Component = ({ open, onClose, onSubmit }) => {
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);

  const submitDialog = () => {
    if (isFinite(x) && isFinite(y)) {
      onSubmit([x, y]);
      closeDialog();
    }
  };

  const closeDialog = () => {
    setX(0);
    setY(0);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      fullWidth={true}
      BackdropProps={{ style: { backgroundColor: "transparent" } }}
    >
      <DialogTitle>Enter Coordinates</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs>
            <TextField
              InputLabelProps={{ shrink: true }}
              label="X"
              required
              autoFocus
              type={"number"}
              value={isFinite(x) ? x : ""}
              error={!isFinite(x)}
              onChange={(event) => setX(parseFloat(event.target.value))}
            />
          </Grid>
          <Grid item xs>
            <TextField
              InputLabelProps={{ shrink: true }}
              label="Y"
              required
              type={"number"}
              value={isFinite(y) ? y : ""}
              error={!isFinite(y)}
              onChange={(event) => setY(parseFloat(event.target.value))}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button onClick={submitDialog} color="primary" variant="contained">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
