import { Button, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { useCancelToken } from "../../hooks/general";
import { paths } from "../../navigation";
import * as api from "../../services/gpxTrace.service";
import { FlightData } from "../../types/documents/Flight";
import BreadcrumbHeader from "../general/BreadcrumbHeader";

const useStyles = makeStyles((theme) => ({
  all: {
    fontWeight: theme.spacing(80),
    boxShadow: theme.spacing(0, 0, 0, 0),
    height: theme.spacing(5),
    width: theme.spacing(24.375),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(2),
    borderRadius: 0,
  },
}));

interface Props {
  flight: FlightData;
}

type Component = (props: Props) => JSX.Element;

export const GPSViewBreadcrumb: Component = ({ flight }) => {
  const classes = useStyles();
  const cancelToken = useCancelToken();

  const download = async () => {
    const url = await api.getGPXURL(cancelToken, flight.gpxKey, "download");
    window.location.assign(url);
  };

  const crumbs = [
    // For now, switch to only showing prev > current rather than full path
    //
    // { name: "Projects", link: paths.projects.list },
    // {
    //   //@ts-ignore
    //   name: flight.package.project.jobCode,
    //   //@ts-ignore
    //   link: paths.projects.view(flight.package.project.id),
    // },
    // {
    //   name: flight.package!.name,
    //   link: paths.packages.view(flight.package!.id),
    // },
    {
      name: "Flights",
      link: paths.packages.viewFlights(flight.package!.id),
    },
    {
      name: flight.reference,
    },
  ];

  return (
    <BreadcrumbHeader crumbs={crumbs}>
      <Button
        className={clsx(classes.all)}
        color="primary"
        variant="contained"
        onClick={download}
        title="download"
      >
        Download GPX File
      </Button>
    </BreadcrumbHeader>
  );
};
