import { Layer } from "ol/layer";
import { Options } from "ol/layer/Layer";
import LayerRenderer from "ol/renderer/Layer";
import VectorSource from "ol/source/Vector";
// import "webgl-lint";
import { WebGLLinesRenderer } from "./WebGLLinesRenderer";

export interface WebGLLinesLayerOptions extends Options<VectorSource> {}

export class WebGLLinesLayer extends Layer<VectorSource> {
  protected createRenderer(): LayerRenderer<WebGLLinesLayer> {
    return new WebGLLinesRenderer(this);
  }
}
