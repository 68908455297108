import {
  ApiContextProvider,
  FormDesignerContainer,
} from "@pulsion/forms-designer-2";
import { FieldConfiguration } from "@pulsion/forms-designer-2/build/JobForm/types";
import { useParams } from "react-router-dom";
import "semantic-ui-css/semantic.min.css";
import { Mode } from "../../components/general/types/Modify";
import { formDesignerApi } from "../../services/formDesigner.service";
import { RouteProps } from "../../types";
import ButtonConfigurationBreadcrumbHeader from "./ButtonConfigurationBreadcrumbHeader";
import { formDesignerTheme } from "./themes/formDesigner";
import { useFormDesignerData } from "./useFormDesignerData";

export interface Props extends RouteProps {
  mode: Mode;
}

export type FormDesignerProps = {
  name: string;
  clientId: string;
  assessmentTypeId: string;
};
export const ButtonConfigurationModify = ({ mode, ...routeProps }: Props) => {
  const { jwt } = routeProps;
  const { formId } = useParams<{ formId?: string }>();
  const [clients, assessmentTypes, loading] = useFormDesignerData(jwt);
  console.log("form designer data", {
    clients,
    assessmentTypes,
  });

  const initialData = {
    assessmentTypeId: "",
    clientId: "",
    name: "",
    active: true,
  };

  const fieldConfiguration: FieldConfiguration[] = [
    {
      header: "Types",
      controls: [
        {
          caption: "Client",
          id: "clientId",
          required: true,
          type: "dropdown",
          visible: true,
          options: clients,
        },
        {
          caption: "Assessment Type",
          id: "assessmentTypeId",
          required: true,
          type: "dropdown",
          visible: true,
          options: assessmentTypes,
        },
      ],
    },
  ];

  if (formId) {
    fieldConfiguration[0].controls.push({
      caption: "Active",
      id: "active",
      required: true,
      type: "check",
      visible: true,
      defaultValue: false,
    });
  }

  return loading ? (
    <></>
  ) : (
    <>
      <ButtonConfigurationBreadcrumbHeader
        mode={mode}
      ></ButtonConfigurationBreadcrumbHeader>
      <ApiContextProvider api={formDesignerApi}>
        <FormDesignerContainer<FormDesignerProps>
          formId={formId ?? ""}
          theme={formDesignerTheme}
          initialData={initialData}
          controlTypes={[
            "buttonSet",
            "check",
            "date",
            "dropdown",
            "groupCheckboxes",
            "label",
            "number",
            "radio",
            "subsection",
            "text",
            "time",
          ]}
          fieldConfiguration={fieldConfiguration}
        ></FormDesignerContainer>
      </ApiContextProvider>
    </>
  );
};
