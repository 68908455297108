import { ChangeEvent, useEffect, useState } from "react";
import { Loader } from "..";
import { sortByKey as byKey } from "../../../helpers";
import { useCancelToken } from "../../../hooks/general";
import { notifications } from "../../../libs/notifications";
import { appStrings as strings } from "../../../resources/strings";
import { listImages } from "../../../services/images.service";
import { ImageMetadata, ImageParameters } from "../../../types/documents";
import { ImageGrid, ImagesInfoHeader, NoImages } from "./Components";
import { ImageModal } from "./Components/ImageModal";

interface Props {
  pageSize?: number;
  handleUploads?: (event: ChangeEvent<HTMLInputElement>) => Promise<void>;
  imageParameters: ImageParameters;
  showInfoBar?: boolean;
}

export const BucketGallery = ({
  pageSize = 12,
  imageParameters,
  handleUploads,
  showInfoBar,
}: Props) => {
  const cancelToken = useCancelToken();

  const [imageMetadata, setAllImgData] = useState<ImageMetadata[]>([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const [loadingText, setLoading] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const { clientId, flightId, assetId, zoneId } = imageParameters;

  const handleThumbnailClick = (index: number) => {
    setSelectedImageIndex(index);
    setOpenModal(true);
  };

  useEffect(() => {
    const listFiles = async () => {
      setLoading("Loading files...");

      try {
        const images = await listImages(
          { clientId, flightId, assetId, zoneId },
          cancelToken
        );
        setAllImgData(images ?? []);
      } catch (e) {
        notifications.error(strings.notifications.messages.retrieveError);
      }

      setLoading("");
    };

    listFiles();
  }, [clientId, flightId, assetId, zoneId, cancelToken]);

  const renderImagesInfoHeader = () => {
    if (!clientId || !flightId) return null;

    const lastImage = [...imageMetadata].sort(byKey("taken", "asc")).pop();

    return (
      <ImagesInfoHeader
        totalImages={imageMetadata.length}
        lastUpdated={lastImage?.taken}
        handleUploads={handleUploads}
      />
    );
  };

  return (
    <>
      {showInfoBar && renderImagesInfoHeader()}

      <Loader active={!!loadingText} text={loadingText} inline>
        {imageMetadata.length ? (
          <>
            <ImageModal
              imgData={imageMetadata}
              selectedIndex={selectedImageIndex}
              setSelectedIndex={setSelectedImageIndex}
              open={openModal}
              onClose={() => setOpenModal(false)}
            />

            <ImageGrid
              imageMetadata={imageMetadata}
              onImageClick={handleThumbnailClick}
              pageSize={pageSize}
            />
          </>
        ) : (
          <NoImages imageParameters={imageParameters} />
        )}
      </Loader>
    </>
  );
};
