import { Feature } from "ol";
import { click } from "ol/events/condition";
import { Geometry } from "ol/geom";
import { Select } from "ol/interaction";

export const getSelect = (
  layerName: string,
  onSelect: (selected: Feature<Geometry>) => void
) => {
  const select = new Select({
    condition: click,
    layers: (layer) => {
      return layer.get("title") === layerName;
    },
    style: null,
  });

  select.on("select", (event) => {
    onSelect(event.selected[0]);
  });

  return select;
};
