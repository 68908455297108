/// <reference path='./olgm.d.ts'>
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import "ol-layerswitcher/dist/ol-layerswitcher.css";
import "ol/ol.css";
import "olgm/olgm.css";
import { ClientData } from "../../../types/documents";
import "../../../types/google";
import { Loader } from "../../general";
import { useStyles } from "../styles";

type ClentNavItemProps = {
  flight: any;
  userClientId: any;
  clientsLoading: any;
  handleSelectClient: any;
  selectedClient: any;
  clients: Record<string, ClientData>;
};

export const ClientNavItem = ({
  flight,
  userClientId,
  clientsLoading,
  handleSelectClient,
  selectedClient,
  clients,
}: ClentNavItemProps): JSX.Element => {
  const classes = useStyles();
  return !flight ? (
    <>
      {!userClientId && (
        <>
          <Grid item>
            <Typography className={classes.font}>Client:</Typography>
          </Grid>
          <Grid item>
            <Loader active={clientsLoading} inline={true}>
              <FormControl className={classes.dropdown}>
                <Select
                  disableUnderline
                  label="Client"
                  onChange={handleSelectClient}
                  value={selectedClient || ""}
                >
                  {Object.values(clients)
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((client) => (
                      <MenuItem key={client.id} value={client.id}>
                        {client.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Loader>
          </Grid>
        </>
      )}
    </>
  ) : (
    <></>
  );
};
