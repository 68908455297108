import { CancelToken } from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import { isOfflineApp } from "../../helpers";
import { useAssetDefects } from "../../hooks/defects/useAssetDefects";
import { useCreateDefect } from "../../hooks/defects/useCreateDefect";
import { notifications } from "../../libs/notifications";
import { defectStrings as strings } from "../../resources/strings";
import * as defectsApi from "../../services/defects.service";
import * as packageAssetsApi from "../../services/packages.service";
import { PackageAsset } from "../../types/documents";
import { ConfirmModal } from "../general/ConfirmModal";
import { AssetStatus } from "../map/helpers/LayerFunctions";
import { ClearDefectModal } from "./ClearDefectModal";
import { CreateFlightWarning } from "./CreateFlightWarning";
import { DefectTypeList } from "./DefectTypeList";
import { DefectsList } from "./DefectsList";
import { DefectsToolbarButtons } from "./DefectsToolbarButtons";
import { DefectsToolbarHeader } from "./DefectsToolbarHeader";
import { JobRendererModal } from "./JobRendererModal";
import { LatestConditionAssessment } from "./LatestConditionAssessment";

enum Mode {
  Default,
  Creating,
  Updating,
  Deleting,
  Clearing,
}

interface Props {
  assetIds: string[];
  flightId: string | null;
  packageId?: string;
  defectCount: number;
  updateFeatureStatus: (
    assetIds: string[],
    status: AssetStatus,
    defectCount?: number
  ) => string[];
  jwt: string;
  cancelToken: CancelToken;
}

type Component = (props: Props) => JSX.Element;

export const DefectsToolBar: Component = ({
  assetIds,
  flightId,
  packageId,
  defectCount,
  updateFeatureStatus,
  jwt,
  cancelToken,
}) => {
  const [mode, setMode] = useState(Mode.Default);

  // selectedDefectId is one of:
  // - empty
  // - a defect selected for deletion
  // - a defect being created/edited
  const [selectedDefectId, setSelectedDefectId, createDefect] = useCreateDefect(
    { flightId, assetIds, jwt, cancelToken }
  );
  const clearModalOpen = mode === Mode.Clearing;
  const showDeleteConfirmation = mode === Mode.Deleting;
  const jobRendererOpen = [Mode.Creating, Mode.Updating].includes(mode);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [selectedAssetId, setSelectedAssetId] = useState<string | null>(null);

  // Whenever setDefectListChangesPending is called with True, the defects will be refreshed.
  // It must then either be reset by calling with false or waiting util after the refresh
  // has finished before accepting another refresh request.
  const [defects, , , setDefectListChangesPending] = useAssetDefects({
    defectCount,
    assetIds,
    jwt,
    cancelToken,
  });
  const [defectsToDelete, setDefectsToDelete] = useState<string[]>([]);

  const [packageName, setPackageName] = useState("");

  useEffect(() => {
    async function getJobCode() {
      if (!packageId) return;
      const pkg = await packageAssetsApi.getPackage("", cancelToken, packageId);
      setPackageName(pkg?.name ?? "");
    }
    getJobCode();
  }, [packageId, cancelToken]);

  // Picks between 2 static string instances, does not need Memo.
  const areYouSureMessage =
    defectsToDelete.length > 1
      ? strings.labels.areYouSureMulti
      : strings.labels.areYouSure;

  const hasDefectsToDelete = useMemo(
    () =>
      defectsToDelete.some((defect) => {
        return defect !== null && defect !== undefined && defect !== "";
      }),
    [defectsToDelete]
  );

  const closeDeleteConfirmation = useCallback(() => {
    setDefectsToDelete([]);
    setSelectedDefectId(() => "");
    setSelectedAssetId(() => "");
    setMode(Mode.Default);
  }, [setSelectedDefectId, setMode]);

  function showClearOrDeleteModal(defectId: string, assetId: string) {
    if (isOfflineApp()) {
      //Delete the defect in the offline app - it removes it from the database
      setSelectedDefectId(defectId);
      setDefectsToDelete([defectId]);
      setSelectedAssetId(assetId);
      setMode(Mode.Deleting);
    } else {
      //Clear the defect in the web app - it marks it as inactive and can add a work order
      setSelectedDefectId(defectId);
      setMode(Mode.Clearing);
    }
  }

  function showDefectInfoModal(defectId: string) {
    setSelectedDefectId(defectId);
    setMode(Mode.Updating);
  }

  async function clearDefect(defectId: string, workOrder: any) {
    await defectsApi.clearDefect(defectId, workOrder, jwt, cancelToken);
    updateDefectCounts([defectId]);
    setDefectListChangesPending(true);
  }

  const updateDefectCounts = useCallback(
    (removedDefects: string[], addedDefects: string[] = []) => {
      let remainingDefects = defects.filter(
        (d) => !removedDefects.includes(d.id) && !addedDefects.includes(d.id)
      );

      if (packageId) {
        remainingDefects = remainingDefects.filter(
          (d) => d.flight.packageId === packageId
        );
      }

      assetIds.forEach((id) => {
        const defectsForAsset = remainingDefects.filter(
          (d) => d.assetId === id
        );

        updateFeatureStatus(
          [id],
          AssetStatus.Defective,
          defectsForAsset.length + addedDefects.length
        );
      });
    },
    [assetIds, defects, packageId, updateFeatureStatus]
  );

  const deleteDefect = useCallback(async () => {
    if (!hasDefectsToDelete && !selectedAssetId) return;
    setDeleteLoading(true);

    try {
      const chunkSize = 250;
      const init: string[][] = [];
      const defectChunks = defectsToDelete.reduce(
        (resultArray, item, index) => {
          const chunkIndex = Math.floor(index / chunkSize);
          if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [];
          }

          resultArray[chunkIndex].push(item);
          return resultArray;
        },
        init
      );
      for (const chunk of defectChunks) {
        await defectsApi.deleteDefects(chunk, jwt, cancelToken);
      }

      updateDefectCounts(defectsToDelete);

      notifications.success(
        defectsToDelete.length > 1
          ? `${defectsToDelete.length} defects deleted`
          : "Defect deleted"
      );
    } catch (err) {
      notifications.error("Failed to delete all defects");
    } finally {
      setDeleteLoading(false);
      closeDeleteConfirmation();
      setDefectListChangesPending(true);
    }
  }, [
    cancelToken,
    closeDeleteConfirmation,
    defectsToDelete,
    hasDefectsToDelete,
    jwt,
    setDefectListChangesPending,
    updateDefectCounts,
    selectedAssetId,
  ]);

  async function createNewDefect(defectTypeId: string) {
    await createDefect(defectTypeId);

    //Open the job renderer, after the new job is saved to the database
    setMode(Mode.Creating);
    setDefectListChangesPending(true);
  }

  const setAssetsCompleted = async () => {
    if (!flightId) return;
    try {
      const updatedAssets = updateFeatureStatus(
        assetIds,
        AssetStatus.Completed
      );

      const packageAssets: PackageAsset[] = updatedAssets.map((assetId) => ({
        assetId,
        defective: false,
        patrolled: true,
        footPatrol: false,
      }));

      await packageAssetsApi.updatePackageAssets(
        jwt,
        cancelToken,
        flightId,
        packageAssets
      );
      setDefectListChangesPending(true);
    } catch (err) {
      notifications.error("Failed to Complete All Assets");
    }
  };

  const markAsFootPatrol = async () => {
    if (!flightId) return;
    try {
      const updatedAssets = updateFeatureStatus(
        assetIds,
        AssetStatus.FootPatrol
      );

      const packageAssets: PackageAsset[] = updatedAssets.map((assetId) => ({
        assetId,
        defective: false,
        patrolled: false,
        footPatrol: true,
      }));

      await packageAssetsApi.updatePackageAssets(
        jwt,
        cancelToken,
        flightId,
        packageAssets
      );
      setDefectListChangesPending(true);
    } catch (err) {
      notifications.error("Failed to Mark as Foot Patrol");
    }
  };

  const markAsDefective = async (selectedDefectId: string) => {
    if (!flightId || !selectedDefectId) return;
    try {
      updateDefectCounts([], [selectedDefectId]);

      const packageAssets: PackageAsset[] = assetIds.map((assetId) => ({
        assetId,
        defective: true,
        patrolled: false,
        footPatrol: false,
      }));

      await packageAssetsApi.updatePackageAssets(
        jwt,
        cancelToken,
        flightId,
        packageAssets,
        selectedDefectId
      );
      setDefectListChangesPending(true);
    } catch (err) {
      notifications.error("Failed to set Assets as Defective");
    }
  };

  const clearAllDefects = useCallback(() => {
    if (defects.length === 0) return;
    setDefectsToDelete(defects.map((defect) => defect.id));
    setMode(Mode.Deleting);
  }, [defects]);

  return (
    <>
      {isOfflineApp() && (
        <>
          <DefectsToolbarHeader />
          {flightId ? (
            <>
              <DefectsToolbarButtons
                clearAllDefects={clearAllDefects}
                setAssetsCompleted={setAssetsCompleted}
                markAsFootPatrol={markAsFootPatrol}
              />

              <DefectTypeList
                flightId={flightId}
                createNewDefect={(defectTypeId: string) =>
                  createNewDefect(defectTypeId)
                }
                cancelToken={cancelToken}
                loading={false}
              />
            </>
          ) : (
            <CreateFlightWarning />
          )}
        </>
      )}
      {!isOfflineApp() && (
        <LatestConditionAssessment assetId={assetIds[0]} jwt={jwt} />
      )}
      {packageId && (
        <DefectsList
          defects={defects.filter((d) => d.flight.packageId === packageId)}
          showClearOrDeleteModal={showClearOrDeleteModal}
          showDefectInfoModal={showDefectInfoModal}
          header={strings.header.packageDefects(packageName)}
          height={200}
        />
      )}
      <DefectsList
        defects={defects.filter((d) => d.flight.packageId !== packageId)}
        showClearOrDeleteModal={showClearOrDeleteModal}
        showDefectInfoModal={showDefectInfoModal}
        header={
          packageId ? strings.header.pastDefects : strings.header.allDefects
        }
        height={packageId ? 200 : 400}
      />
      <ClearDefectModal
        confirmButtonLabel={strings.labels.clearDefect}
        clearDefect={(defectId: string, workOrder: any) =>
          clearDefect(defectId, workOrder)
        }
        defectId={selectedDefectId}
        open={clearModalOpen}
        handleClose={() => setMode(Mode.Default)}
        loading={false}
      />
      <ConfirmModal
        confirmButtonLabel={strings.labels.deleteDefect}
        onConfirm={deleteDefect}
        message={areYouSureMessage}
        open={showDeleteConfirmation}
        handleClose={closeDeleteConfirmation}
        loading={deleteLoading}
      />
      <JobRendererModal
        defectId={selectedDefectId}
        open={jobRendererOpen}
        handleClose={() => {
          if (mode === Mode.Creating) {
            markAsDefective(selectedDefectId);
          }
          setMode(Mode.Default);
        }}
        loading={false}
      />
    </>
  );
};
