import { Feature } from "ol";
import { useContext, useRef, useState } from "react";
import { mapStrings } from "../../../resources/strings";
import { RouteProps } from "../../../types";
import { AssetDetailsModal } from "../helpers/AssetDetailsModal";
import { AvoidanceZoneModal } from "../helpers/AvoidanceZoneModal";
import { DefectsToolbarModal } from "../helpers/DefectsToolbarModal";
import { FeatureSelectorModal } from "../helpers/FeatureSelectorModal";
import {
  AssetStatus,
  updateMapLayersPointProperties,
} from "../helpers/LayerFunctions";
import { PointOfInterestModal } from "../helpers/PointOfInterestModal";
import MapContext from "../MapContext";
interface Props extends RouteProps {
  assetSelectType: string;
  clientName: string;
  flightId: string | null;
  packageId?: string;
}

export const FeatureSelectorLayer = ({
  assetSelectType,
  clientName,
  flightId,
  packageId,
  ...routeProps
}: Props) => {
  const map = useContext(MapContext);

  const [feature, setFeature] = useState<Feature | null>(null);
  const [layer, setLayer] = useState("");
  // Div used as parent component for dialogs to allow proper positioning
  const ref = useRef<HTMLDivElement>(null);

  const closeFeature = () => {
    setFeature(null);
  };

  const updatePointProperties = (
    pointIds: string[],
    status: AssetStatus,
    defectCount?: number
  ) => {
    return updateMapLayersPointProperties(map, pointIds, status, defectCount);
  };

  const handleFeature = (layer: string, feature: Feature) => {
    setLayer(layer);
    setFeature(feature);
  };

  const renderSelectedFeatureModal = () => {
    console.log({ layer, feature });
    if (!feature) return <></>;

    switch (layer) {
      case mapStrings.layers.status(clientName):
      case mapStrings.layers.search:
        return assetSelectType === mapStrings.viewTools.queryAsset ? (
          <AssetDetailsModal
            jwt={routeProps.jwt}
            assetId={feature.getProperties().id}
            onClose={closeFeature}
            container={ref.current}
          />
        ) : (
          <DefectsToolbarModal
            flightId={flightId}
            packageId={packageId}
            jwt={routeProps.jwt}
            singleAsset={feature}
            closeSingleAsset={closeFeature}
            updatePointProperties={updatePointProperties}
            assetSelectType={assetSelectType}
            clientName={clientName}
            container={ref.current}
          />
        );

      case mapStrings.layers.assets(clientName):
        return (
          <AssetDetailsModal
            container={ref.current}
            jwt={routeProps.jwt}
            assetId={feature.getProperties().id}
            onClose={closeFeature}
          />
        );

      case mapStrings.layers.avoidanceZone:
        return (
          <AvoidanceZoneModal
            avoidanceZone={feature}
            mode="update"
            onClose={closeFeature}
            {...routeProps}
          />
        );

      case mapStrings.layers.pointOfInterest:
        return (
          <PointOfInterestModal
            pointOfInterest={feature}
            mode="update"
            onClose={closeFeature}
            {...routeProps}
          />
        );

      default:
        return <></>;
    }
  };

  return (
    <div ref={ref} style={{ overflow: "clip" }}>
      {renderSelectedFeatureModal()}

      {assetSelectType === mapStrings.viewTools.selectMultipleAssets ? (
        <DefectsToolbarModal
          flightId={flightId}
          packageId={packageId}
          jwt={routeProps.jwt}
          singleAsset={feature}
          closeSingleAsset={closeFeature}
          updatePointProperties={updatePointProperties}
          assetSelectType={assetSelectType}
          clientName={clientName}
          container={ref.current}
        />
      ) : (
        <FeatureSelectorModal
          clientName={clientName}
          map={map}
          handleFeature={handleFeature}
          container={ref.current}
        />
      )}
    </div>
  );
};
