import { appStrings } from "./app";

export const projectStrings = {
  global: { ...appStrings },
  header: {
    projects: "Projects",
    projectDetails: "Project Details",
    createProject: "Create Project",
    viewProject: "View Project",
    editProject: "Edit Project",
    packages: "Packages",
  },
  labels: {
    jobCode: "Job Code",
    client: "Client",
    projectType: "Project Type",
    save: "Save",
    reset: "Reset",
    edit: "Edit",
    delete: "Delete",
    numberOfPackages: "No. Packages",
    dateCreated: "Date Created",
    createdBy: "Created By",
    lastUpdated: "Last Updated",
    lastUpdatedBy: "Updated By",
    description: "Description",
    createProject: "Create Project",
    clear: "Clear",
    groupedBy: "Grouped By",
  },
  messages: {
    confirmDelete:
      "Are you sure you want to permanently delete this project and all of its associated packages?",
  },
  notifications: {
    deleted: "Successfully deleted project.",
    failedDelete: "Failed to delete project.",
  },
};
