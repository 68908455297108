import { useState } from "react";
import { ChangeHandler } from "../../components/general/types/Modify";
import {
  ConditionSearch,
  SearchKeys,
} from "../../components/map/search/SearchPanel";

export const useConditionSearchData = (initSearch: ConditionSearch) => {
  const [data, setData] = useState<ConditionSearch>(initSearch);

  const handleChange: ChangeHandler = (e) => {
    const { target } = e;
    const { name, value, checked } = target;
    if (!name) return;

    const searchKeyName = name as SearchKeys;

    const newSearch = data;

    [
      resetGradings,
      resetComponent,
      resetCircuits,
      resetGroupByValueFromTo,
      resetGroupByValue,
    ].forEach((handler) => {
      handler(searchKeyName, newSearch);
    });

    setData(() => ({
      ...newSearch,
      [name]: target.hasOwnProperty("checked") ? checked : value,
    }));
  };

  return [data, handleChange] as const;
};

const resetGroupByValueKeys: SearchKeys[] = ["groupByKey"];
const resetGroupByValueFromToKeys: SearchKeys[] = [
  ...resetGroupByValueKeys,
  "groupByValue",
];
const resetCircuitKeys: SearchKeys[] = [
  ...resetGroupByValueFromToKeys,
  "groupByValueFrom",
  "groupByValueTo",
];
const resetComponentKeys: SearchKeys[] = [...resetCircuitKeys];
const resetGradingsKeys: SearchKeys[] = [...resetComponentKeys, "components"];

const resetGradings = (searchKey: SearchKeys, searchData: ConditionSearch) => {
  if (resetGradingsKeys.includes(searchKey)) {
    searchData.gradings = [];
  }
};

const resetComponent = (searchKey: SearchKeys, searchData: ConditionSearch) => {
  if (resetComponentKeys.includes(searchKey)) {
    searchData.components = "";
  }
};

const resetCircuits = (searchKey: SearchKeys, searchData: ConditionSearch) => {
  if (resetCircuitKeys.includes(searchKey)) {
    searchData.circuitId = [];
  }
};

const resetGroupByValueFromTo = (
  searchKey: SearchKeys,
  searchData: ConditionSearch
) => {
  if (resetGroupByValueFromToKeys.includes(searchKey)) {
    searchData.groupByValueFrom = "";
    searchData.groupByValueTo = "";
  }
};

const resetGroupByValue = (
  searchKey: SearchKeys,
  searchData: ConditionSearch
) => {
  if (resetGroupByValueKeys.includes(searchKey)) {
    searchData.groupByValue = [];
  }
};
