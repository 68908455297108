import {
  Divider,
  Grid,
  List,
  ListItem,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { useCancelToken } from "../../../hooks/general";
import { assetDetailsStrings as strings } from "../../../resources/strings/assets";
import { getAssetDetails } from "../../../services/assets.service";
import { AssetDetails } from "../../../types/documents/Assets";
import { Loader } from "../../general";

interface Props {
  id: string;
  jwt: string;
}

type Component = (props: Props) => JSX.Element;

export const AssetPropertiesList: Component = ({ id, jwt }) => {
  const cancelToken = useCancelToken();
  const [assetDetails, setAssetDetails] = useState<AssetDetails>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      if (!id) return;

      const assetDetails = await getAssetDetails(jwt, cancelToken, id);
      if (!assetDetails) return;

      setAssetDetails(assetDetails);
      setLoading(false);
    };

    getData();
  }, [id, jwt, cancelToken]);

  const renderAssetDetails = () => {
    if (!assetDetails) return <></>;
    return (
      <>
        {renderItem(
          strings.labels.towerName,
          assetDetails.assetContainer ? assetDetails.assetContainer.name : ""
        )}
        {renderItem(
          strings.labels.towerType,
          assetDetails.assetType ? assetDetails.assetType.name : ""
        )}
        {renderItem(strings.labels.towerSide, assetDetails.towerSide)}
        {renderItem(strings.labels.circuitId, assetDetails.circuitId)}
        {renderItem(strings.labels.name, assetDetails.name)}
        {renderItem(strings.labels.voltage, assetDetails.transmissionVoltage)}

        <Divider style={{ margin: "8px" }} />

        {Object.entries(assetDetails.properties)
          .sort(([aKey], [bKey]) => aKey.localeCompare(bKey))
          .map(([key, val]) => renderItem(key, val as string))}
      </>
    );
  };

  const renderItem = (property: string, value: string | number) => {
    return (
      <ListItem disableGutters>
        <Grid container>
          <Grid item xs={7}>
            <Tooltip title={<Typography>{property}</Typography>}>
              <Typography
                noWrap
                style={{ color: "#bbb", paddingRight: "8px" }}
                align="right"
              >
                {property}:
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={5}>
            <Typography style={{ textTransform: "capitalize" }}>
              {value}
            </Typography>
          </Grid>
        </Grid>
      </ListItem>
    );
  };

  return (
    <Loader inline active={loading}>
      <List dense>{renderAssetDetails()}</List>
    </Loader>
  );
};
